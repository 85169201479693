import React from 'react';
import { ProductGreyCard } from './styles';
import { AimTypography, AimIconAndTextButton } from '@aim/components';
import Grid from '@material-ui/core/Grid';

const handleDownload = async (link) => {
  try {
    window.open(link, '_blank');
  } catch (error) {
    console.error(error);
  }
};

const CertificationsCardContainer = ({
  certifications,
  subTitle,
  downloadText,
}) => {
  return (
    <Grid container spacing={1}>
      {certifications?.map((cert, idx) => (
        <Grid item md={6} sm={12} xs={12} key={`CERTIFICATION-${idx}`}>
          <ProductGreyCard
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AimTypography isUpperCase variant="h4">
              {cert?.name}
            </AimTypography>
            <AimTypography variant="text" textAlign="center">
              {subTitle}
            </AimTypography>
            <AimIconAndTextButton
              variant="primary"
              typographyVariant="textBold"
              disabled={!cert?.link}
              text={downloadText}
              isUpperCase
              onClick={() => handleDownload(cert.link)}
            ></AimIconAndTextButton>
          </ProductGreyCard>
        </Grid>
      ))}
    </Grid>
  );
};
export { CertificationsCardContainer };

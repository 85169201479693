import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import {
  AimTypography,
  AimIconAndTextButton,
  AimSnackbar,
  AimSnackbarSeverity,
  theme,
} from '@aim/components';
import { constants } from '@aim/common';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import CheckoutRow from './checkout/CheckoutRow';
import CheckoutSummaryRow from './checkout/CheckoutSummaryRow';

import { useBuyOperations } from '../shared/buyOperationsGqlHelper';
import { isBefore } from 'date-fns';

const SponsorCheckout = ({
  checkoutBookings,
  setCheckoutBookings,
  onClickConfirm,
  paymentGateway,
  i18n,
  paymentType,
  sponsorGateway,
}) => {
  // Hooks
  const { eventId } = useParams();
  const { getEndDateOfEvent } = useBuyOperations();

  // States
  const [isEventEnd, setIsEventEnd] = useState(false);
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  //Effects
  useEffect(() => {
    fetchEndDateOfEvent();
  }, []);

  // Functions
  const fetchEndDateOfEvent = async () => {
    const res = await getEndDateOfEvent(eventId);
    const isEndBeforeToday = isBefore(new Date(res.end), new Date());
    setIsEventEnd(isEndBeforeToday);
  };

  const onDeleteRow = (id) => {
    setCheckoutBookings([...checkoutBookings].filter((cb) => cb.id !== id));
    setSnackbar({
      isOpen: true,
      severity: AimSnackbarSeverity.success,
      message: i18n.snackbar.removeBookingSuccess.label,
    });
  };

  const calculateVatAmount = () =>
    checkoutBookings
      .reduce((prev, curr) => (prev += curr.vatAmount * curr.quantity), 0)
      .toFixed(2);

  const calculateTotalAmount = () =>
    checkoutBookings
      .reduce((prev, curr) => (prev += curr.price * curr.quantity), 0)
      .toFixed(2);

  const CellWithAimTypography = ({ children }) => (
    <AimTypography
      variant={'h5'}
      boxStyle={{ fontWeight: 'bold', textWrap: 'wrap' }}
      textAlign="right"
    >
      {children}
    </AimTypography>
  );

  return (
    <div>
      {isEventEnd && (
        <div>
          <AimTypography variant="text" style={{ color: 'red' }}>
            {i18n.checkoutAlerts.eventIsOver.label}
          </AimTypography>
        </div>
      )}
      <div
        style={{
          backgroundColor: theme.colors.greyScale.backgroundGrey,
          paddingBottom: 10,
          paddingLeft: 35,
          paddingRight: 35,
          paddingTop: 10,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={3}
            xl={5}
            // style={{ maxWidth: 600 }}
          >
            <AimTypography variant={'h5'} boxStyle={{ fontWeight: 'bold' }}>
              {i18n.checkoutTable.columns.service.label}
            </AimTypography>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={1}
            style={{ whiteSpace: 'nowrap', maxWidth: 180 }}
          >
            <CellWithAimTypography>
              {i18n.checkoutTable.columns.unitaryNetPrice.label}
            </CellWithAimTypography>
          </Grid>
          <Grid item xs={1} style={{ whiteSpace: 'nowrap' }}>
            <CellWithAimTypography>
              {i18n.checkoutTable.columns.vatAmount.label}
            </CellWithAimTypography>
          </Grid>
          <Grid item xs={1}>
            <CellWithAimTypography>
              {i18n.checkoutTable.columns.quantity.label}
            </CellWithAimTypography>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={1}
            style={{ whiteSpace: 'nowrap', maxWidth: 180 }}
          >
            <CellWithAimTypography>
              {i18n.checkoutTable.columns.netPrice.label}
            </CellWithAimTypography>
          </Grid>
          <Grid item xs={1} style={{ whiteSpace: 'nowrap' }}>
            <CellWithAimTypography>
              {i18n.checkoutTable.columns.totalNetPrice.label}
            </CellWithAimTypography>
          </Grid>
          <Grid item xs={1} style={{ whiteSpace: 'nowrap' }}>
            <CellWithAimTypography>
              {i18n.checkoutTable.columns.amount.label}
            </CellWithAimTypography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        {checkoutBookings.map((cb) => (
          <CheckoutRow key={cb.id} booking={cb} onDelete={onDeleteRow} />
        ))}
        {paymentType === constants.PaymentTypes.BankTransfer ? (
          <Grid container direction="column" style={{ paddingTop: 20 }}>
            <AimTypography variant={'h5'} boxStyle={{ fontWeight: 'bold' }}>
              {i18n.page.checkout.bankTransferIndication}
            </AimTypography>
            <AimTypography variant="text">
              {`${i18n.page.checkout.iban}: ${
                sponsorGateway?.shop?.iban || ''
              }`}
            </AimTypography>
            <div style={{ display: 'flex' }}>
              <AimTypography
                variant="text"
                isUpperCase
                boxStyle={{ marginRight: 0 }}
              >
                {`${i18n.page.checkout.reasonForPayment}:`}&nbsp;
              </AimTypography>
              <AimTypography variant="text" boxStyle={{ marginLeft: 0 }}>
                {sponsorGateway?.shop?.ibanCausal?.split('\n').map((i) => (
                  <>
                    {i}
                    <br />
                  </>
                )) || ''}
              </AimTypography>
            </div>
            <AimTypography variant="text" isUpperCase>
              {`${i18n.page.checkout.addressedTo}: ${
                sponsorGateway?.shop?.addressedTo || ''
              }`}
            </AimTypography>
          </Grid>
        ) : // <div style={{ padding: 20, marginLeft: 10 }}>
        //  <AimTypography>IBAN {IBAN}</AimTypography>
        //</div> */}
        null}

        <div style={{ marginTop: '5%' }}>
          <CheckoutSummaryRow
            title={i18n.checkoutTable.summary.netTotal.label}
            label={checkoutBookings
              .reduce((a, v) => a + v.netPrice * v.quantity, 0)
              .toFixed(2)}
          />
          <CheckoutSummaryRow
            title={i18n.checkoutTable.summary.vat.label}
            label={calculateVatAmount()}
          />
          <CheckoutSummaryRow
            title={i18n.checkoutTable.summary.total.label}
            label={calculateTotalAmount()}
          />
        </div>
      </div>
      <div style={{ margin: '10px 0px' }}>
        <Grid container alignItems="center">
          <Grid item xs={10}></Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <AimIconAndTextButton
              isUpperCase
              variant="yellow"
              text={i18n.checkoutTable.buttons.buy.label}
              style={{
                // marginRight: '5%',
                paddingLeft: 30,
                paddingRight: 30,
                margin: 0,
              }}
              disabled={
                isEventEnd ||
                (checkoutBookings[0]?.paymentType ===
                  constants.PaymentTypes.CreditCard &&
                  (!paymentGateway || paymentGateway === ''))
              }
              onClick={onClickConfirm}
            >
              <ShoppingCartOutlinedIcon />
            </AimIconAndTextButton>
          </Grid>
        </Grid>
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};

export default SponsorCheckout;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { appState } from '@aim/common';
import {
  theme,
  SponsorWelcomePageTemplate,
  CustomIntl,
  useAimMediaQuery,
  AimTypography,
  AimIconAndTextButton,
} from '@aim/components';

import registerIcon from './assets/images/register.svg';

import { loadEventData } from './../../shared/utilities';
import SponsorRegistrationForm from './../SponsorRegistrationForm';
import { DataContainer } from './DataContainer';
import { translation } from './translation';

const useStyles = makeStyles(() => ({
  cardRoot: (props) => ({
    paddingTop: props.isMobile ? 'auto' : 'calc(50% - 50px)',
    alignItems: props.isMobile ? 'center' : 'start',
    width: props.isMobile ? '100%' : '180px',
    justifyContent: 'center',
    height: '180px',
    borderRadius: 1,
    cursor: 'pointer',
    boxShadow: '0 0 black',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.light.grey,
  }),
  cardContentRoot: {
    flex: 1,
    flexDirection: 'column',
    padding: '0!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SponsorWithData = ({ eventId, data, sponsorWelcomePageData }) => {
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorLanding(intl);
  const [isServicesBtnVisible, setIsServicesBtnVisible] = useState(false);
  const buttonServicesRef = useRef(null);

  const checkVisibility = () => {
    if (buttonServicesRef && buttonServicesRef.current) {
      const rect = buttonServicesRef.current.getBoundingClientRect();
      const isInViewport =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);
      if (isInViewport !== isServicesBtnVisible) {
        setIsServicesBtnVisible(isInViewport);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(checkVisibility, 0);
    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);
    checkVisibility();
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('resize', checkVisibility);
    };
  }, [isServicesBtnVisible]);

  const participation = appState.user.getValue()?.userAndParticipation
    ?.participation;

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;

  const isValidSponsor =
    !participation?.isDeleted && sponsor?.id && !sponsor?.isDeleted;

  const onAccessServicesClick = () =>
    sponsorWelcomePageData?.sponsorPriceRanges?.items.length &&
    !data?.priceRange?.id
      ? history.push(`/events/${eventId}/${data.id}/price-ranges`)
      : history.push(`/events/${eventId}/${data.id}/my-services`);

  return (
    <SponsorWelcomePageTemplate
      sponsorWelcomePage={sponsorWelcomePageData}
      sponsorProspectusLabel={i18n.sponsorProspectus} //TODO i18n
      actionButtons={
        isValidSponsor && (
          <Grid
            container
            alignItems="center"
            style={{
              height: '100%',
            }}
          >
            <Grid
              item
              xs
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{ width: 1, height: 1 }}
                ref={buttonServicesRef}
              ></div>
              <AimIconAndTextButton
                style={{
                  margin: 0,
                  ...(!isServicesBtnVisible && {
                    position: 'fixed',
                    bottom: 10,
                    zIndex: 1000,
                    color: 'white',
                  }),
                }}
                isUpperCase
                text={i18n.accessToServices}
                onClick={onAccessServicesClick}
              />
            </Grid>
          </Grid>
        )
      }
    />
  );
};

export const SponsorLanding = ({ eventId, data, sponsorUser }) => {
  if (
    !data?.sponsorService?.fields?.length ||
    sponsorUser?.fieldValues?.items?.length
  ) {
    // the user already logged in
    return (
      <DataContainer>
        <SponsorWithData
          eventId={eventId}
          data={sponsorUser}
          sponsorWelcomePageData={data}
        />
      </DataContainer>
    );
  }

  // the user never logged in
  return (
    <DataContainer
      backgroundColorOverride={theme.colors.greyScale.backgroundGrey}
    >
      <SponsorRegistrationForm
        sponsor={data}
        onSave={() => loadEventData({ eventId })}
      />
    </DataContainer>
  );
};

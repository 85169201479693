import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  AimTypography,
  AimIconAndTextButton,
  AimFormControlLabel,
  AimRadioButton,
  AimRadioForm,
  AimTextFieldForm,
  theme,
  styled,
} from '@aim/components';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import { constants } from '@aim/common';
import Grid from '@material-ui/core/Grid';
import { updateParticipationServices } from '../facultyGqlHelper';

// const formControlStyle = {
//   width: 'calc(100% - 15px)',
//   backgroundColor: 'white',
//   margin: '0px',
// };

const AimRadioCustom = styled(AimRadioForm)({
  '& .MuiIconButton-label': {
    '& span': {
      backgroundColor: theme.colors.greyScale.white,
    },
  },
});

const ParticipationConfirm = ({
  servicesState,
  onSuccess,
  onError,
  onCancel,
  i18n,
}) => {
  const _i18n = i18n.pax;

  //state
  // const [confirmPax, setConfirmPax] = useState(true);
  // const [paxOnSite, setPaxOnSite] = useState('no');
  const [facultyResponse, setFacultyResponse] = useState();
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      transferId: null,
      paxOnSite: 'no',
      confirmPax: 'true',
    },
  });

  const formControlStyle = { width: 'calc(100% - 20px)' };
  // Effects
  useEffect(() => {
    if (servicesState) {
      const raw = servicesState?.data?.participationFacultyResponse;
      let facultyResponse;
      if (raw) facultyResponse = JSON.parse(raw);
      if (facultyResponse && Object.keys(facultyResponse)?.length > 0) {
        reset({
          ...facultyResponse,
          confirmPax: facultyResponse.confirmPax ? 'true' : 'false',
        });
        // setPaxOnSite(facultyResponse.paxOnSite);
        // setConfirmPax(facultyResponse.confirmPax);
        setFacultyResponse(facultyResponse);
      }
    }
  }, [servicesState]);

  const onSubmit = async (data) => {
    // participationFacultyResponse contains data of SCIENTIFIC COMMITMENT and PARTICIPATION, in backOffice are in the same page

    const paxConfirmed = data.confirmPax === 'true';

    const nextData = { ...data, confirmPax: paxConfirmed };
    const prevData =
      JSON.parse(servicesState?.data?.participationFacultyResponse) || {};
    let dataToSend = {};
    if (Object.keys(data)?.length > 0) {
      dataToSend = {
        ...prevData,
        ...nextData,
        responseStatus: paxConfirmed
          ? constants.FacultyResponseStatus.PUBLISHED
          : constants.FacultyResponseStatus.REJECTED,
      };

      let participationFacultyResponse = JSON.stringify(dataToSend);

      try {
        await updateParticipationServices(
          {
            id: servicesState?.data.id,
            participationFacultyResponse,
            ...(!paxConfirmed
              ? {
                  facultyStatus: constants.FacultyResponseStatus.REJECTED,
                }
              : {
                  facultyStatus: constants.FacultyResponseStatus.DRAFT,
                }),
          },
          true
        );
        onSuccess();
      } catch (err) {
        console.error('err', err);
        onError();
      }
    }
  };

  const handleConfirmPax = (e) => {
    // setConfirmPax(e.target.value === 'true');
    setValue('confirmPax', e.target.value ? 'true' : 'false');
  };

  // const handlePaxOnSite = (e) => {
  //   setPaxOnSite(e.target.value);
  //   setValue('paxOnSite', e.target.value);
  // };

  const isReadOnlyConfirmPax = () => {
    // return (
    //   (facultyResponse?.confirmPax === true &&
    //     facultyResponse?.paxOnSite !== undefined) ||
    //   facultyResponse?.confirmPax === false
    // );
    return (
      facultyResponse?.responseStatus ===
        constants.FacultyResponseStatus.PUBLISHED ||
      facultyResponse?.responseStatus ===
        constants.FacultyResponseStatus.REJECTED
    );
  };

  const renderButtons = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '10px auto',
            ...formControlStyle,
          }}
        >
          {/* <div>
            <AimIconAndTextButton
              variant={'greyFill'}
              text={_i18n.buttons.cancel}
              onClick={onCancel}
            >
              <ReplayIcon />
            </AimIconAndTextButton>
          </div> */}
          <div>
            <AimIconAndTextButton
              variant={'greenFill'}
              text={_i18n.buttons.save}
              onClick={handleSubmit(onSubmit)}
              disabled={isReadOnlyConfirmPax()}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: 'flex', width: '100%' }}>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
          }}
        > */}
        <AimRadioCustom
          control={control}
          // onChange={handleConfirmPax}
          // {...{
          //   ...(!isReadOnlyConfirmPax() && {
          //     onChange: handleConfirmPax,
          //   }),
          // }}
          formControlWidth="calc(100% - 10px)"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          name="confirmPax"
          // defaultValue="true"
        >
          <AimFormControlLabel
            key={0}
            value={'true'}
            control={<AimRadioButton />}
            label={_i18n.confirmPax}
            formControlStyle={formControlStyle}
            disabled={isReadOnlyConfirmPax()}
          />
          {/* </AimRadioCustom> */}
          <div
            style={{
              marginLeft: '40px',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: 5,
              }}
            >
              <AimTypography variant={'textBold'} padding={0}>
                {_i18n.paxOnSite}
              </AimTypography>
              <div
                style={{
                  backgroundColor: theme.colors.greyScale.backgroundGrey,
                  margin: 5,
                  padding: 5,
                }}
              >
                <AimRadioForm
                  formControlWidth="calc(100% - 10px)"
                  control={control}
                  style={{
                    display: 'flex',
                  }}
                  name="paxOnSite"
                  defaultValue="no"
                  // {...{
                  //   ...(!isReadOnlyConfirmPax() && {
                  //     onClick: handlePaxOnSite,
                  //   }),
                  // }}
                >
                  <AimFormControlLabel
                    key={0}
                    value={'yes'}
                    control={<AimRadioButton />}
                    label={_i18n.yes}
                    formControlStyle={formControlStyle}
                    disabled={isReadOnlyConfirmPax()}
                  />
                  <AimFormControlLabel
                    key={1}
                    value={'no'}
                    control={<AimRadioButton />}
                    label={_i18n.no}
                    formControlStyle={formControlStyle}
                    disabled={isReadOnlyConfirmPax()}
                  />
                </AimRadioForm>
              </div>
            </div>

            <div>
              <AimTypography
                variant={'text'}
                boxStyle={{ margin: '15px 0px 0px 0px' }}
              >
                {_i18n.notes}
              </AimTypography>

              {/* NOTES */}
              <AimTextFieldForm
                // inputRef={'notes'}
                control={control}
                name="notes"
                // label={_i18n.pax.notes}
                formControlStyle={{
                  width: '100%',
                  margin: 0,
                  paddingLeft: 0,
                }}
                style={{
                  padding: 0,
                  height: 'auto',
                }}
                multiline
                rows={2}
                textfieldVariant={'secondary'}
                disabled={isReadOnlyConfirmPax()}
              />
            </div>
            <div></div>
          </div>
          {/* <AimRadioCustom
          control={control}
          // onChange={handleConfirmPax}
          {...{
            ...(!isReadOnlyConfirmPax() && { onChange: handleConfirmPax }),
          }}
          formControlWidth="calc(100% - 10px)"
          // value={confirmPax}
          style={{
            display: 'flex',
            flexDirection: 'column',
            // backgroundColor: 'white',
          }}
          name="confirmPax"
          // defaultValue="true"
        > */}
          <AimFormControlLabel
            key={1}
            value={'false'}
            control={<AimRadioButton />}
            label={_i18n.notConfirmPax}
            formControlStyle={formControlStyle}
            disabled={isReadOnlyConfirmPax()}
          />
        </AimRadioCustom>
        {/* </div> */}
      </Grid>
      {renderButtons()}
      {/* {!servicesState?.data?.participationFacultyResponse
        ? renderButtons()
        : null} */}
    </Grid>
  );
};

export default ParticipationConfirm;

export const translation = {
  agencyHomepage: (intl) => ({
    page: {
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Go back',
        }),
        button: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'back',
        }),
      },
      title: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Groups',
      }),
      instructions: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Instructions',
      }),
      budgetPrepaid: intl.formatMessage({
        description: 'budget prepaid label',
        defaultMessage: 'Budget prepaid',
      }),
      seeBudget: {
        Buttontooltip: intl.formatMessage({
          description: 'see budget',
          defaultMessage: 'See budget',
        }),
        title: intl.formatMessage({
          description: 'see budget',
          defaultMessage: 'See budget',
        }),
      },
      closed: intl.formatMessage({
        description: 'closed label',
        defaultMessage: 'Closed',
      }),
      open: intl.formatMessage({
        description: 'open label',
        defaultMessage: 'Open',
      }),
      purchasedParticipations: intl.formatMessage({
        description: 'purchased participations label',
        defaultMessage: 'Puchased participations',
      }),
      addSpots: intl.formatMessage({
        description: 'purchase additional spots',
        defaultMessage: 'Purchase additional spots',
      }),
      prepaidUntil: intl.formatMessage({
        description: 'you can buy prepaid until the',
        defaultMessage: 'You can buy prepaid until the',
      }),
      canBuyPrepaid: intl.formatMessage({
        descritpion: 'can buy prepaid label',
        defaultMessage: 'You can buy prepaid until the',
      }),
      closingGroups: intl.formatMessage({
        description: 'closing groups label',
        defaultMessage: 'Closing groups',
      }),
      freezeDeadline: intl.formatMessage({
        description: 'freeze deadline label',
        defaultMessage: 'Freeze deadline',
      }),
      groups: {
        title: intl.formatMessage({
          defaultMessage: 'Groups',
          description: 'groups title',
        }),
        grid: {
          name: intl.formatMessage({
            defaultMessage: 'Name',
            description: 'name datagrid header label',
          }),
          client: intl.formatMessage({
            defaultMessage: 'Client',
            description: 'client datagrid header label',
          }),
          nPax: intl.formatMessage({
            defaultMessage: 'N° pax',
            description: 'npax datagrid header label',
          }),
          totalExpence: intl.formatMessage({
            defaultMessage: 'Total expence',
            description: 'total expence datagrid header label',
          }),
          budgetPaid: intl.formatMessage({
            defaultMessage: 'Budget paid',
            description: 'budget paid datagrid header label',
          }),
          budgetRemaining: intl.formatMessage({
            defaultMessage: 'Budget remaining',
            description: 'budget remaining datagrid header label',
          }),
          amontDue: intl.formatMessage({
            defaultMessage: 'Amont due',
            description: 'amontDue datagrid header label',
          }),
          status: intl.formatMessage({
            defaultMessage: 'Status',
            description: 'status datagrid header label',
          }),
        },
      },
      closingPrepaidGroup: intl.formatMessage({
        description: 'closing prepaid group',
        defaultMessage: 'Closing prepaid group',
      }),
      closingGroupNoPrepaid: intl.formatMessage({
        description: 'closing groups no prepaid',
        defaultMessage: 'Closing groups no prepaid',
      }),
      paidChangesUntil: intl.formatMessage({
        description: 'paid changes from closing groups until',
        defaultMessage: 'Paid changes from closing groups until',
      }),
      instructions: intl.formatMessage({
        description: 'instructions label',
        defaultMessage: 'Instructions',
      }),
      fileList: {
        fileName: intl.formatMessage({
          description: 'file name',
          defaultMessage: 'Name',
        }),
        fileDimension: intl.formatMessage({
          description: 'file dimension',
          defaultMessage: 'Dimension',
        }),
      },
      purchase: intl.formatMessage({
        description: 'purchase',
        defaultMessage: 'Purchase',
      }),
      purchaseList: intl.formatMessage({
        description: '2 options to organize your groups:',
        defaultMessage: '2 options to organize your groups:',
      }),
      prepaid: {
        label: intl.formatMessage({
          description: 'prepaid',
          defaultMessage: 'Prepaid',
        }),
      },
      purchasePrepaidItem: intl.formatMessage({
        description:
          'prepaid: generate your budget starting from the advantageous shares of the 1st bracket within the deadline_prepaid and manages to insert profiles up to the deadline_composition',
        defaultMessage:
          'generate your budget starting from the advantageous shares of the 1st bracket within the deadline_prepaid and manages to insert profiles up to the deadline_composition',
      }),
      purchasePunctualItem: intl.formatMessage({
        description:
          'punctual: promptly insert the members of your group and you will pay when the group closes',
        defaultMessage:
          'Punctual: promptly insert the members of your group and you will pay when the group closes',
      }),
      prepaidButton: {
        tooltip: intl.formatMessage({
          description: 'prepaid button tooltip',
          defaultMessage: 'Prepaid',
        }),
        title: intl.formatMessage({
          description: 'prepaid button',
          defaultMessage: 'Prepaid',
        }),
      },
      punctualButton: {
        tooltip: intl.formatMessage({
          description: 'punctual button tooltip',
          defaultMessage: 'Punctual',
        }),
        title: intl.formatMessage({
          description: 'punctual button',
          defaultMessage: 'Punctual',
        }),
      },
      prepaidDescr: intl.formatMessage({
        description:
          'remember that you will be able to perform more than a prepaid up to date included',
        defaultMessage:
          'Remember that you will be able to perform more than a prepaid up to date included',
      }),
      punctualDescr: intl.formatMessage({
        description:
          'remember that you fee to be paid at the time of the group closure will always refer to the bracket in which you made the last change',
        defaultMessage:
          'Remember that you fee to be paid at the time of the group closure will always refer to the bracket in which you made the last change',
      }),
      manageYourGroups: intl.formatMessage({
        description: 'manage your groups',
        defaultMessage: 'Manage your groups',
      }),
      manageYourGroupsList: intl.formatMessage({
        description: 'remember that:',
        defaultMessage: 'Remember that:',
      }),
      manageYourGroupsItem1: intl.formatMessage({
        description: 'your prepaid purchases will merge into "prepaid" group',
        defaultMessage:
          'your prepaid purchases will merge into "prepaid" group',
      }),
      manageYourGroupsItem2: intl.formatMessage({
        description:
          'you can manage more than one group for punctual purchases',
        defaultMessage:
          'you can manage more than one group for punctual purchases',
      }),
      goToGroupsButton: {
        tooltip: intl.formatMessage({
          description: 'go to groups button tooltip',
          defaultMessage: 'Go to groups',
        }),
        title: intl.formatMessage({
          description: 'go to groups button tooltip',
          defaultMessage: 'Go to groups',
        }),
      },
      seeFeeTable: intl.formatMessage({
        description: 'see fee table',
        defaultMessage: 'See fee table',
      }),
      seeFeeTableButton: {
        Buttontooltip: intl.formatMessage({
          description: 'open',
          defaultMessage: 'Open',
        }),
        title: intl.formatMessage({
          description: 'open',
          defaultMessage: 'Open',
        }),
      },
    },
    actions: {
      details: intl.formatMessage({
        defaultMessage: 'Group Detail',
        description: 'go to details action row label',
      }),
      edit: intl.formatMessage({
        defaultMessage: 'Edit Group',
        description: 'edit action row label',
      }),
      delete: intl.formatMessage({
        defaultMessage: 'Delete Group',
        description: 'delete action row label',
      }),
    },
    modal: {
      calculator: {
        title: intl.formatMessage({
          description: 'calculate budget',
          defaultMessage: 'Calculate budget',
        }),
        description: intl.formatMessage({
          description: 'calculate budget ',
          defaultMessage: 'Calculate budget',
        }),
        addProfile: {
          tooltip: intl.formatMessage({
            description: 'add profile',
            defaultMessage: 'Add Profile',
          }),
          title: intl.formatMessage({
            description: 'add Profile',
            defaultMessage: 'Add profile',
          }),
        },
        grid: {
          profile: {
            label: intl.formatMessage({
              description: 'profile',
              defaultMessage: 'Profile',
            }),
            placeholder: intl.formatMessage({
              description: 'select a profile',
              defaultMessage: 'Select a profile',
            }),
          },
          participation: {
            label: intl.formatMessage({
              description: 'participation',
              defaultMessage: 'Participation',
            }),
            placeholder: intl.formatMessage({
              description: 'select a participation',
              defaultMessage: 'Select a participation',
            }),
          },
          feeDateRange: {
            label: intl.formatMessage({
              description: 'ticket',
              defaultMessage: 'Ticket',
            }),
            placeholder: intl.formatMessage({
              description: 'select a ticket',
              defaultMessage: 'Select a ticket',
            }),
          },
          price: intl.formatMessage({
            description: 'price',
            defaultMessage: 'Price (€)',
          }),
          quantity: {
            label: intl.formatMessage({
              description: 'quantity',
              defaultMessage: 'Quantity',
            }),
            placeholder: intl.formatMessage({
              description: 'quantity',
              defaultMessage: 'Quantity',
            }),
          },
          total: {
            label: intl.formatMessage({
              description: 'total',
              defaultMessage: 'Total (€)',
            }),
          },
        },
        buttons: {
          close: intl.formatMessage({
            description: 'agency purchase calculate budget dialog close',
            defaultMessage: 'Close',
          }),
          // buy: intl.formatMessage({
          //   description: 'buy',
          //   defaultMessage: 'buy',
          // }),
        },
      },
      prepaidBudget: {
        title: intl.formatMessage({
          description: 'prepaid budget',
          defaultMessage: 'Prepaid budget',
        }),
        description: intl.formatMessage({
          description: 'prepaid budget ',
          defaultMessage: 'Prepaid budget',
        }),
        label: intl.formatMessage({
          description: 'budget',
          defaultMessage: 'budget',
        }),
        buttons: {
          close: intl.formatMessage({
            description: 'close',
            defaultMessage: 'Close',
          }),
          buy: intl.formatMessage({
            description: 'buy',
            defaultMessage: 'buy',
          }),
        },
      },
      groups: {
        title: intl.formatMessage({
          description: 'groups',
          defaultMessage: 'Groups',
        }),
        description: intl.formatMessage({
          description: 'prepaid budget ',
          defaultMessage: 'Prepaid budget',
        }),
        label: intl.formatMessage({
          description: 'choose the group',
          defaultMessage: 'choose the group',
        }),
        buttons: {
          cancel: intl.formatMessage({
            description: 'close',
            defaultMessage: 'Close',
          }),
          addParticipant: intl.formatMessage({
            description: 'add participant',
            defaultMessage: 'Add participant',
          }),
        },
      },
    },
    snackbar: {
      updateBudgetSuccess: intl.formatMessage({
        description: 'update budget success',
        defaultMessage: 'update budget success',
      }),
      gatewayNotExist: intl.formatMessage({
        description: 'gateway not exist',
        defaultMessage: 'Gateway not exist',
      }),
      paymentTypeNotExist: intl.formatMessage({
        description: 'payment Type Not Exist',
        defaultMessage: 'Payment Type Not Exist',
      }),
      deleteAgencyGroup: intl.formatMessage({
        description: 'delete success agency group',
        defaultMessage: 'Delete group success',
      }),
      createGroup: intl.formatMessage({
        defaultMessage: 'Create group success',
        description: 'create group success label',
      }),
      updateGroup: intl.formatMessage({
        defaultMessage: 'Update group success',
        description: 'update group success label',
      }),
      sponsorCodeNotFound: intl.formatMessage({
        defaultMessage: 'Sponsor code not found',
        description: 'sponsor code not found label',
      }),
    },
    checkout: {
      errors: {
        error1: intl.formatMessage({
          description: 'order data request processing error',
          defaultMessage: 'Order data request processing error',
        }),
        error2: intl.formatMessage({
          description: 'order data processing error',
          defaultMessage: 'Order data processing error',
        }),
        error3: intl.formatMessage({
          description: 'order payment initialization error',
          defaultMessage: 'Order payment initialization error',
        }),
        error4: intl.formatMessage({
          description: 'order payment error',
          defaultMessage: 'Order payment error',
        }),
        paymentExist: intl.formatMessage({
          description: 'payment exist',
          defaultMessage: 'Payment exist',
        }),
      },
    },
    dialog: {
      delete: {
        title: intl.formatMessage({
          defaultMessage: 'Delete Group',
          description: 'delete dialog title',
        }),
        text: intl.formatMessage({
          description: 'delete dialog message',
          defaultMessage: 'Are you sure?',
        }),
      },
      create: {
        titleCreate: intl.formatMessage({
          defaultMessage: 'Create group',
          description: 'create dialog title',
        }),
        titleEdit: intl.formatMessage({
          defaultMessage: 'Edit group',
          description: 'edit dialog title',
        }),
        text: intl.formatMessage({
          defaultMessage:
            "Do you have a code form the sponsor? Type it in the field and manage the sponsor's group.",
          description: 'create dialog text',
        }),
        fields: {
          code: intl.formatMessage({
            defaultMessage: 'Code',
            description: 'create group code field label',
          }),
          groupName: intl.formatMessage({
            defaultMessage: 'Group Name',
            description: 'create group groupName field label',
          }),
          client: intl.formatMessage({
            defaultMessage: 'Client',
            description: 'create group client field label',
          }),
        },
      },
    },
  }),
};

import { constants } from '@aim/common';
import {
  getPurchaseInformations,
  getHotelReservationInformations,
} from '../../myAllotment/myAllotmentGqlHelper';
import translation from '../../myAllotment/translation';
import { format } from 'date-fns';
import { utilities } from '@aim/common';
const { decodeDbNumber } = utilities;

export const getMyAllotment = async ({ participationId, eventId, intl }) => {
  const i18n = translation.myAllotment(intl).purchaseTable.header;
  const products = await getPurchaseInformations(
    participationId,
    eventId,
    constants.Services.ALLOTMENT.key
  );

  const purchaseInformations = await Promise.all(
    products?.map(async (product) => {
      const reservation = await getHotelReservationInformations(
        participationId,
        product.productId
      );

      const { payment } = product;

      return {
        ...payment,
        ...reservation,
        productId: product.id,
      };
    })
  );
  return purchaseInformations && purchaseInformations.length > 0
    ? purchaseInformations.map((purchaseInfo) => {
        const parsedInfo = {
          ...purchaseInfo,
          checkIn: purchaseInfo.checkIn
            ? format(new Date(purchaseInfo.checkIn), 'dd/MM/yyyy')
            : '-',
          checkOut: purchaseInfo.checkOut
            ? format(new Date(purchaseInfo.checkOut), 'dd/MM/yyyy')
            : '-',
          amount: purchaseInfo.amount
            ? decodeDbNumber(purchaseInfo?.amount)
            : '-',
          paymentDate: purchaseInfo.date
            ? format(new Date(purchaseInfo.date), 'dd/MM/yyyy')
            : '-',
          paymentStatus: purchaseInfo?.paymentStatus
            ? purchaseInfo?.paymentStatus === 'done'
              ? 'Paid'
              : 'Pending'
            : 'Cancelled',
        };
        return parseMyAllotment(parsedInfo, i18n);
      })
    : [];
};

export const parseMyAllotment = (purchaseInfo, translations) =>
  Object.entries(purchaseInfo).map(([key, value]) =>
    translations[key]
      ? {
          id: key,
          label: translations[key],
          value,
        }
      : { [key]: value }
  );

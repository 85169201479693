export default {
  sponsorStaffDetail: (intl) => ({
    aimTablePage: {
      header: {
        action: {
          add: {
            tooltip: intl.formatMessage({
              description: 'add member tooltip',
              defaultMessage: 'Add member',
            }),
          },
          deleteAll: {
            tooltip: intl.formatMessage({
              description: 'delete selected tooltip',
              defaultMessage: 'Delete selected',
            }),
          },
          close: {
            label: intl.formatMessage({
              description: 'close group button',
              defaultMessage: 'CLOSE GROUP',
            }),
          },
        },
      },
      stats: {
        remainingPlaces: intl.formatMessage({
          description: 'Stats - remaining staff places',
          defaultMessage: 'Remaining places',
        }),
        currentMembers: intl.formatMessage({
          description: 'Stats - staff current member count',
          defaultMessage: 'Current members',
        }),
        status: intl.formatMessage({
          description: 'Stats - current status',
          defaultMessage: 'Status',
        }),
        freeMembers: intl.formatMessage({
          description: 'Stats - free places',
          defaultMessage: 'Free places',
        }),
      },
      table: {
        columns: {
          username: intl.formatMessage({
            description: 'sponsor staff member username',
            defaultMessage: 'ID',
          }),
          givenName: intl.formatMessage({
            description: 'sponsor staff member givenName',
            defaultMessage: 'Name',
          }),
          familyName: intl.formatMessage({
            description: 'sponsor staff member familyName',
            defaultMessage: 'Surname',
          }),
          email: intl.formatMessage({
            description: 'sponsor staff member email',
            defaultMessage: 'Email',
          }),
          profile: intl.formatMessage({
            description: 'sponsor staff member profile',
            defaultMessage: 'Profile',
          }),
          feeDateRange: intl.formatMessage({
            description: 'sponsor staff member feeDateRange',
            defaultMessage: 'Registration',
          }),
          price: intl.formatMessage({
            description: 'sponsor staff member price',
            defaultMessage: 'Price',
          }),
          createdAt: intl.formatMessage({
            description: 'sponsor staff member createdAt',
            defaultMessage: 'Date added',
          }),
        },
        rowAction: {
          edit: {
            tooltip: intl.formatMessage({
              description: 'edit tooltip',
              defaultMessage: 'Edit',
            }),
          },
          delete: {
            tooltip: intl.formatMessage({
              description: 'delete tooltip',
              defaultMessage: 'Delete',
            }),
          },
        },
      },
      dialog: {
        delete: {
          title: intl.formatMessage({
            description: 'Delete staff member dialog title',
            defaultMessage: 'Delete Staff member',
          }),
          message: intl.formatMessage({
            description: 'Delete staff member dialog message',
            defaultMessage: 'Do you want to delete staff member?',
          }),
          agreeText: intl.formatMessage({
            description: 'Delete staff member dialog agree button',
            defaultMessage: 'Confirm',
          }),
          disagreeText: intl.formatMessage({
            description: 'Delete staff member dialog cancel button',
            defaultMessage: 'Cancel',
          }),
        },
        deleteAll: {
          title: intl.formatMessage({
            description: 'Delete staff members dialog title',
            defaultMessage: 'Delete Staff members',
          }),
          message: intl.formatMessage({
            description: 'Delete staff member dialog message',
            defaultMessage: 'Do you want to delete staff members?',
          }),
          agreeText: intl.formatMessage({
            description: 'Delete staff member dialog agree button',
            defaultMessage: 'Confirm',
          }),
          disagreeText: intl.formatMessage({
            description: 'Delete staff member dialog cancel button',
            defaultMessage: 'Cancel',
          }),
        },
        close: {
          title: intl.formatMessage({
            description: 'close staff group dialog title',
            defaultMessage: 'Close staff group',
          }),
          message: intl.formatMessage({
            description: 'close staff group dialog message',
            defaultMessage: 'Do you want to close staff group?',
          }),
          agreeText: intl.formatMessage({
            description: 'close staff group dialog agree button',
            defaultMessage: 'Confirm',
          }),
          disagreeText: intl.formatMessage({
            description: 'close staff group dialog cancel button',
            defaultMessage: 'Cancel',
          }),
        },
      },
      // dialogBulk: {
      //   title: intl.formatMessage({
      //     description: 'Bulk delete dialog title',
      //     defaultMessage: 'Delete selected',
      //   }),
      //   message: intl.formatMessage({
      //     description: 'Bulk delete dialog message',
      //     defaultMessage: 'Do you want to delete all selected staff members?',
      //   }),
      //   agreeText: intl.formatMessage({
      //     description: 'Bulk delete dialog agree button',
      //     defaultMessage: 'Delete all',
      //   }),
      //   disagreeText: intl.formatMessage({
      //     description: 'Bulk delete dialog cancel button',
      //     defaultMessage: 'Keep all',
      //   }),
      // },
      // dialogBuy: {
      //   title: intl.formatMessage({
      //     description: 'buy dialog title',
      //     defaultMessage: 'Buy additional staff participations',
      //   }),
      //   message: intl.formatMessage({
      //     description: 'buy dialog message',
      //     defaultMessage:
      //       'If you want to add new staff members, you have to purchase the corresponding fee. Choose the number of members you would like to engage.',
      //   }),
      //   agreeText: intl.formatMessage({
      //     description: 'buy dialog agree button',
      //     defaultMessage: 'Buy',
      //   }),
      //   disagreeText: intl.formatMessage({
      //     description: 'buy dialog cancel button',
      //     defaultMessage: 'Cancel',
      //   }),
      // },
      snackbar: {
        delete: {
          success: intl.formatMessage({
            description: 'member deleted successfully',
            defaultMessage: 'Success',
          }),
          error: intl.formatMessage({
            description: 'error snackbar',
            defaultMessage: 'Error, try again',
          }),
        },
      },
    },
    page: {
      statuses: {
        open: intl.formatMessage({
          description: 'open status',
          defaultMessage: 'Open',
        }),
        closed: intl.formatMessage({
          description: 'closes status',
          defaultMessage: 'Closed',
        }),
      },
      groupClosingMessage: intl.formatMessage({
        description:
          'deadline expired for information contact the organizing secretariat',
        defaultMessage:
          'Deadline expired for information contact the organizing secretariat',
      }),
    },
    addMemberDialog: {
      columns: {
        profile: intl.formatMessage({
          description: 'profile',
          defaultMessage: 'Profile',
        }),
        ticket: intl.formatMessage({
          description: 'registration',
          defaultMessage: 'Registration',
        }),
        participationMode: intl.formatMessage({
          description: 'participation mode',
          defaultMessage: 'Participation mode',
        }),
        price: intl.formatMessage({
          description: 'price',
          defaultMessage: 'Price',
        }),
        available: intl.formatMessage({
          description: 'available',
          defaultMessage: 'Available',
        }),
      },
      buttons: {
        insert: intl.formatMessage({
          description: 'insert',
          defaultMessage: 'Insert',
        }),
        cancel: intl.formatMessage({
          description: 'cancel',
          defaultMessage: 'Cancel',
        }),
        buy: intl.formatMessage({
          description: 'buy',
          defaultMessage: 'Buy',
        }),
      },
      purchaseTicketsMessage: intl.formatMessage({
        description:
          'sponsor staff fees not availables, go tu purchase message',
        defaultMessage:
          'If you want to add new sponsored participations, you have to purchase the corresponding fee. Indicates the number of members you would like to engage.',
      }),
      title: intl.formatMessage({
        description: 'Select the profile and registration fee dialog title',
        defaultMessage: 'Select the profile and registration fee',
      }),
    },
  }),
};

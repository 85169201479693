import { aws, appState, constants } from '@aim/common';
import { PromisePool } from '@supercharge/promise-pool';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const updateParticipationIsDeletedQuery = /* GraphQL */ `
  mutation UpdateParticipation($participationId: ID!, $isDeleted: Boolean) {
    updateParticipation(
      input: { id: $participationId, isDeleted: $isDeleted }
    ) {
      id
    }
  }
`;

const getAgencyDataQuery = /* GraphQL */ `
  query GetAgencyData($id: ID!, $agencyId: ID!, $type: String!) {
    getAgency(id: $agencyId) {
      id
      name
      prepaidBudget
    }
    getDeadline(externalId: $id, type: $type) {
      value
      externalId
    }
  }
`;

const getGroupDataQuery = /* GraphQL */ `
  query GetAgencyData($id: ID!) {
    getAgencyGroup(id: $id) {
      id
      name
      closingDate
      penaltiesPaidAt
    }
  }
`;

const updateGroupMutation = /* GraphQL */ `
  mutation UpdateAgencyGroup(
    $input: UpdateAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    updateAgencyGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSponsorListMutation = /* GraphQL */ `
  mutation UpdateSponsorList(
    $input: UpdateSponsorListInput!
    $condition: ModelSponsorListConditionInput
  ) {
    updateSponsorList(input: $input, condition: $condition) {
      id
    }
  }
`;

const getBracketsQuery = `
  query GetAgencySpecifications($id: ID!) {
    getEvent(id: $id) {
      id
      fee {
        feeBracketModels {
          items {
            id
            name
            start
            end
          }
        }
      }
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateParticipationIsDeleted = (participationId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: updateParticipationIsDeletedQuery,
      variables: { participationId, isDeleted: true },
    })
      .then((response) => {
        resolve(response.data.updateParticipation);
      })
      .catch((e) => {
        console.error('update-participation-error', e);
        reject();
      })
      .finally(() => hideLoader());
  });

export const getAgencyData = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getAgencyDataQuery,
      variables: { ...input, type: constants.Clusters.Agency.id },
    })
      .then((response) => {
        return resolve({
          ...response.data.getAgency,
          id: response.data.getAgency.id,
          deadlines: response.data?.getDeadline
            ? JSON.parse(response.data.getDeadline.value)
            : {},
        });
      })
      .catch((e) => {
        console.error('get-agency-error', e);
        reject();
      })
      .finally(() => hideLoader());
  });

export const getGroupData = (id) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getGroupDataQuery,
      variables: { id },
    })
      .then((response) => {
        resolve(response.data.getAgencyGroup);
      })
      .catch((e) => {
        console.error('get-agency-group-error', e);
        reject();
      })
      .finally(() => hideLoader());
  });

export const updateGroup = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: updateGroupMutation,
      variables: { input },
    })
      .then(resolve)
      .catch((e) => {
        console.error('update-agency-prepaidBudget', e);
        reject();
      })
      .finally(() => hideLoader());
  });

const updateParticipation = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => {
        resolve(response.data.updateParticipation);
      })
      .catch((e) => {
        console.error('update-participation-error', e);
        reject();
      });
  });

export const closeAgencyGroup = (
  input,
  isSponsorList = false,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: isSponsorList ? updateSponsorListMutation : updateGroupMutation,
      variables: { input },
    })
      .then(() => resolve())
      .catch((e) => {
        console.error('close-agency-group', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getBrackets = (id) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: getBracketsQuery,
      variables: { id },
    })
      .then(resolve)
      .catch((e) => {
        console.log('get-event-brackets', e);
        reject();
      })
      .finally(() => hideLoader());
  });

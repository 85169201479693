import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
//ICONS
import PdfIcon from './../../../assets/images/pdfIcon.png';
import WordIcon from './../../../assets/images/wordIcon.png';
import ExcelIcon from './../../../assets/images/excelIcon.png';
import { theme } from '@aim/components';

const imageExts = ['.jpg', '.jpeg', '.png'];
const excelExts = ['.xls', '.xlsx'];
const pdfExts = ['.pdf'];
const wordExts = ['.doc', '.docx'];

export const getExtensionLabel = (ext, i18n) => {
  if (excelExts.includes(ext.toLowerCase())) {
    return i18n.page.table.excel;
  } else if (wordExts.includes(ext.toLowerCase())) {
    return i18n.page.table.word;
  } else if (pdfExts.includes(ext.toLowerCase())) {
    return i18n.page.table.pdf;
  } else if (imageExts.includes(ext.toLowerCase())) {
    return i18n.page.table.image;
  } else {
    return i18n.page.table.other;
  }
};

export const getIconByExt = (ext) => {
  if (excelExts.includes(ext.toLowerCase())) {
    return (
      <img
        src={ExcelIcon}
        style={{
          width: 25,
          height: 25,
        }}
      />
    );
  }
  if (pdfExts.includes(ext.toLowerCase())) {
    return (
      <img
        src={PdfIcon}
        style={{
          width: 25,
          height: 25,
        }}
      />
    );
  }
  if (wordExts.includes(ext.toLowerCase())) {
    return (
      <img
        src={WordIcon}
        style={{
          width: 25,
          height: 25,
        }}
      />
    );
  }
  if (imageExts.includes(ext.toLowerCase())) {
    return (
      <ImageIcon
        style={{
          width: 30,
          height: 30,
          margin: 0,
          color: theme.colors.greyScale.grey5,
        }}
      />
    );
  } else {
    return (
      <InsertDriveFileIcon
        style={{
          width: 30,
          height: 30,
          margin: 0,
          color: theme.colors.greyScale.grey5,
        }}
      />
    );
  }
};

import React, { useState } from 'react';

import { AimDialog, AimTypography } from '@aim/components';
import { appState } from '@aim/common';

import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const currentTimezone = appState.eventInfo.getValue().timezone;

const moneyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

const ServiceSummaryBuyCardContent = ({
  title,
  description,
  day,
  price,
  freeStaffMembers,
  i18n,
}) => {
  const [descriptionDialog, setDescriptionDialog] = useState({
    isOpen: false,
    title,
    description,
  });

  return (
    <Grid container>
      <Grid item xs={6}>
        <AimTypography variant="textBold" margin={0} component="span">
          {i18n.items.nFreeBadge.label}
        </AimTypography>
      </Grid>
      <Grid item xs={6} style={{ marginBottom: 5 }}>
        <AimTypography
          variant="text"
          margin={0}
          component="span"
          textAlign="right"
        >
          {freeStaffMembers}
        </AimTypography>
      </Grid>
      <Grid item xs={8} style={{ marginBottom: 5 }}>
        <AimTypography
          variant="textBold"
          margin={0}
          // color="textSecondary"
          component="span"
        >
          {i18n.items.serviceAvailableFrom.label}
        </AimTypography>
      </Grid>
      <Grid item xs={4} style={{ marginBottom: 5 }}>
        <AimTypography
          variant="text"
          margin={0}
          component="span"
          textAlign="right"
        >
          {format(utcToZonedTime(day, currentTimezone), 'dd/MM/yyyy')}
        </AimTypography>
      </Grid>

      {description && (
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          {/* <AimTypography
            variant="text"
            margin={0}
            // color="textSecondary"
            // component="span"
            style={{
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': '3',
              overflow: 'hidden',
            }}
          >
            {description.replace(/\n/g, '<br />')}
          </AimTypography> */}

          <div
            style={{
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              '-webkit-line-clamp': '3',
              overflow: 'hidden',
              fontSize: '13px',
              fontWeight: '400',
              textAlign: 'left',
            }}
            dangerouslySetInnerHTML={{
              __html: description.replace(/\n/g, '<br />'),
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '13px',
                cursor: 'pointer',
              }}
              onClick={() =>
                setDescriptionDialog({
                  isOpen: true,
                  title,
                  description,
                })
              }
            >
              {i18n.items.readAll.label}
            </span>
          </div>
          <AimDialog
            open={descriptionDialog?.isOpen}
            title={descriptionDialog?.title}
            disableEnforceFocus
            onClose={() => {
              setDescriptionDialog({ isOpen: false });
            }}
            hideAgreeButton
            disagreeText={i18n.items.dialogCloseButton.label}
          >
            <div
              style={{
                fontSize: '13px',
                fontWeight: '400',
                // textAlign: 'left',
              }}
              dangerouslySetInnerHTML={{
                __html: descriptionDialog?.description?.replace(
                  /\n/g,
                  '<br />'
                ),
              }}
            />
          </AimDialog>
        </Grid>
      )}

      <Grid item xs={6}>
        {/* <AimTypography
          variant="h6"
          margin={0}
          color="textSecondary"
          component="span"
        >
          {`${i18n.items.price.label}`}
        </AimTypography> */}
      </Grid>
      <Grid item xs={6}>
        <AimTypography
          variant="h2"
          margin={0}
          textAlign="end"
          // color="textSecondary"
          component="span"
        >
          {moneyFormatter.format(price)} {/* TODO */}
          {/* TODO WHAT? */}
        </AimTypography>
      </Grid>
    </Grid>
  );
};

export default ServiceSummaryBuyCardContent;

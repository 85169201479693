import { aws, appState, constants } from '@aim/common';
import { useBreakoutRoomUtils } from '../../hooks/useBreakoutRoomUtils';
const { API } = aws;
const { states } = useBreakoutRoomUtils();

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getParticipantByCognitoIdEventId = /* GraphQL */ `
  query ParticipantByCognitoIdEventId(
    $cognitoUserId: ID
    $participationEventId: ModelIDKeyConditionInput
  ) {
    participantByCognitoIdEventId(
      cognitoUserId: $cognitoUserId
      participationEventId: $participationEventId
    ) {
      items {
        id
        username
        isDeleted
        cognitoUserId
        identityPoolIdentityId
        cluster
        givenName
        familyName
        title
        email
        hasAcceptedToBeReviewer
        isFirstAccessCompleted
        phone
        participationParticipationServicesId
        fieldValues {
          items {
            id
            value
            fieldDefinition {
              key
              id
              controlType
            }
          }
        }
        participationGrant(filter: { isDeleted: { ne: true } }) {
          items {
            id
            participationGrantGrantId
          }
        }
        typology {
          id
          name
        }
        profile {
          id
          name
          cluster
          createdAt
          updatedAt
          isAccompanyingPersonEnabled
          maxAccompanyingPersons
          category {
            id
            name
          }
        }
        userShowcase {
          id
          contactEmail
          profileImage {
            id
            extension
            originalName
            size
          }
          coverImage {
            id
            originalName
            extension
            size
          }
        }
        userSettings {
          isPublic
        }
        filledPapers {
          items {
            id
            type
            paper {
              id
              publishDate
              isGeneric
              isDeleted
              name
              type
            }
            file {
              extension
              id
              originalName
              size
            }
          }
        }
        myAgenda {
          items {
            id
            session {
              id
            }
          }
        }
        myBreakoutRoom(filter: { state: { eq: "${states.ACCEPTED}" } }) {
          items {
            id
            isSelfProposed
            note
            state
            slot {
              id
              start
              end
            }
          }
        }
        surveyInvitations {
          items {
            id
            isCompleted
            survey {
              id
            }
          }
        }
        agency {
          id
        }
        sponsorStaff {
          id
          sponsor {
            id
            isDeleted
            publicPage {
              id
              isPublic
              buyOperation {
                id
                purchase {
                  id
                }
              }
            }
            priceRange {
              id
              name
              price
              start
              frontendNameColor
              backgroundColor
            }
            fieldValues {
              items {
                id
              }
            }
          }
        }
        event {
          id
          name
          abstractService {
            id
            maxAbstractsPerAuthor
            abstractTerm
          }
        }
        guests {
          items {
            id
            givenName
            familyName
            country
          }
        }
        sponsor {
          id
          isDeleted
          publicPage {
            id
            isPublic
            sponsorPackageItem {
              package {
                buyOperations {
                  items {
                    id
                    purchase {
                      id
                    }
                  }
                }
              }
            }
            buyOperation {
              id
              purchase {
                id
              }
            }
          }
          priceRange {
            id
            name
            price
            start
            frontendNameColor
            backgroundColor
          }
          fieldValues {
            items {
              id
            }
          }
        }
        isReviewer
        isSpeaker
        isFaculty
        reviewer {
          id
          reviews {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

const getParticipantByCognitoUserId = /* GraphQL */ `
  query GetParticipantByCognitoUserId($cognitoUserId: ID) {
    participantByCognitoUserId(cognitoUserId: $cognitoUserId) {
      items {
        id
        event {
          id
          name
        }
      }
    }
  }
`;

/*"0b12e436-2e0f-4bee-8575-842843b76b91"*/
const getAbstracts = /* GraphQL */ `
  query getUserParticipationsAbstracts($id: ID!) {
    getParticipation(id: $id) {
      isReviewer
      abstractDocuments(filter: { isDeleted: { ne: true } }) {
        items {
          cod0
          cod1
          cod2
          createdAt
          images
          id
          isDeleted
          isReadyToReview
          preferredExposure
          processingStatus
          abstractService {
            id
            maxAbstractsPerAuthor
          }
          status
          tags
          title
          url
          updatedAt
          acceptedPresentationTypology {
            createdAt
            description
            name
            id
            presentationMode
            presentationRequired
            updatedAt
          }
          proposedPresentationTypology {
            createdAt
            description
            id
            name
            presentationMode
            presentationRequired
            updatedAt
          }
          reviews {
            items {
              id
              updatedAt
            }
            nextToken
          }
          keywords
          presenter {
            participant {
              id
            }
          }
        }
      }
    }
  }
`;

const getReviewerReviews = /* GraphQL */ `
  query getAbstractDocsFromReviewer($id: ID!) {
    getParticipation(id: $id) {
      event {
        id
        abstractService {
          id
          categories {
            items {
              id
              name
              subCategories {
                items {
                  id
                  name
                }
              }
            }
          }
          reviewTerm
          reviewers {
            items {
              id
              participant {
                id
                email
              }
              reviewerCategories {
                items {
                  reviewerCategoryReviewerId
                  category {
                    id
                    name
                    subCategories {
                      items {
                        id
                        name
                      }
                    }
                  }
                  reviewerCategoryCategoryId
                }
              }
              reviews {
                items {
                  id
                  scores
                  status
                  abstractDocument {
                    id
                    title
                    status
                    isReadyToReview
                    proposedPresentationTypology {
                      name
                    }
                    category {
                      id
                      name
                    }
                    subCategory {
                      id
                      name
                    }
                    preferredExposure
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getAbstractDocumentNoOp = /* GraphQL */ `
  query GetAbstractDocument($id: ID!) {
    getAbstractDocument(id: $id) {
      id
      code
      title
      abstractSections {
        items {
          id
          value
          fieldDefinition {
            controlType
            id
            isDeleted
            label
            options
            placeholder
          }
        }
      }
      additionalData {
        items {
          id
          value
          fieldDefinition {
            controlType
            id
            isDeleted
            label
            options
            placeholder
          }
        }
      }
      affiliations {
        items {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
        }
      }
      status
      preferredExposure
      subCategory {
        id
        name
      }
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      proposedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      acceptedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      processingStatus
      tags
      keywords
      reviews {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      isReadyToReview
      affiliations {
        items {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
        }
        nextToken
      }
      authors {
        items {
          id
          affiliation {
            id
            institution
          }
          city
          country
          degree
          email
          id
          isPresentingAuthor
          jobTitle
          name
          preTitle
          speciality
          surname
          position
        }
      }
      isDeleted
      createdAt
      updatedAt
      participant {
        id
        owner
        cluster
        paymentStatus
        registrationChannel
        isInvited
        status
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        registrationFee
        sponsor {
          id
          name
          therapeuticArea
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      isOwnerAPresentingAuthor
    }
  }
`;

const listEventParticipationsByCluster = /* GraphQL */ `
  query GetEvent($id: ID!, $cluster: String!) {
    getEvent(id: $id) {
      participations(filter: { cluster: { eq: $cluster } }) {
        items {
          id
          status
          isInvited
          registrationChannel
          sponsor {
            id
            name
            therapeuticArea
            profileImage {
              id
              originalName
              size
              extension
            }
            publicPage {
              id
              isPublic
              buyOperation {
                id
                purchase {
                  id
                  paymentType
                  payment {
                    paymentIdMonetaOnLine
                    paymentIdIngenico
                    paymentIdGpWebpay
                    paymentType
                  }
                }
              }
            }
          }
          email
          familyName
          givenName
        }
      }
    }
  }
`;

const listEventParticipationsQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      participations(filter: { isDeleted: { ne: true } }) {
        items {
          id
          email
          familyName
          givenName
        }
      }
    }
  }
`;

const updateParticipationQuery = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationSponsorQuery = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
      sponsor {
        id
        isDeleted
        priceRange {
          id
          name
          price
          start
          frontendNameColor
          backgroundColor
        }
      }
    }
  }
`;

const getEventAbstractServiceQuery = /* GraphQL */ `
  query GetEventAbstractService($id: ID!) {
    getEvent(id: $id) {
      id
      abstractService {
        id
        instructions
        isPublished
        reviewers {
          items {
            id
            participant {
              id
              email
              givenName
            }
            reviewerCategories {
              items {     
                reviewerCategoryReviewerId
                category {   
                  id
                  name
                }
                reviewerCategoryCategoryId
              }
            }
          }
        }
        abstractDocuments {
          items {
            id 
            authors {
              items {
                city
                country
                degree
                email
                id
                isPresentingAuthor
                jobTitle
                name
                preTitle
                speciality
                surname
                position
              }
            }
          }
        }
        presentationTypologies {
          items {
            id
            name
            presentationMode
            validationDate
            expirationDate
            isLateBreaker
            categories {
              items {
                id
                category {
                  id
                  name
                  subCategories {
                    items {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        categories {
          items {
            id
            name
            subCategories {
              items {
                id
                name
              }
            }
          }
        }
        tags
        agreement
        event {
          services(filter: { serviceName: { eq: "${constants.Services.ABSTRACT.key}" } }) {
            items {
              id
              serviceName
              customFields(filter: { isDeleted: { ne: true } }) {
                items {
                 controlType
                  label
                  id
                  isDeleted
                  contextsOfUse {
                    items {
                      isRequired
                      position
                      isHidden
                      contextName
                      id
                    }
                  }
                  label
                  options
                  id
                  placeholder
                }
              }
            }
          }
          standardFields {
            items {
              controlType
              label
              id
              isDeleted
              contextsOfUse {
                items {
                  isRequired
                  position
                  isHidden
                  contextName
                  id
                }
              }
            }
          }
        }
        aifaEuropeanCvTemplate {
          id
          size
          extension
          originalName
        }
        aifaDisclosureTemplate {
          id
          size
          extension
          originalName
        }
        isAifaEvent
        assesment
        maxAbstractsPerAuthor
        maxCategoriesPerReviewer
        isKeywordAddEnabled
        minNumberKeyword
        maxNumberKeyword
        isImageUploadEnabled
        maxTitleCharacters
        maxBodyCharacters
        isExternalUploadEnabled
        maxNumberExternalUpload
        extensionsExternalUpload
        maxDimensionExternalUpload
        isRichTextUploadEnabled
        maxNumberRichTextUpload
        extensionsRichTextUpload
        maxDimensionRichTextUpload
        isRequestAifaForPresenter
        registry
      }
    }
  }
`;

const getEventAbstractServiceForNewAbstractQuery = /* GraphQL */ `
  query GetEventAbstractService($id: ID!) {
    getEvent(id: $id) {
      id
      abstractService {
        id
        instructions
        isPublished
        presentationTypologies {
          items {
            id
            name
            presentationMode
            validationDate
            expirationDate
            categories {
              items {
                id
                category {
                  id
                  name
                  subCategories {
                    items {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        categories {
          items {
            id
            name
            subCategories {
              items {
                id
                name
              }
            }
          }
        }
        tags
        agreement
        event {
          services(filter: { serviceName: { eq: "${constants.Services.ABSTRACT.key}" } }) {
            items {
              id
              serviceName
              customFields(filter: { isDeleted: { ne: true } }) {
                items {
                  contextsOfUse {
                    items {
                      isRequired
                      position
                      isHidden
                      contextName
                      id
                    }
                  }
                  label
                  options
                  id
                  placeholder
                }
              }
            }
          }
        }
        aifaEuropeanCvTemplate {
          id
          size
          extension
          originalName
        }
        aifaDisclosureTemplate {
          id
          size
          extension
          originalName
        }
        isAifaEvent
        assesment
        maxAbstractsPerAuthor
        maxCategoriesPerReviewer
        isKeywordAddEnabled
        minNumberKeyword
        maxNumberKeyword
        isImageUploadEnabled
        maxTitleCharacters
        maxBodyCharacters
        isExternalUploadEnabled
        maxNumberExternalUpload
        extensionsExternalUpload
        maxDimensionExternalUpload
        isRichTextUploadEnabled
        maxNumberRichTextUpload
        extensionsRichTextUpload
        maxDimensionRichTextUpload
      }
    }
  }
`;

const createAbstractDocumentMutation = /* GraphQL */ `
  mutation CreateAbstractDocument(
    $input: CreateAbstractDocumentInput!
    $condition: ModelAbstractDocumentConditionInput
  ) {
    createAbstractDocument(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateAbstractDocumentMutation = /* GraphQL */ `
  mutation UpdateAbstractDocument(
    $input: UpdateAbstractDocumentInput!
    $condition: ModelAbstractDocumentConditionInput
  ) {
    updateAbstractDocument(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteAbstractDocumentMutation = /* GraphQL */ `
  mutation UpdateAbstractDocument(
    $input: UpdateAbstractDocumentInput!
    $condition: ModelAbstractDocumentConditionInput
  ) {
    updateAbstractDocument(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateReviewerMutation = /* GraphQL */ `
  mutation UpdateReviewer(
    $input: UpdateReviewerInput!
    $condition: ModelReviewerConditionInput
  ) {
    updateReviewer(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateReviewMutation = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
    }
  }
`;

const deleteReviewMutation = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
    }
  }
`;

/**handle participation */
const getParticipationsQuery = /* GraphQL */ `
  query GetParticipation($id: ID!) {
    getParticipation(id: $id) {
      id
      cluster
      isSpeaker
      isParsonalDetailsEditedAtLeastOnce
      myAgenda {
        items {
          id
          session {
            id
            coverImage {
              id
              originalName
              extension
              size
            }
            name
            start
            end
          }
        }
      }
      givenName
      familyName
      email
      filledPapers {
        items {
          id
          type
        }
      }
    }
  }
`;

const getAuthorQuery = /* GraphQL */ `
  query GetAbstractAuthor($id: ID!) {
    getAbstractAuthor(id: $id) {
      id
    }
  }
`;

const createAbstractPresenterMutation = /* GraphQL */ `
  mutation CreateAbstractPresenter(
    $input: CreateAbstractPresenterInput!
    $condition: ModelAbstractPresenterConditionInput
  ) {
    createAbstractPresenter(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

const updateAbstractPresenterMutation = /* GraphQL */ `
  mutation UpdateAbstractPresenter(
    $input: UpdateAbstractPresenterInput!
    $condition: ModelAbstractPresenterConditionInput
  ) {
    updateAbstractPresenter(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

const createAbstractAuthorMutation = /* GraphQL */ `
  mutation CreateAbstractAuthor(
    $input: CreateAbstractAuthorInput!
    $condition: ModelAbstractAuthorConditionInput
  ) {
    createAbstractAuthor(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      preTitle
      name
      surname
      email
      city
      country
      degree
      speciality
      jobTitle
      isPresentingAuthor
    }
  }
`;

const updateAbstractAuthorMutation = /* GraphQL */ `
  mutation UpdateAbstractAuthor(
    $input: UpdateAbstractAuthorInput!
    $condition: ModelAbstractAuthorConditionInput
  ) {
    updateAbstractAuthor(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

const deleteAbstractAuthorMutation = /* GraphQL */ `
  mutation DeleteAbstractAuthor(
    $input: DeleteAbstractAuthorInput!
    $condition: ModelAbstractAuthorConditionInput
  ) {
    deleteAbstractAuthor(input: $input, condition: $condition) {
      id
      affiliation {
        id
        affiliationNumber
        institution
        city
        country
        fiscalCode
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      fieldValues {
        items {
          id
          value
          fieldValueFieldDefinitionId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const updateFieldValueMutation = /* GraphQL */ `
  mutation UpdateFieldValue(
    $input: UpdateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    updateFieldValue(input: $input, condition: $condition) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const deleteFieldValueMutation = /* GraphQL */ `
  mutation DeleteFieldValue(
    $input: DeleteFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    deleteFieldValue(input: $input, condition: $condition) {
      id
      value
      fieldValueFieldDefinitionId
      createdAt
      updatedAt
      fieldDefinition {
        id
        controlType
        label
        placeholder
        options
        isDeleted
        contextsOfUse {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const createAbstractAffiliationMutation = /* GraphQL */ `
  mutation CreateAbstractAffiliation(
    $input: CreateAbstractAffiliationInput!
    $condition: ModelAbstractAffiliationConditionInput
  ) {
    createAbstractAffiliation(input: $input, condition: $condition) {
      id
      affiliationNumber
      institution
      city
      country
      fiscalCode
      createdAt
      updatedAt
    }
  }
`;

const updateAbstractAffiliationMutation = /* GraphQL */ `
  mutation UpdateAbstractAffiliation(
    $input: UpdateAbstractAffiliationInput!
    $condition: ModelAbstractAffiliationConditionInput
  ) {
    updateAbstractAffiliation(input: $input, condition: $condition) {
      id
      affiliationNumber
      institution
      city
      country
      fiscalCode
      createdAt
      updatedAt
    }
  }
`;

const deleteAbstractAffiliationMutation = /* GraphQL */ `
  mutation DeleteAbstractAffiliation(
    $input: DeleteAbstractAffiliationInput!
    $condition: ModelAbstractAffiliationConditionInput
  ) {
    deleteAbstractAffiliation(input: $input, condition: $condition) {
      id
      affiliationNumber
      institution
      city
      country
      fiscalCode
      createdAt
      updatedAt
    }
  }
`;
const createFileMutation = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      originalName
      size
      extension
      createdAt
      updatedAt
    }
  }
`;

const deleteFileMutation = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      originalName
      size
      extension
      createdAt
      updatedAt
    }
  }
`;

export const getEventAbstractService = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEventAbstractServiceQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent.abstractService))
      .catch((e) => {
        console.error('get-event-abstract-serivce', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEventAbstractServiceForNewAbstract = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEventAbstractServiceForNewAbstractQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent.abstractService))
      .catch((e) => {
        console.error('get-event-abstract-serivce', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createAbstractDocument = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createAbstractDocumentMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createAbstractDocument))
      .catch((e) => {
        console.error('create-event-abstract-document', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateAbstractDocument = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateAbstractDocumentMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateAbstractDocument))
      .catch((e) => {
        console.error('update-event-abstract-document', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateReviewer = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateReviewerMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateReviewer))
      .catch((e) => {
        console.error('update-reviewer', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateReview = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: updateReviewMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateReview))
      .catch((e) => {
        console.error('update-review', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteReview = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: deleteReviewMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.deleteReview))
      .catch((e) => {
        console.error('delete-review', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createAbstractPresenter = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createAbstractPresenterMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createAbstractPresenter))
      .catch((e) => {
        console.error('create-abstract-presenter', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateAbstractPresenter = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateAbstractPresenterMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateAbstractPresenter))
      .catch((e) => {
        console.error('update-abstract-presenter', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createAbstractAuthor = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createAbstractAuthorMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createAbstractAuthor))
      .catch((e) => {
        console.error('create-abstract-author', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateAbstractAuthor = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateAbstractAuthorMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateAbstractAuthor))
      .catch((e) => {
        console.error('update-abstract-author', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteAbstractAuthor = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: deleteAbstractAuthorMutation,
      variables: { input: { id } },
    })
      .then((response) => resolve(response.data.deleteAbstractAuthor))
      .catch((e) => {
        console.error('delete-abstract-author', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createFieldValue = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('create-field-value', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateFieldValue = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteFieldValue = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: deleteFieldValueMutation,
      variables: { input: { id } },
    })
      .then((response) => resolve(response.data.deleteFieldValue))
      .catch((e) => {
        console.error('delete-field-value', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createAbstractAffiliation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createAbstractAffiliationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createAbstractAffiliation))
      .catch((e) => {
        console.error('create-abstract-affiliation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateAbstractAffiliation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateAbstractAffiliationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateAbstractAffiliation))
      .catch((e) => {
        console.error('update-abstract-affiliation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteAbstractAffiliation = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: deleteAbstractAffiliationMutation,
      variables: { input: { id } },
    })
      .then((response) => resolve(response.data.deleteAbstractAffiliation))
      .catch((e) => {
        console.error('delete-abstract-affiliation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createFile = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: createFileMutation,
      variables: { input },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.createFile);
      })
      .catch((e) => {
        console.error('create-file', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteFile = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: deleteFileMutation,
      variables: { input: { id } },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.deleteFile);
      })
      .catch((e) => {
        console.error('delete-file', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const deleteAbstractDocument = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: deleteAbstractDocumentMutation,
      variables: {
        input: {
          id: id,
        },
      },
    })
      .then((response) => resolve(response.data.deleteAbstractDocumentMutation))
      .catch((e) => {
        console.error('delete-event-abstract-document', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

// query Bookings($sponsorId: ID!, $purchaseId: ModelIDKeyConditionInput, $filter: ModelBuyOperationFilterInput) {
//   buyOperationBySponsorIdPurchaseId(buyOperationSponsorId: $sponsorId, buyOperationPurchaseId: $purchaseId, filter: $filter, limit: 500) {
//     items {
//       id
//       bookingId
//       paymentType
//       buyOperationPurchaseId
//       sponsor {
//         name
//         id
//       }
//       purchase {
//         id
//         paymentType
//       }
//       expiringDate
//     }
//   }
// }

// {
//   "sponsorId": "515a8dc1-ed6d-4170-bc81-a2c6c3225dd1",
//   "buyOperationPurchaseId": "5b2caa17-fdfe-4c1c-8e18-50851b3412da",
//   "filter": {}

// }

// "filter": {"buyOperationPurchaseId": {"eq" : null}}

export const getByCognitoIdEventId = (userId, eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({
      query: getParticipantByCognitoIdEventId,
      variables: {
        cognitoUserId: userId,
        participationEventId: { eq: eventId },
      },
    })
      .then((response) => {
        const paxNotDeleted = response.data?.participantByCognitoIdEventId?.items?.filter(
          (x) => !x.isDeleted
        );
        const participation =
          paxNotDeleted?.length > 0 ? paxNotDeleted[0] : null;

        if (participation?.sponsor) {
          participation.sponsor = {
            ...participation.sponsor,
            publicPage: {
              ...(participation?.sponsor?.publicPage || {}),
              buyOperation:
                participation?.sponsor?.publicPage?.buyOperation ||
                participation?.sponsor?.publicPage?.sponsorPackageItem?.package
                  ?.buyOperations?.items?.[0],
            },
          };
        }
        resolve(participation);
      })
      .catch((e) => {
        console.error('get-participation-by-cognito-id-event-id', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const loadCognitoUserItParticipations = (
  userId,
  eventId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getParticipantByCognitoUserId,
      variables: {
        cognitoUserId: userId,
      },
    })
      .then(async ({ data }) => {
        resolve(data?.participantByCognitoUserId?.items);
      })
      .catch((e) => {
        console.error('list-cognito-user-id-participations', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getByCluster = (eventId, cluster, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: listEventParticipationsByCluster,
      variables: { id: eventId, cluster },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.participations.items);
      })
      .catch((e) => {
        console.error('list-event-participations-by-cluster', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const listParticipations = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: listEventParticipationsQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.participations.items);
      })
      .catch((e) => {
        console.error('list-event-participations', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getParticipation = async (id, useLoader = true) => {
  return new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationsQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data.getParticipation))
      .catch((e) => {
        console.error('shared-getParticipation-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
};

export const getParticipationsAbstracts = async (paxId) => {
  try {
    const { data } = await aws.API.graphql({
      query: getAbstracts,
      variables: { id: paxId },
    });

    const abstractDocuments = data.getParticipation.abstractDocuments;

    return abstractDocuments;
  } catch (err) {
    console.error(err);
  }
};

export const getAbstractDocumentDetail = (id, useLoader = true) => {
  return new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getAbstractDocumentNoOp,
      variables: { id },
    })
      .then((response) => {
        resolve(response.data.getAbstractDocument);
      })
      .catch((e) => {
        console.error('get-abstract-document', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
};

export const getAbstractAuthor = (id, useLoader = false) => {
  return new Promise((resolve, reject) => {
    useLoader && showLoader();
    aws.API.graphql({
      query: getAuthorQuery,
      variables: { id },
    })
      .then((response) => {
        resolve(response.data.getAbstractAuthor?.id);
      })
      .catch((e) => {
        console.error('get-abstract-author', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
};

export const getReviewerReviewsList = (id) => {
  return new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getReviewerReviews,
      variables: { id: id },
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getParticipation);
      })
      .catch((e) => {
        console.error('get-reviewer-reviews-list', e);
        reject();
      })
      .finally(hideLoader);
  });
};

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateParticipationQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-particpation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipationSponsor = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateParticipationSponsorQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-particpation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

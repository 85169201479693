import React from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

import registerIcon from './assets/images/register.svg';
import abstractIcon from './assets/images/abstract.svg';
import abstractGrantIcon from './assets/images/abstractGrant.svg';
import educationalGrantIcon from './assets/images/educationalGrant.svg';
import allotmentIcon from './assets/images/allotment.svg';
import scientificEventIcon from './assets/images/scientificEvent.svg';
import socialEventIcon from './assets/images/socialEvent.svg';
import programIcon from './assets/images/program.svg';
import visaIcon from './assets/images/visa.svg';

import { constants } from '@aim/common';
import { AimTypography, theme, useAimMediaQuery } from '@aim/components';

import { DataContainer } from './DataContainer';

const useStyles = makeStyles(() => ({
  cardRoot: (props) => ({
    paddingTop: props.isMobile ? 'auto' : 'calc(50% - 50px)',
    alignItems: props.isMobile ? 'center' : 'start',
    width: props.isMobile ? '100%' : '180px',
    justifyContent: 'center',
    height: '180px',
    borderRadius: 1,
    cursor: 'pointer',
    boxShadow: '0 0 black',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.light.grey,
    // border: isSelected
    //   ? 'solid 2px ' + orTheme.palette.action.selected
    //   : 'solid 2px transparent',
  }),
  cardContentRoot: {
    flex: 1,
    flexDirection: 'column',
    padding: '0!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const DefaultLanding = ({
  eventId,
  configuration,
  sendAbstractRoute,
  disabledCards,
  grants,
  eventInfo,
  intl,
  translation,
  isFaculty,
  agenda,
}) => {
  const history = useHistory();
  const i18n = translation.defaultLanding(intl);

  const { isMobile, isMobileOnly } = useAimMediaQuery();
  const classes = useStyles({ isMobile, isMobileOnly });

  const noCards =
    !configuration.welcomePageBuyTicket &&
    !configuration.welcomePageAllotment &&
    !configuration.welcomePageSendAbstract &&
    !configuration.welcomePageAbstractGrant &&
    !configuration.welcomePageSocialEvent &&
    !configuration.welcomePageScientificEvent &&
    !configuration.welcomePageEducationalGrant &&
    !configuration.welcomePageVisa &&
    !configuration.welcomePageProgram;

  return (
    <DataContainer>
      <div
        style={{
          width: isMobile ? '100%' : '85%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            marginTop: noCards ? 0 : 20,
            marginBottom: noCards ? 0 : 20,
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {configuration.welcomePageBuyTicket && (
              <Grid item xs={12} sm={'auto'}>
                <Card
                  className={classes.cardRoot}
                  style={
                    disabledCards.buyTickets
                      ? {
                          backgroundColor:
                            theme.colors.greyScale.backgroundGrey,
                          cursor: 'default',
                        }
                      : {}
                  }
                >
                  <CardContent
                    classes={{ root: classes.cardContentRoot }}
                    onClick={() => {
                      disabledCards.buyTickets
                        ? false
                        : history.push(`/events/${eventId}/tickets/profile`);
                    }}
                  >
                    <img
                      src={registerIcon}
                      style={disabledCards.buyTickets ? { opacity: 0.5 } : {}}
                    />
                    <AimTypography
                      variant="h6"
                      style={
                        disabledCards.buyTickets && {
                          color: theme.colors.greyScale.grey1,
                        }
                      }
                    >
                      {configuration?.welcomePageBuyTicketLabelItem ||
                        i18n.registration}
                    </AimTypography>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {isFaculty && (
              <Grid item xs={12} sm={'auto'}>
                <Card className={classes.cardRoot}>
                  <CardContent
                    classes={{ root: classes.cardContentRoot }}
                    onClick={() => {
                      history.push(`/events/${eventId}/faculty`);
                    }}
                  >
                    <img src={abstractIcon} />
                    <AimTypography variant="h6">
                      {configuration?.welcomePageFacultyLabel || 'Faculty Page'}
                    </AimTypography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {configuration.welcomePageSendAbstract &&
              eventInfo.availableServices.includes(
                constants.Services.ABSTRACT.key
              ) && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent
                      classes={{ root: classes.cardContentRoot }}
                      onClick={() => {
                        history.push(
                          sendAbstractRoute || `/events/${eventId}/abstracts`
                        );
                      }}
                    >
                      <img src={abstractIcon} />
                      <AimTypography variant="h6">
                        {configuration?.welcomePageSendAbstractLabelItem ||
                          i18n.abstract}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            {configuration.welcomePageAbstractGrant &&
            grants.length &&
            grants.findIndex((x) => x.type === 'abstract') > -1 &&
            eventInfo.availableServices.includes(
              constants.Services.GRANT.key
            ) ? (
              <Grid
                item
                xs={12}
                sm={'auto'}
                onClick={() =>
                  history.push(`/events/${eventId}/grants/abstract`)
                }
              >
                <Card className={classes.cardRoot}>
                  <CardContent classes={{ root: classes.cardContentRoot }}>
                    <img src={abstractGrantIcon} />
                    <AimTypography variant="h6">
                      {configuration?.welcomePageAbstractGrantLabelItem ||
                        i18n.abstractGrant}
                    </AimTypography>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            {configuration.welcomePageEducationalGrant &&
            grants.length &&
            grants.findIndex((x) => x.type === 'educational') > -1 &&
            eventInfo.availableServices.includes(
              constants.Services.GRANT.key
            ) ? (
              <Grid
                item
                xs={12}
                sm={'auto'}
                onClick={() =>
                  history.push(`/events/${eventId}/grants/educational`)
                }
              >
                <Card className={classes.cardRoot}>
                  <CardContent classes={{ root: classes.cardContentRoot }}>
                    <img src={educationalGrantIcon} />
                    <AimTypography variant="h6" textAlign="center">
                      {configuration?.welcomePageEducationalGrantLabelItem ||
                        i18n.educationalGrant}
                    </AimTypography>
                  </CardContent>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            {configuration.welcomePageAllotment &&
              eventInfo.availableServices.includes(
                constants.Services.ALLOTMENT.key
              ) && (
                <Grid item xs={12} sm={'auto'}>
                  <Card
                    className={classes.cardRoot}
                    onClick={() => {
                      history.push(`/events/${eventId}/allotment`);
                    }}
                  >
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={allotmentIcon} />
                      <AimTypography variant="h6">
                        {configuration?.welcomePageAllotmentLabelItem ||
                          i18n.allotment}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            {configuration.welcomePageScientificEvent && (
              <Grid item xs={12} sm={'auto'}>
                <Card
                  className={classes.cardRoot}
                  onClick={() =>
                    history.push(
                      `/events/${eventId}/additional-services/${constants.AdditionalServicesServiceTypology.scientific.id}`
                    )
                  }
                >
                  <CardContent classes={{ root: classes.cardContentRoot }}>
                    <img src={scientificEventIcon} />
                    <AimTypography
                      variant="h6"
                      boxStyle={{ fontWeight: 'bold', margin: '10px 10px 0px' }}
                    >
                      {configuration?.welcomePageScientificEventLabelItem ||
                        i18n.attendScientificEvents}
                    </AimTypography>
                    {/* <AimTypography
                      variant="h6"
                      textAlign="center"
                      boxStyle={{
                        fontWeight: 'normal',
                        margin: '0px 10px 5px',
                      }}
                    >
                      {i18n.attendScientificEventsSubTitle}
                    </AimTypography> */}
                  </CardContent>
                </Card>
              </Grid>
            )}
            {configuration.welcomePageSocialEvent && (
              <Grid item xs={12} sm={'auto'}>
                <Card className={classes.cardRoot}>
                  <CardContent
                    classes={{ root: classes.cardContentRoot }}
                    onClick={() =>
                      history.push(
                        `/events/${eventId}/additional-services/${constants.AdditionalServicesServiceTypology.social.id}`
                      )
                    }
                  >
                    <img src={socialEventIcon} />
                    <AimTypography
                      variant="h6"
                      boxStyle={{ fontWeight: 'bold', margin: '10px 10px 0px' }}
                    >
                      {configuration?.welcomePageSocialEventLabelItem ||
                        i18n.attendSocialEvents}
                    </AimTypography>
                    {/* <AimTypography
                      variant="h6"
                      textAlign="center"
                      boxStyle={{
                        fontWeight: 'normal',
                        margin: '0px 10px 5px',
                      }}
                    >
                      {i18n.attendSocialEventsSubTitle}
                    </AimTypography> */}
                  </CardContent>
                </Card>
              </Grid>
            )}
            {configuration.welcomePageVisa &&
              eventInfo.availableServices.includes(
                constants.Services.VISA.key
              ) && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent classes={{ root: classes.cardContentRoot }}>
                      <img src={visaIcon} />
                      <AimTypography
                        variant="h6"
                        onClick={() => {
                          history.push(`/events/${eventId}/visa`);
                        }}
                      >
                        {configuration?.welcomePageVisaLabelItem || i18n.visa}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            {configuration.welcomePageProgram &&
              agenda.sessions?.items?.length > 0 && (
                <Grid item xs={12} sm={'auto'}>
                  <Card className={classes.cardRoot}>
                    <CardContent
                      classes={{ root: classes.cardContentRoot }}
                      onClick={() =>
                        history.push(`/events/${eventId}/landing-agenda`)
                      }
                    >
                      <img src={programIcon} />
                      <AimTypography
                        variant="h6"
                        boxStyle={{
                          fontWeight: 'bold',
                          margin: '10px 10px 0px',
                        }}
                      >
                        {configuration?.welcomePageProgramLabelItem ||
                          i18n.program}
                      </AimTypography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
          </Grid>
        </div>
      </div>
    </DataContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';

import { appState, aws, constants } from '@aim/common';
import {
  CustomIntl,
  AimTypography,
  ProgramTemplate,
  useAimMediaQuery,
} from '@aim/components';

import { translation } from './programPublicPage/translation';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const MainContainer = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      paddingTop: 20,
      paddingBottom: 20,
      alignItems: 'center',
    }}
  >
    {children}
  </div>
);

const mapPrintOptions = (printOptionsItem) =>
  printOptionsItem.items.map((po) => {
    let nextPo = {
      ...po,
      position: po.position ? JSON.parse(po.position) : null,
    };
    let attendeePrintOption = {};
    if (po.key === constants.PrintOptions.attendeeName.key) {
      attendeePrintOption = nextPo;
    } else if (po.key === constants.PrintOptions.abstractAuthors.key) {
      const isUniformToFaculty =
        nextPo?.position?.[
          constants.PrintOptions.abstractAuthors.options.position?.[2].key
        ];
      if (isUniformToFaculty) {
        nextPo = {
          ...nextPo,
          isBold: attendeePrintOption.isBold,
          isItalic: attendeePrintOption.isItalic,
          isUnderlined: attendeePrintOption.isUnderlined,
          position: {
            ...nextPo.position,
            ...attendeePrintOption.position,
          },
        };
      }
    }

    return nextPo;
  });

const ProgramPublicPage = () => {
  const { eventId } = useParams();

  const intl = CustomIntl(useIntl());
  const i18n = translation.programPublicPage(intl);

  const [program, setProgram] = useState();
  const [printOptions, setPrintOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isMobileOnly } = useAimMediaQuery();

  useEffect(() => {
    const fetchData = async () => {
      const res = await aws.standardAPI.post(
        'aimlambdaproxy',
        '/admin/public-program',
        {
          body: {
            eventId,
          },
        }
      );
      return res;
    };

    showLoader();
    setIsLoading(true);
    fetchData()
      .then((result) => {
        setProgram(result?.sessions);
        setPrintOptions(mapPrintOptions(result?.printOptionsItem));
        setIsLoading(false);
      })
      .finally(() => hideLoader());
  }, []);

  // const onClickBack = () => {
  //   history.push(
  //     `/events/${eventId}/landing`
  //   );
  // };

  return (
    <MainContainer>
      <Grid
        container
        style={{
          width: isMobileOnly ? '95%' : '70%',
          minHeight: 'calc(100vh - 260px)',
        }}
      >
        {/* <Grid item xs={12}>
          <AimIconAndTextButton
            onClick={onClickBack}
            text={i18n.back}
            variant="text"
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </Grid> */}
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {isLoading ? (
            <AimTypography>{i18n.loading}</AimTypography>
          ) : program && printOptions ? (
            <ProgramTemplate
              intl={intl}
              sessions={program}
              eventId={eventId}
              isFrontOffice
              previewPdf={false}
              title={i18n.title}
              hideDownloadPdfAndTitle={false}
              inputPrintOptions={printOptions}
            />
          ) : (
            <AimTypography>{i18n.notFound}</AimTypography>
          )}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ProgramPublicPage;

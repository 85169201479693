import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getAbstractDocumentQuery = /* GraphQL */ `
  query GetAbstractDocument($id: ID!) {
    getAbstractDocument(id: $id) {
      id
      title
      attachments {
        items {
          id
          name: originalName
          isMandatory
          size
          updatedAt
          extension
          createdAt
        }
      }
      abstractSections {
        items {
          id
          value
          createdAt
          updatedAt
          fieldDefinition {
            id
            label
            contextsOfUse {
              items {
                contextName
                position
              }
            }
          }
        }
      }
      status
      cod0
      cod1
      cod2
      url
      int
      disc
      preferredExposure
      category {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategory {
        id
        name
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      proposedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      acceptedPresentationTypology {
        id
        name
        description
        category {
          id
          name
          createdAt
          updatedAt
        }
        presentationMode
        presentationRequired
        createdAt
        updatedAt
      }
      processingStatus
      tags
      keywords
      reviews {
        items {
          id
          status
          scores
          reviewResponse
          reviewer {
            id
            participant {
              givenName
              familyName
              email
              id
            }
          }
          isViewNoteFrontend
        }
        nextToken
      }
      isReadyToReview
      affiliations {
        items {
          id
          affiliationNumber
          institution
          city
          country
          fiscalCode
          createdAt
          updatedAt
        }
        nextToken
      }
      authors {
        items {
          id
          affiliation {
            id
            institution
          }
          city
          country
          degree
          email
          id
          isPresentingAuthor
          jobTitle
          name
          preTitle
          speciality
          surname
          position
        }
      }
      images
      isDeleted
      createdAt
      updatedAt
      participant {
        id
        owner
        cluster
        paymentStatus
        registrationChannel
        isInvited
        status
        participationEventId
        reviewer {
          id
          createdAt
          updatedAt
        }
        abstractDocuments {
          nextToken
        }
        webcastSpeeches {
          nextToken
        }
        surveyInvitations {
          nextToken
        }
        fieldValues {
          nextToken
        }
        issues {
          nextToken
        }
        registrationFee
        sponsor {
          id
          name
          description
          therapeuticArea
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      abstractService {
        id
        skipReviewForConflictLabel
        isViewAbstractResult
        categories {
          nextToken
        }
        presentationTypologies {
          items {
            id
            name
          }
        }
        abstractServiceEventId
        abstractDocuments {
          nextToken
        }
        reviewCriteriaModel
        blindReviewProcedure
        instructions
        tags
        abstractTerm
        firstReminder
        secondReminder
        reviewTerm
        draftReminder
        maxAbstractsPerAuthor
        isCountryConflictDisabled
        reviewersQuantity
        agreement
        reviewers {
          nextToken
        }
      }
    }
  }
`;

export const getAbstractDocument = (id) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({ query: getAbstractDocumentQuery, variables: { id } })
      .then((response) => resolve(response.data.getAbstractDocument))
      .catch((e) => {
        console.error('get-abstract-document', e);
        reject();
      })
      .finally(hideLoader);
  });

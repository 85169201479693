import React, { useMemo } from 'react';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  AimIconButton,
  AimDataGrid,
  styled,
  theme,
  useAimMediaQuery,
} from '@aim/components';

import { useDataHelper } from './../../shared/dataHelper';

const GridWrapper = styled('div')({
  backgroundColor: theme.colors.greyScale.white,
  paddingInline: 8,
  paddingBottom: 20,
});

const PhysicalStandFloorGrid = ({
  i18n,
  items,
  setDialog,
  onAddToCart,
  paymentType,
}) => {
  //Hooks
  const { formatNumber } = useDataHelper();
  const { isMobileOnly } = useAimMediaQuery();

  // Columns
  const loadColumns = ({ i18n }) => [
    {
      field: 'code',
      headerName: i18n.table.columns.code.label,
      width: isMobileOnly ? 80 : 100,
    },
    {
      field: 'name',
      headerName: i18n.table.columns.name.label,
      width: isMobileOnly ? 100 : 350,
    },
    {
      field: 'netPrice',
      headerName: i18n.table.columns.netPrice.label,
      width: isMobileOnly ? 80 : 150,
      valueGetter: ({ row }) => Number(row?.netPrice),
      valueFormatter: ({ value }) => (value >= 0 ? formatNumber(value) : ''),
    },
    {
      field: 'vatRate',
      headerName: i18n.table.columns.vatRate.label,
      width: isMobileOnly ? 80 : 150,
      renderCell: ({ row }) => {
        return `${row.vatRate} %`;
      },
    },
    {
      field: 'taxedPrice',
      headerName: i18n.table.columns.taxedPrice.label,
      width: isMobileOnly ? 80 : 150,
      valueGetter: ({ row }) => Number(row?.taxedPrice),
      valueFormatter: ({ value }) => (value >= 0 ? formatNumber(value) : ''),
    },
    {
      field: 'status',
      headerName: i18n.table.columns.status.label,
      width: isMobileOnly ? 80 : 150,
      renderCell: ({ row }) => {
        return !row?.buyOperation
          ? i18n.table.status.free.label
          : !row?.buyOperation?.purchase
          ? i18n.table.status.booked.label
          : i18n.table.status.purchased.label;
      },
    },
    {
      field: 'purchasedBy',
      headerName: i18n.table.columns.purchasedBy.label,
      width: isMobileOnly ? 150 : 300,
      renderCell: ({ row }) => {
        return row.purchasedBy || '-';
      },
    },
    // {
    //   field: 'actions',
    //   headerName: '',
    //   type: 'button',
    //   width: 200,
    //   pinnable: false,
    //   hideSortIcons: true,
    //   renderCell: ({ row }) => {
    //     // eventLists, eventInfo, eventId
    //     return renderRowButtons(row, i18n);
    //   },
    // },
    {
      field: 'actions',
      type: 'actions',
      width: isMobileOnly ? 40 : 150,
      getActions: ({ row }) => {
        return [
          <AimIconButton
            key="0"
            {...{
              ...(isMobileOnly && { showInMenu: true }),
              variant: 'lightBlueFill',
              small: true,
              onClick: () => setDialog({ isOpen: true, row }),
            }}
          >
            <VisibilityIcon />
          </AimIconButton>,
          <AimIconButton
            key="1"
            {...{
              ...(isMobileOnly && { showInMenu: true }),
              variant: 'yellowFill',
              small: true,
              onClick: () => onAddToCart(row.id, true),
              disabled: row?.buyOperation?.id || !paymentType,
            }}
          >
            <SwapHorizIcon />
          </AimIconButton>,
          <AimIconButton
            key="2"
            {...{
              ...(isMobileOnly && { showInMenu: true }),
              variant: 'violetFill',
              small: true,
              onClick: () => onAddToCart(row.id),
              disabled: row?.buyOperation?.id || !paymentType,
            }}
          >
            <ShoppingCartIcon />
          </AimIconButton>,
        ];
      },
    },
  ];

  const columns = useMemo(
    () =>
      loadColumns({
        i18n,
      }),
    [i18n]
  );

  //Functions

  return (
    <GridWrapper>
      <AimDataGrid
        columns={columns}
        rows={items}
        tableName="physical stand items"
        pinnedColumns={{ left: [], right: ['actions'] }}
        sortModel={[{ field: 'code', sort: 'asc' }]}
        disableMultipleSelection
        autoHeight
      />
    </GridWrapper>
  );
};

export { PhysicalStandFloorGrid };

import React, { useRef, useState, /* useMemo, */ useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  format,
  isWithinInterval,
  isAfter,
  parseISO,
  isBefore,
} from 'date-fns';

import EuroIcon from '@material-ui/icons/Euro';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import PublishIcon from '@material-ui/icons/Publish';
import LoopIcon from '@material-ui/icons/Loop';

import { aws, constants, utilities, appState } from '@aim/common';
import {
  CustomIntl,
  AimTablePage,
  // MassiveImportDialog,
  AimSnackbarSeverity,
  AimSnackbar,
  AimConfirmDialog,
} from '@aim/components';

import { translation } from './agencyGroupMembers/index';

import {
  updateParticipationIsDeleted,
  // updateGroup,
  closeAgencyGroup,
} from './agencyGroupMembers/gqlHelper';
import { AgencyGroupDialog } from './agency/agencyHomepage';
import { agencyGqlHelper } from './agency/shared/agencyGqlHelper';
import { sortBy } from 'lodash';
import { endOfDay } from 'date-fns';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getAgencyGroupOrSponsorListQuery = (sponsorListId) => /* GraphQL */ `
  query GetAgencyGroupOrSponsorList(
    $id: ID!
    $productId: String!
    $eventId: ID!
    $gatewayServiceId: String
    $n2NConnectionType: String
  ) {
    getEvent(id: $eventId) {
      id
      agencyService {
        id
        groupDefinitionDeadline
        freezeDeadline
        allowPrepaid
        minNumberComponents
        penaltyPrice
      }
      fee {
        id
        vat {
          id
          amount
        }
        feeBracketModels{
          items{
            id
            start
            end
            name
          }
        }
      }
      gateways(filter: { service: { eq: $gatewayServiceId } }) {
        items {
          id
          service
          shop {
            id
            paymentType
            gatewayType
            iban
            ibanCausal
            addressedTo
          }
        }
      }
      standardFields(filter: { isDeleted: { ne: true } }) {
        items {
          controlType
          id
          isDeleted
          label
          options
          placeholder
          contextsOfUse(filter: { contextName: { eq: "${
            constants.Clusters.Pax.id
          }" } }) {
            items {
              id
              position
              contextName
              isRequired
              isHidden
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "${
        constants.Services.PARTICIPATION.key
      }" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: false } }) {
            items {
              controlType
              id
              isDeleted
              label
              options
              placeholder
              contextsOfUse(filter: { contextName: { eq: "${
                constants.Clusters.Pax.id
              }" } }) {
                items {
                  id
                  position
                  contextName
                  isRequired
                  isHidden
                }
              }
            }
          }
        }
      }
    }
    productByProductId(productId: $productId) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        cartChildren {
          items {
            id
            productId
            clientId
            clientType
            serviceId
            serviceType
            quantity
            participationType
          }
        }
        payment {
          id
          isDeleted
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentMetadata
        }
      }
    }
    getAgencyGroup: ${
      sponsorListId ? 'getSponsorList' : 'getAgencyGroup'
    }(id: $id) {
      id
      name
      status
      closingDate
      isDeleted
      ${
        sponsorListId
          ? `purchases(filter: { isDeleted: { ne: true } }) {
        items {
          id
          isDeleted
          purchasedSubscriptions
          quantity
          participationMode
          feeDateRange {
            id
            start
            end
            label
          }
          profile {
            id
            name
          }
          additionalService {
            id
            title
            typology
          }
          buyOperation {
            id
            purchase {
              id
              payment {
                id
                paymentType
                paymentId
                paymentIdIngenico
                paymentIdGpWebpay
                paymentIdMonetaOnLine
                paymentError
              }
            }
          }
        }
      }
      sponsor {
        id
        name
        freeListMembers
        freeListParticipationMode
        freeListTicket{
          id
          label
        }
        freeListProfile {
          id
          name
        }
      }`
          : ''
      }
      participations(filter: { isDeleted: { ne: true } }) {
        items {
          id
          username
          givenName
          familyName
          email
          createdAt
          updatedAt
          isDeleted
          type
          phone
          notes
          modificationRequestNameChangeUsername
          n2nConnection(filter: {type: {eq: $n2NConnectionType}}) {
            items {
              id
              n2NConnectionsTableAdditionalServicesId
              n2NConnectionsTableParticipationId
              type
              status
              additionalService: additionalServices {
                id
                title
                typology
                amount1
                amount2
                participationMode: participationType
                additionaServiceVat1 {
                  id
                  amount
                }
                additionaServiceVat2 {
                  id
                  amount
                }
                prices {
                  items {
                    start
                    end
                    name
                    netPrice1
                    netPrice2
                    vat1 {
                      id
                      amount
                    }
                    vat2 {
                      id
                      amount
                    }
                  } 
                }
              }
            }
          }
          profile {
            id
            name
            profileFeeBrackets {
              items {
                id
                priceOnAir
                priceOnSite
                feeBracket {
                  id
                  feeDateRange {
                    id
                    start
                    end
                    label
                  }
                  feeBracketModel {
                    id
                    start
                    end
                  }
                }
              }
            }
          }
          feeDateRange {
            id
            label
            start
            end
          }
          fieldValues {
            items {
              id
              fieldValueFieldDefinitionId
              value
            }
          }
          participationHistorical {
            items {
              id
              modificationRequest {
                id
                requestStatus
                participationId
                createdAt
                updatedAt
                penaltyPrice
                type
              }
            }
          }
          cluster
          isParticipant
          isSpeaker
          isReviewer
        }
      }
      modificationRequests(filter: { isDeleted: { ne: true } }) {
        items {
          id
          familyName
          givenName
          email
          status
          phone
          notes
          createdAt
          updatedAt
          isDeleted
          modificationRequest {
            id
            requestId
            participationId
            requestStatus
            createdAt
            updatedAt
            penaltyPrice
            type
            participationRequest {
              isDeleted
              participationSubject {
                id
                isDeleted
              }
            }
          }
          profile {
            id
            name
          }
          cluster
          isParticipant
          isSpeaker
          isReviewer
        }
      }
    }
  }
`;

// CANDIDATO UTILITIES
const calculateTotalAmountDue = (rows, totalPenaltiesRef) => {
  return (
    rows.reduce((prev, curr) => {
      const totalRowPrice =
        (curr.ticketPrice || 0) + (curr.additionalServicesPrice || 0);
      prev += totalRowPrice;
      return prev;
    }, 0) + (totalPenaltiesRef.current || 0)
  );
};

// CANDIDATO UTILITIES
const checkIsBeforeFreezeDeadline = (agencyServiceRef) => {
  return agencyServiceRef.current?.freezeDeadline
    ? isBefore(new Date(), parseISO(agencyServiceRef.current?.freezeDeadline))
    : true;
};

// CANDIDATO UTILITIES
const generateAllPlaces = (sponsorListId, data) => {
  let allPlaces = {};

  if (sponsorListId) {
    const sponsor = data.getAgencyGroup.sponsor;
    const freeMembers = sponsor?.freeListMembers || 0;
    const freePlaces =
      freeMembers > 0
        ? {
            [`${sponsor?.freeListProfile?.id}-${sponsor?.freeListTicket?.id}-${
              sponsor?.freeListParticipationMode ||
              constants.EventTypes.PHYSICAL.id
            }`]: {
              remainingPlaces: freeMembers,
            },
          }
        : {};

    allPlaces = data.getAgencyGroup.purchases.items
      .filter(
        (p) =>
          p?.feeDateRange?.id &&
          p?.buyOperation?.purchase &&
          utilities.isValidPurchase(p.buyOperation.purchase)
      )
      .reduce((prev, curr) => {
        if (
          prev[
            `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.participationMode}`
          ]
        ) {
          prev[
            `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.participationMode}`
          ].remainingPlaces += curr.quantity;
        } else {
          prev[
            `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.participationMode}`
          ] = {
            remainingPlaces: curr.quantity,
          };
        }
        return prev;
      }, freePlaces);
  } else {
    allPlaces = data.productByProductId.items
      .filter(
        (p) =>
          p.serviceType === constants.ProductType.agencyGroupPrepaid &&
          utilities.isValidPaymentForAgency(p.payment)
      )
      .flatMap((p) => {
        const paymentMetadata = JSON.parse(p.payment.paymentMetadata);
        return p.cartChildren.items.map((c) => {
          const bookingItem = paymentMetadata.find(
            (m) => m.productTableId === c.id
          );
          return {
            ...c,
            key: `${bookingItem?.profile}-${bookingItem?.feeDateRange}-${bookingItem.participationMode}`,
            price: bookingItem?.price || 0,
          };
        });
      })
      .reduce((prev, curr) => {
        if (!prev[curr.key]) {
          prev[curr.key] = { remainingPlaces: curr.quantity };
        } else {
          prev[curr.key].remainingPlaces += curr.quantity;
        }
        return prev;
      }, {});
  }
  return allPlaces;
};

// CANDIDATO UTILITIES
const getModificationData = (p) => {
  // get num of modification requests by status
  const initRes = {
    [constants.ModificationRequestStatus.ACCEPTED]: 0,
    [constants.ModificationRequestStatus.REJECTED]: 0,
    [constants.ModificationRequestStatus.PENDING]: 0,
  };

  const modReqs = p.participationHistorical.items.reduce((res, curr) => {
    if (curr.modificationRequest) {
      res[curr.modificationRequest.requestStatus] += 1;
    }
    return res;
  }, initRes);

  // composing of string for modification column
  const modification = Object.keys(modReqs).reduce((res, curr) => {
    if (res !== '' && modReqs[curr] > 0) {
      res += '\n';
    }
    if (modReqs[curr] > 0) {
      return (res += `${curr}${
        p.participationHistorical.items.length > 1 ? `(${modReqs[curr]})` : ''
      }`);
    } else {
      return (res += '');
    }
  }, '');

  return { modification: { label: modification, reqs: modReqs } };
};

const AgencyGroupDetail = () => {
  const intl = CustomIntl(useIntl());
  const { eventId, agencyId, groupId, sponsorListId } = useParams();
  const agencyHelper = agencyGqlHelper();
  const i18n = translation.aimTablePage(intl);
  const history = useHistory();
  const loadDataRef = useRef();
  const gatewayRef = useRef();
  const ticketVatRef = useRef();
  const totalPenaltiesRef = useRef(0);
  const modificationRequestsRef = useRef(0);
  const allPlacesWithRemainingRef = useRef({});
  const modificationReqsAcceptedRef = useRef(0);
  const agencyGroupRef = useRef();
  const firstBracketModel = useRef();
  const isPostDeadlineRef = useRef();
  const agencyServiceRef = useRef();
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [isCalcBudgetDialogOpen, setIsCalcBudgetDialogOpen] = useState(false);
  const cluster = sponsorListId
    ? constants.Clusters.SponsorList.id
    : constants.Clusters.Groups.id;

  const [closeGroupConfirmDialog, setCloseGroupConfirmDialog] = useState({
    isOpen: false,
  });

  useEffect(() => {
    const fetchEventGateway = async () => {
      const nextGateway = await agencyHelper.getEventGateway({
        id: eventId,
        serviceId: constants.GatewayServices().GROUP.key,
      });

      gatewayRef.current = nextGateway;
    };

    if (eventId) {
      fetchEventGateway();
      checkoutResponse();
    }
  }, [eventId]);

  const checkoutResponse = () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    // const paymentId = query.get('pid');

    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.buyBudgetSuccess,
      });
    }
  };

  const handleDelete = async (id) => {
    await updateParticipationIsDeleted(id);
  };

  const handlePayAmountDue = async (rows, totalPenaltiesRef) => {
    const bookings = rows.reduce((bookings, curr) => {
      const { additionalServices, ...rest } = curr.productData || {};
      if (curr.ticketPrice > 0) {
        const bookingItem = bookings.find(
          (b) =>
            b.profileFeeBracketId === curr.productData.profileFeeBracketId &&
            b.participationMode === curr.productData.participationMode
        );
        if (bookingItem) {
          bookingItem.quantity += 1;
        } else {
          bookings.push({
            quantity: 1,
            ...rest,
            productType: constants.ProductType.registration,
          });
        }
      }
      additionalServices
        ?.filter((as) => as.price > 0)
        .forEach((as) => {
          const bookingItem = bookings.find((b) => b.id === as.id);
          if (bookingItem) {
            bookingItem.quantity += 1;
          } else {
            bookings.push({
              ...as,
              id: as.id,
              additionalServiceId: as.id,
              quantity: 1,
              productType:
                as.typology ===
                constants.AdditionalServicesServiceTypology.scientific.id
                  ? constants.ProductType.scientificEvents
                  : constants.ProductType.socialEvents,
            });
          }
        });
      return bookings;
    }, []);

    if (totalPenaltiesRef.current > 0) {
      bookings.push({
        id: 'penalties',
        quantity: 1,
        netPrice: utilities.encodeDbNumber(totalPenaltiesRef.current),
        vatRate: 0,
        price: utilities.encodeDbNumber(totalPenaltiesRef.current),
        productType: constants.ProductType.penalties,
      });
    }

    history.push(`/events/${eventId}/agency/${agencyId}/billing-informations`, {
      bookings,
      groupId,
      sponsorListId,
      gateway: gatewayRef.current,
      prodId: sponsorListId ? sponsorListId : groupId,
      serviceId: eventId,
      serviceType: constants.ProductType.agencyGroup,
      clientId: agencyId,
      clientType: constants.Clusters.Agency.id,
    });
  };

  // const canNotAddMember = (checked, getAgencyGroup) => {
  //   return !(
  //     (
  //       (timeline?.canAddMember && !getAgencyGroup?.closingDate) ||
  //       (timeline?.canAddMember && timeline?.applyPenalties)
  //     )
  //     // !agencyGroup?.penaltiesPaidAt)
  //   );
  // };

  // const canNotEditMember = (getAgencyGroup) => {
  //   return !(
  //     (
  //       (timeline?.canEditMember && !getAgencyGroup?.closingDate) ||
  //       (timeline?.canEditMember && timeline?.applyPenalties)
  //     )
  //     // !agencyGroup?.penaltiesPaidAt)
  //   );
  // };

  // const canNotCloseGroup = (data) => {
  //   return !(
  //     timeline?.canCloseGroup &&
  //     !data?.getAgencyGroup?.closingDate &&
  //     isMinNumberRef.current
  //   );
  // };

  // const canNotPayApprovedChanges = () => {
  //   return !timeline?.closePayingRequests;
  //   // return !(timeline?.closePayingRequests && !agencyGroup?.penaltiesPaidAt);
  // };

  const calculateRemainingPlaces = () =>
    Object.values(allPlacesWithRemainingRef.current)
      .flat()
      .reduce((prev, curr) => (prev += curr.remainingPlaces || 0), 0);

  const calcNModReqsToPay = (data, sortedProducts) => {
    const modReqsToPay = data?.getAgencyGroup?.modificationRequests?.items.filter(
      (req) => {
        return (
          req.modificationRequest &&
          (sortedProducts.length === 0 ||
            isAfter(
              parseISO(req.modificationRequest?.updatedAt),
              parseISO(sortedProducts[0]?.createdAt)
            )) &&
          req.modificationRequest.requestStatus ===
            constants.ModificationRequestStatus.ACCEPTED
        );
      }
    );

    return modReqsToPay;
  };

  const closeGroup = async () => {
    try {
      showLoader();
      await closeAgencyGroup(
        {
          id: sponsorListId ? sponsorListId : groupId,
          closingDate: new Date(),
          status: constants.GroupStatuses.CLOSED.id,
        },
        sponsorListId !== undefined
      );
      await aws.standardAPI.post('aimlambdaproxy', '/async/', {
        body: {
          lambdaName: 'aimConflictLambda',
          type: sponsorListId ? 'SPONSOR_LIST_CLOSED' : 'AGENCY_GROUP_CLOSED',
          id: sponsorListId ? sponsorListId : groupId,
        },
      });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.closeGroup.success,
      });
      loadDataRef.current();
    } catch (e) {
      console.error('error closing group ', e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.closeGroup.errorMessage,
      });
    }
    hideLoader();
  };

  const dataLoader = {
    query: getAgencyGroupOrSponsorListQuery(sponsorListId),
    variables: {
      id: sponsorListId ? sponsorListId : groupId,
      productId: sponsorListId ? sponsorListId : groupId,
      agencyId,
      eventId,
      gatewayServiceId: constants.GatewayServices().GROUP.key,
      n2NConnectionType:
        constants.N2NConnectionTypes.PARTICIPATION_ADDITIONAL_SERVICE,
    },
    transform: (data) => {
      modificationRequestsRef.current = 0;
      modificationReqsAcceptedRef.current = 0;
      totalPenaltiesRef.current = 0;
      agencyGroupRef.current = data.getAgencyGroup;
      agencyServiceRef.current = data.getEvent.agencyService;
      ticketVatRef.current = data.getEvent.fee.vat;
      isPostDeadlineRef.current =
        data.getAgencyGroup?.status === constants.GroupStatuses.CLOSED.id ||
        isAfter(
          new Date(),
          new Date(agencyServiceRef.current?.groupDefinitionDeadline)
        );
      firstBracketModel.current = sortBy(
        data.getEvent.fee.feeBracketModels.items,
        (f) => new Date(f.start)
      )?.[0];
      const sortedParticipations = sortBy(
        data.getAgencyGroup.participations?.items,
        (p) => new Date(p.createdAt)
      );

      // get all places with remaining object that contains object with key of profileId-feeDateRangeId-type with number of free places
      const allPlaces = generateAllPlaces(sponsorListId, data);

      //now get all from amount due purchases
      const sortedProducts = sortBy(
        data.productByProductId?.items.filter(
          (product) =>
            product.serviceType === constants.ProductType.agencyGroup &&
            utilities.isValidPaymentForAgency(product.payment)
        ),
        (p) => new Date(p.createdAt)
      ).reverse();

      const allPurchasesFromAmountDue = sortedProducts
        .flatMap((p) => {
          const paymentMetadata = JSON.parse(p.payment.paymentMetadata);
          return p.cartChildren.items.map((c) => {
            const bookingItem = paymentMetadata.find(
              (m) => m.productTableId === c.id
            );
            const key =
              bookingItem?.productType === constants.ProductType.registration
                ? `${bookingItem?.profileId}-${bookingItem?.feeDateRange}-${bookingItem.participationMode}`
                : bookingItem?.productType ===
                    constants.ProductType.scientificEvents ||
                  bookingItem?.productType ===
                    constants.ProductType.socialEvents
                ? bookingItem?.additionalServiceId
                : 'penalties';
            return {
              ...c,
              key,
              price: bookingItem?.price || 0,
            };
          });
        })
        .reduce((prev, curr) => {
          if (!prev[curr.key]) {
            prev[curr.key] = curr.quantity;
          } else {
            prev[curr.key] += curr.quantity;
          }
          return prev;
        }, {});

      const participations = sortBy(
        sortedParticipations,
        'modificationRequestNameChangeUsername'
      ).map((p) => {
        // price for standard participation) (if not deadpostline)allPlacesWithRemaining

        // const pSortedByModificationRequestCreatedAt = sortBy(
        //   p.participationHistorical?.items,
        //   'modificationRequest.createdAt'
        // ).reverse();
        // const lastModificationRequestPending =
        //   pSortedByModificationRequestCreatedAt[0]?.modificationRequest
        //     ?.requestStatus === constants.ModificationRequestStatus.PENDING;

        const priceKey = `${p.profile?.id}-${p.feeDateRange?.id}-${p.type}`;
        let ticketPrice = 0;
        let priceObj = {};
        if (p.modificationRequestNameChangeUsername) {
          ticketPrice = 0;
        } else if (allPlaces?.[priceKey]?.remainingPlaces > 0) {
          allPlaces[priceKey].remainingPlaces -= 1;
        } else if (allPurchasesFromAmountDue?.[priceKey] > 0) {
          allPurchasesFromAmountDue[priceKey] -= 1;
        } else {
          priceObj = utilities.getParticipationPrice(
            p,
            null,
            ticketVatRef.current
          );
          ticketPrice = priceObj.price;
        }
        let additionalServicesPrice = 0;
        let participationAdditionalServices = [];
        if (p.n2nConnection?.items.length > 0) {
          additionalServicesPrice += p.n2nConnection.items.reduce(
            (prev, { additionalService }) => {
              if (allPurchasesFromAmountDue?.[additionalService.id] > 0) {
                allPurchasesFromAmountDue[additionalService.id] -= 1;
              } else {
                // const vatRate1 = utilities.decodeDbNumber(
                //   additionalService.additionaServiceVat1?.amount ||
                //     additionalService.vat1
                // );
                // const vatRate2 = utilities.decodeDbNumber(
                //   additionalService.additionaServiceVat2?.amount
                // );
                // const additionalServicePrice = utilities.calculateTotalPriceAdditionalService(
                //   additionalService
                // );

                const {
                  vat1Id,
                  vat2Id,
                  vatRate1,
                  vatRate2,
                  netPrice1,
                  netPrice2,
                } = utilities.getAdditionalServiceBracketInfo(
                  additionalService
                );

                const additionalServicePrice = utilities.calculateTotalPriceAdditionalServiceWithBrackets(
                  additionalService
                );

                prev += additionalServicePrice;
                participationAdditionalServices.push({
                  ...additionalService,
                  id: additionalService.id,
                  vat1Id,
                  vat2Id,
                  vatRate1,
                  vatRate2,
                  netPrice1: utilities.decodeDbNumber(netPrice1),
                  netPrice2: utilities.decodeDbNumber(netPrice2),
                  // netPrice1: additionalService.amount1,
                  // netPrice2: additionalService.amount2,
                  // vat1Id: additionalService.additionaServiceVat1?.id,
                  // vat2Id: additionalService.additionaServiceVat2?.id,
                  price: additionalServicePrice,
                });
              }
              return prev;
            },
            0
          );
        }

        const modificationReqs = getModificationData(p);
        const hasModificationReqPending =
          modificationReqs.modification?.reqs?.PENDING > 0;
        // num of modification reqs accepted
        modificationReqsAcceptedRef.current +=
          modificationReqs.modification.reqs[
            constants.ModificationRequestStatus.ACCEPTED
          ];

        return {
          ...p,
          createdAt: format(new Date(p.createdAt), 'dd/MM/yyyy'),
          profile: p.profile?.name,
          noProfile: !p.profile,
          isReadOnly:
            p.modificationRequestNameChangeUsername ||
            hasModificationReqPending,
          ticketPrice,
          additionalServicesPrice,
          productData: {
            ...priceObj,
            netPrice: utilities.encodeDbNumber(priceObj?.netPrice),
            id: priceObj?.profileFeeBracketId,
            profileId: p.profile?.id,
            profileLabel: p.profile?.name,
            feeDateRange: p.feeDateRange?.id,
            feeDateRangeLabel: p.feeDateRange?.label,
            feeDateRangeStart: p.feeDateRange?.start,
            feeDateRangeEnd: p.feeDateRange?.end,
            participationMode: p.type,
            additionalServices: participationAdditionalServices,
          },
          modification:
            modificationReqs.modification.label !== ''
              ? modificationReqs.modification.label
              : '-',
        };
      });

      allPlacesWithRemainingRef.current = { ...allPlaces };

      // totalCostRef.current = 0;
      //   // .filter((x) => x.participationHistorical.items.length === 0)
      const nModReqsToPay = calcNModReqsToPay(data, sortedProducts);
      totalPenaltiesRef.current = nModReqsToPay.reduce((prev, curr) => {
        prev += utilities.decodeDbNumber(
          curr.modificationRequest.penaltyPrice ||
            agencyServiceRef.current?.penaltyPrice ||
            0
        );
        return prev;
      }, 0);

      let modificationRequests = data.getAgencyGroup.modificationRequests?.items
        .filter(
          (x) =>
            x.modificationRequest?.requestStatus ===
            constants.ModificationRequestStatus.PENDING
        )
        .map((req) => {
          modificationRequestsRef.current += 1;

          return {
            ...req,
            createdAt: format(new Date(req.createdAt), 'dd/MM/yyyy'),
            profile: req.profile?.name,
            modification: req.modificationRequest.requestStatus,
            isReadOnly: true,
            noProfile: !req.profile,
          };
        });

      return [...modificationRequests, ...participations];
    },
    bigStats: (tData, data) => {
      return [
        {
          title: 'participationsAvailable',
          value: () => calculateRemainingPlaces(),
        },
        {
          title: 'amountDue',
          value: (rows) =>
            utilities.formatNumber(
              calculateTotalAmountDue(rows, totalPenaltiesRef)
            ),
        },
      ];
    },
    stats: (tData, data) => {
      return [
        {
          title: 'status',
          value: (_, data) =>
            Object.values(constants.GroupStatuses)
              .find(
                (s) =>
                  s.id ===
                  (data.getAgencyGroup.status ||
                    constants.GroupStatuses.OPEN.id)
              )
              ?.label(intl),
        },
        {
          title: 'minMembers',
          value: () => agencyServiceRef.current?.minNumberComponents || '-',
        },
        {
          title: 'closingDate',
          value: () =>
            agencyServiceRef.current?.groupDefinitionDeadline
              ? format(
                  new Date(agencyServiceRef.current?.groupDefinitionDeadline),
                  'dd/MM/yyyy'
                )
              : '',
        },
        {
          title: 'postCloseChangeDeadline',
          value: () =>
            agencyServiceRef.current?.freezeDeadline
              ? format(
                  new Date(agencyServiceRef.current?.freezeDeadline),
                  'dd/MM/yyyy'
                )
              : '',
        },
        {
          title: 'modificationRequests',
          value: () => modificationRequestsRef.current,
        },
        {
          title: 'requestsAccepted',
          value: () => modificationReqsAcceptedRef.current,
        },
      ];
    },
  };

  const table = {
    isRowSelectable: false,
    columnsTemplate: (tData, data) => {
      const standardFields = data.getEvent.standardFields.items;
      const customFields = data.getEvent.services.items[0].customFields.items;

      const fields = utilities.anagraphicFieldsWithoutUploads(
        standardFields,
        customFields,
        [constants.Clusters.Pax.id]
      );
      return [
        {
          id: 'username',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'givenName',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'familyName',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'email',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'phone',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'profile',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'modificationRequestNameChangeUsername',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'createdAt',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'notes',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'modification',
          isCheckbox: false,
        },
        ...fields.map((f) => {
          let type = 'string';
          if (f.controlType === 'checkbox') {
            type = 'boolean';
          } else if (f.controlType === 'date') {
            type = 'date';
          } else {
            type = 'string';
          }
          return {
            id: f.id,
            field: f.label,
            headerName: f.label,
            type,
            width: 150,
            valueFormatter: ({ value }) => {
              if (f.controlType === 'date') {
                return value ? format(new Date(value), 'dd/MM/yyyy') : '';
              } else return value?.value || value;
            },
            valueGetter: ({ row }) => {
              const fv = row.fieldValues?.items.find(
                (fv) => fv.fieldValueFieldDefinitionId === f.id
              );
              return fv ? JSON.parse(fv.value) : '';
            },
          };
        }),
      ];
    },
    rowAction: {
      changeName: {
        icon: <LoopIcon />,
        variant: 'primary',
        hiddenWhen: ({ isReadOnly }) => isReadOnly,
        disabledWhen: () => !checkIsBeforeFreezeDeadline(agencyServiceRef),
        onClick: (row) => handleNameChange(row),
      },
      edit: {
        icon: <EditIcon />,
        variant: 'primary',
        hiddenWhen: ({ isReadOnly }) => isReadOnly,
        disabledWhen: () => !checkIsBeforeFreezeDeadline(agencyServiceRef),
        onClick: (row) =>
          handleEdit({
            id: row.id,
            row,
            isPostDeadline: !!isPostDeadlineRef.current,
          }),
      },
      delete: {
        icon: <ClearIcon />,
        variant: 'secondary',
        hiddenWhen: ({ isReadOnly }) => isReadOnly,
        disabledWhen: () => !checkIsBeforeFreezeDeadline(agencyServiceRef),
        onClick: 'dialog',
        dialog: 'delete',
        snackbar: 'delete',
      },
    },
  };

  const model = (loadData) => {
    loadDataRef.current = loadData;
    return {
      header: {
        backButton: {
          onClick: () => history.push(`/events/${eventId}/agency/${agencyId}`),
        },
        title: (tData, data) => data.getAgencyGroup?.name,
        action: {
          // ...(!agencyGroup?.closingDate && {
          pay: {
            variant: 'primary',
            disabledWhen: (checkedItems, originalData, data) => {
              // MUST never be append but leaved here just to be sure
              const noProfileFound = data.findIndex((row) => row.noProfile);
              return noProfileFound > -1;
              // || canNotCloseGroup(data);
            },
            hiddenWhen: (checkedItems, originalData, data) => {
              // return data?.getAgencyGroup?.closingDate;
              return calculateTotalAmountDue(data, totalPenaltiesRef) === 0;
            },
            onClick: (checkedItems, data) => {
              handlePayAmountDue(data, totalPenaltiesRef);
            },
          },
          close: {
            variant: 'primary',
            hiddenWhen: () =>
              agencyGroupRef.current?.status ===
              constants.GroupStatuses.CLOSED.id,
            disabledWhen: (checkedItems, originalData, data) => {
              // MUST never be append but leaved here just to be sure
              const noProfileFound = data.findIndex((row) => row.noProfile);
              return (
                noProfileFound > -1 ||
                agencyServiceRef.current?.minNumberComponents > data.length
              );
            },
            onClick: async () => {
              setCloseGroupConfirmDialog({
                isOpen: true,
                title: i18n.closeGroupConfirmDialog.title,
                message: i18n.closeGroupConfirmDialog.message,
              });
            },
          },
          ...(groupId && {
            buyBudget: {
              icon: <EuroIcon />,
              variant: 'primary',
              hiddenWhen: () =>
                !agencyServiceRef.current?.allowPrepaid ||
                !firstBracketModel.current ||
                (firstBracketModel.current &&
                  !isWithinInterval(new Date(), {
                    start: parseISO(firstBracketModel.current.start),
                    end: endOfDay(parseISO(firstBracketModel.current.end)),
                  })),
              onClick: () => setIsCalcBudgetDialogOpen(true),
            },
          }),
          add: {
            icon: <AddIcon />,
            variant: 'primary',
            disabledWhen: () => !checkIsBeforeFreezeDeadline(agencyServiceRef),
            onClick: () => handleAdd(isPostDeadlineRef.current),
          },
        },
      },
      preTableComponent: null,
      dataLoader,
      table,
      dialog: {
        delete: {
          onAgree: async (row) => {
            await handleDelete(row.id);
            await loadData();
          },
          onDisagree: () => {},
        },
      },
      snackbar: {
        delete: {},
      },
    };
  };

  const handleAdd = (isPostDeadline) => {
    history.push({
      pathname: `/events/${eventId}/participation/${cluster}/${
        groupId || sponsorListId
      }/new${isPostDeadline ? '/post-deadline' : ''}`,

      state: {
        availableByProfileFeeDateRangeAndTypology:
          allPlacesWithRemainingRef.current,
        ...(sponsorListId && {
          backUrl: `/events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}`,
        }),
      },
    });
  };

  const handleEdit = ({ id, row, isPostDeadline }) => {
    // we read one availability only if it is purchased
    const key = `${row?.profile?.id}-${row?.feeDateRange?.id}-${row.type}`;
    if (allPlacesWithRemainingRef.current?.[key]) {
      allPlacesWithRemainingRef.current[key].remainingPlaces += 1;
    }

    const availableByProfileFeeDateRangeAndTypology = {
      ...allPlacesWithRemainingRef.current,
      [key]: allPlacesWithRemainingRef.current[key],
    };

    history.push({
      pathname: `/events/${eventId}/participation/${cluster}/${
        groupId || sponsorListId
      }/${id}${isPostDeadline ? '/post-deadline' : ''}`,
      state: {
        availableByProfileFeeDateRangeAndTypology,
        ...(sponsorListId && {
          backUrl: `/events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}`,
        }),
      },
    });
  };

  const handleNameChange = ({ id }) => {
    //we add one availability, so the number of avaialable remains correct in edit mode
    history.push({
      pathname: `/events/${eventId}/participation/${cluster}/${
        groupId || sponsorListId
      }/${id}/name-change`,
      state: {
        ...(sponsorListId && {
          backUrl: `/events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}`,
        }),
      },
    });
  };

  return (
    <div style={{ width: '90%' }}>
      <AimTablePage
        model={model}
        translation={i18n}
        intl={intl}
        isStatsOpenOnInit
      />

      <AgencyGroupDialog
        isDialogOpen={isCalcBudgetDialogOpen}
        onDisagree={() => setIsCalcBudgetDialogOpen(false)}
        onAgree={(bookings) =>
          history.push(
            `/events/${eventId}/agency/${agencyId}/billing-informations`,
            {
              bookings,
              groupId,
              gateway: gatewayRef.current,
              prodId: groupId,
              serviceId: eventId,
              serviceType: constants.ProductType.agencyGroupPrepaid,
              clientId: agencyId,
              clientType: constants.Clusters.Agency.id,
            }
          )
        }
        i18n={i18n}
        eventId={eventId}
        intl={intl}
      />
      <AimConfirmDialog
        dialog={closeGroupConfirmDialog}
        setDialog={setCloseGroupConfirmDialog}
        onAgree={() => {
          closeGroup();
        }}
        intl={intl}
      />
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};

export default AgencyGroupDetail;

import { aws, appState, constants, utilities } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventCodeQuery = /* GraphQL */ `
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      id
      code
    }
  }
`;

const createSponsorMutation = /* GraphQL */ `
  mutation CreateSponsor(
    $input: CreateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    createSponsor(input: $input, condition: $condition) {
      id
      name
      event {
        id
        eventOrder {
          items {
            id
            code
            codeName
          }
        }
      }
    }
  }
`;

const updateSponsorMutation = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createSponsorStaffMutation = /* GraphQL */ `
  mutation CreateSponsorStaff(
    $input: CreateSponsorStaffInput!
    $condition: ModelSponsorStaffConditionInput
  ) {
    createSponsorStaff(input: $input, condition: $condition) {
      id
      sponsor {
        id
      }
      event {
        id
      }
    }
  }
`;

export const createSponsorListMutation = /* GraphQL */ `
  mutation CreateSponsorList(
    $input: CreateSponsorListInput!
    $condition: ModelSponsorListConditionInput
  ) {
    createSponsorList(input: $input, condition: $condition) {
      id
      sponsor {
        id
      }
      event {
        id
      }
    }
  }
`;

const createEventOrderMutation = /* GraphQL */ `
  mutation CreateEventOrder(
    $input: CreateEventOrderInput!
    $condition: ModelEventOrderConditionInput
  ) {
    createEventOrder(input: $input, condition: $condition) {
      id
    }
  }
`;

const createBillingInformationMutation = /* GraphQL */ `
  mutation CreateBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const createParticipationMutation = /* GraphQL */ `
  mutation CreateParticipation(
    $input: CreateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    createParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const getAgencyQuery = /* GraphQL */ `
  query GetAgency($id: ID!) {
    getAgency(id: $id) {
      name
      address
      city
      postalCode
      country
      event {
        id
      }
      fieldValues {
        items {
          id
          value
          fieldDefinition {
            key
            id
            controlType
          }
        }
      }
    }
  }
`;

const updateAgencyQuery = /* GraphQL */ `
  mutation UpdateAgency(
    $input: UpdateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    updateAgency(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventFieldsQuery = /* GraphQL */ `
  query getFieldsListByContexts(
    $id: ID!
    $filter: ModelFieldContextFilterInput
  ) {
    getEvent(id: $id) {
      standardFields(filter: { isDeleted: { ne: true } }) {
        items {
          key
          controlType
          id
          label
          options
          placeholder
          blockField {
            id
            order
            block {
              id
              firstField
            }
          }
          contextsOfUse(filter: $filter) {
            items {
              id
              position
              contextName
              isHidden
              isRequired
            }
          }
        }
      }
      services(filter: { serviceName: { eq: "${constants.Services.AGENCIES.key}" } }) {
        items {
          serviceName
          id
          customFields(filter: { isDeleted: { ne: true } }) {
            items {
              controlType
              id
              label
              options
              placeholder
              blockField {
                id
                order
                block {
                  id
                  firstField
                }
              }
              contextsOfUse(filter: $filter) {
                items {
                  id
                  position
                  contextName
                  isHidden
                  isRequired
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getEventCode = (eventId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();

    aws.API.graphql({
      query: getEventCodeQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        return resolve(response.data.getEvent.code);
      })
      .catch((e) => {
        console.error('get-event-code', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

const createSponsorStaff = ({ data }) =>
  new Promise((resolve, reject) => {
    const sponsor = data.createSponsor;

    const input = {
      name: `Staff ${sponsor.name}`,
      sponsorStaffSponsorId: sponsor.id,
      sponsorStaffEventId: sponsor.event.id,
    };

    aws.API.graphql({
      query: createSponsorStaffMutation,
      variables: { input },
    })
      .then(({ data }) => resolve(data.createSponsorStaff))
      .catch((e) => {
        console.error('create-sponsor-staff', e);
        reject(e);
      });
  });

const createSponsorList = ({ data }) =>
  new Promise((resolve, reject) => {
    const sponsor = data.createSponsor;

    const input = {
      name: `List ${sponsor.name}`,
      sponsorListSponsorId: sponsor.id,
      sponsorListEventId: sponsor.event.id,
    };

    aws.API.graphql({
      query: createSponsorListMutation,
      variables: { input },
    })
      .then(({ data }) => resolve(data.createSponsorList))
      .catch((e) => {
        console.error('create-sponsor-list', e);
        reject(e);
      });
  });

const createEventOrder = ({ data }) =>
  new Promise((resolve, reject) => {
    const sponsor = data.createSponsor;
    const item = sponsor.event.eventOrder?.items?.filter((el) =>
      el?.codeName === 'event' ? el?.code : null
    );

    let input = {
      eventOrderSponsorId: sponsor.id,
      eventOrderEventId: sponsor.event.id,
    };

    if (item?.[0]?.code) {
      input.code = item?.[0]?.code;
    }

    aws.API.graphql({
      query: createEventOrderMutation,
      variables: { input },
    })
      .then(({ data }) => resolve(data))
      .catch((e) => {
        console.error('create-event-order', e);
        reject(e);
      });
  });

const createBillingInformation = (input) =>
  new Promise((resolve, reject) => {
    aws.API.graphql({
      query: createBillingInformationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createBillingInformation.id))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      });
  });

const createMainBuyer = ({ input }) =>
  input
    ? new Promise((resolve, reject) => {
        aws.API.graphql({
          query: createParticipationMutation,
          variables: {
            input: { ...input, cluster: 'buyer', isMainBuyer: true },
          },
        })
          .then(({ data }) => resolve(data.createParticipation))
          .catch((e) => {
            console.error('create-main-buyer', e);
            reject(e);
          });
      })
    : null;

const updateSponsor = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    aws.API.graphql({ query: updateSponsorMutation, variables: { input } })
      .then((response) => resolve(response.data.updateSponsor))
      .catch((e) => {
        console.error('update-sponsor', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createSponsor = async (input, useLoader = true) => {
  useLoader && showLoader();
  // const eventCode = await getEventCode(input.sponsorEventId);
  // const participationSequence = await utilities.getNextSequenceValue({
  //   // eventId: participation.event.id,
  //   eventCode,
  //   sequenceName: 'participationSequence',
  // });

  // const username = `${eventCode}-${participationSequence}`;
  return new Promise((resolve, reject) => {
    const {
      buyerUsername,
      buyerGivenName,
      buyerFamilyName,
      buyerEmail,
      buyerPhone,
      buyerIsInvited,
      buyerCognitoUserId,
      city,
      country,
      ...rest
    } = input;

    aws.API.graphql({
      query: createSponsorMutation,
      variables: {
        input: { ...rest, city: input.city, country: input.country },
      },
    })
      .then((res) =>
        Promise.all([
          createSponsorStaff(res),
          createSponsorList(res),
          createEventOrder(res),
          ...(input.city || input.country
            ? [
                createBillingInformation({
                  invoiceTo: constants.InvoiceToType.COMPANY.id,
                  country: input.country, // country?.label,
                  city: input.city || '', // city?.label,
                  // countryIsoCode: country?.code,
                  name: input.name || '',
                  address: input.address || '',
                  phone: input.phone || '',
                  taxCode: input.taxIdNumber || '',
                  postalCode: input.postalCode || '',
                }),
              ]
            : [undefined]),
          createMainBuyer({
            input: {
              username: buyerUsername,
              givenName: buyerGivenName,
              familyName: buyerFamilyName,
              email: buyerEmail,
              phone: buyerPhone,
              isInvited: buyerIsInvited,
              cognitoUserId: buyerCognitoUserId,
              participationSponsorId: res?.data?.createSponsor?.id,
              participationEventId: rest.sponsorEventId,
              status: constants.BuyersStatus.INVITED.key,
            },
          }),
        ])
      )
      .then(
        async ([
          sponsorStaff,
          sponsorList,
          eventOrder,
          billingInformationId,
          participation,
        ]) => {
          const { sponsor } = sponsorStaff;

          await updateSponsor(
            {
              id: sponsor.id,
              sponsorSponsorStaffId: sponsorStaff.id,
              sponsorSponsorListId: sponsorList.id,
              sponsorEventOrderId: eventOrder?.createEventOrder?.id,
              sponsorBillingInformationId: billingInformationId,
            },
            false
          );

          resolve({
            sponsorId: sponsor?.id,
            participationId: participation?.id,
          });
        }
      )
      .catch((e) => {
        console.error('create-sponsor', e);
        reject(e);
      })
      .finally(() => useLoader && hideLoader());
  });
};

export const createFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    aws.API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('create-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getAgency = (agencyId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getAgencyQuery,
      variables: { id: agencyId },
    })
      .then((response) => {
        return resolve(response.data.getAgency);
      })
      .catch((e) => {
        console.error('get-agency', e);
        reject();
      })
      .finally(hideLoader);
  });

export const updateAgency = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: updateAgencyQuery,
      variables: { input },
    })
      .then((response) => {
        return resolve(response);
      })
      .catch((e) => {
        console.error(e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventFields = ({ eventId, contextsOfUse, isLoader = true }) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    aws.API.graphql({
      query: getEventFieldsQuery,
      variables: {
        id: eventId,
        filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
      },
    })
      .then(({ data }) => {
        const result = {
          isParticipant: data.getEvent.isParticipant,
          isSpeaker: data.getEvent.isSpeaker,
          isReviewer: data.getEvent.isReviewer,
          standardFields: data.getEvent.standardFields.items,
          customFields: data.getEvent.services.items.length
            ? data.getEvent.services.items[0].customFields.items
            : [],
        };
        resolve(result);
      })
      .catch((e) => {
        console.error('list-event-field-by-context', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export default function (intl) {
  return {
    wizard: {
      snackbar: {
        maxSizeError: intl.formatMessage({
          description: 'max size upload',
          defaultMessage: 'File too large, max size allowed: ',
        }),
        maxFilesError: intl.formatMessage({
          description: 'max files upload',
          defaultMessage: 'Maximum number of files reached: ',
        }),
        extensionNotAllowed: intl.formatMessage({
          description: 'upload file extension not allowed',
          defaultMessage: 'Extension not allowed',
        }),
      },
      instructions: intl.formatMessage({
        description: 'instructions',
        defaultMessage: 'Instructions',
      }),
      presentationType: intl.formatMessage({
        description: 'presentationType',
        defaultMessage: 'Presentation Type',
      }),
      topics: intl.formatMessage({
        description: 'topics',
        defaultMessage: 'Topics',
      }),
      affiliations: intl.formatMessage({
        description: 'affiliations',
        defaultMessage: 'Affiliations',
      }),
      presenter: intl.formatMessage({
        description: 'Presenter',
        defaultMessage: 'Presenter',
      }),
      authorsAndPresenter: intl.formatMessage({
        description: 'Authors and Presenter',
        defaultMessage: 'Authors and Presenter',
      }),
      aifaData: intl.formatMessage({
        description: 'ECM data',
        defaultMessage: 'ECM data',
      }),
      additionalData: intl.formatMessage({
        description: 'Additional data',
        defaultMessage: 'Additional Data',
      }),
      content: intl.formatMessage({
        description: 'content',
        defaultMessage: 'Content',
      }),
      authors: intl.formatMessage({
        description: 'Authors',
        defaultMessage: 'Authors',
      }),
      agreements: intl.formatMessage({
        description: 'agreements',
        defaultMessage: 'Agreements',
      }),
      labels: {
        title: intl.formatMessage({
          description: 'abstract title label',
          defaultMessage: 'Abstract title',
        }),
        requiredField: intl.formatMessage({
          description: 'required Field',
          defaultMessage: 'This is required',
        }),
        category: intl.formatMessage({
          description: 'category label',
          defaultMessage: 'Category',
        }),
        subcategory: intl.formatMessage({
          description: 'subcategory label',
          defaultMessage: 'Subcategory',
        }),
        tags: intl.formatMessage({
          description: 'tags label',
          defaultMessage: 'Tags',
        }),
        keywords: intl.formatMessage({
          description: 'keywords label',
          defaultMessage: 'Keywords',
        }),
        keywordsMaxReached: intl.formatMessage({
          description: 'maximum keywords reached',
          defaultMessage: 'Maximum keywords reached',
        }),
        affiliationNumber: intl.formatMessage({
          description: 'affiliation number',
          defaultMessage: 'N° Affiliation',
        }),
        institution: intl.formatMessage({
          description: 'institution',
          defaultMessage: 'Institution',
        }),
        city: intl.formatMessage({
          description: 'city',
          defaultMessage: 'City',
        }),
        country: intl.formatMessage({
          description: 'country',
          defaultMessage: 'Country',
        }),
        fiscalCode: intl.formatMessage({
          description: 'fiscla code',
          defaultMessage: 'Fiscal code',
        }),
        affiliation: intl.formatMessage({
          description: 'affiliation',
          defaultMessage: 'Affiliation',
        }),
        checkboxAutorsAndPresenter: intl.formatMessage({
          description: 'I am the presenting author',
          defaultMessage: 'I am the presenting author',
        }),
        privacyAcceptance: intl.formatMessage({
          description:
            'to continue, confirm the acceptance of agreements (mandatory for sending the abstract)',
          defaultMessage:
            'To continue, confirm the acceptance of agreements (mandatory for sending the abstract)',
        }),
        shiftEnterHelperText: intl.formatMessage({
          description:
            'new abstract wizard content step helper text to insert a new line without creating a new paragraph, press shift + enter.',
          defaultMessage:
            'To insert a new line without creating a new paragraph, press Shift + Enter.',
        }),
        insertImageHelperText: intl.formatMessage({
          description:
            'to insert an image click on the paragraph and then on the "+" on the left',
          defaultMessage:
            'To insert an image click on the paragraph and then on the "+" on the left',
        }),
        charactersNumber: intl.formatMessage({
          description: 'number of chars',
          defaultMessage: 'N° of characters',
        }),
        titleCharactersNumber: intl.formatMessage({
          description: 'number of title chars',
          defaultMessage: 'N° max title characters',
        }),
        bodyCharactersNumber: intl.formatMessage({
          description: 'Number of body chars',
          defaultMessage: 'N° max body characters',
        }),
        bodyImagesNumber: intl.formatMessage({
          description: 'Number of body images',
          defaultMessage: 'N° max body images',
        }),
        uploadFile: intl.formatMessage({
          description: 'upload document',
          defaultMessage: 'Upload documents',
        }),
        uploadFileSubtitle: intl.formatMessage({
          description: 'upload files',
          defaultMessage: 'Select files to upload',
        }),
        uploadFileExtensions: intl.formatMessage({
          description: 'upload files extension',
          defaultMessage: 'You can upload following formats',
        }),
        aifaWarningText: intl.formatMessage({
          description: 'AIFA warning text',
          defaultMessage:
            'WARNING: further to the Italian regulations for CME accreditation, please note that the abstract submission will be complete only when the following documents of the Presenting Author are provided:',
        }),
        aifaCVText: intl.formatMessage({
          description: 'AIFA CV text',
          defaultMessage: '- CV in European format of the Presenting author',
        }),
        aifaDisclosureText: intl.formatMessage({
          description: 'AIFA disclosure text',
          defaultMessage: '- Disclosure form of the Presentig author',
        }),
        aifaBottomText: intl.formatMessage({
          description: 'AIFA bottom text',
          defaultMessage:
            'If you have both documents, please upload them by clicking "browse", then click "next".',
        }),
        uploadCV: intl.formatMessage({
          description: 'upload CV',
          defaultMessage: 'Upload CV',
        }),
        uploadDisclosure: intl.formatMessage({
          description: 'upload disclosure',
          defaultMessage: 'Upload disclosure',
        }),
        downloadCV: intl.formatMessage({
          description: 'download CV text',
          defaultMessage: 'Download the ptototype of the European CV',
        }),
        downloadDisclosure: intl.formatMessage({
          description: 'download Disclosure text',
          defaultMessage: 'Download the ptototype of the Disclosure',
        }),
        uploadFilesTypologies: intl.formatMessage({
          description: 'you can upload following formats: .pdf',
          defaultMessage: 'You can upload following formats: .pdf',
        }),
        downloadDocumentsNote: intl.formatMessage({
          description: 'abstract documents note',
          defaultMessage:
            'Abstract received without these documents will not be sent to the evaluation commitee, therefore it will be as if thy have never been received.',
        }),
        browseLabel: intl.formatMessage({
          description: 'browse label',
          defaultMessage: 'browse',
        }),
        dragAndDrog: {
          title: intl.formatMessage({
            description: 'drag and drop your files',
            defaultMessage: 'Drag & Drop your files',
          }),
          clickText: intl.formatMessage({
            description: 'click text',
            defaultMessage: 'or you can select a file from your pc',
          }),
          fileLimitText: intl.formatMessage({
            description: 'file limit reached',
            defaultMessage: 'number of file reached',
          }),
        },
        counterCharacters: intl.formatMessage({
          description: 'counter',
          defaultMessage: 'Counter',
        }),
        contentStepTitle: intl.formatMessage({
          description: 'title label',
          defaultMessage: 'Title',
        }),
        contentStepClickHereToSeeSpecialChars: intl.formatMessage({
          description: 'special chars label',
          defaultMessage: 'Click here to see allowed special characters',
        }),
        contentStepInsertSpecialChar: intl.formatMessage({
          description: 'insert special char text',
          defaultMessage: 'Insert special char',
        }),
        preTitle: intl.formatMessage({
          description: 'pretitle label',
          defaultMessage: 'Title',
        }),
        name: intl.formatMessage({
          description: 'name label',
          defaultMessage: 'Name',
        }),
        surname: intl.formatMessage({
          description: 'surname label',
          defaultMessage: 'Surname',
        }),
        email: intl.formatMessage({
          description: 'email label',
          defaultMessage: 'Email',
        }),
        degree: intl.formatMessage({
          description: 'degree label',
          defaultMessage: 'Degree',
        }),
        speciality: intl.formatMessage({
          description: 'speciality label',
          defaultMessage: 'Speciality',
        }),
        jobTitle: intl.formatMessage({
          description: 'job title label',
          defaultMessage: 'Job title',
        }),
        presentingAuthor: intl.formatMessage({
          description: 'presenting author label',
          defaultMessage: 'Presenting author',
        }),
      },
      errors: {
        labels: {
          isAgreementChecked: intl.formatMessage({
            description: 'errors pax abstracts edit isAgreementChecked label',
            defaultMessage: 'Accept Agreement is required to save',
          }),
          aifaEuropeanCv: intl.formatMessage({
            description: 'errors pax abstracts edit aifaEuropeanCv message',
            defaultMessage: 'Upload European CV required',
          }),
          aifaDisclosure: intl.formatMessage({
            description: 'errors pax abstracts edit aifaDisclosure message',
            defaultMessage: 'Upload disclosure required',
          }),
          title: intl.formatMessage({
            description: 'errors pax abstracts edit aifaDisclosure message',
            defaultMessage: 'Title required',
          }),
          atLeastOnePresenter: intl.formatMessage({
            description:
              'errors pax abstracts edit at least one presenter message',
            defaultMessage: 'At least one presenter is required',
          }),
        },
        snackbar: {
          isAgreementChecked: intl.formatMessage({
            description: 'errors pax abstracts edit isAgreementChecked message',
            defaultMessage: 'Step Agreements: Accept Agreement is required',
          }),
          aifaEuropeanCv: intl.formatMessage({
            description: 'errors pax abstracts edit aifaEuropeanCv message',
            defaultMessage: 'Step ECM data: European CV is required',
          }),
          aifaDisclosure: intl.formatMessage({
            description: 'errors pax abstracts edit aifaDisclosure message',
            defaultMessage: 'Step ECM data: Disclosure is required',
          }),
          title: intl.formatMessage({
            description: 'errors pax abstracts edit aifaDisclosure message',
            defaultMessage: 'Step Content: Title is required',
          }),
          presenterNeeded: intl.formatMessage({
            description: 'errors pax abstracts edit presenterNeeded message',
            defaultMessage: 'Step Authors: You have not selected any presenter',
          }),
          presenterNotComplete: intl.formatMessage({
            description:
              'errors pax abstract edit presenterNotComplete message',
            defaultMessage: 'Step Authors: Please fill in all required fields',
          }),
          minNumberKeyword: intl.formatMessage({
            description: 'errors pax abstracts edit minNumberKeyword message',
            defaultMessage: 'Number of keyword must be greater than',
          }),
        },
      },
      placeholders: {
        title: intl.formatMessage({
          description: 'write abstract title label',
          defaultMessage: 'Write abstract title',
        }),
        category: intl.formatMessage({
          description: 'select category placeholder',
          defaultMessage: 'Select category',
        }),
        subcategory: intl.formatMessage({
          description: 'select subcategory placeholder',
          defaultMessage: 'Select subcategory',
        }),
        tags: intl.formatMessage({
          description: 'select tag placeholder',
          defaultMessage: 'Select tags',
        }),
        keywords: intl.formatMessage({
          description: 'add keywords placeholder',
          defaultMessage: 'Add keywords',
        }),
      },
      subtitles: {
        instructions: intl.formatMessage({
          description: 'select label',
          defaultMessage: 'Choose the participation mode',
        }),
        presentations: intl.formatMessage({
          description: 'presentation label',
          defaultMessage: 'Choose preferred type of presentation',
        }),
        lateBreaker: intl.formatMessage({
          description: 'late Breaker label, item is late breaker',
          defaultMessage: 'late breaker',
        }),
        topics: intl.formatMessage({
          description: 'topics label',
          defaultMessage: 'Choose the topic among the propossed ones',
        }),
        affiliations: intl.formatMessage({
          description: 'subtitle label',
          defaultMessage:
            'Affiliations should include: Institute & Department - City - Country',
        }),
        keywords: intl.formatMessage({
          description:
            'pax abstracts edit front office wizard keywords section subtitle',
          defaultMessage:
            'Write your chosen keyword in the section below and click on the add button to activate it',
        }),
        authorsAndPresenter: {
          instructionOne: intl.formatMessage({
            description:
              'If you are the abstract presenter tick the appropriate field, your data will be automatically copied',
            defaultMessage:
              'If you are the abstract presenter tick the appropriate field, your data will be automatically copied',
          }),
          instructionTwo: intl.formatMessage({
            description:
              'List all authors in the order in which you wish for them to appear in the abstract summary',
            defaultMessage:
              'List all authors in the order in which you wish for them to appear in the abstract summary',
          }),
          instructionThree: intl.formatMessage({
            description: 'Enter the complete first name (e.g. Henry Bryan)',
            defaultMessage: 'Enter the complete first name (e.g. Henry Bryan)',
          }),
          instructionFour: intl.formatMessage({
            description:
              'According to AIFA regulations, the personal details of all coauthors of an accepted abstract must be included in the final scientific program for AIFA approval. The corresponding author is requested to include type of degree, specialty, (area of expertise) and job title of the presenting author in the box during the abstract submission.',
            defaultMessage:
              'According to AIFA regulations, the personal details of all coauthors of an accepted abstract must be included in the final scientific program for AIFA approval. The corresponding author is requested to include type of degree, specialty, (area of expertise) and job title of the presenting author in the box during the abstract submission.',
          }),
        },
      },
    },
    page: {
      backButton: {
        tooltip: intl.formatMessage({
          description: 'backToAbstracts',
          defaultMessage: 'Abstracts',
        }),
      },
      back: {
        tooltip: intl.formatMessage({
          description: 'backToAbstracts',
          defaultMessage: 'Back to abstracts',
        }),
      },
      backText: {
        backText: intl.formatMessage({
          description: 'back',
          defaultMessage: 'back',
        }),
      },
      saveError: {
        tooltip: intl.formatMessage({
          description: 'save abstract error',
          defaultMessage: 'Error, data not saved',
        }),
      },
    },
    actions: {
      back: intl.formatMessage({
        description: 'back',
        defaultMessage: 'Back',
      }),
      forward: intl.formatMessage({
        description: 'forward',
        defaultMessage: 'Forward',
      }),
      saveAndExit: intl.formatMessage({
        description: 'submit abstract',
        defaultMessage: 'Submit abstract',
      }),
      saveDraft: intl.formatMessage({
        description: 'save as draft abstract wizard',
        defaultMessage: 'Save as draft',
      }),
      preview: intl.formatMessage({
        description: 'abstract preview',
        defaultMessage: 'Preview',
      }),
    },
    dialogs: {
      deleteRow: {
        title: intl.formatMessage({
          description: 'delete presentation specification',
          defaultMessage: 'Delete presentation specification',
        }),
        content: intl.formatMessage({
          description:
            'do you want delete the selected presentation specification?',
          defaultMessage:
            'Do you want delete the selected presentation specification?',
        }),
        buttons: {
          yes: intl.formatMessage({
            description: 'yes',
            defaultMessage: 'Yes',
          }),
          no: intl.formatMessage({
            description: 'no',
            defaultMessage: 'No',
          }),
        },
      },
      uploadFile: {
        title: intl.formatMessage({
          description: 'import presentation specifications',
          defaultMessage: 'Import Presentation specifications',
        }),
        buttons: {
          browseFile: intl.formatMessage({
            description: 'Browse files',
            defaultMessage: 'Browse files',
          }),
          browseFileLabel: intl.formatMessage({
            description: 'select the path for the files to import',
            defaultMessage: 'Select the path for the files to import',
          }),
          downloadTemplate: intl.formatMessage({
            description: 'download the template',
            defaultMessage: 'Download the template',
          }),
        },
      },
      grantDialog: {
        title: intl.formatMessage({
          description: 'Grant partecipation dislog on abstract wizard title',
          defaultMessage: 'Grant partecipation',
        }),
        description: intl.formatMessage({
          description:
            'Grant partecipation dislog on abstract wizard description',
          defaultMessage:
            'Do you want to submit this abstract for an abstract grant?',
        }),
        yesButton: intl.formatMessage({
          description:
            'Grant partecipation dislog on abstract wizard yes button',
          defaultMessage: 'Yes',
        }),
        noButton: intl.formatMessage({
          description:
            'Grant partecipation dislog on abstract wizard no button',
          defaultMessage: 'No',
        }),
      },
      confirmDialog: {
        title: intl.formatMessage({
          description: 'confirmation form title',
          defaultMessage: 'Confirmation form',
        }),
        message: intl.formatMessage({
          description: 'are you sure you want to submit your abstract?',
          defaultMessage: 'Are you sure you want to submit your abstract?',
        }),
        submitButton: intl.formatMessage({
          description: 'submit button',
          defaultMessage: 'Submit',
        }),
        cancelButton: intl.formatMessage({
          description: 'cancel button',
          defaultMessage: 'Cancel',
        }),
      },
    },
    toolbar: {
      buttons: {
        sendEmail: {
          tooltip: intl.formatMessage({
            description: 'send email',
            defaultMessage: 'Send Email',
          }),
        },
        exportExcel: {
          tooltip: intl.formatMessage({
            description: 'export excel',
            defaultMessage: 'Export Excel',
          }),
        },
        delete: {
          tooltip: intl.formatMessage({
            description: 'delete',
            defaultMessage: 'Delete',
          }),
        },
        import: {
          tooltip: intl.formatMessage({
            description: 'import',
            defaultMessage: 'Import',
          }),
        },
        filter: {
          tooltip: intl.formatMessage({
            description: 'filter',
            defaultMessage: 'Filter',
          }),
        },
        add: {
          tooltip: intl.formatMessage({
            description: 'add new presentation specification',
            defaultMessage: 'Add new presentation specification',
          }),
        },
      },
    },
    table: {
      title: intl.formatMessage({
        description: 'presentation specifications',
        defaultMessage: 'Presentation specification',
      }),
      subTitle: intl.formatMessage({
        description:
          'the specifications will be applied as requirements when loading the material.',
        defaultMessage:
          'The specifications will be applied as requirements when loading the material.',
      }),
      noData: intl.formatMessage({
        description: 'there are no presentation specifications',
        defaultMessage: 'There are no presentation specifications.',
      }),
      noDataLink: intl.formatMessage({
        description: 'add one',
        defaultMessage: 'Add one',
      }),
      buttons: {
        cancel: {
          label: intl.formatMessage({
            description: 'cancel',
            defaultMessage: 'Cancel',
          }),
        },
      },
      columns: {
        name: {
          label: intl.formatMessage({
            description: 'name',
            defaultMessage: 'Name',
          }),
          placeholder: intl.formatMessage({
            description: 'write here an name',
            defaultMessage: 'Write here an name',
          }),
        },
        description: {
          label: intl.formatMessage({
            description: 'description',
            defaultMessage: 'Description',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a description',
            defaultMessage: 'Write here a description',
          }),
        },
        value: {
          label: intl.formatMessage({
            description: 'value',
            defaultMessage: 'Value',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a value',
            defaultMessage: 'Write here a value',
          }),
        },
      },
      rows: [
        {
          name: intl.formatMessage({
            description: 'Number of characters',
            defaultMessage: 'Number of characters',
          }),
          description: intl.formatMessage({
            description:
              'Number of characters that cannot be exceeded by the participant',
            defaultMessage:
              'Number of characters that cannot be exceeded by the participant',
          }),
        },
        {
          name: intl.formatMessage({
            description: 'Poster format',
            defaultMessage: 'Poster format',
          }),
          description: intl.formatMessage({
            description:
              'Dimensions of the poster in height, width and orientation',
            defaultMessage:
              'Dimensions of the poster in height, width and orientation',
          }),
        },
        {
          name: intl.formatMessage({
            description: 'E-Poster Images',
            defaultMessage: 'E-Poster Images',
          }),
          description: intl.formatMessage({
            description: 'Resolution to be respected for images',
            defaultMessage: 'Resolution to be respected for images',
          }),
        },
        {
          name: intl.formatMessage({
            description: 'E-Poster image size',
            defaultMessage: 'E-Poster image size',
          }),
          description: intl.formatMessage({
            description: 'Maximum size supported for single image',
            defaultMessage: 'Maximum size supported for single image',
          }),
        },
        {
          name: intl.formatMessage({
            description: 'Video E-Poster',
            defaultMessage: 'Video E-Poster',
          }),
          description: intl.formatMessage({
            description: 'Format to be respected for videos',
            defaultMessage: 'Format to be respected for videos',
          }),
        },
        {
          name: intl.formatMessage({
            description: 'E-Poster video size',
            defaultMessage: 'E-Poster video size',
          }),
          description: intl.formatMessage({
            description: 'Maximum supported size',
            defaultMessage: 'Maximum supported size',
          }),
        },
        {
          name: intl.formatMessage({
            description: 'Speech',
            defaultMessage: 'Speech',
          }),
          description: intl.formatMessage({
            description: 'Speech duration',
            defaultMessage: 'Speech duration',
          }),
        },
        {
          name: intl.formatMessage({
            description: 'Maximum number of mailings',
            defaultMessage: 'Maximum number of mailings',
          }),
          description: intl.formatMessage({
            description: 'Maximum sending threshold for a single user',
            defaultMessage: 'Maximum sending threshold for a single user',
          }),
        },
      ],
    },
  };
}

import { format } from 'date-fns';

import { appState, constants, utilities } from '@aim/common';
const { decodeDbNumber, formatNumber } = utilities;
import { utcToZonedTime } from 'date-fns-tz';

import {
  getProducts,
  getProfileFeeBracket,
  // getParticipationInfo,
} from '../../myTickets/gqlHelper';

const currentTimezone = appState.eventInfo.getValue().timezone;

const ucWords = (str) => {
  str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
  return str;
};

export const getMyTickets = async ({
  clientId,
  eventId,
  serviceType = constants.Services.REGISTRATION.key,
  intl,
  translations,
}) => {
  const res = await getProducts(clientId, eventId, serviceType);
  if (res) {
    const items = res?.productByClientIdServiceTypeServiceId?.items.filter(
      (p) => utilities.isValidPayment(p.payment)
    );

    const getItem = async ({ item }) => {
      const profileFeeBracket = await getProfileFeeBracket(item.productId);
      // const participationInfo = await getParticipationInfo(clientId);
      const fee = profileFeeBracket?.feeBracket;

      const obj = {
        __id: item.id,
        __code: `${item.serviceId};${item.id};${item.clientId};${item.productId}`,
        ticketId: item.productId,
        accompanyingPersons:
          item?.guests?.items.length > 0
            ? item?.guests?.items
                .map((guest) => {
                  return `${ucWords(guest?.givenName)} ${ucWords(
                    guest?.familyName
                  )}`;
                })
                .join(', ')
            : `-`,
        code: item.payment?.paymentId || ``,
        // profile: ucWords(participationInfo?.profile?.name) || ``,
        profile: ucWords(profileFeeBracket.profile?.name) || ``,
        participationMode: Object.values(constants.EventTypes)
          .find((x) => x.id === item?.productType)
          ?.label(intl),
        paymentStatus: Object.values(constants.PaymentStatuses)
          .find((x) => x.key === item?.payment?.paymentStatus)
          .label(intl),
        total: item?.payment?.amount
          ? formatNumber(decodeDbNumber(item.payment.amount))
          : '-',
        startDate:
          format(
            utcToZonedTime(new Date(fee?.feeDateRange?.start), currentTimezone),
            'dd/MM/yyyy'
          ) || '-',
        endDate:
          format(
            utcToZonedTime(new Date(fee?.feeDateRange?.end), currentTimezone),
            'dd/MM/yyyy'
          ) || '-',
        registrationFee: fee?.feeDateRange?.label || '-',
        paymentType: item.payment?.paymentType,
        rawData: item || null,
      };

      return obj;
    };

    const promises = [];
    items.forEach((item) => {
      promises.push(getItem({ item }));
    });

    const data = await Promise.all(promises);

    return data && data.length > 0
      ? data.map((ticket) => parseMyTicket(ticket, translations))
      : [];
  }
};

export const parseMyTicket = (ticketData, translations) =>
  Object.entries(ticketData).map(([key, value]) =>
    translations[key]
      ? {
          id: key,
          label: translations[key],
          value,
        }
      : { [key]: value }
  );

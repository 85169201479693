import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AimDialog,
  AimIconAndTextButton,
  AimTextFieldForm,
  AimRichText,
  styled,
} from '@aim/components';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';

import { constants } from '@aim/common';
import Grid from '@material-ui/core/Grid';
import { updateParticipationServices } from '../facultyGqlHelper';

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

const AifaDataDialog = ({ dialogState, onClose, onSuccess, onError, i18n }) => {
  const _i18n = i18n.dialogs.aifaData;

  const formControlStyle = { width: 'calc(100% - 20px)' };
  const [isEditable, setIsEditable] = useState(false);

  const { control, handleSubmit, getValues, reset } = useForm({
    mode: 'onSubmit',
    shouldUnregister: false,
  });

  // Effects
  useEffect(() => {
    if (dialogState) {
      const rawAifaData = dialogState?.data?.aifaDataFacultyResponse;
      let facultyResponse;
      if (rawAifaData) facultyResponse = JSON.parse(rawAifaData);
      setIsEditable(
        facultyResponse?.responseStatus !==
          constants.FacultyResponseStatus.PUBLISHED
      );
      if (facultyResponse && Object.keys(facultyResponse)?.length > 0)
        reset(facultyResponse);
    }
  }, [dialogState]);

  const onSubmit = async (data, status) => {
    let dataToSend = {};
    if (Object.keys(data)?.length > 0)
      dataToSend = {
        ...data,
        responseStatus: status,
      };
    let aifaDataFacultyResponse = JSON.stringify(dataToSend);
    try {
      await updateParticipationServices(
        {
          id: dialogState?.data.id,
          aifaDataFacultyResponse,
        },
        true
      );
      onSuccess();
      onClose();
    } catch (err) {
      console.error('err', err);
      onError();
    }
  };

  const renderButtons = () => {
    return (
      <ButtonContainer>
        <AimIconAndTextButton
          variant="greyFill"
          text={_i18n.buttons.cancel}
          onClick={onClose}
        >
          <ReplayIcon />
        </AimIconAndTextButton>
        <AimIconAndTextButton
          text={_i18n.buttons.confirm}
          variant="greenFill"
          onClick={() => {
            handleSubmit(
              onSubmit(getValues(), constants.FacultyResponseStatus.DRAFT)
            );
          }}
          disabled={!isEditable}
        >
          <CheckIcon />
        </AimIconAndTextButton>
      </ButtonContainer>
    );
  };

  if (!dialogState) return null;

  return (
    <AimDialog
      open={dialogState.isOpen || false}
      title={_i18n.title}
      onClose={onClose}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
      fullWidth={true}
      maxWidth="md"
    >
      <Grid container>
        <Grid item xs={12}>
          <AimRichText
            value={dialogState?.aifaDataDescription}
            readOnly
            variant="white"
            editorStyleOverride={{
              minHeight: 'auto',
              maxHeight: 'fit-content',
              overflow: 'hidden !important',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextFieldForm
            control={control}
            label={_i18n.degree}
            name="degree"
            formControlStyle={formControlStyle}
            hasNoneValue
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextFieldForm
            label={_i18n.specialization}
            type="text"
            name="specialization"
            control={control}
            formControlStyle={formControlStyle}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextFieldForm
            label={_i18n.qualification}
            type="text"
            name="qualification"
            control={control}
            formControlStyle={formControlStyle}
            disabled={!isEditable}
          />
        </Grid>
      </Grid>
      {renderButtons()}
    </AimDialog>
  );
};

export default AifaDataDialog;

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useFieldArray, useForm } from 'react-hook-form';
import { groupBy } from 'lodash';
import { PromisePool } from '@supercharge/promise-pool';

import {
  AimList,
  AimTypography,
  // AimNumericInput,
  // styled,
  theme,
  AimDialog,
} from '@aim/components';

import {
  getAdditionalServices,
  getProductsByClientIdServiceId,
} from './gqlHelper';
import { appState, constants } from '@aim/common';

// const formControlStyle = {
//   width: 'calc(100% - 20px)',
// };

// const handleQuantityChange = (index, control, value) => {
//   if (value < 1) {
//     return;
//   }

//   control.setValue(`bookings[${index}].quantity`, !isNaN(value) ? value : 1);
//   // setTotal(parseInt(value, 10) * price);
// };

// const handleClick = (index, control, watchQuantity, value) => {
//   const nextQuantity = watchQuantity + value;

//   if (parseInt(nextQuantity) < 1) {
//     return;
//   }

//   control.setValue(`bookings[${index}].quantity`, !isNaN(value) ? value : 1);
//   // setTotal(parseInt(nextQuantity, 10) * price);
// };

const Row = ({ row, watch, control }) => {
  // const watchId = watch(`additionalServices[${index}].id`);
  // const watchQuantity = watch(`additionalServices[${index}].quantity`);

  return (
    <Grid
      container
      alignItems="center"
      // style={{
      //   margin: '0px 10px',
      //   padding: '0px',
      //   width: '100%',
      // }}
      // key={row.formKey}
    >
      <Grid item xs={9}>
        <AimTypography variant="text">{row.title}</AimTypography>
      </Grid>
      {/* <Grid item xs={3}>
        <Controller
          name={`bookings[${index}].quantity`}
          control={control}
          render={(props) => (
            <AimNumericInput
              value={props.value || 0}
              editableInput
              onChange={(value) => handleQuantityChange(index, control, value)}
              onClickPlus={() => handleClick(index, control, 1)}
              onClickMinus={() => handleClick(index, control, -1)}
              disabledMinus={!watchQuantity || watchQuantity <= 1}
              // disabledPlus={!watchParticipationMode}
              style={{ minHeight: 'auto' }}
            />
          )}
        />
      </Grid> */}
    </Grid>
  );
};

export const TicketAdditionalServicesDialog = ({
  isDialogOpen,
  // style,
  eventId,
  i18n,
  onDisagree,
  onAgree,
  selectedTicket,
  history,
}) => {
  const { control, watch, /* formState, */ reset } = useForm({
    bookings: [],
    shouldUnregister: false,
  });
  const { fields } = useFieldArray({
    control,
    name: 'additionalServices',
    keyName: 'formKey',
  });

  const [checkedItems, setCheckedItems] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  // const additionalServicesRef = useRef();

  useEffect(() => {
    if (eventId && selectedTicket) {
      const fetchAdditionalServices = async () => {
        const nextAdditionalServices = await getAdditionalServices(eventId);
        const filteredAdditionalServices = nextAdditionalServices?.items.filter(
          (x) => x.participationType === selectedTicket.type
        );

        // get additional services
        const availableAdditionalServices = await PromisePool.for(
          filteredAdditionalServices
        )
          .withConcurrency(10)
          .onTaskStarted((_, pool) => {
            appState.loaderValue.next(pool.processedPercentage());
          })
          .handleError(async (error) => {
            console.error('💥 ERROR: ', error);
            // throwing errors will stop PromisePool and you must catch them yourself
            throw error;
          })
          .process(async (service) => {
            const res = await getProductsByClientIdServiceId(
              eventId,
              constants.AdditionalServicesServiceTypology[service.typology]
                ?.productServiceType
            );
            const groupedAdditionalServiceProducts = groupBy(
              res,
              (product) => product.productId
            );

            const availableSubscription = service.maxSubscription
              ? parseInt(service.maxSubscription, 10) -
                (groupedAdditionalServiceProducts?.[service.id]?.length || 0)
              : undefined;
            return { id: service.id, availableSubscription };
          });

        const availableAdditionalServiceIds = availableAdditionalServices?.results?.map(
          ({ id }) => id
        );
        const nextFilteredAdditionalServices = filteredAdditionalServices?.filter(
          (x) =>
            (
              availableAdditionalServiceIds.includes(x.id) &&
              availableAdditionalServices?.results?.find(
                (aas) => aas.id === x.id
              )
            )?.availableSubscription > 0
        );

        if (nextFilteredAdditionalServices.length) {
          setAdditionalServices(nextFilteredAdditionalServices);
          reset({ additionalServices: nextFilteredAdditionalServices });
        } else {
          history.push(
            `/events/${eventId}/tickets/billing-info/${selectedTicket.id}/${selectedTicket.type}`
          );
        }
      };
      fetchAdditionalServices(eventId);
    }
  }, [eventId, isDialogOpen]);

  const onConfirmClick = () => {
    onAgree(checkedItems);
  };

  const HeaderRow = (
    <Grid
      container
      // alignItems="center"
      // style={{
      //   margin: '0px 10px 10px 10px',
      //   padding: '0px',
      //   width: '100%',
      // }}
    >
      <Grid item xs={9}>
        <AimTypography boxStyle={{ margin: '0 0 0 8px' }} variant={`textBold`}>
          {i18n.additionalServicesConfirmationDialog.header.title}
        </AimTypography>
      </Grid>
      {/* <Grid item xs={3}>
        <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
          {i18n.additionalServicesConfirmationDialog.header.number}
        </AimTypography>
      </Grid> */}
    </Grid>
  );

  if (additionalServices.length) {
    return (
      <AimDialog
        disableEnforceFocus
        fullWidth
        // maxWidth={viewParticipationCol ? 'lg' : 'md'}
        open={isDialogOpen}
        title={i18n.additionalServicesConfirmationDialog.title}
        onClose={() => {
          reset({ additionalServices: [] });
          onDisagree();
        }}
        disagreeText={i18n.additionalServicesConfirmationDialog.buttons.cancel}
        agreeText={i18n.additionalServicesConfirmationDialog.buttons.confirm}
        onDisagree={onDisagree}
        onAgree={() => onConfirmClick()}
      >
        <AimList
          headerStyle={{
            padding: 20,
            backgroundColor: theme.colors.greyScale.backgroundGrey,
            paddingLeft: 5,
          }}
          itemStyle={{
            backgroundColor: 'white',
            margin: 0,
            padding: 10,
            paddingLeft: 5,
          }}
          onChecked={setCheckedItems}
          checkedItems={checkedItems}
          checkable={true}
          deletable={false}
          rows={fields}
          rowKey="id"
          // onDeleted={(e, row, idx) => remove(idx)}
          // isDeleteSmall
          // deleteIconVariant="yellow"
          // itemStyle={{ margin: 0, padding: '0px 0px 3px 0px' }}
          marginHeader={false}
          HeaderRow={HeaderRow}
        >
          <Row {...{ control, rows: fields, watch }} />
        </AimList>
      </AimDialog>
    );
  } else return null;
};

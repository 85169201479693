import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import FormLabel from '@material-ui/core/FormLabel';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

import { appState, constants } from '@aim/common';
import {
  ParticipationEdit,
  defaultSaveData,
  createModificationRequestData,
  CustomIntl,
  AimIconAndTextButton,
  useAimMediaQuery,
  theme,
} from '@aim/components';
import TicketForm from './participationEditWrapperFrontoffice/TicketForm';
import AdditionalServicesForm from './participationEditWrapperFrontoffice/AdditionalServicesForm';
import translation from './participationEditWrapperFrontoffice/translation';
import { getParticipation } from './participationEditWrapperFrontoffice/gqlHelper';
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const ParticipationEditWrapperFrontoffice = ({
  isPostDeadline,
  isNameChange,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.wrapper(intl);
  const { eventId, participationId, cluster, clusterId } = useParams();
  const participationFormSubmitButtonRef = useRef();
  const ticketFormRef = useRef();
  const socialEventsFormRef = useRef();
  const scientificEventsFormRef = useRef();
  const { isMobileOnly } = useAimMediaQuery();
  //every change in the participation object will trigger a reset on ticketForm and additionalServicesForm
  const [participation, setParticipation] = useState();
  const history = useHistory();
  const historyState = history.location.state;
  const availableByProfileFeeDateRangeAndTypology =
    history.location?.state?.availableByProfileFeeDateRangeAndTypology;
  const backUrl = history.location?.state?.backUrl;

  const [user] = useState(appState.user.getValue());
  const isTicketFormVisible =
    !isNameChange &&
    (cluster === constants.Clusters.Groups.id ||
      cluster === constants.Clusters.SponsorList.id);
  const isScientificEventsFormVisible =
    !isNameChange &&
    (cluster === constants.Clusters.Groups.id ||
      cluster === constants.Clusters.SponsorList.id);
  const isSocialEventsFormVisible =
    !isNameChange &&
    (cluster === constants.Clusters.Groups.id ||
      cluster === constants.Clusters.SponsorList.id);

  const getBackUrl = () => {
    if (backUrl) {
      return backUrl;
    } else if (cluster === constants.Clusters.SponsorStaff.id) {
      const sponsorId = user?.userAndParticipation?.participation?.sponsor?.id;
      return `/events/${eventId}/${sponsorId}/services-configuration/staff`;
    } else if (cluster === constants.Clusters.SponsorList.id) {
      const sponsorId = user?.userAndParticipation?.participation?.sponsor?.id;
      return `/events/${eventId}/${sponsorId}/services-configuration/list`;
    } else if (cluster === constants.Clusters.Groups.id) {
      const agencyId = user?.userAndParticipation?.participation?.agency?.id;
      if (clusterId) {
        return `/events/${eventId}/agency/${agencyId}/groups/${clusterId}`;
      }
      return `/events/${eventId}/agency/${agencyId}`;
    }
  };

  useEffect(() => {
    const loadParticipation = async () => {
      if (participationId) {
        showLoader();
        const participationData = await getParticipation({
          participationId,
        });
        hideLoader();
        setParticipation(participationData);
      } else {
        setParticipation({ n2nConnection: { items: [] } });
      }
    };

    loadParticipation();
  }, [participationId]);

  const onSaveCustom = async ({
    submittedData,
    currentParticipation,
    dirtyFields,
  }) => {
    showLoader();

    if (isTicketFormVisible) {
      const { feeDateRange, type, profile } = ticketFormRef.current.getValues();

      if (feeDateRange) {
        dirtyFields = {
          ...dirtyFields,
          baseDefaultValues: {
            ...dirtyFields.baseDefaultValues,
            feeDateRange: true,
          },
        };
        submittedData.baseDefaultValues.feeDateRange = feeDateRange;
      }
      if (type) {
        dirtyFields = {
          ...dirtyFields,
          baseDefaultValues: { ...dirtyFields.baseDefaultValues, type: true },
        };
        submittedData.baseDefaultValues.type = type;
      }
      if (profile) {
        dirtyFields = {
          ...dirtyFields,
          baseDefaultValues: {
            ...dirtyFields.baseDefaultValues,
            profile: true,
          },
        };
        submittedData.baseDefaultValues.profile = profile;
      }
    }

    const additionalServices = [];
    if (isSocialEventsFormVisible && isScientificEventsFormVisible) {
      const {
        additionalServices: socialEvents,
      } = socialEventsFormRef.current.getValues();
      additionalServices.push(...socialEvents);
      const {
        additionalServices: scientificEvents,
      } = scientificEventsFormRef.current.getValues();
      additionalServices.push(...scientificEvents);
    }
    submittedData.additionalServices = additionalServices;

    await defaultSaveData({
      participation: currentParticipation,
      submittedData,
      dirtyFields,
      editFrontOffice: true,
      skipAdditionalServices: !(
        isSocialEventsFormVisible && isScientificEventsFormVisible
      ),
    });

    history.push(getBackUrl());
    hideLoader();
  };

  const onRequest = async ({ submittedData, currentParticipation }) => {
    showLoader();

    if (isTicketFormVisible) {
      const { feeDateRange, type, profile } = ticketFormRef.current.getValues();

      if (feeDateRange) {
        submittedData.baseDefaultValues.feeDateRange = feeDateRange;
      }
      if (type) {
        submittedData.baseDefaultValues.type = type;
      }
      if (profile) {
        submittedData.baseDefaultValues.profile = profile;
      }
    }

    const additionalServices = [];
    if (isSocialEventsFormVisible) {
      const {
        additionalServices: socialEvents,
      } = socialEventsFormRef.current.getValues();
      additionalServices.push(...socialEvents);
    }
    if (isScientificEventsFormVisible) {
      const {
        additionalServices: scientificEvents,
      } = scientificEventsFormRef.current.getValues();
      additionalServices.push(...scientificEvents);
    }
    submittedData.additionalServices = additionalServices;

    await createModificationRequestData({
      participation: currentParticipation,
      submittedData,
      user,
      eventId,
      participationId,
      cluster,
      clusterId,
      isNameChange,
    });
    history.push(getBackUrl());

    //una volta fatta la richiesta di modifica bloccare ulteriori richieste?
    hideLoader();
  };

  const buildRequiredBaseFields = () => {
    let requiredFields = [];
    if (
      cluster === constants.Clusters.Groups.id ||
      cluster === constants.Clusters.SponsorStaff.id
    ) {
      requiredFields = ['profile'];
    }
    if (cluster === constants.Clusters.SponsorStaff.id) {
      requiredFields = ['email'];
    }
    return requiredFields;
  };

  const buildToHideBaseFields = () => {
    let hiddenFields = [];
    if (cluster === constants.Clusters.SponsorStaff.id) {
      hiddenFields = ['profile', 'feeDateRange', 'type', 'status'];
    } else if (
      cluster === constants.Clusters.Groups.id ||
      cluster === constants.Clusters.SponsorList.id
    ) {
      hiddenFields = ['status'];
    }
    return hiddenFields;
  };

  const buildToSkipBaseFieldsIds = () => {
    return [
      'profile',
      'type',
      'feeDateRange',
      'isParticipant',
      'isSpeaker',
      'isReviewer',
      'isFaculty',
      'seg',
      'ana',
      'typology',
    ];
  };

  const getExtraProps = () => {
    if (!participationId && historyState?.newPaxTicketInfo) {
      return {
        profile: historyState?.newPaxTicketInfo.profile,
        feeDateRange: historyState?.newPaxTicketInfo.feeDateRange,
        type: historyState?.newPaxTicketInfo.participationMode,
      };
    }
  };

  const onProfileChanged = (nextProfile) => {
    socialEventsFormRef.current?.setProfile(nextProfile);
    scientificEventsFormRef.current?.setProfile(nextProfile);
  };

  const onParticipationModeChanged = (nextParticipationMode) => {
    socialEventsFormRef.current?.setParticipationMode(nextParticipationMode);
    scientificEventsFormRef.current?.setParticipationMode(
      nextParticipationMode
    );
  };

  const onProfileFeeBracketIdChanged = (nextProfileFeeBracketId) => {
    socialEventsFormRef.current?.setProfileFeeBracketId(
      nextProfileFeeBracketId
    );
    scientificEventsFormRef.current?.setProfileFeeBracketId(
      nextProfileFeeBracketId
    );
  };

  const handleSave = async () => {
    if (!participationFormSubmitButtonRef.current) return;
    let isValid = true;
    if (isTicketFormVisible) {
      const res = await ticketFormRef.current.trigger();
      if (isValid && !res) {
        isValid = res;
      }
    }
    if (isSocialEventsFormVisible) {
      const res = await socialEventsFormRef.current.trigger();
      if (isValid && !res) {
        isValid = res;
      }
    }
    if (isScientificEventsFormVisible) {
      const res = await scientificEventsFormRef.current.trigger();
      if (isValid && !res) {
        isValid = res;
      }
    }
    if (isValid) {
      participationFormSubmitButtonRef.current.click();
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'inherit',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          marginBottom: 20,
          flexGrow: 0,
          marginRight: '5%',
          marginLeft: '5%',
        }}
      >
        <div style={{ display: 'flex', flex: 1, width: '100%' }}>
          <AimIconAndTextButton
            variant="none"
            text={i18n.back}
            style={{ padding: 0 }}
            onClick={() => history.push(getBackUrl())}
          >
            <ArrowBack />
          </AimIconAndTextButton>
        </div>
        <div
          style={{
            background: theme.colors.greyScale.backgroundGrey,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <div style={{ margin: isMobileOnly ? 10 : '0px 50px' }}>
            <FormLabel
              style={{
                marginBottom: 5,
                paddingBottom: 0,
                color: 'black',
                fontWeight: 'bold',
                fontSize: 14,
                fontFamily: 'hind',
                '&.MuiFormLabel-root.Mui-focused': {
                  color: 'black',
                },
              }}
            >
              {i18n.anagraphic}
            </FormLabel>
            <div
              style={{
                borderTop: '1px solid #84819A47',
                paddingTop: 20,
              }}
            ></div>
            {isPostDeadline ? (
              <ParticipationEdit
                intl={intl}
                history={history}
                onSave={onRequest}
                editFrontOffice
                isPostDeadline
                customFormControlStyle={{ margin: 8 }}
                {...{
                  eventId,
                  refButton: participationFormSubmitButtonRef,
                  variant: 'secondary',
                  participationData: {
                    id: participationId,
                    cluster,
                    clusterId,
                    ...getExtraProps(),
                  },
                  requiredBaseFields: buildRequiredBaseFields(),
                  toSkipBaseFieldsIds: buildToSkipBaseFieldsIds(),
                  toHideBaseFields: buildToHideBaseFields(),
                  containerStyle: { padding: 0, margin: 0 },
                }}
              />
            ) : isNameChange ? (
              <ParticipationEdit
                intl={intl}
                history={history}
                onSave={onRequest}
                editFrontOffice
                isNameChange
                customFormControlStyle={{ margin: 8 }}
                {...{
                  eventId,
                  refButton: participationFormSubmitButtonRef,
                  variant: 'secondary',
                  participationData: {
                    id: participationId,
                    cluster,
                    clusterId,
                    ...getExtraProps(),
                  },
                  requiredBaseFields: buildRequiredBaseFields(),
                  toSkipBaseFieldsIds: buildToSkipBaseFieldsIds(),
                  toHideBaseFields: buildToHideBaseFields(),
                  containerStyle: { padding: 0, margin: 0 },
                }}
              />
            ) : (
              <ParticipationEdit
                intl={intl}
                history={history}
                onSave={onSaveCustom}
                editFrontOffice
                customFormControlStyle={{ margin: 8 }}
                {...{
                  eventId,
                  refButton: participationFormSubmitButtonRef,
                  variant: 'secondary',
                  participationData: {
                    id: participationId,
                    cluster,
                    clusterId,
                    ...getExtraProps(),
                  },
                  requiredBaseFields: buildRequiredBaseFields(),
                  toSkipBaseFieldsIds: buildToSkipBaseFieldsIds(),
                  toHideBaseFields: buildToHideBaseFields(),
                  containerStyle: { padding: 0, margin: 0 },
                }}
              />
            )}
          </div>
          {participation ? (
            <>
              {isTicketFormVisible ? (
                <div
                  style={{
                    paddingLeft: isMobileOnly ? 10 : 50,
                    paddingRight: isMobileOnly ? 10 : 50,
                    paddingTop: 20,
                  }}
                >
                  <FormLabel
                    style={{
                      marginBottom: 5,
                      paddingBottom: 0,
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: 14,
                      fontFamily: 'hind',
                      '&.MuiFormLabel-root.Mui-focused': {
                        color: 'black',
                      },
                    }}
                  >
                    {i18n.registration}
                  </FormLabel>
                  <div
                    style={{
                      borderTop: '1px solid #84819A47',
                    }}
                  ></div>
                  <div style={{ padding: '0px 10px' }}>
                    <TicketForm
                      onProfileChanged={onProfileChanged}
                      onParticipationModeChanged={onParticipationModeChanged}
                      onProfileFeeBracketIdChanged={
                        onProfileFeeBracketIdChanged
                      }
                      ticketFormRef={ticketFormRef}
                      participation={participation}
                      availableByProfileFeeDateRangeAndTypology={
                        availableByProfileFeeDateRangeAndTypology
                      }
                      cluster={cluster}
                    />
                  </div>
                </div>
              ) : null}
              {isSocialEventsFormVisible ? (
                <div
                  style={{
                    paddingLeft: isMobileOnly ? 10 : 50,
                    paddingRight: isMobileOnly ? 10 : 50,
                    paddingTop: 20,
                  }}
                >
                  <FormLabel
                    style={{
                      marginBottom: 5,
                      paddingBottom: 0,
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: 14,
                      fontFamily: 'hind',
                      '&.MuiFormLabel-root.Mui-focused': {
                        color: 'black',
                      },
                    }}
                  >
                    {i18n.socialEvent}
                  </FormLabel>
                  <div
                    style={{
                      borderTop: '1px solid #84819A47',
                    }}
                  ></div>
                  <div style={{ padding: '0px 10px' }}>
                    <AdditionalServicesForm
                      cluster={cluster}
                      additionalServicesFormRef={socialEventsFormRef}
                      typology={
                        constants.AdditionalServicesServiceTypology.social.id
                      }
                      participation={participation}
                    />
                  </div>
                </div>
              ) : null}
              {isScientificEventsFormVisible ? (
                <div
                  style={{
                    paddingLeft: isMobileOnly ? 10 : 50,
                    paddingRight: isMobileOnly ? 10 : 50,
                    paddingTop: 20,
                  }}
                >
                  <FormLabel
                    style={{
                      marginBottom: 5,
                      paddingBottom: 0,
                      color: 'black',
                      fontWeight: 'bold',
                      fontSize: 14,
                      fontFamily: 'hind',
                      '&.MuiFormLabel-root.Mui-focused': {
                        color: 'black',
                      },
                    }}
                  >
                    {i18n.scientificEvent}
                  </FormLabel>
                  <div
                    style={{
                      borderTop: '1px solid #84819A47',
                    }}
                  ></div>
                  <div style={{ padding: '0px 10px' }}>
                    <AdditionalServicesForm
                      cluster={cluster}
                      additionalServicesFormRef={scientificEventsFormRef}
                      typology={
                        constants.AdditionalServicesServiceTypology.scientific
                          .id
                      }
                      participation={participation}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              margin: isMobileOnly ? '0px 10px' : '0px 60px',
              marginTop: 20,
            }}
          >
            <AimIconAndTextButton
              variant="primary"
              text={isPostDeadline ? i18n.request : i18n.save}
              onClick={handleSave}
              style={{ flex: isMobileOnly ? 1 : undefined }}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationEditWrapperFrontoffice;

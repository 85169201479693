import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import {
  AimTypography,
  AimIconAndTextButton,
  AimDragDropFileUploader,
  AimSnackbar,
  AimSnackbarSeverity,
  CustomIntl,
  ProgramTemplate,
  theme,
  styled,
} from '@aim/components';

import { aws, appState, constants, fileHelper } from '@aim/common';
import { translation as facultyTranslation } from './faculty/translation';
import translation from './faculty/allotment/translation';
import AdditionalServicesDialog from './faculty/additionalServices/AdditionalServiceDialog';
import AccreditationMaterialDialog from './faculty/accreditationMaterial/AccreditationMaterialDialog';
import AifaDataDialog from './faculty/aifaData/AifaDataDialog';
import TravelTransferDialog from './faculty/travelAndTransfer/TravelTransferDialog';
import AllotmentDialog from './faculty/allotment/AllotmentDialog';
import ScientificCommitmentDialog from './faculty/scientificCommitment/ScientificCommitmentDialog';
import ParticipationConfirm from './faculty/participation/ParticipationConfirm';
import DownloadArea from './faculty/DownloadArea';

import {
  getParticipationServices,
  listAdditionalServices,
  // getAgendaSessionsByEventAndParticipant,
  getAgendaSessionsByEvent,
  updateParticipationServices,
} from './faculty/facultyGqlHelper';

const formControlStyle = { width: 'calc(100% - 20px)' };

const FacultyContainer = styled('div')(({ smDown }) => ({
  width: smDown ? '100%' : '100vw',
  backgroundColor: '#FFF',
}));

const FacultyInnerContainer = styled('div')(({ smDown }) => ({
  flex: 1,
  width: smDown ? 'calc(100% - 60px)' : 'calc(100% - 120px)',
  margin: 'auto',
  marginBottom: 20,
}));

const HeaderContainer = styled('div')(({ smDown }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: smDown ? 'column' : 'row',
}));

const FacultySectionWrapper = styled('div')({
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  padding: 30,
  minHeight: 150,
  marginBottom: 20,
});

const CardsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
  marginTop: 10,
});

const CardWrapper = styled('div')({
  width: 250,
  height: 125,
  background: theme.colors.greyScale.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  position: 'relative',
  cursor: 'pointer',
});

const CardStatus = styled('div')({
  position: 'absolute',
  width: 30,
  height: 30,
  backgroundColor: '#29E129',
  top: 15,
  right: 15,
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
});

const CardInfo = styled('div')({
  position: 'absolute',
  bottom: 15,
  right: 15,
  // backgroundColor: 'white',
  // border: '1px solid grey',
  // boxShadow: '0 0 0 5px #cccaca',
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontWeight: 400,
    fontSize: 12,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Info = styled('div')({
  width: 300,
  height: 300,
});

const UploadAreaContent = styled('div')({
  backgroundColor: 'white',
});

const UploadArea = styled('div')({
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  padding: 20,
  minHeight: 150,
  marginBottom: 20,
});

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const typology = {
  ACCREDITATION_MATERIAL_CV: {
    id: 'accreditationMaterialCV',
  },
  ACCREDITATION_MATERIAL_COI: {
    id: 'accreditationMaterialCOI',
  },
  ACCREDITATION_MATERIAL_SURVEY: {
    id: 'accreditationMaterialSurvey',
  },
  PARTICIPATION_FILE: {
    id: 'participationFile',
  },
  TRAVEL_BOOK_BY_AGENCY: {
    id: 'travelPrefsDocBookByAgency',
  },
  TRAVEL_BOOK_BY_MYSELF: {
    id: 'travelPrefsDocBookByMyself',
  },
};

const FacultySection = ({ children, subtitle, title }) => (
  <FacultySectionWrapper>
    <AimTypography variant="h4Regular" style={{ textTransform: 'uppercase' }}>
      {title}
    </AimTypography>
    <AimTypography variant="text">{subtitle}</AimTypography>
    {children}
  </FacultySectionWrapper>
);

const FacultyCard = ({ title, status, onClick, infoData }) => {
  return (
    <CardWrapper onClick={onClick}>
      {status && (
        <CardStatus
          style={{
            backgroundColor:
              status === constants.FacultyResponseStatus.PUBLISHED
                ? '#29E129'
                : theme.colors.greyScale.grey3,
          }}
        >
          <CheckIcon />
        </CardStatus>
      )}
      <AimTypography
        variant="h5"
        boxStyle={{ textAlign: 'center', fontWeight: 'bold' }}
      >
        {title}
      </AimTypography>
      <CardInfo style={{ display: 'flex', flex: 1 }}>
        <HtmlTooltip
          title={<Info>{infoData}</Info>}
          style={{ backgroundColor: 'white' }}
        >
          <InfoIcon
            style={{
              fontSize: '1.50rem',
              // paddingLeft: 5,
              color: theme.colors.greyScale.grey3,
              cursor: 'pointer',
            }}
          />
        </HtmlTooltip>
      </CardInfo>
    </CardWrapper>
  );
};

const InfoRow = ({ i18n, label, value, columnDirection = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: columnDirection ? 'column' : 'row',
      }}
    >
      <span style={{ fontWeight: 'bold' }}>{`${label}:`}&nbsp;</span>
      {value ? (
        <span>{value}</span>
      ) : (
        <span style={{ color: '#cccaca' }}>{`- ${i18n.info.insert} -`}</span>
      )}
    </div>
  );
};

const renderAccreditationMaterialInfo = ({ i18n, participationServices }) => {
  const facultyResponse =
    participationServices?.accreditationMaterialFacultyResponse;
  const facultyAttachmentsResponse =
    participationServices?.facultyAttachmentsResponse;
  if (facultyResponse) {
    const nextFacultyResponse = JSON.parse(facultyResponse) || {};
    const nextFacultyAttachmentsResponse =
      JSON.parse(facultyAttachmentsResponse) || [];
    const accreditationMaterialCV = nextFacultyAttachmentsResponse.filter(
      (fd) => fd.type === typology.ACCREDITATION_MATERIAL_CV.id
    );
    const accreditationMaterialCOI = nextFacultyAttachmentsResponse.filter(
      (fd) => fd.type === typology.ACCREDITATION_MATERIAL_COI.id
    );
    const accreditationMaterialSurvey = nextFacultyAttachmentsResponse.filter(
      (fd) => fd.type === typology.ACCREDITATION_MATERIAL_SURVEY.id
    );
    return (
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280 }}>
        <InfoRow
          i18n={i18n}
          label={i18n.dialogs.accreditationMaterial.sectionTitles.fiscalCode}
          value={nextFacultyResponse.fiscalCode}
        />
        <div style={{ marginTop: 5 }}>
          {accreditationMaterialCV && (
            <div style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>
                {accreditationMaterialCV[0]?.topic}
              </span>
              {accreditationMaterialCV.map((cv) => (
                <div key={cv?.file?.id}>
                  <span>{cv?.file?.originalName}</span>
                </div>
              ))}
            </div>
          )}
          {accreditationMaterialCOI && (
            <div style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>
                {accreditationMaterialCOI[0]?.topic}
              </span>
              {accreditationMaterialCOI.map((cv) => (
                <div key={cv?.file?.id}>
                  <span>{cv?.file?.originalName}</span>
                </div>
              ))}
            </div>
          )}
          {accreditationMaterialSurvey && (
            <div style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>
                {accreditationMaterialSurvey[0]?.topic}
              </span>
              {accreditationMaterialSurvey.map((cv) => (
                <div key={cv?.file?.id}>
                  <span>{cv?.file?.originalName}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
  return <span>{i18n.info.noInfo}</span>;
};

const renderAIFAInfo = ({ i18n, participationServices }) => {
  const facultyResponse = participationServices?.aifaDataFacultyResponse;
  if (facultyResponse) {
    const nextFacultyResponse = JSON.parse(facultyResponse) || {};

    return (
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280 }}>
        <InfoRow
          i18n={i18n}
          label={i18n.dialogs.aifaData.qualification}
          value={nextFacultyResponse.qualification}
        />
        <InfoRow
          i18n={i18n}
          label={i18n.dialogs.aifaData.degree}
          value={nextFacultyResponse.degree}
        />
        <InfoRow
          i18n={i18n}
          label={i18n.dialogs.aifaData.specialization}
          value={nextFacultyResponse.specialization}
        />
      </div>
    );
  }
  return <span>{i18n.info.noInfo}</span>;
};

const renderTravelInfo = ({ i18n, participationServices, currentTimezone }) => {
  const facultyResponse = participationServices?.travelFacultyResponse;
  let nextFacultyResponse;
  if (facultyResponse) {
    nextFacultyResponse = JSON.parse(facultyResponse) || {};
    if (nextFacultyResponse.bookByAuth) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', minWidth: 280 }}
        >
          <span>{i18n.info.bookByAuth}</span>
          <div style={{ marginTop: 5 }}>
            <span>{i18n.info.travel.oneWay}</span>
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.travelDate}
              value={
                nextFacultyResponse.prefsTravelOneWayDate !== ''
                  ? format(
                      utcToZonedTime(
                        new Date(nextFacultyResponse.prefsTravelOneWayDate),
                        currentTimezone
                      ),
                      'dd-MM-yyyy'
                    )
                  : undefined
              }
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.travelBy}
              value={nextFacultyResponse.prefsTravelOneWayTravelBy}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.flightTrainN}
              value={nextFacultyResponse.prefsTravelOneWayFlightTrainN}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.leavingFrom}
              value={nextFacultyResponse.prefsTravelOneWayLeavingFrom}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.at}
              value={
                !!nextFacultyResponse.prefsTravelOneWayDate &&
                !!nextFacultyResponse.prefsTravelOneWayLeavingAt
                  ? format(
                      utcToZonedTime(
                        new Date(
                          `${nextFacultyResponse.prefsTravelOneWayDate} ${nextFacultyResponse.prefsTravelOneWayLeavingAt}`
                        ),
                        currentTimezone
                      ),
                      'HH:mm'
                    )
                  : undefined
              }
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.intermediateAirport}
              value={nextFacultyResponse.prefsTravelOneWayIntermediateAirport}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.arrivingIn}
              value={nextFacultyResponse.prefsTravelOneWayArrivingIn}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.at}
              value={
                !!nextFacultyResponse.prefsTravelOneWayDate &&
                !!nextFacultyResponse.prefsTravelOneWayArrivingAt
                  ? format(
                      utcToZonedTime(
                        new Date(
                          `${nextFacultyResponse.prefsTravelOneWayDate} ${nextFacultyResponse.prefsTravelOneWayArrivingAt}`
                        ),
                        currentTimezone
                      ),
                      'HH:mm'
                    )
                  : undefined
              }
            />
          </div>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <span>{i18n.info.travel.return}</span>
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.travelDate}
              value={
                nextFacultyResponse.prefsTravelReturnDate !== ''
                  ? format(
                      utcToZonedTime(
                        new Date(nextFacultyResponse.prefsTravelReturnDate),
                        currentTimezone
                      ),
                      'dd-MM-yyyy'
                    )
                  : undefined
              }
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.travelBy}
              value={nextFacultyResponse.prefsTravelReturnTravelBy}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.flightTrainN}
              value={nextFacultyResponse.prefsTravelReturnFlightTrainN}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.leavingFrom}
              value={nextFacultyResponse.prefsTravelReturnLeavingFrom}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.at}
              value={
                !!nextFacultyResponse.prefsTravelReturnDate &&
                !!nextFacultyResponse.prefsTravelReturnLeavingAt
                  ? format(
                      utcToZonedTime(
                        new Date(
                          `${nextFacultyResponse.prefsTravelReturnDate} ${nextFacultyResponse.prefsTravelReturnLeavingAt}`
                        ),
                        currentTimezone
                      ),
                      'HH:mm'
                    )
                  : undefined
              }
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.intermediateAirport}
              value={nextFacultyResponse.prefsTravelReturnIntermediateAirport}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.arrivingIn}
              value={nextFacultyResponse.prefsTravelReturnArrivingIn}
            />
            <InfoRow
              i18n={i18n}
              label={i18n.info.travel.at}
              value={
                !!nextFacultyResponse.prefsTravelReturnDate &&
                !!nextFacultyResponse.prefsTravelReturnArrivingAt
                  ? format(
                      utcToZonedTime(
                        new Date(
                          `${nextFacultyResponse.prefsTravelReturnDate} ${nextFacultyResponse.prefsTravelReturnArrivingAt}`
                        ),
                        currentTimezone
                      ),
                      'HH:mm'
                    )
                  : undefined
              }
            />
          </div>

          <InfoRow
            i18n={i18n}
            label={i18n.info.travel.airplaneFidelityCard}
            value={nextFacultyResponse.prefsTravelAirplaneFidelityCard}
          />
          <InfoRow
            i18n={i18n}
            label={i18n.info.travel.trainFidelityCard}
            value={nextFacultyResponse.prefsTravelTrainFidelityCard}
          />
          <InfoRow
            i18n={i18n}
            label={i18n.info.travel.telephoneNumber}
            value={nextFacultyResponse.prefsTravelTelephoneNumber}
          />
          <InfoRow
            i18n={i18n}
            label={i18n.info.travel.notes}
            value={nextFacultyResponse.prefsTravelNotes}
          />
        </div>
      );
    } else if (nextFacultyResponse.bookByAgency) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', minWidth: 280 }}
        >
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <span>{i18n.info.bookByAgency}</span>
          </div>
        </div>
      );
    } else if (nextFacultyResponse.bookByMyself) {
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280 }}>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <span>{i18n.info.bookByMyself}</span>
        </div>
      </div>;
    }
  }
  return <span>{i18n.info.noInfo}</span>;
};

const renderAllotmentInfo = ({
  i18n,
  i18nAllotment,
  participationServices,
}) => {
  const facultyResponse = participationServices?.allotmentFacultyResponse;
  if (facultyResponse) {
    const nextFacultyResponse = JSON.parse(facultyResponse) || {};

    // {
    //   bookByAuth: true;
    //   chooseBooking: '0';
    //   noBook: false;
    //   responseStatus: 'draft';
    // }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280 }}>
        {nextFacultyResponse.bookByAuth && (
          <span>{i18nAllotment.page.authBooking}</span>
        )}
        {nextFacultyResponse.noBook && (
          <span>{i18nAllotment.page.noBooking}</span>
        )}
      </div>
    );
  }
  return <span>{i18n.info.noInfo}</span>;
};

const renderAdditionalServicesInfo = ({
  i18n,
  participationServices,
  availableAdditionalServices,
  currentTimezone,
}) => {
  const facultyResponse =
    participationServices?.additionalServicesFacultyResponse;
  if (facultyResponse) {
    const nextFacultyResponse = JSON.parse(facultyResponse) || {};

    return (
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280 }}>
        {nextFacultyResponse.selectedServices.map((serviceId) => {
          const nextAdditionalService = availableAdditionalServices.find(
            (as) => as.id === serviceId
          );

          const nextValue = `${format(
            utcToZonedTime(
              new Date(nextAdditionalService.dateService),
              currentTimezone
            ),
            'dd LLLL yyyy'
          )} ${nextAdditionalService.startTime}${
            nextAdditionalService.locationName
              ? ` - ${nextAdditionalService.locationName}`
              : ''
          }${
            nextAdditionalService.locationAddress
              ? ` - ${nextAdditionalService.locationAddress}`
              : ''
          }`;
          return (
            <div key={serviceId}>
              <InfoRow
                i18n={i18n}
                label={nextAdditionalService.title}
                value={nextValue}
                columnDirection
              />
            </div>
          );
        })}
        <InfoRow
          i18n={i18n}
          label={i18n.dialogs.additionalServices.sectionTitles.notes}
          value={nextFacultyResponse.speakerNotes}
          columnDirection
        />
      </div>
    );
  }
  return <span>{i18n.info.noInfo}</span>;
};

const Faculty = () => {
  //Hooks
  const intl = CustomIntl(useIntl());
  const i18n = facultyTranslation.faculty(intl);
  const i18nAllotment = translation.allotment(intl);
  const { eventId } = useParams();
  const history = useHistory();
  const themeMU = useTheme();
  const smDown = useMediaQuery(themeMU.breakpoints.down('xs'));
  const s3EventFolder = `events/${eventId}`;
  const currentTimezone = appState.eventInfo.getValue().timezone;

  // State
  const [
    availableAdditionalServices,
    setAvailableAdditionalServices,
  ] = useState([]);
  const [participationServices, setParticipationServices] = useState();
  const [participationConfirm, setParticipationConfirm] = useState();
  const [
    participationAgendaSessions,
    setParticipationAgendaSessions,
  ] = useState();
  const [files, setFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [uploadAreaDisabled, setUploadAreaDisabled] = useState();

  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [additionalServicesDialog, setAdditionalServicesDialog] = useState({
    isOpen: false,
  });
  const [travelTransferDialog, setTravelTransferDialog] = useState({
    isOpen: false,
  });
  const [allotmentDialog, setAllotmentDialog] = useState({
    isOpen: false,
  });

  const [
    accreditationMaterialDialog,
    setAccreditationMaterialDialog,
  ] = useState({
    isOpen: false,
  });

  const [aifaDataDialog, setAifaDataDialog] = useState({ isOpen: false });

  const [scientificCommitmentDialog, setScientificCommitmentDialog] = useState({
    isOpen: false,
  });

  // Effects
  useEffect(() => {
    if (appState.user.getValue().userAndParticipation.participation.id)
      fetchData(false);
  }, []);

  // API
  const fetchData = async (isOnSubmit) => {
    showLoader();
    const participationId = appState.user.getValue().userAndParticipation
      .participation.id;

    const fetchOps = [
      getParticipationServices(participationId, false),
      listAdditionalServices(eventId, false),
      getAgendaSessionsByEvent(eventId),
      // getAgendaSessionsByEventAndParticipant(eventId, participationId),
    ];
    const [
      participationServicesRes,
      additionalServicesRes,
      agendaSessionsRes,
    ] = await Promise.all(fetchOps);

    const updatedAvailableAdditionalServices = additionalServicesRes.filter(
      (as) =>
        participationServicesRes?.additionalServicesIncludedServices?.includes(
          as.id
        )
    );

    // files
    const filesDataParsed = participationServicesRes.facultyAttachmentsResponse
      ? JSON.parse(participationServicesRes.facultyAttachmentsResponse)?.filter(
          (f) => f.type === typology.PARTICIPATION_FILE.id
        )
      : [];
    setFiles(filesDataParsed);
    setFilesToRemove([]);

    const paxIncludedInSubTree = (pax, currItem, level) => {
      // search in children
      const items = currItem[getChildrenType(level)]?.items;
      if (items) {
        for (let index = 0; index < items.length; index++) {
          const nextCurrItem = items[index];
          const includedItem = nextCurrItem.attendeeRoles?.items?.filter(
            (attendeeRole) =>
              attendeeRole.attendees?.items?.length &&
              attendeeRole.attendees.items.some(
                (attendee) => attendee.participation.id === participationId
              )
          );
          if (includedItem.length > 0) {
            return includedItem;
          } else {
            return paxIncludedInSubTree(pax, nextCurrItem, level + 1);
          }
        }
      } else {
        return undefined;
      }
    };

    const getChildrenType = (level) => {
      if (level === 0) return 'sessions';
      else if (level === 1) return 'subSessions';
      else if (level === 2) return 'speeches';
    };

    const composeTreeOfSessionsWithPaxIncluded = ({
      parentItem,
      itemsList,
      level = 0,
    }) => {
      return itemsList
        ?.map((currItem) => {
          // search if pax included in current item
          let includedItem = currItem.attendeeRoles?.items?.filter(
            (attendeeRole) =>
              attendeeRole.attendees?.items?.length &&
              attendeeRole.attendees.items.some(
                (attendee) => attendee.participation.id === participationId
              )
          );
          // search if pax included in sub tree
          if (!includedItem || includedItem.length === 0)
            includedItem = paxIncludedInSubTree(
              participationId,
              currItem,
              level + 1
            );
          const nextChildren = getChildrenType(level + 1);
          const nextChildrenPropName = `${nextChildren}`;

          // if (!includedItem || includedItem.length === 0) {
          //   delete currItem[childrenPropName];
          // }

          const nextCurrItem = { ...currItem };
          delete nextCurrItem[nextChildrenPropName];

          if (includedItem?.length > 0) {
            parentItem = {
              ...nextCurrItem,
              ...(nextChildren
                ? {
                    [nextChildrenPropName]: {
                      items: composeTreeOfSessionsWithPaxIncluded({
                        parentItem: nextCurrItem,
                        itemsList: currItem[nextChildrenPropName]?.items,
                        level: level + 1,
                      }),
                    },
                  }
                : {}),
            };
            return parentItem;
          }
        })
        ?.filter((x) => x);
    };

    const resultTree = composeTreeOfSessionsWithPaxIncluded({
      itemsList: agendaSessionsRes,
    });

    setParticipationAgendaSessions({ items: resultTree });

    // participation confirm
    const participationConfirmRes = JSON.parse(
      participationServicesRes?.participationFacultyResponse
    );

    setParticipationConfirm(participationConfirmRes);

    setAvailableAdditionalServices(updatedAvailableAdditionalServices);
    setParticipationServices(participationServicesRes);
    // setParticipationAgendaSessions(nextParticipationAgendaAttendeesRes);

    const uploadDisabled =
      checkAllIncludedPrefsHaveStatusPublished(participationServicesRes) ||
      participationServicesRes.facultyStatus ===
        constants.FacultyResponseStatus.PUBLISHED ||
      participationServicesRes.publicationDateOfPrefs;
    setUploadAreaDisabled(uploadDisabled);
    hideLoader();
  };

  // Helpers
  const getStatus = (schemaFacultyResField) => {
    const rawFacultyResponse = participationServices?.[schemaFacultyResField];
    if (rawFacultyResponse) {
      const facultyResponse = JSON.parse(rawFacultyResponse);
      return facultyResponse.responseStatus;
    }
  };

  const checkAllIncludedPrefsHaveStatusPublished = (participationServices) => {
    const participationFacultyResponse = JSON.parse(
      participationServices['participationFacultyResponse']
    );
    const accreditationMaterialFacultyResponse = JSON.parse(
      participationServices['accreditationMaterialFacultyResponse']
    );

    const aifaDataFacultyResponse = JSON.parse(
      participationServices['aifaDataFacultyResponse']
    );

    const allotmentFacultyResponse = JSON.parse(
      participationServices['allotmentFacultyResponse']
    );

    const travelFacultyResponse = JSON.parse(
      participationServices['travelFacultyResponse']
    );

    const additionalServicesFacultyResponse = JSON.parse(
      participationServices['additionalServicesFacultyResponse']
    );

    const allIncludedPrefsPublished =
      participationFacultyResponse &&
      (!participationServices.accreditationMaterialIncluded ||
        (participationServices.accreditationMaterialIncluded &&
          accreditationMaterialFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.aifaDataRequiredIncluded ||
        (participationServices.aifaDataRequiredIncluded &&
          aifaDataFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.allotmentIncluded ||
        (participationServices.allotmentIncluded &&
          allotmentFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.isTravelIncluded ||
        (participationServices.isTravelIncluded &&
          travelFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED)) &&
      (!participationServices.additionalServicesIncluded ||
        (participationServices.additionalServicesIncluded &&
          additionalServicesFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.PUBLISHED));

    return allIncludedPrefsPublished;
  };

  const checkAllIncludedPrefsHaveStatusDraft = (participationServices) => {
    const participationFacultyResponse = JSON.parse(
      participationServices['participationFacultyResponse']
    );
    const accreditationMaterialFacultyResponse = JSON.parse(
      participationServices['accreditationMaterialFacultyResponse']
    );

    const aifaDataFacultyResponse = JSON.parse(
      participationServices['aifaDataFacultyResponse']
    );

    const allotmentFacultyResponse = JSON.parse(
      participationServices['allotmentFacultyResponse']
    );

    const travelFacultyResponse = JSON.parse(
      participationServices['travelFacultyResponse']
    );

    const additionalServicesFacultyResponse = JSON.parse(
      participationServices['additionalServicesFacultyResponse']
    );

    const allIncludedPrefsPublished =
      participationFacultyResponse &&
      (!participationServices.accreditationMaterialIncluded ||
        (participationServices.accreditationMaterialIncluded &&
          accreditationMaterialFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.DRAFT)) &&
      (!participationServices.aifaDataRequiredIncluded ||
        (participationServices.aifaDataRequiredIncluded &&
          aifaDataFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.DRAFT)) &&
      (!participationServices.allotmentIncluded ||
        (participationServices.allotmentIncluded &&
          allotmentFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.DRAFT)) &&
      (!participationServices.isTravelIncluded ||
        (participationServices.isTravelIncluded &&
          travelFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.DRAFT)) &&
      (!participationServices.additionalServicesIncluded ||
        (participationServices.additionalServicesIncluded &&
          additionalServicesFacultyResponse?.responseStatus ===
            constants.FacultyResponseStatus.DRAFT));

    return allIncludedPrefsPublished;
  };

  const getTravelTrasferCardLabel = () => {
    if (!participationServices) return '';
    const {
      isTravelIncluded,
      isTransferAIncluded,
      isTransferRIncluded,
    } = participationServices;
    if (isTravelIncluded && (isTransferAIncluded || isTransferRIncluded))
      return i18n.cards.travelTransfer;
    else if (isTravelIncluded) return i18n.cards.travel;
    else if (isTransferAIncluded || isTransferRIncluded)
      return i18n.cards.transfer;
  };

  const deleteAndUploadFiles = async (pId) => {
    const dirPath = `${s3EventFolder}/participations/${pId}`;
    // files to remove
    const nextFilesToRemove = [...new Set(filesToRemove.map((t) => t.file))];
    for (let i = 0; i < nextFilesToRemove.length; i++) {
      const file = nextFilesToRemove[i];
      if (file.id) {
        await fileHelper.deleteFile({
          dirPath,
          fileData: file,
          useLoader: false,
        });
      }
    }
    setFilesToRemove([]);

    // files to add
    const nextFiles = [...new Set(files.map((t) => t.file))];
    const newFiles = nextFiles.filter((f) => !f.id);
    const nextNewFiles = await Promise.all(
      newFiles.map(async (file) => {
        const originalName = file?.name || new Date().getTime().toString();

        const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
        const extension = matchExtension ? matchExtension[0] : '';

        const fileSize = file?.size;

        const input = {
          originalName: originalName,
          extension: extension,
          size: Number(fileSize) || 0,
        };

        const fileId = await fileHelper.createFile({
          dirPath,
          fileData: input,
          localFile: file,
        });
        const fileInput = { ...input, name: originalName, id: fileId };
        return fileInput;
      })
    );
    return nextNewFiles;
  };

  const removeFile = async (file) => {
    setFilesToRemove([
      ...filesToRemove,
      {
        // type: typology.PARTICIPATION_FILE.id,
        // topic: 'Participation contents',
        file,
      },
    ]);
    const nextUpdatedFiles = files.filter((fd) =>
      file.id ? fd.file.id !== file.id : fd.file.name !== file.name
    );
    setFiles(nextUpdatedFiles);
  };

  const downloadFile = async (item, dirPath, key) => {
    if (item.id) {
      await fileHelper.downloadFromS3({
        dirPath: dirPath
          ? dirPath
          : `${s3EventFolder}/participations/${participationServices.participation.id}/`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const sendMailWithSummary = async () => {
    //send mail with summary
    const participations = [
      {
        id: participationServices?.participation.id,
      },
    ];
    const respSendMail = await aws.standardAPI.post(
      'apiSendMail',
      '/sendMail/trigger',
      {
        body: {
          trigger: 'Faculty summary',
          participations,
          eventId,
        },
      }
    );
  };

  // const onClickDownload = async (row) => {
  //   const dirPath = `${s3EventFolder}/${participationServices.participation.id}/faculty/${row.folder}`;
  //   await downloadFile(row.file, dirPath);
  // };

  // send email, update with publication date and status published, no more changes can be made
  const saveAndSend = async () => {
    showLoader();
    if (
      checkAllIncludedPrefsHaveStatusDraft(participationServices) &&
      participationServices.facultyStatus !==
        constants.FacultyResponseStatus.PUBLISHED &&
      !participationServices.publicationDateOfPrefs
    ) {
      try {
        // files to save in facultyAttachmentsResponse
        const filesToRemoveCopy = [...filesToRemove];
        const facultyAttachmentsFiles = participationServices?.facultyAttachmentsResponse
          ? JSON.parse(participationServices.facultyAttachmentsResponse)
          : [];

        let filesNotRemoved = [...facultyAttachmentsFiles];
        if (filesToRemoveCopy?.length) {
          const filesToRemoveCopyIds = [
            ...new Set(filesToRemoveCopy.map((fd) => fd.file.id)),
          ];

          filesNotRemoved = facultyAttachmentsFiles.filter(
            (fd) =>
              !filesToRemoveCopyIds?.length ||
              !filesToRemoveCopyIds.includes(fd?.file?.id)
          );
        }

        const newFiles = await deleteAndUploadFiles(
          participationServices.participation.id
        );
        const nextNewFiles = newFiles.map((f) => ({
          type: typology.PARTICIPATION_FILE.id,
          topic: 'Participation contents',
          file: f,
        }));

        let accreditationMaterialFacultyToSend;
        if (participationServices?.accreditationMaterialFacultyResponse) {
          accreditationMaterialFacultyToSend = {
            ...JSON.parse(
              participationServices?.accreditationMaterialFacultyResponse
            ),
            responseStatus: constants.FacultyResponseStatus.PUBLISHED,
          };
        }

        let aifaAccreditationMaterialFacultyToSend;
        if (participationServices?.aifaAccreditationMaterialFacultyResponse) {
          aifaAccreditationMaterialFacultyToSend = {
            ...JSON.parse(
              participationServices?.aifaAccreditationMaterialFacultyResponse
            ),
            responseStatus: constants.FacultyResponseStatus.PUBLISHED,
          };
        }

        let aifaDataFacultyToSend;
        if (participationServices?.aifaDataFacultyResponse) {
          aifaDataFacultyToSend = {
            ...JSON.parse(participationServices?.aifaDataFacultyResponse),
            responseStatus: constants.FacultyResponseStatus.PUBLISHED,
          };
        }

        let travelFacultyToSend;
        if (participationServices?.travelFacultyResponse) {
          travelFacultyToSend = {
            ...JSON.parse(participationServices?.travelFacultyResponse),
            responseStatus: constants.FacultyResponseStatus.PUBLISHED,
          };
        }

        let allotmentFacultyToSend;
        if (participationServices?.allotmentFacultyResponse) {
          allotmentFacultyToSend = {
            ...JSON.parse(participationServices?.allotmentFacultyResponse),
            responseStatus: constants.FacultyResponseStatus.PUBLISHED,
          };
        }

        let additionalServicesFacultyToSend;
        if (participationServices?.additionalServicesFacultyResponse) {
          additionalServicesFacultyToSend = {
            ...JSON.parse(
              participationServices?.additionalServicesFacultyResponse
            ),
            responseStatus: constants.FacultyResponseStatus.PUBLISHED,
          };
        }

        const publishedStatusInput = {
          accreditationMaterialFacultyResponse: JSON.stringify(
            accreditationMaterialFacultyToSend
          ),
          aifaAccreditationMaterialFacultyResponse: JSON.stringify(
            aifaAccreditationMaterialFacultyToSend
          ),
          aifaDataFacultyResponse: JSON.stringify(aifaDataFacultyToSend),
          travelFacultyResponse: JSON.stringify(travelFacultyToSend),
          allotmentFacultyResponse: JSON.stringify(allotmentFacultyToSend),
          additionalServicesFacultyResponse: JSON.stringify(
            additionalServicesFacultyToSend
          ),
        };

        // update with date and faculty status to PUBLISHED
        await updateParticipationServices({
          id: participationServices.id,
          publicationDateOfPrefs: new Date(),
          facultyStatus: constants.FacultyResponseStatus.PUBLISHED,
          ...publishedStatusInput,
          facultyAttachmentsResponse: JSON.stringify([
            ...filesNotRemoved,
            ...nextNewFiles,
          ]),
        });
        fetchData(true);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.success,
        });

        // send mail
        await sendMailWithSummary();
      } catch (err) {
        console.error('err', err);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.errors.saveAndSend,
        });
      }
    }
    hideLoader();
  };

  const formattingFiles = (filesToFormat) => {
    return filesToFormat.map((f) => ({
      type: typology.PARTICIPATION_FILE.id,
      topic: 'Participation contents',
      file: f,
    }));
  };

  // send email, update with status draft, changes can still be made
  const saveDraft = async () => {
    showLoader();
    if (
      !checkAllIncludedPrefsHaveStatusPublished(participationServices) &&
      !participationServices.publicationDateOfPrefs
    ) {
      try {
        // files to save in facultyAttachmentsResponse
        const filesToRemoveCopy = [...filesToRemove];
        const facultyAttachmentsFiles = participationServices?.facultyAttachmentsResponse
          ? JSON.parse(participationServices.facultyAttachmentsResponse)
          : [];

        let filesNotRemoved = [...facultyAttachmentsFiles];
        if (filesToRemoveCopy?.length) {
          const filesToRemoveCopyIds = [
            ...new Set(filesToRemoveCopy.map((fd) => fd.file.id)),
          ];

          filesNotRemoved = facultyAttachmentsFiles.filter(
            (fd) =>
              !filesToRemoveCopyIds?.length ||
              !filesToRemoveCopyIds.includes(fd?.file?.id)
          );
        }

        const newFiles = await deleteAndUploadFiles(
          participationServices.participation.id
        );
        const nextNewFiles = newFiles.map((f) => ({
          type: typology.PARTICIPATION_FILE.id,
          topic: 'Participation contents',
          file: f,
        }));

        // const inputParticipationFacultyResponse = participationServices?.participationFacultyResponse
        //   ? JSON.parse(participationServices?.participationFacultyResponse)
        //   : {};

        const input = {
          id: participationServices.id,
          facultyStatus: constants.FacultyResponseStatus.DRAFT,
          // participationFacultyResponse: JSON.stringify({
          //   ...inputParticipationFacultyResponse,
          // }),
          facultyAttachmentsResponse: JSON.stringify([
            ...filesNotRemoved,
            ...nextNewFiles,
          ]),
        };

        // update with date
        await updateParticipationServices(input);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.success,
          message: i18n.snackbar.success,
        });

        // send mail
        await sendMailWithSummary();
      } catch (err) {
        console.error('err', err);
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.errors.saveDraft,
        });
      }
    }
    hideLoader();
  };

  // Renders

  const renderDialogs = () => (
    <>
      <AdditionalServicesDialog
        dialogState={additionalServicesDialog}
        i18n={i18n}
        onClose={() => setAdditionalServicesDialog({ isOpen: false })}
        onSuccess={() => {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.success,
          });
          fetchData(true);
        }}
        onConflictingEvents={() =>
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.errors.concurrentEvents,
          })
        }
        onError={() =>
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.errors.additionalServices,
          })
        }
      />
      {participationServices && (
        <AccreditationMaterialDialog
          dialogState={accreditationMaterialDialog}
          typology={typology}
          participation={participationServices?.participation}
          i18n={i18n}
          onClose={() => setAccreditationMaterialDialog({ isOpen: false })}
          onSuccess={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.snackbar.success,
            });
            fetchData(true);
          }}
          onError={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.accreditationMaterial,
            })
          }
        />
      )}
      <AifaDataDialog
        dialogState={aifaDataDialog}
        i18n={i18n}
        onClose={() => setAifaDataDialog({ isOpen: false })}
        onSuccess={() => {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.success,
          });
          fetchData(true);
        }}
        onError={() =>
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.errors.aifaData,
          })
        }
      />
      {travelTransferDialog.isOpen && (
        <TravelTransferDialog
          dialogState={travelTransferDialog}
          typology={typology}
          i18n={i18n}
          onClose={() => setTravelTransferDialog({ isOpen: false })}
          onSuccess={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.snackbar.success,
            });
            fetchData(true);
          }}
          onConflictingEvents={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.concurrentEvents,
            })
          }
          onError={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.travelTransfer,
            })
          }
        />
      )}
      {allotmentDialog.isOpen && (
        <AllotmentDialog
          dialogState={allotmentDialog}
          i18n={i18n}
          onClose={() => setAllotmentDialog({ isOpen: false })}
          onSuccess={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.snackbar.success,
            });
            fetchData(true);
          }}
          onConflictingEvents={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.concurrentEvents,
            })
          }
          onError={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.allotment,
            })
          }
        />
      )}
      {scientificCommitmentDialog.isOpen && (
        <ScientificCommitmentDialog
          dialogState={scientificCommitmentDialog}
          i18n={i18n}
          onClose={() => setScientificCommitmentDialog({ isOpen: false })}
          onSuccess={() => {
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.success,
              message: i18n.snackbar.success,
            });
            fetchData(true);
          }}
          onError={() =>
            setSnackbar({
              isOpen: true,
              severity: AimSnackbarSeverity.error,
              message: i18n.snackbar.errors.scientificCommitment,
            })
          }
        />
      )}
    </>
  );

  const renderButtons = () => {
    return (
      <>
        {participationServices && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'right', // 'space-between',
              alignItems: 'center',
              margin: '10px auto',
              ...formControlStyle,
            }}
          >
            <div>
              <AimIconAndTextButton
                variant={'greenFill'}
                text={i18n.buttons.saveDraft}
                onClick={saveDraft}
                disabled={
                  participationServices.publicationDateOfPrefs ||
                  participationServices.facultyStatus ===
                    constants.FacultyResponseStatus.REJECTED
                }
              >
                <CheckIcon />
              </AimIconAndTextButton>
              <AimIconAndTextButton
                variant={'greenFill'}
                text={i18n.buttons.saveAndSend}
                onClick={saveAndSend}
                disabled={
                  participationServices.publicationDateOfPrefs ||
                  checkAllIncludedPrefsHaveStatusPublished(
                    participationServices
                  ) ||
                  participationServices.facultyStatus ===
                    constants.FacultyResponseStatus.PUBLISHED
                }
              >
                <CheckIcon />
              </AimIconAndTextButton>
            </div>
          </div>
        )}
      </>
    );
  };

  if (!participationServices) return null;

  return (
    <FacultyContainer smDown={smDown}>
      <FacultyInnerContainer smDown={smDown}>
        <AimIconAndTextButton
          variant="none"
          text={i18n.buttons.back}
          style={{
            padding: 0,
          }}
          onClick={() => history.replace(`/events/${eventId}/landing`)}
        >
          <ArrowBack />
        </AimIconAndTextButton>

        {/* HEADER */}
        <HeaderContainer smDown={smDown}>
          <div>
            <AimTypography variant={'h1'}>{i18n.title}</AimTypography>
            <AimTypography variant="h4Regular">
              {`${format(
                new Date(participationServices?.participation.event.start),
                'dd LLLL yyyy'
              )} - 
              ${format(
                new Date(participationServices?.participation.event.end),
                'dd LLLL yyyy'
              )}`}
            </AimTypography>
          </div>
          <div>
            <AimIconAndTextButton
              isUpperCase
              variant="yellow"
              style={{ whiteSpace: 'nowrap', marginTop: '8px' }}
              text={i18n.buttons.purchaseServices}
              onClick={() => history.push(`/events/${eventId}/landing`)}
            >
              <ArrowForward />
            </AimIconAndTextButton>
          </div>
        </HeaderContainer>

        {/* AGENDA SESSIONS */}
        {participationAgendaSessions?.items.length > 0 ? (
          <FacultySectionWrapper>
            <ProgramTemplate
              intl={intl}
              sessions={participationAgendaSessions}
              eventId={eventId}
              isFrontOffice
              previewPdf={false}
              hideDownloadPdfAndTitle={false}
              title={i18n.sections.agenda.title}
              titleProps={{
                variant: 'h4Regular',
                style: { textTransform: 'uppercase' },
                margin: 0,
              }}
              cardProps={{ style: { padding: 0 } }}
            />
          </FacultySectionWrapper>
        ) : null}

        {/* PARTICIPATION CONFIRM */}
        <FacultySection
          title={i18n.sections.participation.title}
          subtitle={
            participationServices.participationIncluded
              ? `${i18n.pax.subTitle1} ${i18n.pax.subTitle2}`
              : i18n.pax.subTitle2
          }
        >
          <CardsWrapper>
            <ParticipationConfirm
              servicesState={{
                eventId,
                data: participationServices,
              }}
              i18n={i18n}
              // onCancel={}
              onSuccess={() => {
                setSnackbar({
                  isOpen: true,
                  severity: AimSnackbarSeverity.success,
                  message: i18n.snackbar.success,
                });
                fetchData();
              }}
              onError={() =>
                setSnackbar({
                  isOpen: true,
                  severity: AimSnackbarSeverity.error,
                  message: i18n.snackbar.errors.aifaData,
                })
              }
            />
          </CardsWrapper>
        </FacultySection>

        {/* if participation confirm published  */}
        {participationConfirm?.responseStatus ===
          constants.FacultyResponseStatus.PUBLISHED &&
          participationConfirm?.confirmPax === true && (
            <>
              {/* FIRST ROW CARDS */}
              <FacultySection
                title={i18n.sections.participationDetails.title}
                subtitle={i18n.sections.participationDetails.subtitle}
              >
                <CardsWrapper>
                  {/* {participationServices?.participationScientificCommitment &&
                  (participationServices?.participationReportUpload ||
                    participationServices?.participationPresentationUpload) && (
                    <FacultyCard
                      title="Scientific Commitment"
                      status={getStatus('participationFacultyResponse')}
                      onClick={() =>
                        setScientificCommitmentDialog({
                          isOpen: true,
                          eventId,
                          data: participationServices,
                        })
                      }
                    />
                  )} */}

                  {/* ACCREDITATION MATERIAL */}
                  {participationServices?.accreditationMaterialIncluded && (
                    <FacultyCard
                      title="Accreditation Material"
                      status={getStatus('accreditationMaterialFacultyResponse')}
                      onClick={() => {
                        setAccreditationMaterialDialog({
                          isOpen: true,
                          eventId,
                          data: participationServices,
                          accreditationMaterialDescription: participationServices.accreditationMaterialDescription
                            ? JSON.parse(
                                participationServices.accreditationMaterialDescription
                              )
                            : '',
                          accreditationMaterialTemplates: participationServices.accreditationMaterialTemplates
                            ? JSON.parse(
                                participationServices.accreditationMaterialTemplates
                              )
                            : '',
                          typology,
                        });
                      }}
                      infoData={() =>
                        renderAccreditationMaterialInfo({
                          i18n,
                          participationServices,
                        })
                      }
                    />
                  )}

                  {/* AIFA */}
                  {participationServices?.aifaDataRequiredIncluded && (
                    <FacultyCard
                      title="Aifa Data"
                      status={getStatus('aifaDataFacultyResponse')}
                      onClick={() =>
                        setAifaDataDialog({
                          isOpen: true,
                          eventId,
                          data: participationServices,
                          aifaDataDescription: participationServices?.aifaDataDescription
                            ? JSON.parse(
                                participationServices?.aifaDataDescription
                              )
                            : '',
                        })
                      }
                      infoData={() =>
                        renderAIFAInfo({ i18n, participationServices })
                      }
                    />
                  )}
                </CardsWrapper>
              </FacultySection>

              {/* SECOND ROW CARDS */}
              <FacultySection
                title={i18n.sections.servicesAndPreferences.title}
                subtitle={i18n.sections.servicesAndPreferences.subtitle}
              >
                <CardsWrapper>
                  {/* TRAVEL TRANSFER */}
                  {(participationServices?.isTravelIncluded ||
                    participationServices?.isTransferAIncluded ||
                    participationServices?.isTransferRIncluded) && (
                    <FacultyCard
                      title={getTravelTrasferCardLabel()}
                      status={getStatus('travelFacultyResponse')}
                      onClick={() =>
                        setTravelTransferDialog({
                          isOpen: true,
                          eventId,
                          participationServices,
                        })
                      }
                      infoData={() =>
                        renderTravelInfo({
                          i18n,
                          participationServices,
                          currentTimezone,
                        })
                      }
                    />
                  )}

                  {/* ALLOTMENT */}
                  {participationServices?.allotmentIncluded && (
                    <FacultyCard
                      title={i18n.cards.allotment}
                      status={getStatus('allotmentFacultyResponse')}
                      onClick={() =>
                        setAllotmentDialog({
                          isOpen: true,
                          eventId,
                          participationServices,
                        })
                      }
                      infoData={() =>
                        renderAllotmentInfo({
                          i18n,
                          i18nAllotment,
                          participationServices,
                        })
                      }
                    />
                  )}

                  {/* ADDITIONAL SERVICES */}
                  {participationServices?.additionalServicesIncluded && (
                    <FacultyCard
                      title={i18n.cards.additionalServices}
                      status={getStatus('additionalServicesFacultyResponse')}
                      onClick={() =>
                        setAdditionalServicesDialog({
                          isOpen: true,
                          eventId,
                          data: {
                            availableAdditionalServices,
                            participationServices,
                          },
                        })
                      }
                      infoData={() =>
                        renderAdditionalServicesInfo({
                          i18n,
                          participationServices,
                          availableAdditionalServices,
                          currentTimezone,
                        })
                      }
                    />
                  )}
                </CardsWrapper>
              </FacultySection>
            </>
          )}

        {/* DOWNLOAD AREA / UPLOAD AREA */}
        {participationServices?.participation &&
          participationServices?.facultyDownloadArea && (
            <>
              <DownloadArea
                {...{
                  files: JSON.parse(participationServices.facultyDownloadArea),
                  i18n,
                  dirPath: `${s3EventFolder}/participations/${participationServices.participation.id}`,
                }}
              />
              <UploadAreaContent>
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <AimTypography
                    variant="textBold"
                    boxStyle={{ marginLeft: 0 }}
                  >
                    {i18n.upload.uploadArea}
                  </AimTypography>
                </div>
                <Divider style={{ width: '100%', marginBottom: 10 }} />
                <UploadArea>
                  <AimDragDropFileUploader
                    {...{
                      dragTitle: i18n.upload.dragAndDrop.title,
                      dragSubTitle: i18n.upload.dragAndDrop.subTitle,
                      clickText: i18n.upload.dragAndDrop.clickText,
                      onUploadFile: (filesToAdd) =>
                        setFiles([...files, ...formattingFiles(filesToAdd)]),
                      onRemove: removeFile,
                      onDownload: downloadFile,
                      files: [...new Set(files.map((t) => t.file))],
                      maxSize: '5242880',
                      disabledRemove: uploadAreaDisabled,
                      disabledDownload: uploadAreaDisabled,
                      dropzoneAdditionalProps: {
                        multiple: true,
                        disabled: uploadAreaDisabled,
                      },
                    }}
                  />
                  {/* <AimDragDropFileUploader
                dragTitle={`${i18n.download.dragAndDrop.title}`}
                dragSubTitle={i18n.download.dragAndDrop.subTitle}
                clickText={i18n.download.dragAndDrop.clickText}
                fileLimitText={i18n.download.dragAndDrop.fileLimitText}
                onUploadFile={(filesToAdd) =>
                  setFiles([...files, ...filesToAdd])
                }
                // onLoad={onLoad}
                // onUploadFile={uploadFiles}
                onRemove={removeFile}
                onDownload={downloadFile}
                files={files}
                maxFiles={1}
                dropzoneAdditionalProps={{ multiple: true }}
              /> */}
                </UploadArea>
              </UploadAreaContent>
            </>
          )}

        {renderButtons()}
      </FacultyInnerContainer>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
      {renderDialogs()}
    </FacultyContainer>
  );
};

export default Faculty;

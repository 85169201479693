import { appState, constants, aws, utilities } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getEventDataQuery = (cluster) => /* GraphQL */ `
  query GetEventDataTicketForm($id: ID!) {
    getEvent(id: $id) {
      id
      type
      fee {
        id
        vat {
          id
          amount
        }
        feeDateRanges {
          items {
            id
            start
            end
            label
          }
        }
      }
      profiles (filter:
        {
          or: [
                {clusters: {contains: "${cluster}"}}, 
                {cluster: {eq:"${cluster}"}}
              ]
            , 
            isDeleted: {ne: true}
          }) {
        items {
          name
          id
          groups
          sponsorlist
          profileFeeBrackets {
            items {
              id
              priceOnAir
              priceOnSite
              profileFeeConfiguration {
                id
                isActive
              }
              feeBracket {
                createdAt
                feeBracketModel {
                  end
                  name
                  start
                }
                feeDateRange {
                  id
                  start
                  end
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getEventAdditionalServicesQuery = /* GraphQL */ `
  query GetEventAdditionalServicesFormData($id: ID!, $typology: String!) {
    getEvent(id: $id) {
      additionalServices(
        filter: { typology: { eq: $typology }, isDeleted: { ne: true } }
      ) {
        items {
          id
          title
          isDeleted
          amount1
          amount2
          additionaServiceVat1 {
            id
            amount
          }
          additionaServiceVat2 {
            id
            amount
          }
          participationType
          reservedToCluster
          reservedToProfiles
          prices {
            items {
              start
              end
              name
              netPrice1
              netPrice2
              vat1 {
                id
                amount
              }
              vat2 {
                id
                amount
              }
            }
          }
        }
      }
    }
  }
`;

const getParticipationQuery = /* GraphQL */ `
  query GetParticipation($id: ID!) {
    getParticipation(id: $id) {
      id
      participationFeeDateRangeId
      participationFeeBracketModelId
      type
      participationProfileId
      n2nConnection (filter: { type: { eq: "${constants.N2NConnectionTypes.PARTICIPATION_ADDITIONAL_SERVICE}" } } ) {
        items {
          id
          n2NConnectionsTableAdditionalServicesId
        }
      }
    }
  }
`;

export const getParticipation = ({ participationId }) => {
  showLoader();
  return aws.API.graphql({
    query: getParticipationQuery,
    variables: { id: participationId },
  })
    .then((response) => {
      const { data } = response;
      return data.getParticipation;
    })
    .catch((e) => {
      console.error(
        'get-participation-participation-edit-wrapper-frontoffice',
        e
      );
      return null;
    })
    .finally(hideLoader);
};

export const getEventData = (id, cluster, clusterId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventDataQuery(cluster),
      variables: { id },
    })
      .then((response) => {
        const { data } = response;
        resolve({
          profiles: data.getEvent.profiles.items
            .filter((p) =>
              cluster === constants.Clusters.Groups.id
                ? p.groups.some(
                    (groupClusterId) =>
                      groupClusterId === clusterId || groupClusterId === 'all'
                  )
                : cluster === constants.Clusters.SponsorList.id
                ? p.sponsorlist.some(
                    (sponsorClusterId) =>
                      sponsorClusterId === clusterId ||
                      sponsorClusterId === 'all'
                  )
                : true
            )
            .map((p) => ({
              ...p,
              profileFeeBrackets: {
                items: p.profileFeeBrackets.items.map((b) => ({
                  ...b,
                  priceOnAir: utilities.decodeDbNumber(b.priceOnAir),
                  priceOnSite: utilities.decodeDbNumber(b.priceOnSite),
                })),
              },
            })),
          feeDateRanges: data.getEvent.fee.feeDateRanges.items,
          eventType: data.getEvent.type,
          vat: {
            vatRate: utilities.decodeDbNumber(data.getEvent.fee.vat.amount),
            vatId: data.getEvent.fee.vat.id,
          },
        });
      })
      .catch((e) => {
        console.error('event-profiles-participation-form', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventAdditionalServices = ({ eventId, typology }) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventAdditionalServicesQuery,
      variables: { id: eventId, typology },
    })
      .then((response) => {
        const { data } = response;
        resolve(
          data.getEvent.additionalServices.items.filter((s) => !s.isDeleted)
        );
      })
      .catch((e) => {
        console.error('event-additional-services-participation-form', e);
        reject();
      })
      .finally(hideLoader);
  });

import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { appState, aws } from '@aim/common';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LoginLayout from './shared/LoginLayout';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  AimTextField,
  AimTypography,
  CustomIntl,
} from '@aim/components';
import { translations } from './shared/translations';
import { useIntl } from 'react-intl';

const formControlStyle = { width: 'calc(100% - 10px)' };

const ForceChangePassword = (props) => {
  const intl = CustomIntl(useIntl());
  const i18n = translations.forceChangePassword(intl);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [errorMessage, setErrorMessage] = useState();

  const { isLoader } = appState;

  const validationSchema = {
    password: yup.string().required(i18n.passwordRequired),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], i18n.passwordMustMatch),
  };

  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {},
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });

  const setIsWorking = (state) => {
    isLoader.next(state);
  };

  const completeNewPassword = async (data) => {
    try {
      setIsWorking(true);
      let session = await aws.Auth.completeNewPassword(
        props.config.user,
        data.password
      );
      if (session) {
        setIsWorking(false);
        props.config.setWindow('LOGIN');
      }
    } catch (err) {
      console.error(err);
      setErrorMessage(err.message);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: err.message,
      });
      setIsWorking(false);
    }
  };

  return (
    <LoginLayout title={i18n.pageTitle}>
      <form onSubmit={handleSubmit(completeNewPassword)}>
        <Grid container>
          <Grid item xs={12}>
            <AimTextField
              inputRef={register}
              textfieldVariant="grey"
              label={i18n.password}
              name="password"
              placeholder={i18n.password}
              type="password"
              formControlStyle={formControlStyle}
            />
            {errors['password'] && (
              <AimTypography variant="formError">
                *{errors['password'].message}
              </AimTypography>
            )}{' '}
          </Grid>
          <Grid item xs={12}>
            <AimTextField
              inputRef={register}
              textfieldVariant="grey"
              label={i18n.repeatpassword}
              name="passwordConfirm"
              placeholder={i18n.repeatpassword}
              type="password"
              formControlStyle={formControlStyle}
            />
            {errors['passwordConfirm'] && (
              <AimTypography variant="formError">
                *{errors['passwordConfirm'].message}
              </AimTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            {errorMessage && (
              <AimTypography variant="formError">
                {errorMessage ? (
                  <p style={{ color: 'red' }}>{errorMessage}</p>
                ) : null}
              </AimTypography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '20px 14px 0px',
            }}
          >
            <AimIconAndTextButton
              text={i18n.confirm}
              type="submit"
              isUpperCase
              style={{ width: '100%' }}
            >
              {/* <ArrowForwardIcon /> */}
            </AimIconAndTextButton>
          </Grid>
        </Grid>
      </form>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </LoginLayout>
  );
};

export default ForceChangePassword;

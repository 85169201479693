import React from 'react';

import { AimTypography, theme, styled } from '@aim/components';

const Wrapper = styled('div')({
  width: 300,
  height: 300,
  position: 'relative',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  margin: 10,
});

const Overlay = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.3)',
});

const Content = styled('div')({
  zIndex: 10,
  position: 'absolute',
  left: 10,
  bottom: 10,
  width: 'calc(100% - 10px)',
});

const SoldOutOverlay = styled(AimTypography)({
  position: 'absolute',
  left: -10,
  top: -10,
  transform: 'translateY(-100%)',
  width: 'calc(100% + 10px)',
  padding: 5,
  backgroundColor: 'rgba(0,0,0,0.4)',
  textAlign: 'center',
  color: theme.colors.greyScale.white,
});

const Title = styled(AimTypography)({
  color: theme.colors.greyScale.white,
});
const Date = styled(AimTypography)({
  color: theme.colors.greyScale.white,
});

const AdditionalServiceCard = ({
  title,
  image,
  onClick,
  availableSubscription,
  i18n,
  date,
  time,
  isNotAvailable,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      style={{
        backgroundImage: `url(${image})`,
        cursor:
          onClick && availableSubscription && !isNotAvailable
            ? 'pointer'
            : 'default',
      }}
    >
      <Content>
        <Title variant="h2">{title}</Title>
        <Date variant="text">{`${date}${time ? `, at ${time}` : ''}`}</Date>

        {!isNotAvailable &&
        availableSubscription !== undefined &&
        availableSubscription < 1 ? (
          <SoldOutOverlay textAlign="center" variant="h1">
            {i18n.card.soldOut}
          </SoldOutOverlay>
        ) : null}
        {isNotAvailable ? (
          <SoldOutOverlay textAlign="center" variant="h1">
            {i18n.card.notAvailable}
          </SoldOutOverlay>
        ) : null}
      </Content>
      <Overlay />
    </Wrapper>
  );
};

export default AdditionalServiceCard;

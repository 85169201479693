import { aws, appState } from '@aim/common';
const { API } = aws;

import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 8); // 1 id/hour --> 1% probability of collision in 27 years

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createProductMutation = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateProductMutation = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const productGqlHelper = () => {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createProductMutation,
        variables: {
          input: { ...input, ...(!input.productId && { productId: nanoid() }) },
        },
      })
        .then((response) => resolve(response.data.createProduct))
        .catch((e) => {
          console.error('create-product', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateProductMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateProduct))
        .catch((e) => {
          console.error('update-product', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return {
    create,
    update,
  };
};

export const myTicketsTranslations = (intl) => ({
  myTickets: {
    cardTitle: intl.formatMessage({
      defaultMessage: 'Booking Info',
      description: 'myTickets card title',
    }),
    allotmentTitle: intl.formatMessage({
      defaultMessage: 'Allotment Info',
      description: 'myTickets allotmentTitle card',
    }),
    socialEventsTitle: intl.formatMessage({
      defaultMessage: 'Social Events Info',
      description: 'myTickets socialEventsTitle card',
    }),
    scientificEventsTitle: intl.formatMessage({
      defaultMessage: 'Scientific Events Info',
      description: 'myTickets scientificEventsTitle card',
    }),
    certificationTitle: intl.formatMessage({
      defaultMessage:
        'Click inside to download your certificate of participation',
      description: 'myTickets certificationTitle card',
    }),
    donwloadBtn: intl.formatMessage({
      defaultMessage: 'download',
      description: 'myTickets donwloadBtn card',
    }),
    code: intl.formatMessage({
      defaultMessage: 'Code',
      description: 'myTickets card code',
    }),
    profile: intl.formatMessage({
      defaultMessage: 'Profile',
      description: 'myTickets card profile',
    }),
    participationMode: intl.formatMessage({
      defaultMessage: 'Participation Mode',
      description: 'myTickets card participationMode',
    }),
    registrationFee: intl.formatMessage({
      defaultMessage: 'Registration Fee',
      description: 'myTickets card registrationFee',
    }),
    startDate: intl.formatMessage({
      defaultMessage: 'Start date',
      description: 'myTickets card startDate',
    }),
    endDate: intl.formatMessage({
      defaultMessage: 'End date',
      description: 'myTickets card endDate',
    }),
    accompanyingPersons: intl.formatMessage({
      defaultMessage: 'Accompanying persons',
      description: 'myTickets card accompanyingPersons',
    }),
    total: intl.formatMessage({
      defaultMessage: 'Total',
      description: 'myTickets card total',
    }),
    paymentStatus: intl.formatMessage({
      defaultMessage: 'Payment status',
      description: 'myTickets card paymentStatus',
    }),
  },
});

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
// import { sortBy } from 'lodash';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';

import { isAfter, parseISO } from 'date-fns';

import {
  appState,
  aws,
  // constants,
  // awsmobile,
  // fileHelper,
  // utilities,
  awsmobile,
} from '@aim/common';

import {
  AimCheckbox,
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  AnagraphicForm,
  // useAimMediaQuery,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';

import LoginLayout from '../../login/shared/LoginLayout';
import { setUserCognitoIdentityProviderIdentityId } from '../../../shared/utilities';
// import { getEventDetails, getFile } from '../../participation/gqlHelper';

import { createAgency, createFieldValue } from './gqlHelper';

import translation from './translation';

const languagesCodes = {
  english: 'en_AIM_Informativa clienti-fornitori persone giuridiche_2024.pdf',
  italian: 'AIM_Informativa clienti-fornitori persone giuridiche_2024.pdf',
};

const AgencyRegistrationForm = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.agencyEdit(intl);
  // const { isMobile, isMobileOnly } = useAimMediaQuery();
  // const { downloadFromS3 } = fileHelper;

  const [user] = useState(appState.user.getValue());
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [isRegistrationClosed, setIsRegistrationClosed] = useState(false);
  // const [event, setEvent] = useState();
  // const [privacyFiles, setPrivacyFiles] = useState([]);
  const [isPrivacySelected, setIsPrivacySelected] = useState(false);
  const [baseFieldsCollection, setBaseFieldsCollection] = useState([]);
  const [additionalFieldsCollection, setAdditionalFieldsCollection] = useState(
    []
  );
  const [baseDefaultValues, setBaseDefaultValues] = useState([]);
  const [additionalDefaultValues, setAdditionalDefaultValues] = useState([]);
  const [currentParticipation, setCurrentParticipation] = useState();
  const [privacyUrl, setPrivacyUrl] = useState();
  const saveDataRef = useRef();

  useEffect(() => {
    const getPrivacyFileFromS3 = async () => {
      const eventInfo = appState.eventInfo.getValue();
      const s3Folder = `privacy/`;
      const language = eventInfo?.language?.name?.toLowerCase() || 'english';
      const privacyFile = languagesCodes[language];
      const link = await aws.Storage.get(`${s3Folder}${privacyFile}`);
      setPrivacyUrl(link);
    };

    const eventInfo = appState.eventInfo.getValue();
    if (eventInfo.byInvitationOnlyFromEnabled) {
      if (isAfter(Date.now(), parseISO(eventInfo.byInvitationOnlyFromDate))) {
        setIsRegistrationClosed(true);
        return;
      }
    }
    getPrivacyFileFromS3();
  }, []);

  useEffect(() => {
    if (!user) return;

    const getData = async () => {
      showLoader();

      const nextParticipation = user?.userAndParticipation?.participation;
      // const cluster = nextParticipation?.cluster || constants.Clusters.Pax.id;
      // const evDetails = await getEventDetails(eventId, cluster, false);
      // setEvent(evDetails);

      // const privacyFilesJSON = evDetails.privacyFiles;
      // if (privacyFiles) {
      //   const privacyFilesParsed = JSON.parse(privacyFilesJSON);
      //   const privacyFilesPromises = [];
      //   privacyFilesParsed?.forEach((privacyFile) => {
      //     privacyFilesPromises.push(getFile(privacyFile));
      //   });
      //   Promise.all(privacyFilesPromises).then((values) => {
      //     setPrivacyFiles(values);
      //   });
      // }

      let participation = {
        ...nextParticipation,
        event: { id: eventId },
        username: user.awsUser.username,
        cognitoUserId: user.awsUser.attributes.sub,
        email: user.awsUser.attributes.email,
        isParticipant: true,
        status: 'registered',
      };
      setCurrentParticipation(participation);
      hideLoader();
    };

    getData();
  }, [user]);

  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);

  // const formControlStyle = { width: 'calc(100% - 10px)' };

  useEffect(() => {
    const createBaseFieldsCollection = ({ i18n, isReadOnly }) => {
      const fields = {
        agency: [
          {
            id: 'name',
            controlType: 'text',
            label: i18n.labels.companyName,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'baseDefaultValues',
          },
          {
            id: 'city',
            controlType: 'autocompletecity',
            label: i18n.labels.city,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'baseDefaultValues',
          },
          {
            id: 'country',
            controlType: 'autocompletecountries',
            label: i18n.labels.country,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'baseDefaultValues',
          },
          {
            id: 'telephone',
            controlType: 'text',
            label: i18n.labels.telephone,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'baseDefaultValues',
          },
          {
            id: 'address',
            controlType: 'text',
            label: i18n.labels.address,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'baseDefaultValues',
          },
          {
            id: 'postalCode',
            controlType: 'text',
            label: i18n.labels.postalCode,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'baseDefaultValues',
          },
          {
            id: 'taxIdNumber',
            controlType: 'text',
            label: i18n.labels.taxIdNumber,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'baseDefaultValues',
          },

          // {
          //   id: 'status',
          //   controlType: 'select',
          //   defaultValue: 'suspended',
          //   label: i18n.labels.status,
          //   options: constants.ParticipantStatus.map((status) => {
          //     return { label: status.label(intl), value: status.value };
          //   }),
          //   disabled: isReadOnly,
          //   prefix: 'baseDefaultValues',
          // },
        ],
      };
      return {
        ...fields,
        // agency: fields.agency,
        // .filter((f) => !toSkipBaseFieldsIds.includes(f.id))
        // .map((f) =>
        //   toDisableBaseFields.includes(f.id) ? { ...f, disabled: true } : f
        // )
        // .map((f) =>
        //   requiredBaseFields.includes(f.id) ? { ...f, isRequired: true } : f
        // )
        // .map((f) =>
        //   toHideBaseFields.includes(f.id)
        //     ? { ...f, gridItemProps: { style: { display: 'none' } } }
        //     : f
        // ),
      };
    };
    const nextBaseFieldsCollection = createBaseFieldsCollection({
      i18n,
    });

    setBaseFieldsCollection(nextBaseFieldsCollection);

    const nextBaseDefaultValues = {
      title: '',
    };
    setBaseDefaultValues(nextBaseDefaultValues);

    const createAdditionalFieldsCollection = ({ i18n, isReadOnly }) => {
      const fields = {
        participation: [
          {
            id: 'givenName',
            controlType: 'text',
            label: i18n.labels.givenName,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'additionalDefaultValues',
          },
          {
            id: 'familyName',
            controlType: 'text',
            label: i18n.labels.familyName,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'additionalDefaultValues',
          },
          {
            id: 'bemail',
            controlType: 'text',
            label: i18n.labels.bemail,
            disabled: true,
            isRequired: true,
            prefix: 'additionalDefaultValues',
          },
          {
            id: 'bphone',
            controlType: 'text',
            label: i18n.labels.bphone,
            disabled: isReadOnly,
            isRequired: true,
            prefix: 'additionalDefaultValues',
          },
        ],
      };
      return {
        ...fields,
      };
    };
    const nextAdditionalFieldsCollection = createAdditionalFieldsCollection({
      i18n,
    });
    setAdditionalFieldsCollection(nextAdditionalFieldsCollection);

    const nextAdditionalDefaultValues = {
      bemail: user?.awsUser?.attributes?.email,
    };
    setAdditionalDefaultValues(nextAdditionalDefaultValues);
  }, []);

  const saveFieldValues = async (nextFields, agencyId) => {
    await Promise.all([
      ...nextFields.map(async (f) => {
        await createFieldValue(
          {
            agencyFieldValuesId: agencyId,
            fieldValueFieldDefinitionId: f.fieldDefinition?.id,
            value: JSON.stringify(f.value),
            eventId,
          },
          false
        );
      }),
    ]);
  };

  const sendData = async (submittedData) => {
    showLoader();

    const { additionalDefaultValues, baseDefaultValues } = submittedData;

    const { familyName, givenName, bphone } = additionalDefaultValues;
    const {
      name,
      address,
      city,
      postalCode,
      taxIdNumber,
      country,
      telephone,
    } = baseDefaultValues;

    const newAgency = {
      name,
      address,
      city: city.value,
      postalCode,
      country: country.value,
      phone: telephone,
      taxIdNumber,
      agencyEventId: eventId,
    };

    const newBuyer = {
      buyerFamilyName: familyName,
      buyerGivenName: givenName,
      buyerPhone: bphone,
      buyerUsername: currentParticipation.username,
      buyerEmail: currentParticipation.email,
      buyerIsInvited: true,
      buyerCognitoUserId: currentParticipation.cognitoUserId,
    };

    try {
      const { agencyId, participationId } = await createAgency({
        ...newBuyer,
        ...newAgency,
      });

      const nextFields = [
        ...submittedData.standardDefaultValues,
        ...submittedData.customDefaultValues,
      ];
      saveFieldValues(nextFields, agencyId);

      // to avoid simultaneous accesses
      await setUserCognitoIdentityProviderIdentityId(
        participationId,
        appState.user.getValue().awsUser.storage[
          `CognitoIdentityId-${awsmobile.aws_cognito_identity_pool_id}`
        ]
      );

      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.saveSuccess.label,
      });
      window.location.reload();
      hideLoader();
    } catch (error) {
      console.error(error);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.saveError.label,
      });
    } finally {
      hideLoader();
    }
    hideLoader();
  };

  const handleSave = () => {
    if (!saveDataRef.current) return;
    saveDataRef.current.click();
  };

  const handleLogout = async () => {
    await aws.signOutFromWebsite(eventId);
  };

  return (
    <LoginLayout
      title={i18n.registrationForm.title}
      width="70%"
      customStyles={{
        display: 'flex',
        flexDirection: 'column',
        margin: '5% auto',
        marginLeft: 'auto', // to overwrite it in loginLayout
      }}
    >
      <Grid container>
        {!user?.awsUser?.isBackofficeUser &&
          isRegistrationClosed &&
          currentParticipation &&
          !currentParticipation?.id && (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <AimTypography variant="text">
                {i18n.registrationForm.registrationClosedSubtitle}
              </AimTypography>
              <AimTypography
                variant="textBold"
                onClick={handleLogout}
                style={{ cursor: 'pointer' }}
              >
                {i18n.registrationForm.clickHereToLogout}
              </AimTypography>
            </Grid>
          )}
        {user?.awsUser?.isBackofficeUser &&
        currentParticipation &&
        !currentParticipation?.id ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AimTypography variant="text">
                {i18n.registrationForm.backofficeUserBlocked}
              </AimTypography>
            </Grid>
          </>
        ) : null}
        {currentParticipation?.id || !isRegistrationClosed ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AimTypography variant="text">
                {i18n.registrationForm.subtitle}
              </AimTypography>
            </Grid>
          </>
        ) : null}
      </Grid>

      {user &&
      (currentParticipation?.id ||
        (!isRegistrationClosed && !user?.awsUser?.isBackofficeUser)) ? (
        <div
          style={{
            height: '60vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            // paddingRight: '20px',
          }}
        >
          <AnagraphicForm
            {...{
              intl,
              eventId,
              type: 'agency',
              refButton: saveDataRef,
              baseFieldsCollection,
              baseDefaultValues,
              additionalFieldsCollection,
              additionalDefaultValues,
              additionalSectionTitle:
                i18n.registrationForm.additionalSectionTitle,
              onSaveDataFn: sendData,
            }}
          />

          {/* PRIVACY */}
          <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: 12 }}
          >
            <AimTypography variant="textBold">
              <>
                <span style={{ fontWeight: 'normal' }}>
                  {i18n.registrationForm.termsAndPrivacyText}{' '}
                </span>
                {privacyUrl && (
                  <a
                    href={privacyUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                    {i18n.registrationForm.termsAndPrivacyPolicy}
                  </a>
                )}
                <span style={{ fontWeight: 'normal' }}> (*) </span>
              </>
            </AimTypography>
            <AimCheckbox
              variant="grey"
              name="privacy"
              onChange={() => setIsPrivacySelected(!isPrivacySelected)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              marginTop: 20,
            }}
          >
            <AimIconAndTextButton
              variant="secondary"
              text={i18n.actions.cancel}
              onClick={() => history.push(`/events/${eventId}/registration`)}
            >
              <ReplayIcon />
            </AimIconAndTextButton>
            <AimIconAndTextButton
              variant="primary"
              text={i18n.actions.save}
              onClick={handleSave}
              disabled={!isPrivacySelected}
            >
              <CheckIcon />
            </AimIconAndTextButton>
          </div>
          <AimSnackbar
            open={snackbar.isOpen}
            onClose={() => setSnackbar({ isOpen: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </AimSnackbar>
        </div>
      ) : null}
    </LoginLayout>
  );
};

export default AgencyRegistrationForm;

import { aws, appState } from '@aim/common';
const { API } = aws;

import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createPurchaseMutation = /* GraphQL */ `
  mutation CreatePurchase(
    $input: CreatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    createPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const updatePurchaseMutation = /* GraphQL */ `
  mutation UpdatePurchase(
    $input: UpdatePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    updatePurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const getPurchaseQuery = /* GraphQL */ `
  query GetPurchase($id: ID!) {
    getPurchase(id: $id) {
      id
      buyOperations {
        items {
          id
          symposium {
            id
          }
          bannerArea {
            id
          }
          publicPage {
            id
          }
        }
      }
      payment {
        id
      }
    }
  }
`;

const getSponsorPurchases = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      event {
        physicalStandService {
          hidePriceWhenPriceIsEqualToAmount
          shouldHidePrice
        }
      }
      purchases {
        items {
          id
          eventId
          buyOperations {
            items {
              id
              eventId
              bookingId
              date
              expiringDate
              paymentType
              unitaryVatRate
              unitaryPrice
              symposium {
                id
                agendaSession {
                  name
                }
              }
              bannerArea {
                id
                bannerPage {
                  name
                }
              }
              publicPage {
                id
              }
              breakoutRoom {
                id
              }
              physicalStandSpace {
                id
                name
              }
              physicalStandSpaceItems {
                id
                amountPerQuantity
                quantity
                item {
                  id
                  name
                }
              }
              sponsorVirtualStand {
                id
                virtualStand {
                  id
                  name
                }
              }
              sponsorStaffPurchase {
                id
                quantity
                sponsorStaff {
                  id
                  name
                }
                feeDateRange {
                  id
                  label
                  start
                  end
                }
              }
              sponsorListPurchase {
                id
                quantity
                sponsorList {
                  id
                  name
                }
                feeDateRange {
                  id
                  label
                  start
                  end
                }
              }
              symposiumServicesPurchase {
                id
                quantity
                symposiumServicesItem {
                  id
                  name
                }
              }
              otherSponsorizationsBookingItem {
                id
                amountPerQuantity
                quantity
                item {
                  id
                  name
                }
              }
              sponsorPackage {
                id
                name
                services {
                  items {
                    name
                    quantity
                    netPrice
                    vatRate
                    id
                  }
                }
              }
            }
          }
          paymentType
          date
          purchaseId
          payment {
            id
            paymentId
            paymentIdMonetaOnLine
            paymentIdIngenico
            paymentIdGpWebpay
            paymentError
            date
            stepName
            stepNote
            paymentType
            paymentStatus
            amount
            createdAt
            updatedAt
            BillingInformation {
              isVatExent: isVatEvent
            }
          }
          sponsor {
            id
            name
            description
            therapeuticArea
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const deletePurchaseMutation = /* GraphQL */ `
  mutation DeletePurchase(
    $input: DeletePurchaseInput!
    $condition: ModelPurchaseConditionInput
  ) {
    deletePurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventGatewayByServiceQuery = /* GraphQL */ ` 
  getEvent(id: $eventId) {
    id
    gateways(filter: { service: { eq: $gatewayServiceId } }) {
      items {
        id
        service
        shop {
          id
          paymentType
          gatewayType
          iban
        }
      }
    }
  }
`;

const getEventQuery = /* GraphQL */ `
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      id
      code
    }
  }
`;

export const usePurchases = () => {
  const list = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();
      API.graphql({
        query: getSponsorPurchases,
        variables: { id },
      })
        .then((response) => resolve(response.data.getSponsor))
        .catch((e) => {
          console.error('get-sponsor-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const get = (id, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: getPurchaseQuery,
        variables: { id },
      })
        .then((response) => resolve(response.data.getPurchase))
        .catch((e) => {
          console.error('get-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const create = ({ eventCode, ...input }, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      const purchaseId = `${eventCode}-${nanoid()}`;
      API.graphql({
        query: createPurchaseMutation,
        variables: { input: { ...input, purchaseId } },
      })
        .then((response) => resolve(response.data.createPurchase))
        .catch((e) => {
          console.error('create-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updatePurchaseMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updatePurchase))
        .catch((e) => {
          console.error('update-purchase', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const remove = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({ query: deletePurchaseMutation, variables: { input } })
        .then(async (response) => {
          resolve(response.data.deletePurchase);
        })
        .catch((e) => {
          console.error('delete-sponsor-area', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  return { list, get, create, update, remove };
};

export const getEventGatewayByService = (id, serviceId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEventGatewayByServiceQuery,
      variables: { id, serviceId },
    })
      .then((response) => resolve(response.data.gateways.items[0]))
      .catch((e) => {
        console.error('get-event-gateway-service', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEvent = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getEventQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        return resolve(response.data.getEvent);
      })
      .catch((e) => {
        console.error('purchase-get-event', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

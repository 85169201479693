import React, { useState, useEffect, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { ErrorMessage } from '@hookform/error-message';

import { fileHelper } from '@aim/common';

import {
  StepDivider,
  Title,
  useAimMegadraftForm,
  AimCharTable,
  AimSpecialChars,
  AimTypography,
  AimTextFieldForm,
  AimIconAndTextButton,
  AimDialog,
  AimDragDropFileUploader,
  AimSnackbar,
  AimSnackbarSeverity,
} from '@aim/components';

const Fields = ({
  fields,
  control,
  abstractService,
  setSnackbar,
  i18n,
  handleNewCount,
  onFileChange,
  getValues,
  totalBodyCounterRef,
  updateBodyImagesCounterRef,
}) => {
  const isInternalUploadEnabled =
    abstractService?.isRichTextUploadEnabled &&
    abstractService?.maxNumberRichTextUpload > 0 &&
    abstractService?.maxDimensionRichTextUpload > 0 &&
    abstractService?.extensionsRichTextUpload?.length > 0;

  return fields.map((field, idx) => {
    const [Box, paste] = useAimMegadraftForm({
      name: `abstractSections[${idx}].value`,
      control,
      variant: 'white',
      defaultValue: getValues(`abstractSections[${idx}].value`),
      extensions: abstractService.extensionsRichTextUpload,
      filesMaxSize: abstractService.maxDimensionRichTextUpload * 1000000,
      maxFiles: abstractService.maxNumberRichTextUpload,
      updateImagesCounterRef: updateBodyImagesCounterRef,
      onFileTooBig: () =>
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: `${i18n.wizard.snackbar.maxSizeError} ${abstractService?.maxDimensionRichTextUpload} MB`,
        }),
      onTooManyFiles: () =>
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: `${i18n.wizard.snackbar.maxFilesError} ${abstractService?.maxNumberRichTextUpload}`,
        }),
      onExtensionNotAllowed: () =>
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.wizard.snackbar.extensionNotAllowed,
        }),
      hideSidebar: !isInternalUploadEnabled,
    });

    return (
      <Grid item container key={field.fieldDefinition.id}>
        <Grid item xs={12}>
          <AimTypography variant="textBold">
            {field.fieldDefinition.label}
          </AimTypography>
        </Grid>
        <Grid item xs={12}>
          <AimSpecialChars
            onSelect={paste}
            buttonText={i18n.wizard.labels.contentStepInsertSpecialChar}
          />
          <Box
            onTextChange={handleNewCount(field.fieldDefinition.id)}
            onFileChange={(data) => {
              onFileChange(data, field, idx);
            }}
            isOnChangeDisabled={(chars = 0) =>
              !abstractService?.maxBodyCharacters
                ? false
                : totalBodyCounterRef.current + chars >=
                  abstractService?.maxBodyCharacters
            }
          />
        </Grid>
      </Grid>
    );
  });
};
const MemorizedFields = React.memo(Fields);

const CountersBase = ({
  bodyCharactersNumber,
  maxBodyCharacters,
  titleCharactersNumber,
  maxTitleCharacters,
  bodyImagesNumber,
  maxBodyImages,
  updateFnRef,
  updateBodyImagesCounterRef,
  totalBodyCounterRef,
  titleCharactersCountRef,
  counterCharacters,
  getValues,
}) => {
  const [titleCounter, setTitleCounter] = useState(
    getValues('title')?.length || 0
  );
  const [counters, setCounters] = useState({});
  const [totalCounter, setTotalCounter] = useState(0);
  const [bodyImagesCounter, setBodyImagesCounter] = useState(0);

  useEffect(() => {
    updateFnRef.current = setCounters;
    titleCharactersCountRef.current = setTitleCounter;
    updateBodyImagesCounterRef.current = setBodyImagesCounter;
  }, []);

  useEffect(() => {
    const nextTotal = Object.values(counters).reduce(
      (prev, curr) => (prev += curr),
      0
    );
    setTotalCounter(nextTotal);
    totalBodyCounterRef.current = nextTotal;
  }, [counters]);

  return (
    <>
      <Grid item xs={3}>
        <AimTypography>{`${titleCharactersNumber} ${maxTitleCharacters}`}</AimTypography>
      </Grid>
      <Grid item xs={3}>
        <AimTypography>{`${counterCharacters}: ${titleCounter}`}</AimTypography>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={3}>
        <AimTypography>{`${bodyCharactersNumber} ${maxBodyCharacters}`}</AimTypography>
      </Grid>
      <Grid item xs={3}>
        <AimTypography>{`${counterCharacters}: ${totalCounter}`}</AimTypography>
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={3}>
        <AimTypography>{`${bodyImagesNumber} ${maxBodyImages}`}</AimTypography>
      </Grid>
      <Grid item xs={3}>
        <AimTypography>{`${counterCharacters}: ${bodyImagesCounter}`}</AimTypography>
      </Grid>
      <Grid item xs={6} />
    </>
  );
};

const Counters = React.memo(CountersBase);

const ContentStep = ({
  i18n,
  title,
  control,
  abstractService,
  s3Folder,
  files,
  setFiles,
  setValue,
  getValues,
  errors,
}) => {
  const {
    titleCharactersNumber,
    counterCharacters,
    bodyCharactersNumber,
    bodyImagesNumber,
  } = i18n.wizard.labels;
  const { maxTitleCharacters = 0, maxBodyCharacters = 0 } = abstractService;
  const updateCountersRef = useRef();
  const updateBodyImagesCounterRef = useRef();
  const totalBodyCounterRef = useRef();
  const [openDialog, setOpenDialog] = useState(false);
  const titleCharactersCountRef = useRef(0);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const { downloadFromS3 } = fileHelper;

  const handleNewCount = (fieldName) => (t) => {
    updateCountersRef.current((counters) => ({
      ...counters,
      [fieldName]: t.length,
    }));
  };

  const { fields } = useFieldArray({
    control,
    name: 'abstractSections',
    keyName: 'key',
  });

  const onFileChange = async ({ added, removed }, item, index) => {
    if (added.length)
      setValue(`abstractSections[${index}].addedFiles`, [
        ...(getValues(`abstractSections[${index}].addedFiles`) || []),
        added[0],
      ]);
    if (removed.length)
      setValue(`abstractSections[${index}].removedFiles`, [
        ...(getValues(`abstractSections[${index}].removedFiles`) || []),
        removed[0],
      ]);
    return;
  };

  const downloadFile = async (item) => {
    if (item.id) {
      await downloadFromS3({
        dirPath: s3Folder,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const getMaxSizeValidator = (file) => {
    if (
      file.size >
      parseFloat(abstractService?.maxDimensionExternalUpload) * 1000000
    ) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: `${i18n.wizard.snackbar.maxSizeError} ${abstractService?.maxDimensionExternalUpload} MB`,
      });
      return {
        code: 'name-too-large',
        message: `Name is larger than ${abstractService.maxDimensionExternalUpload} characters`,
      };
    }
    return null;
  };

  const isExternalUploadEnabled =
    abstractService?.isImageUploadEnabled &&
    abstractService?.maxNumberExternalUpload > 0 &&
    abstractService?.maxDimensionExternalUpload > 0 &&
    abstractService?.extensionsExternalUpload?.length > 0;

  return (
    <div style={{ paddingRight: 14 }}>
      <Title>{title}</Title>
      <StepDivider />
      <Grid container>
        <Grid item xs={12}>
          {isExternalUploadEnabled && (
            <AimIconAndTextButton
              text={i18n.wizard.labels.uploadFile}
              variant="violetFill"
              onClick={() => setOpenDialog(!openDialog)}
            >
              <VerticalAlignTopIcon />
            </AimIconAndTextButton>
          )}
        </Grid>
        <Counters
          titleCharactersNumber={titleCharactersNumber}
          maxTitleCharacters={maxTitleCharacters}
          counterCharacters={counterCharacters}
          bodyCharactersNumber={bodyCharactersNumber}
          maxBodyCharacters={maxBodyCharacters}
          bodyImagesNumber={bodyImagesNumber}
          maxBodyImages={abstractService.maxNumberRichTextUpload}
          updateFnRef={updateCountersRef}
          updateBodyImagesCounterRef={updateBodyImagesCounterRef}
          totalBodyCounterRef={totalBodyCounterRef}
          titleCharactersCountRef={titleCharactersCountRef}
          getValues={getValues}
        />
        <Grid item xs={12}>
          <AimTypography>
            {i18n.wizard.labels.shiftEnterHelperText}
          </AimTypography>
        </Grid>
        <Grid item xs={12}>
          <AimTypography>
            {i18n.wizard.labels.insertImageHelperText}
          </AimTypography>
        </Grid>
        <Grid item xs={12}>
          <AimCharTable
            buttonLabel={
              i18n.wizard.labels.contentStepClickHereToSeeSpecialChars
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AimTextFieldForm
            name="title"
            label={i18n.wizard.labels.contentStepTitle}
            labelStyle={{ marginLeft: -30 }}
            isRequired
            control={control}
            formControlStyle={{ width: 'calc(100% - 90px)', marginLeft: 80 }}
            textfieldVariant="white"
            onChange={(e) =>
              titleCharactersCountRef.current(e.target.value?.length || 0)
            }
            inputProps={{ maxLength: maxTitleCharacters }}
          />
          <ErrorMessage
            errors={errors}
            name="title"
            render={() => (
              <AimTypography variant="formError">
                {i18n.wizard.errors.labels.title}
              </AimTypography>
            )}
          />
        </Grid>
        {
          <MemorizedFields
            {...{
              fields,
              control,
              abstractService,
              setSnackbar,
              i18n,
              handleNewCount,
              onFileChange,
              getValues,
              totalBodyCounterRef,
              updateBodyImagesCounterRef,
            }}
          />
        }
      </Grid>
      <AimDialog
        open={openDialog}
        title={i18n.wizard.labels.uploadFile}
        onAgree={() => {
          // onAgree(value);
          //salvare i dati in locale e salvare tutto alla fine
        }}
        onClose={() => setOpenDialog(false)}
        maxWidth={'lg'}
        fullWidth
      >
        <Grid>
          <AimTypography isTitle>
            {i18n.wizard.labels.uploadFileSubtitle} - Max:{' '}
            {abstractService.maxNumberExternalUpload} files
          </AimTypography>

          <AimTypography>
            {`${i18n.wizard.labels.uploadFileExtensions}: ${abstractService?.extensionsExternalUpload}`}
          </AimTypography>
        </Grid>

        <AimDragDropFileUploader
          style={{
            width: 'calc(100% - 10px)',
            alignSelf: 'center',
            marginLeft: 8,
            marginRight: 8,
          }}
          multiple
          dragTitle={i18n.wizard.labels.dragAndDrog.title}
          // dragSubTitle={placeholder}
          clickText={i18n.wizard.labels.dragAndDrog.clickText}
          fileLimitText={i18n.wizard.labels.dragAndDrog.fileLimitText}
          // onLoad={onLoad}
          onUploadFile={(newFiles) => {
            setFiles([...files, ...newFiles]);
          }}
          onRemove={(file, index) => {
            const newFiles = files.filter((_, elemIdx) => elemIdx !== index);
            setFiles(newFiles);
          }}
          onDownload={(file) => downloadFile(file)}
          files={files}
          maxFiles={abstractService?.maxNumberExternalUpload}
          isSmallGridEnabled
          dropzoneAdditionalProps={{
            accept: abstractService?.extensionsExternalUpload,
            ...(abstractService?.maxDimensionExternalUpload > 0 && {
              validator: getMaxSizeValidator,
            }),
          }}
        />
      </AimDialog>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </div>
  );
};

export default ContentStep;

const translation = {
  billingInformations: (intl) => ({
    pageTitle: intl.formatMessage({
      description: 'agency payment billing informations form page title',
      defaultMessage: 'Billing Informations',
    }),
    confirm: intl.formatMessage({
      description:
        'agency payment billing informations form confirm button label',
      defaultMessage: 'Confirm',
    }),
  }),
};

export default translation;

import { myTicketsTranslations } from './myTickets';

export const translations = (intl) => ({
  ...myTicketsTranslations(intl),
  printBtn: intl.formatMessage({
    defaultMessage: 'Print',
    description: 'my services card print btn',
  }),
  openBtn: intl.formatMessage({
    defaultMessage: 'Open',
    description: 'my services card open btn',
  }),
});

import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { nanoid } from 'nanoid';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import {
  AimTextField,
  AimIconButton,
  AimList,
  AimTypography,
  StepDivider,
  Title,
  CitiesAutocompleteByGeoDbDynamic,
  CountriesAutocompleteByGeoDbDynamic,
} from '@aim/components';

const formControlStyle = { width: 'calc(100% - 10px)' };

const Row = ({
  row,
  index,
  register,
  control,
  errors,
  clearErrors,
  setValue,
}) => (
  <Grid container>
    <AimTextField
      inputRef={register}
      formControlStyle={{ display: 'none' }}
      name={`affiliations[${index}].id`}
      defaultValue={row.id}
    />
    <Grid item xs={11} sm={11}>
      <Grid container>
        <Grid item xs={4} sm={4}>
          <AimTextField
            inputRef={register}
            name={`affiliations[${index}].institution`}
            defaultValue={row.institution}
            formControlStyle={formControlStyle}
            displayLabel={false}
            textfieldVariant="white"
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <CitiesAutocompleteByGeoDbDynamic
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            name={`affiliations[${index}].city`}
            defaultValue={row.city}
            formControlStyle={formControlStyle}
            textfieldVariant="white"
            clearErrors={clearErrors}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <CountriesAutocompleteByGeoDbDynamic
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            name={`affiliations[${index}].country`}
            defaultValue={row.country}
            formControlStyle={formControlStyle}
            textfieldVariant="white"
            clearErrors={clearErrors}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const HeaderRow = ({ i18n }) => (
  <Grid container>
    <Grid item xs={11} sm={11}>
      <Grid container>
        <Grid item xs={4} sm={4}>
          <AimTypography variant="columnHeader">
            {i18n.wizard.labels.institution}
          </AimTypography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <AimTypography variant="columnHeader">
            {i18n.wizard.labels.city}
          </AimTypography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <AimTypography variant="columnHeader">
            {i18n.wizard.labels.country}
          </AimTypography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const AffiliationsStep = ({
  control,
  register,
  title,
  i18n,
  errors,
  clearErrors,
  setValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'affiliations',
    keyName: 'key',
  });

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <AimTypography variant="text">
        {i18n.wizard.subtitles.affiliations}
      </AimTypography>
      <div
        style={{
          background: 'white',
          borderRadius: 4,
          flex: 1,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <AimList
          rows={fields}
          rowKey="key"
          variant="grey"
          onDeleted={(e, row, idx) => remove(idx)}
          HeaderRow={<HeaderRow i18n={i18n} />}
          childrenProps={{ register, control }}
        >
          <Row {...{ i18n, control, errors, clearErrors, setValue }} />
        </AimList>
        <AimIconButton
          variant="yellowFill"
          onClick={() =>
            append({
              nanoId: nanoid(),
            })
          }
        >
          <AddIcon />
        </AimIconButton>
      </div>
    </>
  );
};

export default AffiliationsStep;

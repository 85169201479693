import React from 'react';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import ClearIcon from '@material-ui/icons/Clear';

import { AimTypography, AimIconButton } from '@aim/components';
import { format } from 'date-fns';

const AgencyCheckoutRow = ({ booking, onDelete }) => {
  const getPrice = () => booking.price.toFixed(2);
  return (
    <Grid container>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={4}
        xl={6}
        // style={{ maxWidth: 600 }}
      >
        <AimTypography variant={'h4Regular'}>
          {booking.bookingLabel}
        </AimTypography>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={1} style={{ maxWidth: 180 }}>
        <AimTypography variant="text" textAlign="right">
          {booking.netPrice?.toFixed(2)}
        </AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="text" textAlign="right">
          {booking.vatAmount?.toFixed(2)}
        </AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant="text" textAlign="right">
          {booking.quantity}
        </AimTypography>
      </Grid>
      {/* net price */}
      <Grid item xs={2} sm={2} md={2} lg={2} xl={1} style={{ maxWidth: 180 }}>
        <AimTypography variant="text" textAlign="right">
          {getPrice()}
        </AimTypography>
      </Grid>
      {/* total net price */}
      <Grid item xs={1}>
        <AimTypography variant="h4Regular" textAlign="right">
          {(booking.netPrice * booking.quantity)?.toFixed(2)}
        </AimTypography>
      </Grid>
      {/* total gross price / total amount */}
      <Grid item xs={1} sm={1} md={1}>
        <AimTypography variant="h4Regular" textAlign="right">
          {(booking.price * booking.quantity)?.toFixed(2)}
        </AimTypography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default AgencyCheckoutRow;

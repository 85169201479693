import React, { useState, useEffect } from 'react';

import { useIntl } from 'react-intl';
import useI18n from '../shared/i18n';

import { format, startOfDay, endOfDay, addSeconds, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { useParams, useHistory } from 'react-router-dom';
import {
  CustomIntl,
  styled,
  theme,
  AimTypography,
  AimIconButton,
  AimIconAndTextButton,
} from '@aim/components';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';

import { useBreakoutRoomService } from '../../shared/breakoutRoomServiceGqlHelper';
import { useDataHelper } from '../../shared/dataHelper';
import { useBreakoutRoomUtils } from '../../../hooks/useBreakoutRoomUtils';
import TitleAndBackButton from '../../shared/TitleAndBackButton';
import { utilities } from '@aim/common';

const CollapseContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  padding: '28px 28px 26px 28px',
  backgroundColor: '#30CFD021',
  marginBottom: '20px',
  flexWrap: 'wrap',
});

export const MainContainer = styled('div')({
  width: '100%',
  height: 'calc(100vh - 245px)',
  display: 'flex',
  flexDirection: 'column',
  // background: theme.colors.greyScale.backgroundGrey,
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  backgroundColor: 'white',
  margin: '0 5%',
  padding: '10px 10px 0',
  borderBottom: `30px solid ${theme.colors.greyScale.backgroundGrey}`,
  // Handel scrollbar properties
  '& ::-webkit-scrollbar': {
    width: 6,
  },
  '& ::-webkit-scrollbar-track': {
    background: '#ffffff',
  },
  '& ::-webkit-scrollbar-thumb': {
    background: 'rgba(149, 152, 154, 0.43)',
    borderRadius: 5,
  },
  '& ::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(149, 152, 154, 1)',
  },
});

export const CustomDivider = styled(Divider)({
  backgroundColor: 'white !important',
  height: '2px !important',
});

const breakoutRoomsDefaults = [
  'date',
  'timetable',
  'maxAttendants',
  'duration',
];

const romeTimezone = 'Europe/Rome';

const BreakoutRoomList = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n.breakoutRoomList(intl);
  const { eventId, sponsorId } = useParams();
  const [collapsableCtrl, setCollapsableCtrl] = useState({});
  const [event, setEvent] = useState();
  const [breakoutRooms, setBreakoutRooms] = useState([]);
  const [breakoutRoomService, setBreakoutRoomService] = useState();
  const [fetchedBreakoutRooms, setFetchedBreakoutRooms] = useState([]);
  const [areConfigurationsEditable, setAreConfigurationsEditable] = useState(
    false
  );
  const history = useHistory();
  const breakoutRoomHelper = useBreakoutRoomService();
  const { getHours } = useBreakoutRoomUtils();

  useEffect(() => {
    fetchData();
  }, []);

  // Generic Functions
  const handleExpandClick = (index) => {
    setCollapsableCtrl((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Handling Data Functions

  const fetchData = async () => {
    const br = await breakoutRoomHelper.get(eventId);
    setEvent(br);
    if (br.breakoutRoomService.breakoutRooms.items) {
      setAreConfigurationsEditable(br.breakoutRoomService.isSponsorEditable);
      const fetchedBr = br.breakoutRoomService.breakoutRooms.items;
      setFetchedBreakoutRooms(fetchedBr);
      setBreakoutRoomService(br.breakoutRoomService);
      const sponsorBr = fetchedBr.filter(
        (br) => br?.sponsor?.id === sponsorId && !!br.buyOperation?.purchase?.id
      );
      const parsedBr = sponsorBr.map((br) => ({
        _date: br.date,
        date: br.date
          ? format(
              utcToZonedTime(new Date(br.date), romeTimezone),
              'dd/MM/yyyy'
            )
          : undefined,
        timetable:
          br.start1 && br.end1
            ? `${getHours(br.start1)} - ${getHours(br.end1)}  ${getHours(
                br.start2
              )} - ${getHours(br.end2)}`
            : undefined,
        maxAttendants: br.maxAttendants || undefined,
        duration: br.duration || undefined,
        id: br.id,
        titleDate: format(
          new Date(br.buyOperation.purchase.date),
          'dd/MM/yyyy'
        ),
        hasPaymentError: !utilities.isValidPurchase(br?.buyOperation?.purchase),
      }));
      setBreakoutRooms(
        parsedBr
          .filter((i) => !i.hasPaymentError)
          .sort((a, b) => new Date(b._date) - new Date(a._date))
      );
    }
  };

  const isBrEditable = (br) => {
    let flag = true;
    if (!br._date) {
      //controllo se l'evento è ancora in corso
      const min = endOfDay(new Date());

      flag = isBefore(min, new Date(event.end));
      return flag ? null : 'eventEnd';
    }
    //la br è editabile solo se a livello globale può essere modificata e se si è in una tata valida (entro la mezzanotte del giorno prima che inizi)
    flag = isBefore(new Date(), addSeconds(startOfDay(new Date(br._date)), -1));
    return flag ? null : 'tooLate';
  };

  const pushNavigation = (breakoutRoom, request) => {
    history.push({
      pathname: `/events/${eventId}/${sponsorId}/services-configuration/breakoutrooms/${
        breakoutRoom.id
      }${request === 'edit' ? '/edit' : ''}`,
      state: {
        breakoutRoom: {
          ...fetchedBreakoutRooms.find((br) => br.id === breakoutRoom.id),
          breakoutRoomService: {
            id: breakoutRoomService.id,
            price: breakoutRoomService.price,
            vatRate: breakoutRoomService.vatRate,
            maxAvailableHours: breakoutRoomService.maxAvailableHours,
            isSponsorEditable: breakoutRoomService.isSponsorEditable,
          },
        },
      },
    });
  };

  return (
    <MainContainer>
      <TitleAndBackButton
        backOnClickFunction={() =>
          history.push(`/events/${eventId}/${sponsorId}/my-services`)
        }
        backOnHoverText={i18n.page.backButton.tooltip}
        title={i18n.page.title}
      />
      <Container>
        {breakoutRooms &&
          breakoutRooms.map((br, i) => {
            const disableTooltipMessage = isBrEditable(br);

            return (
              <div
                key={i}
                style={{
                  background: theme.colors.greyScale.backgroundGrey, //'#F8F6FA',
                  padding: 10,
                  marginBottom: 8,
                  width: '100%',
                }}
              >
                <Grid container alignContent="center">
                  <Grid item xs={6}>
                    <AimTypography
                      variant="h6"
                      style={{ lineHeight: '48px', marginLeft: 8 }}
                    >
                      {br.date
                        ? `${i18n.cardContent.breakoutRoom} - ${br.date}`
                        : `${i18n.cardContent.breakoutRoom} - ${i18n.cardContent.purchasedAt} ${br.titleDate}`}
                    </AimTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container alignItems="center" justify="flex-end">
                      {areConfigurationsEditable && (
                        <Tooltip
                          title={
                            i18n.cardContent.tooltip[disableTooltipMessage] ||
                            ''
                          }
                        >
                          <span>
                            <AimIconAndTextButton
                              variant={'yellowFill'}
                              text={i18n.page.icons.edit}
                              disabled={disableTooltipMessage}
                              onClick={() => {
                                pushNavigation(br, 'edit');
                              }}
                            >
                              <EditIcon />
                            </AimIconAndTextButton>
                          </span>
                        </Tooltip>
                      )}
                      <AimIconAndTextButton
                        variant={'lightBlueFill'}
                        text={i18n.page.icons.preview}
                        disabled={!br.timetable}
                        onClick={() => {
                          pushNavigation(br);
                        }}
                      >
                        <VisibilityIcon />
                      </AimIconAndTextButton>
                      <AimIconButton
                        variant="none"
                        onClick={() => handleExpandClick(i)}
                        style={{ float: 'right', marginRight: 15 }}
                      >
                        <ExpandMoreIcon />
                      </AimIconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse
                  key={i}
                  in={i === 0 ? !collapsableCtrl[i] : collapsableCtrl[i]}
                >
                  <Grid item xs={12}>
                    <CustomDivider />
                  </Grid>
                  <CollapseContent
                    style={{
                      backgroundColor: theme.colors.greyScale.backgroundGrey,
                      margin: 5,
                      marginTop: 13,
                      padding: 5,
                    }}
                  >
                    <Grid container alignItems="center" justify="flex-start">
                      <Grid item xs={12}>
                        <ul>
                          {breakoutRoomsDefaults.map((value, idx) => (
                            <li
                              key={idx}
                              style={{
                                listStyle: 'none',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <AimTypography variant="textBold">
                                {i18n.cardContent[value]}:
                              </AimTypography>
                              <AimTypography variant="text" margin={0}>
                                {br[value]
                                  ? br[value]
                                  : i18n.cardContent.notAvailable}
                              </AimTypography>
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    </Grid>
                  </CollapseContent>
                </Collapse>
              </div>
            );
          })}
      </Container>
    </MainContainer>
  );
};

export default BreakoutRoomList;

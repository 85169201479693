import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Logo from './shared/logo/Logo';
import Footer from './shared/Footer';
import { useLocation, useParams } from 'react-router-dom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { appState, aws, utilities } from '@aim/common';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  styled,
  theme,
} from '@aim/components';

import { translation } from './genericPayments/translation';

const MainContainer = ({ children }) => (
  <div
    style={{
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      paddingTop: 20,
      display: 'flex',
    }}
  >
    {children}
  </div>
);

const PageContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '95%',
  margin: '0 auto',
  paddingLeft: '11%',
  paddingRight: '11%',
  paddingTop: 70,
});

const CartRow = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginTop: 50,
  borderBottom: `1px solid ${theme.colors.greyScale.grey3}`,
});

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const Row = (data) => {
  const {
    leftLabel,
    rightLabel,
    leftValue,
    rightValue,
    leftValueVariant = 'h6',
    rightValueWeight,
  } = data;

  return (
    <CartRow>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
      >
        {leftLabel && (
          <AimTypography variant="h6" boxStyle={{ marginLeft: 0 }}>
            {leftLabel}
          </AimTypography>
        )}
        {rightLabel && (
          <AimTypography variant="h6" boxStyle={{ marginRight: 0 }}>
            {rightLabel}
          </AimTypography>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {leftValue && (
          <AimTypography
            boxStyle={{ marginBottom: 0, marginLeft: 0 }}
            variant={leftValueVariant}
          >
            {leftValue}
          </AimTypography>
        )}
        {rightValue && (
          <AimTypography
            variant="h2"
            boxStyle={{
              marginBottom: 0,
              marginRight: 0,
              fontWeight: rightValueWeight,
            }}
          >
            {rightValue}
          </AimTypography>
        )}
      </div>
    </CartRow>
  );
};

const GenericPaymentsCartPublicPage = () => {
  // i18n
  const intl = CustomIntl(useIntl());
  const i18n = translation.cart(intl);
  const { state } = useLocation();
  const [data, setData] = useState(state);
  const paymentMetadata = state;

  useEffect(() => {
    if (paymentMetadata) {
      const { netAmount, amount, vatRate } = paymentMetadata;
      const decodedVatRate = decodeDbNumber(vatRate);
      const decodedNetAmount = decodeDbNumber(netAmount);

      if (paymentMetadata.userAmountType === 'total') {
        const netAmount = amount / (1 + decodedVatRate / 100);
        setData({
          ...paymentMetadata,
          netAmount,
          vatRate: decodedVatRate,
        });
      } else {
        const amount = decodedNetAmount * (1 + decodedVatRate / 100);
        setData({
          ...data,
          netAmount: decodedNetAmount,
          vatRate: decodedVatRate,
          amount,
        });
      }
    }
  }, [paymentMetadata]);

  const { decodeDbNumber, formatNumber } = utilities;

  const { eventId, genericPaymentId } = useParams();

  const goToPayment = async () => {
    const baseurl = window.location.host; // testurl.split('//')[1] ||

    try {
      showLoader();
      const response = await aws.standardAPI.post(
        'aimlambdaproxy',
        '/admin/generic-payment/init-payment',
        {
          body: {
            paymentId: paymentMetadata.paymentId,
            url: baseurl,
            relurl: `events/${eventId}/genericPayments/${genericPaymentId}`,
          },
        }
      );
      hideLoader();
      if (response.error > 0) {
        history.push(
          `${history.location?.state?.url}/checkout-error?errorcode=` +
            response.error
        );
      } else {
        const { paymentid, hostedpageurl } = response;
        window.location = `${hostedpageurl}?paymentid=${paymentid}`;
      }
    } catch (error) {
      console.log('error', error);
      hideLoader();
    }
  };

  return (
    <>
      <MainContainer>
        <Logo />
        <PageContent>
          {data ? (
            <>
              <AimTypography
                variant={'textThin'}
                style={{ alignSelf: 'flex-start' }}
              >
                {i18n.title}
              </AimTypography>

              <div
                style={{
                  width: '95%',
                  alignSelf: 'center',
                  backgroundColor: theme.colors.greyScale.backgroundGrey,
                  paddingBottom: 50,
                  paddingLeft: 40,
                  paddingRight: 40,
                  placeSelf: 'flex-end',
                }}
              >
                <Row
                  leftLabel={i18n.service}
                  rightLabel={i18n.price}
                  leftValue={data.description}
                  leftValueVariant={'h2'}
                  rightValue={formatNumber(data.netAmount)}
                />
                <Row
                  leftValue={`${i18n.vat} (${data.vatRate}% on ${formatNumber(
                    data.netAmount
                  )})`}
                  rightValue={formatNumber(
                    utilities.amountOfVat(data.netAmount, data.vatRate)
                  )}
                />
                <Row
                  leftValue={i18n.total}
                  rightValue={formatNumber(data.amount)}
                  rightValueWeight={'bold'}
                />
              </div>
              <AimIconAndTextButton
                style={{
                  alignSelf: 'flex-end',
                  width: '100px',
                  marginTop: 26,
                }}
                isUpperCase
                onClick={goToPayment}
                text={'buy'}
              >
                <ShoppingCartIcon />
              </AimIconAndTextButton>
            </>
          ) : (
            <AimTypography
              variant={'textThin'}
              boxStyle={{ textAlign: 'center' }}
            >
              {i18n.unauthorized}
            </AimTypography>
          )}
        </PageContent>
        <Footer />
      </MainContainer>
    </>
  );
};

export default GenericPaymentsCartPublicPage;

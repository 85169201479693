export default {
  abstractDetail: (intl) => ({
    page: {
      review: {
        decline: {
          label: intl.formatMessage({
            description: 'decline review label',
            defaultMessage: 'Decline',
          }),
          tooltip: intl.formatMessage({
            description: 'decline review tooltip',
            defaultMessage: 'Decline review',
          }),
        },
        declineDialog: {
          title: intl.formatMessage({
            description: 'decline dialog title',
            defaultMessage: 'Decline Abstract',
          }),
          text: intl.formatMessage({
            description: 'decline dialog text',
            defaultMessage: 'Are you sure you want to decline this abstract?',
          }),
        },
        messages: {
          saveSuccess: intl.formatMessage({
            description: 'review saved success message',
            defaultMessage: 'Review saved',
          }),
          saveFail: intl.formatMessage({
            description: 'review saved error message',
            defaultMessage: 'Error saving review',
          }),
          declineSuccess: intl.formatMessage({
            description: 'review decline success message',
            defaultMessage: 'Abstract successfully declined',
          }),
          declineFail: intl.formatMessage({
            description: 'review decline error message',
            defaultMessage: 'Error declining abstract',
          }),
        },
      },
      title: intl.formatMessage({
        description: 'title text',
        defaultMessage: 'Abstract Detail',
      }),
      back: {
        label: intl.formatMessage({
          description: 'back to event',
          defaultMessage: 'Abstracts',
        }),
        tooltip: intl.formatMessage({
          description: 'back to event',
          defaultMessage: 'Abstracts',
        }),
      },

      abstractSections: {
        title: intl.formatMessage({
          description: 'abstract sections title',
          defaultMessage: 'Abstract Title',
        }),
      },
      presenter: {
        title: intl.formatMessage({
          description: 'presenting author title',
          defaultMessage: 'Presenting Author',
        }),
      },
      authors: {
        title: intl.formatMessage({
          description: 'author title',
          defaultMessage: 'Authors',
        }),
        affiliation: {
          label: intl.formatMessage({
            description: 'affilition label',
            defaultMessage: 'Affiliation',
          }),
        },
      },
    },
    criteria: {
      buttons: {
        save: {
          label: intl.formatMessage({
            description: 'criteria save button',
            defaultMessage: 'Save',
          }),
        },
        cancel: {
          label: intl.formatMessage({
            description: 'criteria cancel button',
            defaultMessage: 'Cancel',
          }),
        },
      },
      form: {
        error: {
          label: intl.formatMessage({
            description: 'form criteria error',
            defaultMessage: 'Form must be fullfilled',
          }),
        },
        rejected: {
          label: intl.formatMessage({
            description:
              'I prefer to not evaluate this abstract because of interest conflict',
            defaultMessage:
              'I prefer to not evaluate this abstract because of interest conflict',
          }),
        },
        fullfilled: {
          label: intl.formatMessage({
            description: 'already reviewd message',
            defaultMessage: 'Already Reviewed',
          }),
        },
        column: {
          criteriaName: {
            label: intl.formatMessage({
              description: 'criteria name',
              defaultMessage: 'Criteria',
            }),
          },
          criteriaRating: {
            label: intl.formatMessage({
              description: 'criteria rating',
              defaultMessage: 'Rating',
            }),
          },
          criteriaNotes: {
            label: intl.formatMessage({
              description: 'criteria notes',
              defaultMessage: 'Notes',
            }),
          },
        },
      },
    },
  }),
  sponsorCart: (intl) => ({
    page: {
      title: {
        cart: intl.formatMessage({
          description: 'cart title text',
          defaultMessage: 'Cart',
        }),
        checkout: intl.formatMessage({
          description: 'checkout title text',
          defaultMessage: 'Purchase',
        }),
      },
      back: {
        cart: {
          label: intl.formatMessage({
            description: 'back to services',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to services',
            defaultMessage: 'My Services',
          }),
        },
        checkout: {
          label: intl.formatMessage({
            description: 'back to cart',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to cart',
            defaultMessage: 'Cart',
          }),
        },
      },
      messages: {
        noBookings: {
          label: intl.formatMessage({
            description: 'no bookings message',
            defaultMessage: 'Cart is empty',
          }),
        },
      },
      errors: {
        loadBookings: {
          label: intl.formatMessage({
            description: 'load bookings error',
            defaultMessage: 'Error loading bookings list',
          }),
        },
        purchase: {
          label: intl.formatMessage({
            description: 'purchase error',
            defaultMessage: 'Error during purchase',
          }),
        },
        invalidCart: intl.formatMessage({
          description: 'the cart is not valid anymore error snackbar',
          defaultMessage: 'The cart is not valid anymore',
        }),
      },
      checkout: {
        bankTransferIndication: intl.formatMessage({
          description: 'bank transfer indication label',
          defaultMessage: 'Bank transfer indication',
        }),
        iban: intl.formatMessage({
          description: 'IBAN label',
          defaultMessage: 'IBAN',
        }),
        reasonForPayment: intl.formatMessage({
          description: 'Reason for payment label',
          defaultMessage: 'Reason for payment',
        }),
        addressedTo: intl.formatMessage({
          description: 'addressed to',
          defaultMessage: 'Addressed to',
        }),
      },
      totalAmountDue: intl.formatMessage({
        description: 'Total amount due label',
        defaultMessage: 'Total amount due',
      }),
    },
    snackbar: {
      deleteBookingSuccess: {
        label: intl.formatMessage({
          description: 'delete bookings success',
          defaultMessage: 'Delete item successfully',
        }),
      },
      removeBookingSuccess: {
        label: intl.formatMessage({
          description: 'remove bookings success',
          defaultMessage: 'Remove item successfully',
        }),
      },
      deleteBookingFail: {
        label: intl.formatMessage({
          description: 'delete bookings fail',
          defaultMessage: 'Error during item delete',
        }),
      },
    },
    dialog: {
      delete: {
        title: intl.formatMessage({
          description: 'Delete cart item title',
          defaultMessage: 'Delete item',
        }),
        message: intl.formatMessage({
          description: 'Dialog delete message',
          defaultMessage:
            'Are you sure you want to remove this item from the cart? Prices might have been changed, you will lose the reserved price if you continue',
        }),
        agreeText: intl.formatMessage({
          description: 'Dialog delete onAgreeText',
          defaultMessage: 'Delete',
        }),
        disagreeText: intl.formatMessage({
          description: 'Dialog delete onDisagreeText',
          defaultMessage: 'Cancel',
        }),
      },
    },
    services: {
      symposium: intl.formatMessage({
        description: 'symposium service',
        defaultMessage: 'Symposium',
      }),
      banner: intl.formatMessage({
        description: 'banner service',
        defaultMessage: 'Banner',
      }),
      publicPage: intl.formatMessage({
        description: 'public page service',
        defaultMessage: 'Public Page',
      }),
      breakoutRoom: intl.formatMessage({
        description: 'breakoutRoom service',
        defaultMessage: 'Breakout Room',
      }),
      physicalStand: intl.formatMessage({
        description: 'physicalStand service',
        defaultMessage: 'Physical Stand',
      }),
      otherStandServices: intl.formatMessage({
        description: 'otherStandServices service',
        defaultMessage: 'Other Stand Services',
      }),
      otherSponsorizations: intl.formatMessage({
        description: 'otherSponsorizations service',
        defaultMessage: 'Other Sponsorizations',
      }),
      virtualStand: intl.formatMessage({
        description: 'virtualStand service',
        defaultMessage: 'Virtual Stand',
      }),
      sponsorStaff: intl.formatMessage({
        description: 'sponsorstaff service',
        defaultMessage: 'Staff Members',
      }),
      sponsorList: intl.formatMessage({
        description: 'sponsorlist service',
        defaultMessage: 'Sponsored Participations',
      }),
      symposiumServices: intl.formatMessage({
        description: 'symposium services',
        defaultMessage: 'Symposium Services',
      }),
      sponsorPackages: intl.formatMessage({
        description: 'sponsor packages',
        defaultMessage: 'Sponsor Packages',
      }),
    },
    paymentTypes: {
      bankTransfer: {
        label: intl.formatMessage({
          description: 'bank transfer payment type',
          defaultMessage: 'Bank Transfer',
        }),
      },
      creditCard: {
        label: intl.formatMessage({
          description: 'credit card payment type',
          defaultMessage: 'Credit Card',
        }),
      },
      both: {
        label: intl.formatMessage({
          description: 'credit card/bank transfer label',
          defaultMessage: 'Credit Card/Bank Transfer',
        }),
      },
    },
    bookingTable: {
      titles: {
        bankTransfer: intl.formatMessage({
          description: 'bank transfer table title',
          defaultMessage: 'Services that you can purchase by bank transfer',
        }),
        creditCard: intl.formatMessage({
          description: 'credit card table title',
          defaultMessage: 'Services that you can purchase by credit card',
        }),
        bothPayments: intl.formatMessage({
          description: 'credit card/bank transfer table title',
          defaultMessage:
            'Services that you can purchase by credit card/bank transfer',
        }),
      },
      buttons: {
        buy: {
          label: intl.formatMessage({
            description: 'buy button',
            defaultMessage: 'PURCHASE',
          }),
        },
        export: {
          label: intl.formatMessage({
            description: 'export button',
            defaultMessage: 'EXPORT',
          }),
        },
      },
      columns: {
        bookingId: {
          label: intl.formatMessage({
            description: 'booking id column',
            defaultMessage: 'ID',
          }),
        },
        service: {
          label: intl.formatMessage({
            description: 'service column',
            defaultMessage: 'Service',
          }),
        },
        instance: {
          label: intl.formatMessage({
            description: 'instance column',
            defaultMessage: 'Instance',
          }),
        },
        date: {
          label: intl.formatMessage({
            description: 'booking date column',
            defaultMessage: 'Booking date',
          }),
        },
        expiringDate: {
          label: intl.formatMessage({
            description: 'booking expiring date column',
            defaultMessage: 'Expiring Date',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'unitary price column',
            defaultMessage: 'Unitary Net Price',
          }),
        },
        price: {
          label: intl.formatMessage({
            description: 'unitary column',
            defaultMessage: 'Unitary Price',
          }),
        },
        quantity: {
          label: intl.formatMessage({
            description: 'quantity column',
            defaultMessage: 'Quantity',
          }),
        },
        vatRate: {
          label: intl.formatMessage({
            description: 'VAT percentage column',
            defaultMessage: 'VAT %',
          }),
        },
        vatAmount: {
          label: intl.formatMessage({
            description: 'VAT euro column',
            defaultMessage: 'VAT €',
          }),
        },
        totalNetPrice: {
          label: intl.formatMessage({
            description: 'total net price column',
            defaultMessage: 'Total Net Price',
          }),
        },
        totalGrossPrice: {
          label: intl.formatMessage({
            description: 'total gross price column',
            defaultMessage: 'Total Gross Price',
          }),
        },
        paymentType: {
          label: intl.formatMessage({
            description: 'payment type column',
            defaultMessage: 'Payment Type',
          }),
        },
      },
      totalText: {
        label: intl.formatMessage({
          description: 'Total amount due by payment type text',
          defaultMessage: 'Total amount due by',
        }),
        labelSintetic: intl.formatMessage({
          description: 'Total amount due by payment type sintetic text',
          defaultMessage: 'Total',
        }),
      },
    },
    checkoutTable: {
      buttons: {
        buy: {
          label: intl.formatMessage({
            description: 'buy checkout button',
            defaultMessage: 'Buy',
          }),
        },
      },
      columns: {
        service: {
          label: intl.formatMessage({
            description: 'service column',
            defaultMessage: 'Service',
          }),
        },
        unitaryNetPrice: {
          label: intl.formatMessage({
            description: 'unitary net price column',
            defaultMessage: 'Unitary net (€)',
          }),
        },
        vatAmount: {
          label: intl.formatMessage({
            description: 'vat amount column',
            defaultMessage: 'VAT €',
          }),
        },
        quantity: {
          label: intl.formatMessage({
            description: 'quantity column',
            defaultMessage: 'Quantity',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'net price column',
            defaultMessage: 'Net price (€)',
          }),
        },
        totalNetPrice: {
          label: intl.formatMessage({
            description: 'total net price column',
            defaultMessage: 'Total net (€)',
          }),
        },
        amount: {
          label: intl.formatMessage({
            description: 'amount column',
            defaultMessage: 'Amount (€)',
          }),
        },
      },
      summary: {
        netTotal: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Net Total (€)',
          }),
        },
        vat: {
          label: intl.formatMessage({
            description: 'vat summary',
            defaultMessage: 'VAT',
          }),
        },
        total: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Total (€)',
          }),
        },
      },
    },
    checkoutAlerts: {
      eventIsOver: {
        label: intl.formatMessage({
          description: "the event is over, you can't confirm the purchase",
          defaultMessage: "The event is over, you can't confirm the purchase",
        }),
      },
    },
  }),
  checkoutSuccess: (intl) => ({
    page: {
      messages: {
        firstRow: intl.formatMessage({
          description: 'checkout success first row',
          defaultMessage: 'Thank you, the purchase was successful!',
        }),
        secondRow: intl.formatMessage({
          description: 'checkout success second row',
          defaultMessage:
            'Remember that you can always check your information in your personal area.',
        }),
      },
      buttons: {
        services: {
          label: intl.formatMessage({
            description: 'checkout go to services',
            defaultMessage: 'GO TO SERVICES',
          }),
        },
        purchases: {
          label: intl.formatMessage({
            description: 'checkout go to purchases',
            defaultMessage: 'GO TO YOUR PURCHASES',
          }),
        },
      },
    },
  }),
  checkoutError: (intl) => ({
    page: {
      buttons: {
        services: {
          label: intl.formatMessage({
            description: 'checkout go to services',
            defaultMessage: 'GO TO SERVICES',
          }),
        },
        purchases: {
          label: intl.formatMessage({
            description: 'checkout go to purchases',
            defaultMessage: 'GO TO YOUR PURCHASES',
          }),
        },
      },
    },
    errors: {
      error1: intl.formatMessage({
        description: 'order data request processing error',
        defaultMessage: 'Order data request processing error',
      }),
      error2: intl.formatMessage({
        description: 'order data processing error',
        defaultMessage: 'Order data processing error',
      }),
      error3: intl.formatMessage({
        description: 'order payment initialization error',
        defaultMessage: 'Order payment initialization error',
      }),
      error4: intl.formatMessage({
        description: 'order payment error',
        defaultMessage: 'Order payment error',
      }),
    },
  }),
  sponsorsPurchasesTable: (intl) => ({
    table: {
      noData: intl.formatMessage({
        description: 'there are no purchases',
        defaultMessage: 'There are no purchases yet.',
      }),
      columns: {
        purchaseCode: intl.formatMessage({
          description: 'purchase code column header',
          defaultMessage: 'Purchase Code',
        }),
        sponsor: intl.formatMessage({
          description: 'sponsor name column header',
          defaultMessage: 'Sponsor',
        }),
        package: intl.formatMessage({
          description: 'package column header',
          defaultMessage: 'Package',
        }),
        date: intl.formatMessage({
          description: 'buy date column header',
          defaultMessage: 'Buy Date',
        }),
        orderDate: intl.formatMessage({
          description: 'order date column header',
          defaultMessage: 'Order date',
        }),
        netTotal: intl.formatMessage({
          description: 'net total column header',
          defaultMessage: 'Net total (€)',
        }),
        vatAmount: intl.formatMessage({
          description: 'vat column header',
          defaultMessage: 'Vat (€)',
        }),
        vatPercentage: intl.formatMessage({
          description: 'vat percentage column header',
          defaultMessage: 'Vat (%)',
        }),
        totalAmount: intl.formatMessage({
          description: 'total amount column header',
          defaultMessage: 'Total amount (€)',
        }),
        paymentMethod: intl.formatMessage({
          description: 'payment method column header',
          defaultMessage: 'Payment Method',
        }),
        paymentStatus: intl.formatMessage({
          description: 'payment status column header',
          defaultMessage: 'Payment status',
        }),
        note: intl.formatMessage({
          description: 'note column header',
          defaultMessage: 'Note',
        }),
        quantity: intl.formatMessage({
          description: 'quantity column header',
          defaultMessage: 'Quantity',
        }),
        paidFee: intl.formatMessage({
          description: 'paid fee column header',
          defaultMessage: 'Paid fee',
        }),
        totalCost: intl.formatMessage({
          description: 'total cost column header',
          defaultMessage: 'Total cost',
        }),
        totalFee: intl.formatMessage({
          description: 'total fee column header',
          defaultMessage: 'Total fee',
        }),
        totalVatAmount: intl.formatMessage({
          description: 'total vat amount column header',
          defaultMessage: 'Total vat (€)',
        }),
        totalAmountVatIncluded: intl.formatMessage({
          description: 'total amount vat included column header',
          defaultMessage: 'Total amount vat included',
        }),
        actions: intl.formatMessage({
          description: 'actions',
          defaultMessage: 'Actions',
        }),
      },
      subColumns: {
        label: intl.formatMessage({
          description: 'service column header',
          defaultMessage: 'Service',
        }),
        service: intl.formatMessage({
          description: 'service column header',
          defaultMessage: 'Service',
        }),
        instance: intl.formatMessage({
          description: 'instance column header',
          defaultMessage: 'Instance',
        }),
        item: intl.formatMessage({
          description: 'item column header',
          defaultMessage: 'Item',
        }),
        serviceType: intl.formatMessage({
          description: 'service type column header',
          defaultMessage: 'Service type',
        }),
        vat: intl.formatMessage({
          description: 'vat column header',
          defaultMessage: 'Vat(%)',
        }),
        cost: intl.formatMessage({
          description: 'cost column header',
          defaultMessage: 'Cost',
        }),
        netPrice: intl.formatMessage({
          description: 'net price column header',
          defaultMessage: 'Net Price',
        }),
        unitaryCost: intl.formatMessage({
          description: 'unitary cost column header',
          defaultMessage: 'Unitary cost (€)',
        }),
        unitaryPrice: intl.formatMessage({
          description: 'unitary price column header',
          defaultMessage: 'Unitary price (€)',
        }),
        unitaryNetPrice: intl.formatMessage({
          description: 'unitary net price column header',
          defaultMessage: 'Unitary net price (€)',
        }),
        unitaryVatRate: intl.formatMessage({
          description: 'unitary vat rate column header',
          defaultMessage: 'Vat (%)',
        }),
        unitaryVatAmount: intl.formatMessage({
          description: 'unitary vat amount column header',
          defaultMessage: 'Unitary vat (€)',
        }),
        quantity: intl.formatMessage({
          description: 'quantity column header',
          defaultMessage: 'Quantity',
        }),
        totalNetPrice: intl.formatMessage({
          description: 'total net price column header',
          defaultMessage: 'Total net price (€)',
        }),
        totalCost: intl.formatMessage({
          description: 'total cost column header',
          defaultMessage: 'Total cost (€)',
        }),
        totalVat: intl.formatMessage({
          description: 'total VAT column header',
          defaultMessage: 'Total vat (€)',
        }),
        totalPriceVatIncluded: intl.formatMessage({
          description: 'total price vat included column header',
          defaultMessage: 'Total price vat included (€)',
        }),
        // finalPrice: intl.formatMessage({
        //   description: 'finalprice column header',
        //   defaultMessage: 'Final Price',
        // }),
      },
    },
  }),
  customOrdersTable: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'custom orders page title',
          defaultMessage: 'custom orders',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Back',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button text',
            defaultMessage: 'back',
          }),
        },
      },
    },
    table: {
      labels: {
        orderId: intl.formatMessage({
          description: 'ID label',
          defaultMessage: 'ID',
        }),
        description: intl.formatMessage({
          description: 'description label',
          defaultMessage: 'Description',
        }),
        netPrice: intl.formatMessage({
          description: 'Net price label',
          defaultMessage: 'Net price',
        }),
        vat: intl.formatMessage({
          description: 'Vat % label',
          defaultMessage: 'VAT %',
        }),
        totalPrice: intl.formatMessage({
          description: 'Total Price label',
          defaultMessage: 'Total price',
        }),
        paymentDate: intl.formatMessage({
          description: 'Payment Date label',
          defaultMessage: 'Payment date',
        }),
        paymentStatus: intl.formatMessage({
          description: 'Payment status label',
          defaultMessage: 'Payment status',
        }),
      },
      paymentStatusDone: intl.formatMessage({
        description: 'Complete label',
        defaultMessage: 'Complete',
      }),
      paymentStatusPending: intl.formatMessage({
        description: 'Pending label',
        defaultMessage: 'Pending',
      }),
    },
  }),
  sponsorInvoices: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'invoices page title',
          defaultMessage: 'invoices',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Back',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button text',
            defaultMessage: 'back',
          }),
        },
      },
    },
    table: {
      labels: {
        billingCustomer: intl.formatMessage({
          description: 'billing Customer label',
          defaultMessage: 'Billing Customer',
        }),
        docNumber: intl.formatMessage({
          description: 'document number label',
          defaultMessage: 'Document N°',
        }),
        docType: intl.formatMessage({
          description: 'document type label',
          defaultMessage: 'Document type',
        }),
        totalAmount: intl.formatMessage({
          description: 'total amount label',
          defaultMessage: 'Total amount',
        }),
        actions: intl.formatMessage({
          description: 'actions label',
          defaultMessage: 'Actions',
        }),
      },
    },
  }),
  sponsorsPurchasesPage: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'purchase page title',
          defaultMessage: 'My Purchases',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Back',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button text',
            defaultMessage: 'back',
          }),
        },
      },
    },
    paymentTypes: {
      creditCard: {
        label: intl.formatMessage({
          description: 'credit card label',
          defaultMessage: 'Credit Card',
        }),
      },
      bankTransfer: {
        label: intl.formatMessage({
          description: 'bank transfer label',
          defaultMessage: 'Bank Transfer',
        }),
      },
      both: {
        label: intl.formatMessage({
          description: 'credit card/bank transfer label',
          defaultMessage: 'Credit Card/Bank Transfer',
        }),
      },
    },
    servicesName: {
      bannerArea: intl.formatMessage({
        description: 'bank transfer label',
        defaultMessage: 'Banner Area',
      }),
      symposium: intl.formatMessage({
        description: 'bank transfer label',
        defaultMessage: 'Symposium',
      }),
      publicPage: intl.formatMessage({
        description: 'public page label',
        defaultMessage: 'Public Page',
      }),
      breakoutRoom: intl.formatMessage({
        description: 'breakoutRoom label',
        defaultMessage: 'Breakout Room',
      }),
      physicalStandSpace: intl.formatMessage({
        description: 'physicalStandSpace label',
        defaultMessage: 'Physical Stand Space',
      }),
      physicalStandSpaceItems: intl.formatMessage({
        description: 'physicalStandSpaceItems label',
        defaultMessage: 'Other Stand Services',
      }),
      virtualStand: intl.formatMessage({
        description: 'virtualStand label',
        defaultMessage: 'Virtual Stand',
      }),
      sponsorStaff: intl.formatMessage({
        description: 'sponsorStaff label',
        defaultMessage: 'Staff Members',
      }),
      sponsorList: intl.formatMessage({
        description: 'sponsorStaff label',
        defaultMessage: 'Sponsored Participations',
      }),
      symposiumServices: intl.formatMessage({
        description: 'symposium services label',
        defaultMessage: 'Symposium Services',
      }),
      sponsorPackages: intl.formatMessage({
        description: 'sponsor packages label',
        defaultMessage: 'Sponsor Packages',
      }),
      otherSponsorizations: intl.formatMessage({
        description: 'other Sponsorizations label',
        defaultMessage: 'Other Sponsorizations',
      }),
    },
  }),
  sponsorRegistrationForm: (intl) => ({
    page: {
      title: intl.formatMessage({
        description: 'sponsor registration form title',
        defaultMessage: 'Welcome',
      }),
      messages: {
        firstPart: intl.formatMessage({
          description: 'sponsor registration form message first part',
          defaultMessage: 'Welcome to the new platform',
        }),
        secondPart: intl.formatMessage({
          description: 'sponsor registration form message second part',
          defaultMessage:
            'before accessing the reserved services, we ask you to fill in this information. The procedure will take a few minutes.',
        }),
      },
    },
  }),
  sponsorQuickLinks: (intl) => ({
    title: intl.formatMessage({
      description: 'sponsor quick links form title',
      defaultMessage: 'Quick links',
    }),
    services: {
      label: intl.formatMessage({
        description: 'sponsor quick links services',
        defaultMessage: 'Services',
      }),
    },
    premiumRanges: {
      label: intl.formatMessage({
        description: 'sponsor quick links premium ranges',
        defaultMessage: 'Premium ranges',
      }),
    },
    servicesConfiguration: {
      label: intl.formatMessage({
        description: 'sponsor quick links services configuration',
        defaultMessage: 'Services configuration',
      }),
    },
    cart: {
      label: intl.formatMessage({
        description: 'sponsor quick links cart',
        defaultMessage: 'Cart',
      }),
    },
  }),
  sponsorServicesConfigurationPage: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'purchase page title',
          defaultMessage: 'My services configuration',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Back to my area',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button text',
            defaultMessage: 'My Area',
          }),
        },
      },
    },
    cards: {
      breakoutRoom: intl.formatMessage({
        description: 'sponsor service configuration wall breakout room',
        defaultMessage: 'Breakout Rooms',
      }),
      virtualStand: intl.formatMessage({
        description: 'sponsor service configuration wall virtual stand',
        defaultMessage: 'Virtual Stand',
      }),
      liveExhibition: intl.formatMessage({
        description: 'sponsor service configuration wall live exhibition',
        defaultMessage: 'Live exhibition',
      }),
      sponsorBanner: intl.formatMessage({
        description: 'sponsor service configuration wall sponsor banner',
        defaultMessage: 'Sponsor Banner',
      }),
      publicPage: intl.formatMessage({
        description: 'sponsor service configuration wall public page',
        defaultMessage: 'Public Page',
      }),
      physicalStand: intl.formatMessage({
        description: 'physical stand service configuration wall public page',
        defaultMessage: 'Physical Stand',
      }),
      staff: intl.formatMessage({
        description: 'staff service configuration',
        defaultMessage: 'Staff',
      }),
      list: intl.formatMessage({
        description: 'list service configuration',
        defaultMessage: 'List',
      }),
    },
  }),
  sponsorPublicPageService: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Public Page',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'instructions title',
          defaultMessage: 'Instructions',
        }),
      },
    },
    columns: {
      name: {
        label: intl.formatMessage({
          description: 'service name column',
          defaultMessage: 'Service',
        }),
        message: intl.formatMessage({
          description: 'service name message',
          defaultMessage: 'Public Page',
        }),
      },
      netPrice: {
        label: intl.formatMessage({
          description: 'net price column',
          defaultMessage: 'Net Price',
        }),
      },
      vatRate: {
        label: intl.formatMessage({
          description: 'vat rate column',
          defaultMessage: 'Vat Rate',
        }),
      },
      taxedPrice: {
        label: intl.formatMessage({
          description: 'taxed price column',
          defaultMessage: 'Taxed Price',
        }),
      },
    },
    snackbar: {
      missingDownloadsError: intl.formatMessage({
        description: 'remaining mandatory files error message',
        defaultMessage:
          'You must read all mandatory documents before accessing this page',
      }),
    },
  }),
  physicalStand: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Physical Stand',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Instructions',
        }),
      },
      documents: {
        label: intl.formatMessage({
          description:
            'documents label, this label specifically has to translate to Documents',
          defaultMessage: 'Documents',
        }),
      },
      mandatoryDocuments: {
        label: intl.formatMessage({
          description:
            'this label instructs on how you have to read the mandatory documents (files) in order to continue with any form of submitting',
          defaultMessage: 'Read the mandatory documents to continue',
        }),
      },
      infoCard: {
        days: {
          label: intl.formatMessage({
            description: 'page title',
            defaultMessage: 'Validity days',
          }),
        },
        deadline: {
          label: intl.formatMessage({
            description: 'page title',
            defaultMessage: 'Deadline',
          }),
        },
      },
      fileList: {
        fileName: {
          label: intl.formatMessage({
            description: 'file name',
            defaultMessage: 'Name',
          }),
        },
        fileDimension: {
          label: intl.formatMessage({
            description: 'file dimension',
            defaultMessage: 'Dimension',
          }),
        },
      },
    },
    snackbar: {
      missingDownloadsError: intl.formatMessage({
        description: 'remaining mandatory files error message',
        defaultMessage:
          'You must read all mandatory documents before accessing this page',
      }),
    },
  }),
  otherSponsorizations: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'other sponsorizations page title',
          defaultMessage: 'Other sponsorizations',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'other sponsorizations instructions',
          defaultMessage: 'Instructions',
        }),
      },
      services: {
        label: intl.formatMessage({
          description: 'other sponsorizations services',
          defaultMessage: 'Services',
        }),
        noServicesFound: intl.formatMessage({
          description: 'other sponsorizations no services message',
          defaultMessage: 'No services found',
        }),
      },
    },
    snackbar: {
      missingDownloadsError: intl.formatMessage({
        description: 'remaining mandatory files error message',
        defaultMessage:
          'You must read all mandatory documents before accessing this page',
      }),
    },
  }),
  otherSponsorizationsSub: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services Sponsorizations',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'other sponsorizations subcategory page title',
          defaultMessage: 'Other sponsorizations',
        }),
      },
      items: {
        label: intl.formatMessage({
          description: 'other sponsorizations subcategory items',
          defaultMessage: 'N° items',
        }),
      },
      services: {
        label: intl.formatMessage({
          description: 'other sponsorizations subcategory name',
          defaultMessage: 'Name',
        }),
        noServicesFound: intl.formatMessage({
          description: 'other sponsorizations no services message',
          defaultMessage: 'No categories found',
        }),
      },
    },
  }),
  otherSponsorizationsItemsList: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Subcategory',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
    },
    table: {
      noData: intl.formatMessage({
        description: 'there are no items',
        defaultMessage: 'There are no items.',
      }),
      unlimitedQuantity: intl.formatMessage({
        description: 'unlimited quantity label',
        defaultMessage: 'Unlimited',
      }),
      soldOutQuantity: intl.formatMessage({
        description: 'sold out quantity label',
        defaultMessage: 'Sold out',
      }),
      columns: {
        item: {
          label: intl.formatMessage({
            description: 'item',
            defaultMessage: 'Item',
          }),
        },
        type: {
          label: intl.formatMessage({
            description: 'type',
            defaultMessage: 'Type',
          }),
        },
        availability: {
          label: intl.formatMessage({
            description: 'availability',
            defaultMessage: 'Availability',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'net price',
            defaultMessage: 'Net Price',
          }),
        },
        vatRate: {
          label: intl.formatMessage({
            description: 'vat rate',
            defaultMessage: 'VAT',
          }),
        },
        totalAmount: {
          label: intl.formatMessage({
            description: 'totalAmount',
            defaultMessage: 'Total amount',
          }),
        },
        purchasedBy: {
          label: intl.formatMessage({
            description: 'purchasedBy',
            defaultMessage: 'Purchased by',
          }),
        },
        actions: {
          label: intl.formatMessage({
            description: 'actions',
            defaultMessage: 'Actions',
          }),
        },
      },
    },
    dialog: {
      title: {
        label: intl.formatMessage({
          description: 'other spons item dialog title',
          defaultMessage: 'Item Info',
        }),
      },
      closeButton: {
        label: intl.formatMessage({
          description: 'other spons item dialog close button',
          defaultMessage: 'Close',
        }),
      },
      fields: {
        subCategory: {
          label: intl.formatMessage({
            description: 'other spons item dialog subcategory',
            defaultMessage: 'Subcategory',
          }),
        },
        type: {
          label: intl.formatMessage({
            description: 'uother spons item dialog type',
            defaultMessage: 'Type',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'uother spons item dialog net price',
            defaultMessage: 'Net Price',
          }),
        },
        vatRate: {
          label: intl.formatMessage({
            description: 'uother spons item dialog vat rate',
            defaultMessage: 'Vat Rate',
          }),
        },
        totalAmount: {
          label: intl.formatMessage({
            description: 'uother spons item dialog total amount',
            defaultMessage: 'Total Amount',
          }),
        },
        note: {
          label: intl.formatMessage({
            description: 'uother spons item dialog note',
            defaultMessage: 'Note',
          }),
        },
      },
    },
    addToCartDialog: {
      title: {
        label: intl.formatMessage({
          description: 'add to cart dialog title',
          defaultMessage: 'Add to Cart',
        }),
      },
      fields: {
        quantity: {
          label: intl.formatMessage({
            description: 'quantity label',
            defaultMessage: 'Quantity',
          }),
        },
      },
      buttons: {
        add: {
          label: intl.formatMessage({
            description: 'add to cart button',
            defaultMessage: 'Add to cart',
          }),
        },
        close: {
          label: intl.formatMessage({
            description: 'close button',
            defaultMessage: 'Close',
          }),
        },
      },
    },
    snackbar: {
      addToCartFail: {
        label: intl.formatMessage({
          description: 'add to cart fail',
          defaultMessage: 'Error during add to cart operation',
        }),
      },
    },
  }),
  otherServices: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Symposium',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Instructions',
        }),
      },
      infoCard: {
        days: {
          label: intl.formatMessage({
            description: 'page title',
            defaultMessage: 'Validity days',
          }),
        },
        deadline: {
          label: intl.formatMessage({
            description: 'page title',
            defaultMessage: 'Deadline',
          }),
        },
      },
      fileList: {
        fileName: {
          label: intl.formatMessage({
            description: 'file name',
            defaultMessage: 'Name',
          }),
        },
        fileDimension: {
          label: intl.formatMessage({
            description: 'file dimension',
            defaultMessage: 'Dimension',
          }),
        },
      },
    },
  }),
  physicalStandList: (intl) => ({
    table: {
      title: intl.formatMessage({
        description: 'floors',
        defaultMessage: 'Floors',
      }),
      noData: intl.formatMessage({
        description: 'there are no floors',
        defaultMessage: 'There are no floors.',
      }),
      columns: {
        id: {
          label: intl.formatMessage({
            description: 'id',
            defaultMessage: 'ID',
          }),
        },
        floor: {
          label: intl.formatMessage({
            description: 'floor',
            defaultMessage: 'Floor',
          }),
        },
        spaces: {
          label: intl.formatMessage({
            description: 'spaces',
            defaultMessage: 'Spaces',
          }),
        },
        actions: {
          label: intl.formatMessage({
            description: 'actions',
            defaultMessage: 'Actions',
          }),
        },
      },
    },
  }),
  physicalStandFloorsList: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Floors',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
    },
    table: {
      title: intl.formatMessage({
        description: 'floors',
        defaultMessage: 'Floors',
      }),
      noData: intl.formatMessage({
        description: 'there are no floors',
        defaultMessage: 'There are no floors.',
      }),
      columns: {
        code: {
          label: intl.formatMessage({
            description: 'code',
            defaultMessage: 'Code',
          }),
        },
        name: {
          label: intl.formatMessage({
            description: 'physical stand space name',
            defaultMessage: 'Name',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'net price',
            defaultMessage: 'Net Price',
          }),
        },
        vatRate: {
          label: intl.formatMessage({
            description: 'vat rate',
            defaultMessage: 'Vat Rate',
          }),
        },
        taxedPrice: {
          label: intl.formatMessage({
            description: 'taxed price',
            defaultMessage: 'Taxed Price',
          }),
        },
        status: {
          label: intl.formatMessage({
            description: 'status',
            defaultMessage: 'Status',
          }),
        },
        purchasedBy: {
          label: intl.formatMessage({
            description: 'purchased by',
            defaultMessage: 'Purchased By',
          }),
        },
        actions: {
          label: intl.formatMessage({
            description: 'actions',
            defaultMessage: 'Actions',
          }),
        },
      },
      status: {
        free: {
          label: intl.formatMessage({
            description: 'status free',
            defaultMessage: 'Free',
          }),
        },
        booked: {
          label: intl.formatMessage({
            description: 'status booked',
            defaultMessage: 'Booked',
          }),
        },
        purchased: {
          label: intl.formatMessage({
            description: 'status purchased',
            defaultMessage: 'Purchased',
          }),
        },
      },
    },
    snackbar: {
      addToCartFail: {
        label: intl.formatMessage({
          description: 'add to cart fail',
          defaultMessage: 'Error during add to cart operation',
        }),
      },
    },
    dialog: {
      fields: {
        code: {
          label: intl.formatMessage({
            description: 'dialog code field',
            defaultMessage: 'Code',
          }),
        },
        name: {
          label: intl.formatMessage({
            description: 'dialog name field',
            defaultMessage: 'Name',
          }),
        },
        spaceLength: {
          label: intl.formatMessage({
            description: 'dialog space length field',
            defaultMessage: 'Length mt (x)',
          }),
        },
        spaceHeight: {
          label: intl.formatMessage({
            description: 'dialog space heigth field',
            defaultMessage: 'Height mt (y)',
          }),
        },
        spaceDepth: {
          label: intl.formatMessage({
            description: 'dialog surface field',
            defaultMessage: 'Depth mt (z)',
          }),
        },
        surface: {
          label: intl.formatMessage({
            description: 'dialog surface field',
            defaultMessage: 'Surface (mt2)',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'dialog net price field',
            defaultMessage: 'Net Price',
          }),
        },
        vatRate: {
          label: intl.formatMessage({
            description: 'dialog vat rate field',
            defaultMessage: 'Vat Rate',
          }),
        },
        taxedPrice: {
          label: intl.formatMessage({
            description: 'dialog taxed price field',
            defaultMessage: 'Taxed Price',
          }),
        },
        note: {
          label: intl.formatMessage({
            description: 'dialog note field',
            defaultMessage: 'Notes',
          }),
        },
      },
      actions: {
        plantImage: {
          label: intl.formatMessage({
            description: 'dialog download plant image',
            defaultMessage: 'download plant image',
          }),
        },
        close: {
          label: intl.formatMessage({
            description: 'dialog close',
            defaultMessage: 'Close',
          }),
        },
      },
    },
  }),
  breakoutRoomPageService: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Breakout Room',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'instructions title',
          defaultMessage: 'Instructions',
        }),
      },
    },
    columns: {
      name: {
        label: intl.formatMessage({
          description: 'service name column',
          defaultMessage: 'Service',
        }),
        message: intl.formatMessage({
          description: 'service name message',
          defaultMessage: 'Breakout Room',
        }),
      },
      price: {
        label: intl.formatMessage({
          description: 'net price column',
          defaultMessage: 'Net Price',
        }),
      },
      vatRate: {
        label: intl.formatMessage({
          description: 'vat rate column',
          defaultMessage: 'Vat Rate',
        }),
      },
      taxedPrice: {
        label: intl.formatMessage({
          description: 'taxed price column',
          defaultMessage: 'Taxed Price',
        }),
      },
      quantity: {
        label: intl.formatMessage({
          description: 'quantity column',
          defaultMessage: 'Quantity',
        }),
      },
    },
    snackbar: {
      missingDownloadsError: intl.formatMessage({
        description: 'remaining mandatory files error message',
        defaultMessage:
          'You must read all mandatory documents before process with purchase',
      }),
    },
  }),
  physicalStandCategories: (intl) => ({
    page: {
      noCategories: {
        label: intl.formatMessage({
          description: 'no categories yet label',
          defaultMessage: 'There are no categories configured yet',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services Configuration',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'physical stand page title',
          defaultMessage: 'Configure your physical stand',
        }),
      },
      instructions: intl.formatMessage({
        description: 'instructions label',
        defaultMessage: 'Instructions',
      }),
      fileList: {
        fileName: intl.formatMessage({
          description: 'file name',
          defaultMessage: 'Name',
        }),
        fileDimension: intl.formatMessage({
          description: 'file dimension',
          defaultMessage: 'Dimension',
        }),
      },
    },
    cards: {
      subCategories: {
        label: intl.formatMessage({
          description: 'card sub categories label',
          defaultMessage: 'Sub-Categories',
        }),
      },
      services: {
        label: intl.formatMessage({
          description: 'card services label',
          defaultMessage: 'Services',
        }),
      },
    },
    snackbar: {
      missingDownloadsError: intl.formatMessage({
        description: 'remaining mandatory files error message',
        defaultMessage:
          'You must read all mandatory documents before accessing this page',
      }),
    },
  }),
  physicalStandSpaces: (intl) => ({
    page: {
      noSpaces: {
        label: intl.formatMessage({
          description: 'no categories yet label',
          defaultMessage: 'There are no physical stand spaces configured yet',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services Configuration',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Purchased Physical Stand Spaces',
        }),
      },
    },
    table: {
      viewOrder: {
        label: intl.formatMessage({
          description: 'card sub categories label',
          defaultMessage: 'View Purchase',
        }),
      },
      noSpaceItems: {
        label: intl.formatMessage({
          description: 'card services label',
          defaultMessage: 'There are no other stand service configured yet',
        }),
      },
      headers: {
        code: intl.formatMessage({
          description: 'card services label',
          defaultMessage: 'Code',
        }),
        name: intl.formatMessage({
          description: 'stand spaces name label',
          defaultMessage: 'Name',
        }),
        floorName: intl.formatMessage({
          description: 'stand spaces floor name label',
          defaultMessage: 'Floor name',
        }),
        floorCode: intl.formatMessage({
          description: 'stand spaces floor code label',
          defaultMessage: 'Floor',
        }),
        purchasedPhysicalStandSpaces: intl.formatMessage({
          description: 'purchased physical stand spaces label',
          defaultMessage: 'Purchased Physical Stand Spaces',
        }),
        category: intl.formatMessage({
          description: 'card services label',
          defaultMessage: 'Category',
        }),
        subCategory: intl.formatMessage({
          description: 'card services label',
          defaultMessage: 'Sub-Category',
        }),
        item: intl.formatMessage({
          description: 'card services label',
          defaultMessage: 'Item',
        }),
        quantity: intl.formatMessage({
          description: 'card services label',
          defaultMessage: 'Quantity',
        }),
      },
    },
  }),
  physicalStandItemsSubcategories: (intl) => ({
    page: {
      back: intl.formatMessage({
        description: 'back button tooltip',
        defaultMessage: 'back',
      }),
      title: intl.formatMessage({
        description: 'Physical stand items subcategory page title',
        defaultMessage: 'Stand Items Subcategories',
      }),
      items: intl.formatMessage({
        description: 'Physical stand items subcategory items',
        defaultMessage: 'N° items',
      }),
      services: intl.formatMessage({
        description: 'Physical stand items subcategory name',
        defaultMessage: 'Name',
      }),
      noServicesFound: intl.formatMessage({
        description: 'Physical stand items subcategory no services message',
        defaultMessage: 'No subcategories found',
      }),
    },
  }),
  physicalStandItemsList: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Floors',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Physical Stand',
        }),
      },
      instructions: intl.formatMessage({
        description: 'instructions label',
        defaultMessage: 'Instructions',
      }),
      fileList: {
        fileName: intl.formatMessage({
          description: 'file name',
          defaultMessage: 'Name',
        }),
        fileDimension: intl.formatMessage({
          description: 'file dimension',
          defaultMessage: 'Dimension',
        }),
      },
    },
    table: {
      title: intl.formatMessage({
        description: 'floors',
        defaultMessage: 'Floors',
      }),
      noData: intl.formatMessage({
        description: 'there are no floors',
        defaultMessage: 'There are no floors.',
      }),
      unlimitedQuantity: intl.formatMessage({
        description: 'unlimited quantity label',
        defaultMessage: 'Unlimited',
      }),
      soldOutQuantity: intl.formatMessage({
        description: 'sold out quantity label',
        defaultMessage: 'Sold out',
      }),
      columns: {
        service: {
          label: intl.formatMessage({
            description: 'service',
            defaultMessage: 'Service',
          }),
        },
        subCategory: {
          label: intl.formatMessage({
            description: 'sub-category',
            defaultMessage: 'Sub-Category',
          }),
        },
        type: {
          label: intl.formatMessage({
            description: 'type',
            defaultMessage: 'Type',
          }),
        },
        availability: {
          label: intl.formatMessage({
            description: 'availability',
            defaultMessage: 'Availability',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'net price',
            defaultMessage: 'Net Price',
          }),
        },
        vatRate: {
          label: intl.formatMessage({
            description: 'vat rate',
            defaultMessage: 'Vat',
          }),
        },
        totalPrice: {
          label: intl.formatMessage({
            description: 'total price',
            defaultMessage: 'Total Price',
          }),
        },
        actions: {
          label: intl.formatMessage({
            description: 'actions',
            defaultMessage: 'Actions',
          }),
        },
      },
      status: {
        free: {
          label: intl.formatMessage({
            description: 'status free',
            defaultMessage: 'Free',
          }),
        },
        purchased: {
          label: intl.formatMessage({
            description: 'status purchased',
            defaultMessage: 'Purchased',
          }),
        },
      },
    },
    snackbar: {
      addToCartFail: {
        label: intl.formatMessage({
          description: 'add to cart fail',
          defaultMessage: 'Error during add to cart operation',
        }),
      },
    },
    dialog: {
      title: {
        label: intl.formatMessage({
          description: 'upnext tiles title',
          defaultMessage: 'Item Info',
        }),
      },
      closeButton: {
        label: intl.formatMessage({
          description: 'upnext tiles title',
          defaultMessage: 'Close',
        }),
      },
      fields: {
        name: {
          label: intl.formatMessage({
            description: 'upnext tiles title',
            defaultMessage: 'Name',
          }),
        },
        subCategory: {
          label: intl.formatMessage({
            description: 'upnext tiles title',
            defaultMessage: 'Subcategory',
          }),
        },
        type: {
          label: intl.formatMessage({
            description: 'upnext tiles title',
            defaultMessage: 'Type',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'upnext tiles title',
            defaultMessage: 'Net Price',
          }),
        },
        vatRate: {
          label: intl.formatMessage({
            description: 'upnext tiles title',
            defaultMessage: 'Vat Rate',
          }),
        },
        vatPrice: {
          label: intl.formatMessage({
            description: 'upnext tiles title',
            defaultMessage: 'Total price',
          }),
        },
        note: {
          label: intl.formatMessage({
            description: 'upnext tiles title',
            defaultMessage: 'Note',
          }),
        },
      },
    },
    addToCartDialog: {
      title: {
        label: intl.formatMessage({
          description: 'add to cart dialog title',
          defaultMessage: 'Add to Cart',
        }),
      },
      fields: {
        quantity: {
          label: intl.formatMessage({
            description: 'quantity label',
            defaultMessage: 'Quantity',
          }),
        },
      },
      buttons: {
        add: {
          label: intl.formatMessage({
            description: 'add to cart button',
            defaultMessage: 'Add to cart',
          }),
        },
        close: {
          label: intl.formatMessage({
            description: 'close button',
            defaultMessage: 'Close',
          }),
        },
      },
    },
  }),
  sessions: (intl) => ({
    page: {
      upNext: {
        title: intl.formatMessage({
          description: 'upnext tiles title',
          defaultMessage: 'Up Next',
        }),
        noSessions: intl.formatMessage({
          description: 'no sessions upnext',
          defaultMessage: 'There are no sessions coming up next',
        }),
      },
      sessionsCarousel: {
        detailButton: {
          label: intl.formatMessage({
            description: 'session details button',
            defaultMessage: 'Session details',
          }),
        },
        joinButton: {
          label: intl.formatMessage({
            description: 'session join button',
            defaultMessage: 'Join',
          }),
        },
        title: {
          label: intl.formatMessage({
            description: 'page title',
            defaultMessage: 'Sessions',
          }),
        },
      },
      slide: {
        liveNow: {
          label: intl.formatMessage({
            description: 'live now session text',
            defaultMessage: 'LIVE NOW',
          }),
        },
      },
    },
  }),
  sponsorBannerPurchase: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      buy: {
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'PURCHASE',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Sponsor Banner',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Instructions',
        }),
      },
    },
    snackbar: {
      addToCartFail: {
        label: intl.formatMessage({
          description: 'add to cart fail',
          defaultMessage: 'Error during add to cart operation',
        }),
      },
    },
    headers: {
      template: {
        label: intl.formatMessage({
          description: 'sponsor banner header template',
          defaultMessage: 'Template',
        }),
      },
      areas: {
        label: intl.formatMessage({
          description: 'sponsor banner header areas',
          defaultMessage: 'Sponsorable Areas',
        }),
      },
      netPrice: {
        label: intl.formatMessage({
          description: 'sponsor banner header net price',
          defaultMessage: 'Net Price (€)',
        }),
      },
      vatRate: {
        label: intl.formatMessage({
          description: 'sponsor banner header vat rate',
          defaultMessage: 'Vat Rate (%)',
        }),
      },
      taxedPrice: {
        label: intl.formatMessage({
          description: 'sponsor banner header taxed price',
          defaultMessage: 'Taxed Price (€)',
        }),
      },
    },
  }),
  myServices: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Price Ranges',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: intl.formatMessage({
        description: 'opportunities my services title',
        defaultMessage: 'Opportunities',
      }),
    },
    serviceList: {
      needToBuyPublicPageMessage: {
        label: intl.formatMessage({
          description:
            'you have to buy public page before buying this service message',
          defaultMessage: 'You have to purchase public page first',
        }),
      },
      buyButtonDisabledMessage: {
        label: intl.formatMessage({
          description: 'buy button disabled tooltip',
          defaultMessage: 'Service no longer available for purchase',
        }),
      },
      pageTitle: {
        label: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Exhibition',
        }),
      },
      card: {
        bookings: {
          label: intl.formatMessage({
            description: 'service card bookings label',
            defaultMessage: 'Bookings',
          }),
        },
        orders: {
          label: intl.formatMessage({
            description: 'service card orders label',
            defaultMessage: 'Purchases',
          }),
        },
      },
      packages: intl.formatMessage({
        description: 'packages label',
        defaultMessage: 'Packages',
      }),
    },
    cards: {
      breakoutRoom: intl.formatMessage({
        description: 'sponsor service configuration wall breakout room',
        defaultMessage: 'Breakout Rooms',
      }),
      virtualStand: intl.formatMessage({
        description: 'sponsor service configuration wall virtual stand',
        defaultMessage: 'Virtual Stand',
      }),
      liveExhibition: intl.formatMessage({
        description: 'sponsor service configuration wall live exhibition',
        defaultMessage: 'Live exhibition',
      }),
      sponsorBanner: intl.formatMessage({
        description: 'sponsor service configuration wall sponsor banner',
        defaultMessage: 'Sponsor Banner',
      }),
      publicPage: intl.formatMessage({
        description: 'sponsor service configuration wall public page',
        defaultMessage: 'Public Page',
      }),
      physicalStand: intl.formatMessage({
        description: 'physical stand service configuration wall public page',
        defaultMessage: 'Physical Stand',
      }),
      staff: intl.formatMessage({
        description: 'staff service configuration',
        defaultMessage: 'Staff',
      }),
      list: intl.formatMessage({
        description: 'list service configuration',
        defaultMessage: 'List',
      }),
      listAssignedToAgency: intl.formatMessage({
        description: 'list assigned to agency service configuration',
        defaultMessage: 'Sponsored participations are assigned to an agency.',
      }),
    },
  }),
  priceRangeList: (intl) => ({
    page: {
      subtitle: {
        label: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Premium ranges',
        }),
      },

      forwardButton: {
        label: intl.formatMessage({
          description: 'back button label',
          defaultMessage: 'BUY',
        }),
      },
    },
    items: {
      serviceAvailableFrom: {
        label: intl.formatMessage({
          description: 'Service available from',
          defaultMessage: 'Service available from',
        }),
      },
      price: {
        label: intl.formatMessage({
          description: 'Price (€)',
          defaultMessage: 'Price',
        }),
      },
    },
  }),
  symposium: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Symposium',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Instructions',
        }),
      },
      infoCard: {
        days: {
          label: intl.formatMessage({
            description: 'page title',
            defaultMessage: 'Validity days',
          }),
        },
        deadline: {
          label: intl.formatMessage({
            description: 'page title',
            defaultMessage: 'Deadline',
          }),
        },
      },
      fileList: {
        fileName: {
          label: intl.formatMessage({
            description: 'file name',
            defaultMessage: 'Name',
          }),
        },
        fileDimension: {
          label: intl.formatMessage({
            description: 'file dimension',
            defaultMessage: 'Dimension',
          }),
        },
      },
    },
  }),
  symposiumSessionsList: (intl) => ({
    table: {
      title: intl.formatMessage({
        description: 'sessions',
        defaultMessage: 'Sessions',
      }),
      noData: intl.formatMessage({
        description: 'there are no sessions',
        defaultMessage: 'There are no sessions.',
      }),
      columns: {
        name: {
          label: intl.formatMessage({
            description: 'session name',
            defaultMessage: 'Session',
          }),
        },
        day: {
          label: intl.formatMessage({
            description: 'day',
            defaultMessage: 'Day',
          }),
        },
        start: {
          label: intl.formatMessage({
            description: 'start',
            defaultMessage: 'Start',
          }),
        },
        end: {
          label: intl.formatMessage({
            description: 'end',
            defaultMessage: 'End',
          }),
        },
        room: {
          label: intl.formatMessage({
            description: 'rooms',
            defaultMessage: 'Rooms',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'net price',
            defaultMessage: 'Net Price',
          }),
        },
        vat: {
          label: intl.formatMessage({
            description: 'vat',
            defaultMessage: 'Vat',
          }),
        },
        status: {
          label: intl.formatMessage({
            description: 'status',
            defaultMessage: 'Status',
          }),
        },
        actions: {
          label: intl.formatMessage({
            description: 'actions',
            defaultMessage: 'Actions',
          }),
        },
      },
      status: {
        free: {
          label: intl.formatMessage({
            description: 'status free',
            defaultMessage: 'Free',
          }),
        },
        purchased: {
          label: intl.formatMessage({
            description: 'status purchased',
            defaultMessage: 'Purchased',
          }),
        },
      },
    },
    snackbar: {
      addToCartFail: {
        label: intl.formatMessage({
          description: 'add to cart fail',
          defaultMessage: 'Error during add to cart operation',
        }),
      },
    },
  }),
  webcastGridCardFilter: (intl) => ({
    filters: {
      fields: {
        givenName: {
          label: intl.formatMessage({
            description: 'name',
            defaultMessage: 'Name',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a name',
            defaultMessage: 'Search by name',
          }),
        },
        familyName: {
          label: intl.formatMessage({
            description: 'topic',
            defaultMessage: 'Topic',
          }),
          placeholder: intl.formatMessage({
            description: 'search by topic',
            defaultMessage: 'Search by topic',
          }),
        },
        email: {
          label: intl.formatMessage({
            description: 'email',
            defaultMessage: 'Email',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a email',
            defaultMessage: 'Write here a email',
          }),
        },
      },
    },
  }),
  webcastGridCardsFilter: (intl) => ({
    filters: {
      fields: {
        name: {
          label: intl.formatMessage({
            description: 'name',
            defaultMessage: 'Name',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a name',
            defaultMessage: 'Write here a name',
          }),
        },
        agency: {
          label: intl.formatMessage({
            description: 'agency',
            defaultMessage: 'Agency',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a agency',
            defaultMessage: 'Write here a agency',
          }),
        },
        maxInvitations: {
          label: intl.formatMessage({
            description: 'max invitations',
            defaultMessage: 'Max invitations',
          }),
          placeholder: intl.formatMessage({
            description: 'write here max invitations',
            defaultMessage: 'Write here Max invitations',
          }),
        },
        currentInvitations: {
          label: intl.formatMessage({
            description: 'current invitations',
            defaultMessage: 'Current invitations',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a current invitations',
            defaultMessage: 'Write here a Current invitations',
          }),
        },
        status: {
          label: intl.formatMessage({
            description: 'status',
            defaultMessage: 'Status',
          }),
          placeholder: intl.formatMessage({
            description: 'write here a status',
            defaultMessage: 'Write here a status',
          }),
        },
      },
    },
  }),
  virtualStandCard: (intl) => ({
    topText: {
      upgrade: {
        label: intl.formatMessage({
          description: 'virtaul stand card upgrade top text',
          defaultMessage: 'Upgrade',
        }),
      },
      alreadyPurchased: {
        label: intl.formatMessage({
          description: 'virtaul stand card already purchased text',
          defaultMessage: 'Already Purchased',
        }),
      },
    },
    bottomText: {
      price: {
        label: intl.formatMessage({
          description: 'virtaul stand card price vat included',
          defaultMessage: 'Price (Vat Included)',
        }),
      },
      vatRate: {
        label: intl.formatMessage({
          description: 'virtaul stand card vat rate',
          defaultMessage: 'Vat Rate',
        }),
      },
    },
  }),
  virtualStandList: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'virtual stand list title',
          defaultMessage: 'Virtual Stand',
        }),
      },
      subtitle: {
        label: intl.formatMessage({
          description: 'virtual stand list subtitle',
          defaultMessage:
            'Complete the configuration by purchasing a virtual stand package',
        }),
      },
      noItemsInList: {
        label: intl.formatMessage({
          description: 'virtual stand list no items to list',
          defaultMessage: 'There are no virtual stand avalaible',
        }),
      },
    },
  }),
  virtualStand: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Virtual Stand',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Instructions',
        }),
      },
      fileList: {
        fileName: {
          label: intl.formatMessage({
            description: 'file name',
            defaultMessage: 'Name',
          }),
        },
        fileDimension: {
          label: intl.formatMessage({
            description: 'file dimension',
            defaultMessage: 'Dimension',
          }),
        },
      },
    },
  }),
  virtualStandConfiguration: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'virtual stand configuration title',
          defaultMessage: 'My Virtual Stands',
        }),
      },
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Services Configuration',
        }),
        label: intl.formatMessage({
          description: 'back button label',
          defaultMessage: 'Services Configuration',
        }),
      },
      noData: {
        label: intl.formatMessage({
          description: 'no virtual stand label',
          defaultMessage: 'There are no purchased virtual stands',
        }),
      },
      card: {
        showVirtualStand: {
          disabled: intl.formatMessage({
            description: 'show virtual stand disabled label',
            defaultMessage: 'The stand is not yet configured',
          }),
          label: intl.formatMessage({
            description: 'show virtual stand label',
            defaultMessage: 'Show',
          }),
        },
      },
    },
  }),
  sponsorStaff: (intl) => ({
    page: {
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go To Services',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'back',
          }),
        },
      },
      title: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Sponsor Staff',
        }),
      },
      subtitle: {
        label: intl.formatMessage({
          description: 'page subtitle',
          defaultMessage: 'Sponsor Staff subtitle',
        }),
      },
      instructions: {
        label: intl.formatMessage({
          description: 'page title',
          defaultMessage: 'Instructions',
        }),
      },
      freeStaffMembers: {
        label: intl.formatMessage({
          description: 'staff participation available for free',
          defaultMessage: 'Staff participation available for free:',
        }),
      },
      addSpots: {
        label: intl.formatMessage({
          description: 'purchase additional spots',
          defaultMessage: 'Purchase additional spots',
        }),
      },
      deadline: {
        label: intl.formatMessage({
          description: 'deadline closure staff group',
          defaultMessage: 'Deadline closure staff group',
        }),
      },
      fileList: {
        fileName: {
          label: intl.formatMessage({
            description: 'file name',
            defaultMessage: 'Name',
          }),
        },
        fileDimension: {
          label: intl.formatMessage({
            description: 'file dimension',
            defaultMessage: 'Dimension',
          }),
        },
      },
      purchaseButton: {
        tooltip: {
          label: intl.formatMessage({
            description: 'purchase button tooltip',
            defaultMessage: 'Purchase',
          }),
        },
        title: {
          label: intl.formatMessage({
            description: 'purchase button',
            defaultMessage: 'Purchase',
          }),
        },
      },
    },
    modal: {
      title: intl.formatMessage({
        description: 'buy additional staff members',
        defaultMessage: 'Buy additional staff members',
      }),
      subTitle: intl.formatMessage({
        description: 'prices are exclusive of VAT',
        defaultMessage: 'Prices are exclusive of VAT',
      }),
      description: intl.formatMessage({
        description:
          'description message to buy additional staff participations ',
        defaultMessage:
          'If you want to add new staff member, you have to purchase the corresponding fee. Indicates the number of members you would like to engage.',
      }),
      grid: {
        profile: {
          label: intl.formatMessage({
            description: 'profile',
            defaultMessage: 'Profile',
          }),
          placeholder: intl.formatMessage({
            description: 'select a profile',
            defaultMessage: 'Select a profile',
          }),
        },
        participationType: {
          label: intl.formatMessage({
            description: 'participation',
            defaultMessage: 'Participation',
          }),
          placeholder: intl.formatMessage({
            description: 'select a participation',
            defaultMessage: 'Select a participation',
          }),
        },
        feeDateRange: {
          label: intl.formatMessage({
            description: 'ticket',
            defaultMessage: 'Ticket',
          }),
          placeholder: intl.formatMessage({
            description: 'select a ticket',
            defaultMessage: 'Select a ticket',
          }),
        },
        price: intl.formatMessage({
          description: 'price',
          defaultMessage: 'Price',
        }),
        quantity: {
          label: intl.formatMessage({
            description: 'quantity',
            defaultMessage: 'Quantity',
          }),
          placeholder: intl.formatMessage({
            description: 'quantity',
            defaultMessage: 'Quantity',
          }),
        },
        total: {
          label: intl.formatMessage({
            description: 'total',
            defaultMessage: 'Total',
          }),
        },
      },
      buttons: {
        cancel: intl.formatMessage({
          description: 'cancel button',
          defaultMessage: 'Cancel',
        }),
        buy: intl.formatMessage({
          description: 'buy',
          defaultMessage: 'buy',
        }),
      },
    },
    snackbar: {
      addToCartFail: intl.formatMessage({
        description: 'add to cart fail',
        defaultMessage: 'Add to cart fail',
      }),
    },
  }),
  sponsorList: (intl) => ({
    page: {
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Go To Services',
        }),
        button: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'back',
        }),
      },
      title: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Sponsor Delegates',
      }),
      instructions: intl.formatMessage({
        description: 'page title',
        defaultMessage: 'Instructions',
      }),
      purchasedParticipations: intl.formatMessage({
        description: 'purchased participations label',
        defaultMessage: 'Puchased participations',
      }),
      addSpots: intl.formatMessage({
        description: 'purchase additional spots',
        defaultMessage: 'Purchase additional spots',
      }),
      deadline: intl.formatMessage({
        description: 'deadline closure list group',
        defaultMessage: 'Deadline closure list group',
      }),
      fileList: {
        fileName: intl.formatMessage({
          description: 'file name',
          defaultMessage: 'Name',
        }),
        fileDimension: intl.formatMessage({
          description: 'file dimension',
          defaultMessage: 'Dimension',
        }),
      },
      purchaseButton: {
        tooltip: intl.formatMessage({
          description: 'purchase button tooltip',
          defaultMessage: 'Purchase',
        }),
        title: intl.formatMessage({
          description: 'purchase button',
          defaultMessage: 'Purchase',
        }),
      },
    },
    modal: {
      title: intl.formatMessage({
        description: 'buy sponsored participations',
        defaultMessage: 'Buy Sponsored participations',
      }),
      subTitle: intl.formatMessage({
        description: 'prices are exclusive of VAT',
        defaultMessage: 'Prices are exclusive of VAT',
      }),
      description: intl.formatMessage({
        description:
          'description message to buy additional List participations ',
        defaultMessage:
          'Indicates the number of members you would like to engage.',
      }),
      grid: {
        profile: {
          label: intl.formatMessage({
            description: 'profile',
            defaultMessage: 'Profile',
          }),
          placeholder: intl.formatMessage({
            description: 'select a profile',
            defaultMessage: 'Select a profile',
          }),
        },
        participationType: {
          label: intl.formatMessage({
            description: 'participation',
            defaultMessage: 'Participation',
          }),
          placeholder: intl.formatMessage({
            description: 'select a participation',
            defaultMessage: 'Select a participation',
          }),
        },
        feeDateRange: {
          label: intl.formatMessage({
            description: 'ticket',
            defaultMessage: 'Ticket',
          }),
          placeholder: intl.formatMessage({
            description: 'select a ticket',
            defaultMessage: 'Select a ticket',
          }),
        },
        price: intl.formatMessage({
          description: 'price',
          defaultMessage: 'Price',
        }),
        quantity: {
          label: intl.formatMessage({
            description: 'quantity',
            defaultMessage: 'Quantity',
          }),
          placeholder: intl.formatMessage({
            description: 'quantity',
            defaultMessage: 'Quantity',
          }),
        },
        total: {
          label: intl.formatMessage({
            description: 'total',
            defaultMessage: 'Total',
          }),
        },
      },
      buttons: {
        cancel: intl.formatMessage({
          description: 'dialog cancel button',
          defaultMessage: 'Cancel',
        }),
        buy: intl.formatMessage({
          description: 'buy',
          defaultMessage: 'buy',
        }),
      },
    },
    snackbar: {
      addToCartFail: intl.formatMessage({
        description: 'add to cart fail',
        defaultMessage: 'Add to cart fail',
      }),
    },
  }),
  cartPopover: (intl) => ({
    title: intl.formatMessage({
      description: 'cart popover title',
      defaultMessage: 'Cart',
    }),
    goToCart: intl.formatMessage({
      description: 'cart popover go to cart',
      defaultMessage: 'Go to cart',
    }),
  }),
  agencyPurchasesPage: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'purchase page title',
          defaultMessage: 'My Purchases',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Back',
          }),
        },
        button: {
          label: intl.formatMessage({
            description: 'back button text',
            defaultMessage: 'back',
          }),
        },
      },
    },
    paymentTypes: {
      creditCard: {
        label: intl.formatMessage({
          description: 'credit card label',
          defaultMessage: 'Credit Card',
        }),
      },
      bankTransfer: {
        label: intl.formatMessage({
          description: 'bank transfer label',
          defaultMessage: 'Bank Transfer',
        }),
      },
      both: {
        label: intl.formatMessage({
          description: 'credit card/bank transfer label',
          defaultMessage: 'Credit Card/Bank Transfer',
        }),
      },
    },
  }),
  agencyPurchasesTable: (intl) => ({
    table: {
      noData: intl.formatMessage({
        description: 'there are no purchases',
        defaultMessage: 'There are no purchases yet.',
      }),
      columns: {
        purchaseCode: intl.formatMessage({
          description: 'purchase code column header',
          defaultMessage: 'Purchase Code',
        }),
        agency: intl.formatMessage({
          description: 'agency column header',
          defaultMessage: 'Agency',
        }),
        date: intl.formatMessage({
          description: 'buy date column header',
          defaultMessage: 'Buy Date',
        }),
        orderDate: intl.formatMessage({
          description: 'order date column header',
          defaultMessage: 'Order date',
        }),
        netTotal: intl.formatMessage({
          description: 'net total column header',
          defaultMessage: 'Net total (€)',
        }),
        vatAmount: intl.formatMessage({
          description: 'vat amount column header',
          defaultMessage: 'Vat (€)',
        }),
        totalAmount: intl.formatMessage({
          description: 'total amount column header',
          defaultMessage: 'Total amount (€)',
        }),
        paymentMethod: intl.formatMessage({
          description: 'payment method column header',
          defaultMessage: 'Payment method',
        }),
        paymentStatus: intl.formatMessage({
          description: 'payment status column header',
          defaultMessage: 'Payment status',
        }),
        group: intl.formatMessage({
          description: 'group column header',
          defaultMessage: 'Group',
        }),
        serviceType: intl.formatMessage({
          description: 'service type column header',
          defaultMessage: 'Service type',
        }),
        quantity: intl.formatMessage({
          description: 'quantity column header',
          defaultMessage: 'Quantity',
        }),
        paidFee: intl.formatMessage({
          description: 'paid fee column header',
          defaultMessage: 'Paid fee',
        }),
        // totalCost: intl.formatMessage({
        //   description: 'total cost column header',
        //   defaultMessage: 'Total cost',
        // }),
        totalFee: intl.formatMessage({
          description: 'total fee column header',
          defaultMessage: 'Total fee',
        }),
        totalVatAmount: intl.formatMessage({
          description: 'total vat amount column header',
          defaultMessage: 'Total vat (€)',
        }),
        totalAmountVatIncluded: intl.formatMessage({
          description: 'total amount vat included column header',
          defaultMessage: 'Total amount vat included',
        }),
        actions: intl.formatMessage({
          description: 'actions',
          defaultMessage: 'Actions',
        }),
      },
      subColumns: {
        service: intl.formatMessage({
          description: 'service column header',
          defaultMessage: 'Service',
        }),
        description: intl.formatMessage({
          description: 'description column header',
          defaultMessage: 'Description',
        }),
        serviceType: intl.formatMessage({
          description: 'service type column header',
          defaultMessage: 'Service type',
        }),
        vat: intl.formatMessage({
          description: 'vat column header',
          defaultMessage: 'Vat(%)',
        }),
        // cost: intl.formatMessage({
        //   description: 'cost column header',
        //   defaultMessage: 'Cost',
        // }),
        netPrice: intl.formatMessage({
          description: 'net price column header',
          defaultMessage: 'Net Price',
        }),
        // unitaryCost: intl.formatMessage({
        //   description: 'unitary cost column header',
        //   defaultMessage: 'Unitary cost (€)',
        // }),
        unitaryPrice: intl.formatMessage({
          description: 'unitary price column header',
          defaultMessage: 'Unitary price (€)',
        }),
        unitaryNetPrice: intl.formatMessage({
          description: 'unitary net price column header',
          defaultMessage: 'Unitary net price (€)',
        }),
        unitaryVatRate: intl.formatMessage({
          description: 'unitary vat rate column header',
          defaultMessage: 'Vat (%)',
        }),
        unitaryVatAmount: intl.formatMessage({
          description: 'unitary vat amount column header',
          defaultMessage: 'Unitary vat (€)',
        }),
        quantity: intl.formatMessage({
          description: 'quantity column header',
          defaultMessage: 'Quantity',
        }),
        totalNetPrice: intl.formatMessage({
          description: 'total net price column header',
          defaultMessage: 'Total net price (€)',
        }),
        // totalCost: intl.formatMessage({
        //   description: 'total cost column header',
        //   defaultMessage: 'Total cost (€)',
        // }),
        totalVat: intl.formatMessage({
          description: 'total VAT column header',
          defaultMessage: 'Total vat (€)',
        }),
        totalPriceVatIncluded: intl.formatMessage({
          description: 'total price vat included column header',
          defaultMessage: 'Total price vat included (€)',
        }),
      },
      billingInformation: {
        billingEmail: intl.formatMessage({
          description: 'sponsor purchases billing email label',
          defaultMessage: 'Billing email',
        }),
        pec: intl.formatMessage({
          description: 'sponsor purchases pec label',
          defaultMessage: 'PEC',
        }),
        invoiceToLabel: intl.formatMessage({
          description: 'sponsor purchases invoice to label',
          defaultMessage: 'Invoice To',
        }),
        billingName: intl.formatMessage({
          description: 'sponsor purchases billing name label',
          defaultMessage: 'Billing name',
        }),
        address: intl.formatMessage({
          description: 'sponsor purchases address label',
          defaultMessage: 'Address',
        }),
        postalCode: intl.formatMessage({
          description: 'sponsor purchases postal code label',
          defaultMessage: 'Postal code',
        }),
        city: intl.formatMessage({
          description: 'sponsor purchases city label',
          defaultMessage: 'City',
        }),
        province: intl.formatMessage({
          description: 'sponsor purchases province label',
          defaultMessage: 'Province',
        }),
        region: intl.formatMessage({
          description: 'sponsor purchases region label',
          defaultMessage: 'Region',
        }),
        country: intl.formatMessage({
          description: 'sponsor purchases country label',
          defaultMessage: 'Country',
        }),
        countryIsoCode: intl.formatMessage({
          description: 'sponsor purchases country iso code label',
          defaultMessage: 'Country ISO code',
        }),
        countryIsoAlpha3: intl.formatMessage({
          description: 'sponsor purchases country iso code alpha 3 label',
          defaultMessage: 'Country ISO code ALPHA3',
        }),
        taxCode: intl.formatMessage({
          description: 'sponsor purchases fiscal code label',
          defaultMessage: 'Fiscal Code',
        }),
        vatCode: intl.formatMessage({
          description: 'sponsor purchases vat code label',
          defaultMessage: 'Vat code',
        }),
        phone: intl.formatMessage({
          description: 'sponsor purchases phone label',
          defaultMessage: 'Phone',
        }),
        reference: intl.formatMessage({
          description: 'sponsor purchases reference label',
          defaultMessage: 'Reference',
        }),
        invoiceToPublicAuthority: intl.formatMessage({
          description: 'sponsor purchases invoice to public authority label',
          defaultMessage: 'Invoice to public authority',
        }),
        uniqueCode: intl.formatMessage({
          description: 'sponsor purchases unique code label',
          defaultMessage: 'Unique code',
        }),
        ipaCode: intl.formatMessage({
          description: 'sponsor purchases ipa code label',
          defaultMessage: 'Ipa code',
        }),
        isSplitPayment: intl.formatMessage({
          description: 'sponsor purchases split payment label',
          defaultMessage: 'Split payment',
        }),
        isVatExent: intl.formatMessage({
          description: 'sponsor purchases Vat exent label',
          defaultMessage: 'Vat exent',
        }),
        po: intl.formatMessage({
          description: 'sponsor purchases po label',
          defaultMessage: 'Po',
        }),
        cig: intl.formatMessage({
          description: 'sponsor purchases cig label',
          defaultMessage: 'Cig',
        }),
        cup: intl.formatMessage({
          description: 'sponsor purchases cup label',
          defaultMessage: 'Cup',
        }),
        tripletta: intl.formatMessage({
          description: 'sponsor purchases tripletta label',
          defaultMessage: 'Tripletta',
        }),
        venue: intl.formatMessage({
          description: 'sponsor purchases venue label',
          defaultMessage: 'Venue',
        }),
      },
    },
  }),
};

import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';
import {
  AimIconAndTextButton,
  CustomIntl,
  AimTypography,
  AimDataGrid,
  AimIconButton,
  useAimMediaQuery,
} from '@aim/components';
import { listGenericPayment } from './shared/CustomOrdersTableGqlHelper';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';
import translation from './shared/translation';
import { appState, utilities, constants } from '@aim/common';
import format from 'date-fns/format';

const { decodeDbNumber, encodeDbNumber } = utilities;

const OrderDetail = ({ label, value, style }) => (
  <div style={{ marginBottom: '2px', display: 'flex' }}>
    <AimTypography variant="h6" boxStyle={{ margin: 0, marginRight: 5 }}>
      {label}:
    </AimTypography>
    <AimTypography variant="text" boxStyle={{ margin: 0, ...style }}>
      {value}
    </AimTypography>
  </div>
);

const OrderCard = ({ item, i18n }) => {
  console.log(item);
  const cardStyle = {
    backgroundColor: '#F8F6FA',
    borderRadius: '4px',
    padding: '20px 16px',
    margin: '10px 0',
  };

  const headerStyle = {
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
    borderBottom: '1px solid #ddd',
    paddingBottom: '5px',
  };

  const details = [
    { label: i18n.table.labels.description, value: `${item.description}` },
    { label: i18n.table.labels.netPrice, value: `${item.netPrice}€` },
    { label: i18n.table.labels.vat, value: `${item.vat}%` },
    { label: i18n.table.labels.totalPrice, value: `${item.totalPrice}€` },
    { label: i18n.table.labels.paymentDate, value: item.paymentDate },
    {
      label: i18n.table.labels.paymentStatus,
      value: item.paymentStatus,
      style: {
        borderRadius: '16px',
        padding: '0 14px',
        display: 'inline-block',
        backgroundColor:
          item.paymentStatus === constants.PaymentStatuses.PENDING.key
            ? '#C9DEFF'
            : '#BEF6BE',
      },
    },
  ];

  return (
    <div style={cardStyle}>
      <AimTypography variant="h6" style={headerStyle} boxStyle={{ margin: 0 }}>
        #{item.orderId}
      </AimTypography>
      {details.map((detail, index) => (
        <OrderDetail
          key={index}
          label={detail.label}
          value={detail.value}
          style={detail.style || {}}
        />
      ))}
    </div>
  );
};

const CustomOrdersTable = () => {
  const { isMobile } = useAimMediaQuery();
  const intl = CustomIntl(useIntl());
  const i18n = translation.customOrdersTable(intl);
  const { sponsorId, eventId, participationId } = useParams();
  console.log('sponsorId', sponsorId);

  const [genericPaymetData, setGenericPaymetData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await listGenericPayment(eventId);
    if (res && res.items) {
      let filteredItems = [];
      if (sponsorId) {
        filteredItems = res.items.filter(
          (item) => item.sponsor && item.sponsor.id === sponsorId
        );
      } else if (participationId) {
        filteredItems = res.items.filter(
          (item) =>
            item.participation && item.participation.id === participationId
        );
      }
      const mappedData = filteredItems.map((item) => {
        console.log('item', item);
        const paymentDate = format(
          new Date(item.payment.updatedAt),
          'dd/MM/yyyy'
        );
        const isPending =
          item.payment.paymentStatus === constants.PaymentStatuses.PENDING.key;
        return {
          ...item,
          orderId: item.payment.paymentId,
          description: item.description,
          netPrice: decodeDbNumber(item.netAmount).toFixed(2),
          vat: decodeDbNumber(item.vatRate),
          totalPrice: decodeDbNumber(item.totalAmount).toFixed(2),
          paymentDate: !isPending ? paymentDate : '-',
          paymentStatus: item.payment.paymentStatus,
        };
      });

      setGenericPaymetData(mappedData);
    }
  };

  const loadColumns = ({ i18n }) => [
    {
      field: 'orderId',
      headerName: i18n.table.labels.orderId,
      minWidth: 250,
    },
    {
      field: 'description',
      headerName: i18n.table.labels.description,
      minWidth: 250,
    },
    {
      field: 'netPrice',
      headerName: i18n.table.labels.netPrice,
      minWidth: 250,
    },
    {
      field: 'vat',
      headerName: i18n.table.labels.vat,
      minWidth: 250,
    },
    {
      field: 'totalPrice',
      headerName: i18n.table.labels.totalPrice,
      minWidth: 250,
    },
    {
      field: 'paymentDate',
      headerName: i18n.table.labels.paymentDate,
      minWidth: 250,
    },
    {
      field: 'paymentStatus',
      headerName: i18n.table.labels.paymentStatus,
      minWidth: 250,
      renderCell: (params) => {
        const isPending =
          params.value === constants.PaymentStatuses.PENDING.key;
        const bgColor = isPending ? '#C9DEFF' : '#BEF6BE';
        const statusText = isPending
          ? i18n.table.paymentStatusPending
          : i18n.table.paymentStatusDone;
        return (
          <div
            style={{
              backgroundColor: bgColor,
              padding: '2px 8px',
              borderRadius: '10px',
              display: 'inline-block',
            }}
          >
            {statusText}
          </div>
        );
      },
    },
  ];

  const columns = useMemo(
    () =>
      loadColumns({
        i18n: i18n,
      }),
    [i18n]
  );

  return (
    <>
      <MainContainer>
        <InnerContainer>
          <div style={{ display: 'flex-root', marginTop: 20 }}>
            <Tooltip title={i18n.page.back.tooltip.label}>
              <AimIconAndTextButton
                variant="text"
                text="back"
                onClick={() => history.goBack()}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <AimTypography variant="h1">{i18n.page.title.label}</AimTypography>
          </div>
          {!isMobile ? (
            <div
              style={{
                flex: 1,
                marginBottom: 40,
                height: `calc(${
                  appState?.mainContainerSize?.getValue?.()?.height
                    ? `${appState?.mainContainerSize?.getValue?.()?.height}px`
                    : '100vh'
                } - 152px)`,
              }}
            >
              <AimDataGrid
                rows={genericPaymetData}
                columns={columns}
                tableName={'customOrders'}
                pinnedColumns={{ left: [], right: ['actions'] }}
              />
            </div>
          ) : (
            <div style={{ marginBottom: 40 }}>
              {genericPaymetData.map((item) => (
                <OrderCard key={item.orderId} item={item} i18n={i18n} />
              ))}
            </div>
          )}
        </InnerContainer>
      </MainContainer>
    </>
  );
};

export default CustomOrdersTable;

import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import {
  ThemeProvider,
  createTheme,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';

import { appState, MuiTheme } from '@aim/common';
import * as Langs from './../compiled-lang';
import App from './App';

const WebsiteRootComponent = () => {
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    const localeSubscription = appState.locale.subscribe((locale) => {
      setLocale(locale);
    });

    return () => {
      localeSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log('replace locale');

    console.log('locale', locale);
    const oldScript = document.getElementById('google-maps-script');

    console.log('oldScript', oldScript);

    const srcUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCXdMAYyPO5sYllepsvWeyVNOolSAQWPa4&libraries=places&language=${
      locale || 'en'
    }`;

    console.log('srcUrl', srcUrl);
    console.log('oldScript.parentNode', oldScript?.parentNode);

    if (oldScript?.src !== srcUrl) {
      oldScript &&
        document.head.contains(oldScript) &&
        document.head.removeChild(oldScript);
      delete window.google;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = srcUrl;
      document.head.appendChild(script);
    }
  }, [locale]);

  const theme = createTheme(MuiTheme);

  const generateClassName = createGenerateClassName({
    seed: 'aim-website',
  });

  return (
    <IntlProvider locale={locale} messages={Langs[locale]} onError={() => {}}>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          <App />
        </StylesProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default WebsiteRootComponent;

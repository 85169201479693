import React, { useEffect, useState } from 'react';
import {
  AimDialog,
  AimTypography,
  AimIconAndTextButton,
  AimDragDropFileUploader,
  AimTextField,
  AimRichText,
  useAimMediaQuery,
  styled,
} from '@aim/components';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import GetAppIcon from '@material-ui/icons/GetApp';

import { appState, fileHelper, constants } from '@aim/common';
import Grid from '@material-ui/core/Grid';
import { updateParticipationServices } from '../facultyGqlHelper';
import DownloadArea from '../DownloadArea';

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const AccreditationMaterialDialog = ({
  dialogState,
  typology,
  participation,
  onClose,
  onSuccess,
  onError,
  i18n,
}) => {
  const _i18n = i18n.dialogs.accreditationMaterial;
  const { isMobile } = useAimMediaQuery();
  const { downloadFromS3, createFile, deleteFile } = fileHelper;

  const [files, setFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [fiscalCode, setFiscalCode] = useState();
  // const [templatesFiles, setTemplatesFiles] = useState();
  // const [participation, setParticipation] = useState();
  const formControlStyle = { width: 'calc(100% - 20px)' };

  const [isEditable, setIsEditable] = useState(false);

  // Effects
  useEffect(() => {
    if (dialogState) {
      const rawAccreditation =
        dialogState?.data?.accreditationMaterialFacultyResponse;

      let facultyResponse;
      if (rawAccreditation)
        facultyResponse = JSON.parse(rawAccreditation) || {};

      setIsEditable(
        facultyResponse?.responseStatus !==
          constants.FacultyResponseStatus.PUBLISHED
      );
      setFiscalCode(facultyResponse?.fiscalCode);

      const facultyAttachmentsResponse = dialogState?.data
        ?.facultyAttachmentsResponse
        ? JSON.parse(dialogState?.data?.facultyAttachmentsResponse)
        : [];

      if (facultyAttachmentsResponse?.length) {
        const accreditationFiles = facultyAttachmentsResponse
          ? facultyAttachmentsResponse.filter(
              (fd) =>
                fd.type === typology.ACCREDITATION_MATERIAL_CV.id ||
                fd.type === typology.ACCREDITATION_MATERIAL_COI.id ||
                fd.type === typology.ACCREDITATION_MATERIAL_SURVEY.id
            )
          : [];

        setFiles(accreditationFiles);
      }

      // setParticipation(dialogState?.data?.participation);
      // if (dialogState?.accreditationMaterialTemplates) {
      //   const templates = dialogState?.accreditationMaterialTemplates.map(
      //     (file) => ({
      //       name: file.name,
      //       extension: file.extension,
      //     })
      //   );
      //   setTemplatesFiles(templates);
      // }
    }
  }, [dialogState.isOpen]);

  // Helpers
  const deleteAndUploadFiles = async () => {
    const dirPath = `events/${dialogState?.eventId}/participations/${participation.id}`;
    // files to remove
    const nextFilesToRemove = [...new Set(filesToRemove.map((t) => t.file))];
    for (let i = 0; i < nextFilesToRemove.length; i++) {
      const file = nextFilesToRemove[i];
      if (file.id) {
        await fileHelper.deleteFile({
          dirPath,
          fileData: file,
          useLoader: false,
        });
      }
    }
    setFilesToRemove([]);

    // files to add
    // const nextFiles = [...new Set(files.map((t) => t.file))];
    const newFilesFormatted = files.filter((fd) => !fd.file.id);
    const nextNewFilesFormatted = await Promise.all(
      newFilesFormatted.map(async (fileFormatted) => {
        const originalName =
          fileFormatted?.file?.name || new Date().getTime().toString();

        const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
        const extension = matchExtension ? matchExtension[0] : '';

        const fileSize = fileFormatted?.file?.size;

        const input = {
          originalName: originalName,
          extension: extension,
          size: Number(fileSize) || 0,
        };

        const fileId = await fileHelper.createFile({
          dirPath,
          fileData: input,
          localFile: fileFormatted?.file,
        });
        const fileInput = { ...input, name: originalName, id: fileId };

        const nextFileFormatted = { ...fileFormatted, file: fileInput };
        return nextFileFormatted;
      })
    );
    return nextNewFilesFormatted;
  };

  const onSubmit = async (status) => {
    showLoader();
    const filesToRemoveCopy = [...filesToRemove];
    const facultyAttachmentsFiles = dialogState?.data
      ?.facultyAttachmentsResponse
      ? JSON.parse(dialogState.data.facultyAttachmentsResponse)
      : [];

    let filesNotRemoved = [...facultyAttachmentsFiles];
    if (filesToRemoveCopy?.length) {
      const filesToRemoveCopyIds = [
        ...new Set(filesToRemoveCopy.map((fd) => fd.file.id)),
      ];
      filesNotRemoved = facultyAttachmentsFiles.filter(
        (fd) =>
          !filesToRemoveCopyIds?.length ||
          !filesToRemoveCopyIds.includes(fd?.file?.id)
      );
    }

    // new files to upload
    const newFilesFormatted = await deleteAndUploadFiles();

    const accreditationMaterialFacultyResponse = JSON.stringify({
      fiscalCode,
      responseStatus: status,
    });

    const facultyAttachmentsResponse = JSON.stringify([
      ...filesNotRemoved,
      ...newFilesFormatted,
    ]);

    try {
      await updateParticipationServices({
        id: dialogState?.data.id,
        accreditationMaterialFacultyResponse,
        facultyAttachmentsResponse,
      });
      hideLoader();
      onSuccess();
      onClose();
    } catch (err) {
      console.error('err', err);
      onError();
    }
  };

  const uploadFiles = async (f, key, topic) => {
    const file = f[0];
    const nextFiles = {
      ...files,
      [key]: [
        ...(files[key] ? files[key] : []),
        {
          type: typology.PARTICIPATION_FILE.id,
          topic,
          file,
        },
      ],
    };
    setFiles(nextFiles);
  };

  const removeFile = async (file) => {
    setFilesToRemove([
      ...filesToRemove,
      {
        file,
      },
    ]);
    const nextUpdatedFiles = files.filter((fd) =>
      file.id ? fd.file.id !== file.id : fd.file.name !== file.name
    );
    setFiles(nextUpdatedFiles);
  };

  const downloadFile = async (item, key) => {
    if (item.id) {
      await downloadFromS3({
        dirPath: `events/${dialogState?.eventId}/faculty/accreditationMaterial/${key}`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const downloadTemplateFile = async (item, participation, key) => {
    if (item.id) {
      await downloadFromS3({
        dirPath: `events/${dialogState?.eventId}/participations/${participation.id}/faculty/templates`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const renderButtons = () => {
    return (
      <ButtonContainer>
        <AimIconAndTextButton
          variant="greyFill"
          text={_i18n.buttons.cancel}
          onClick={onClose}
        >
          <ReplayIcon />
        </AimIconAndTextButton>
        <AimIconAndTextButton
          text={_i18n.buttons.confirm}
          variant="greenFill"
          onClick={() => {
            onSubmit(constants.FacultyResponseStatus.DRAFT);
          }}
          disabled={!isEditable}
        >
          <CheckIcon />
        </AimIconAndTextButton>
      </ButtonContainer>
    );
  };

  const formattingFiles = ({ filesToFormat, type, topic }) => {
    return filesToFormat.map((f) => ({
      type,
      topic,
      file: f,
    }));
  };

  if (!dialogState) return null;

  return (
    <AimDialog
      open={dialogState.isOpen || false}
      title={_i18n.title}
      onClose={onClose}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
      style={{
        width: isMobile ? '100%' : '85%',
        minWidth: '300px',
        maxWidth: '100%',
        margin: '0 auto',
      }}
      PaperProps={{
        style: {
          ...(isMobile && { margin: 10 }),
          paddingRight: isMobile ? 10 : 30,
          paddingLeft: isMobile ? 10 : 30,
          paddingTop: isMobile ? 10 : 20,
          paddingBottom: isMobile ? 10 : 30,
          maxWidth: '80%',
          ...(!isMobile && { minWidth: 600 }),
        },
      }}
    >
      <Grid container>
        {dialogState?.accreditationMaterialDescription && (
          <Grid item xs={12}>
            <AimRichText
              value={dialogState?.accreditationMaterialDescription}
              readOnly
              variant="white"
              editorStyleOverride={{
                minHeight: 'auto',
                maxHeight: 'fit-content',
                overflow: 'hidden !important',
              }}
            />
          </Grid>
        )}
        {dialogState?.data?.accreditationMaterialFiscalCodeIncluded && (
          <Grid item xs={12}>
            <AimTextField
              label={_i18n.sectionTitles.fiscalCode}
              formControlStyle={formControlStyle}
              value={fiscalCode}
              onChange={(e) => setFiscalCode(e.target.value)}
              disabled={!isEditable}
            />
          </Grid>
        )}
        {dialogState?.data?.accreditationMaterialCVIncluded && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.accreditationMaterialCV}
            </AimTypography>
            <AimTypography variant="text">
              {_i18n.sectionTitles.accreditationMaterialCVFormatAccepted}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                {...{
                  dragTitle: i18n.upload.dragAndDrop.title,
                  dragSubTitle: i18n.upload.dragAndDrop.subTitle,
                  clickText: i18n.upload.dragAndDrop.clickText,
                  onUploadFile: (filesToAdd) =>
                    setFiles([
                      ...files,
                      ...formattingFiles({
                        filesToFormat: filesToAdd,
                        type: typology.ACCREDITATION_MATERIAL_CV.id,
                        topic: _i18n.sectionTitles.accreditationMaterialCV,
                      }),
                    ]),
                  onRemove: removeFile,
                  onDownload: downloadFile,
                  files: [
                    ...new Set(
                      files
                        .filter(
                          (fd) =>
                            fd.type === typology.ACCREDITATION_MATERIAL_CV.id
                        )
                        .map((t) => t.file)
                    ),
                  ],
                  maxSize: '5242880',
                  disabledRemove: !isEditable,
                  disabledDownload: !isEditable,
                  dropzoneAdditionalProps: {
                    multiple: true,
                    disabled: !isEditable,
                  },
                }}
              />
            </div>
          </Grid>
        )}
        {dialogState?.data?.accreditationMaterialCOIIncluded && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.accreditationMaterialCOI}
            </AimTypography>
            <AimTypography variant="text">
              {_i18n.sectionTitles.accreditationMaterialCOIFormatAccepted}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                {...{
                  dragTitle: i18n.upload.dragAndDrop.title,
                  dragSubTitle: i18n.upload.dragAndDrop.subTitle,
                  clickText: i18n.upload.dragAndDrop.clickText,
                  onUploadFile: (filesToAdd) =>
                    setFiles([
                      ...files,
                      ...formattingFiles({
                        filesToFormat: filesToAdd,
                        type: typology.ACCREDITATION_MATERIAL_COI.id,
                        topic: _i18n.sectionTitles.accreditationMaterialCOI,
                      }),
                    ]),
                  onRemove: removeFile,
                  onDownload: downloadFile,
                  files: [
                    ...new Set(
                      files
                        .filter(
                          (fd) =>
                            fd.type === typology.ACCREDITATION_MATERIAL_COI.id
                        )
                        .map((t) => t.file)
                    ),
                  ],
                  maxSize: '5242880',
                  disabledRemove: !isEditable,
                  disabledDownload: !isEditable,
                  dropzoneAdditionalProps: {
                    multiple: true,
                    disabled: !isEditable,
                  },
                }}
              />
            </div>
          </Grid>
        )}
        {dialogState?.data?.accreditationMaterialSurveyIncluded && (
          <Grid item xs={12}>
            <AimTypography variant="h4">
              {_i18n.sectionTitles.accreditationMaterialSurvey}
            </AimTypography>
            <AimTypography variant="text">
              {_i18n.sectionTitles.accreditationMaterialSurveyFormatAccepted}
            </AimTypography>
            <div style={{ backgroundColor: 'white', margin: 5 }}>
              <AimDragDropFileUploader
                {...{
                  dragTitle: i18n.upload.dragAndDrop.title,
                  dragSubTitle: i18n.upload.dragAndDrop.subTitle,
                  clickText: i18n.upload.dragAndDrop.clickText,
                  onUploadFile: (filesToAdd) =>
                    setFiles([
                      ...files,
                      ...formattingFiles({
                        filesToFormat: filesToAdd,
                        type: typology.ACCREDITATION_MATERIAL_SURVEY.id,
                        topic: _i18n.sectionTitles.accreditationMaterialSurvey,
                      }),
                    ]),
                  onRemove: removeFile,
                  onDownload: downloadFile,
                  files: [
                    ...new Set(
                      files
                        .filter(
                          (fd) =>
                            fd.type ===
                            typology.ACCREDITATION_MATERIAL_SURVEY.id
                        )
                        .map((t) => t.file)
                    ),
                  ],
                  maxSize: '5242880',
                  disabledRemove: !isEditable,
                  disabledDownload: !isEditable,
                  dropzoneAdditionalProps: {
                    multiple: true,
                    disabled: !isEditable,
                  },
                }}
              />
            </div>
          </Grid>
        )}
        {dialogState?.accreditationMaterialTemplates && participation && (
          <Grid item xs={12}>
            <DownloadArea
              files={dialogState?.accreditationMaterialTemplates}
              dirPath={`events/${dialogState?.eventId}/participations/${participation.id}/faculty/templates`}
            />
            {/* dialogState?.accreditationMaterialTemplates.map((file) => (
               <AimIconAndTextButton
                   key={file.id}
                   variant={'greyFill'}
                   text={file.originalName}
                   onClick={() => downloadTemplateFile(file, participation)}
                 >
                   <GetAppIcon />
                 </AimIconAndTextButton>
               )) */}
          </Grid>
        )}
      </Grid>
      {renderButtons()}
    </AimDialog>
  );
};

export default AccreditationMaterialDialog;

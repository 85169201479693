const translation = {
  wrapper: (intl) => ({
    back: intl.formatMessage({
      description: 'back',
      defaultMessage: 'Back',
    }),
    anagraphic: intl.formatMessage({
      description: 'anagraphic',
      defaultMessage: 'Anagraphic',
    }),
    registration: intl.formatMessage({
      description: 'registration',
      defaultMessage: 'Registration',
    }),
    socialEvent: intl.formatMessage({
      description: 'social event',
      defaultMessage: 'Social event',
    }),
    scientificEvent: intl.formatMessage({
      description: 'scientific event',
      defaultMessage: 'Scientific event',
    }),
    save: intl.formatMessage({
      description: 'save button',
      defaultMessage: 'Save',
    }),
    request: intl.formatMessage({
      description: 'send modification request button',
      defaultMessage: 'Send request',
    }),
  }),
  ticketForm: (intl) => ({
    profile: intl.formatMessage({
      description: 'profile',
      defaultMessage: 'Profile',
    }),
    participationMode: intl.formatMessage({
      description: 'participation mode',
      defaultMessage: 'Participation mode',
    }),
    feeDateRange: intl.formatMessage({
      description: 'registration fee',
      defaultMessage: 'Registration fee',
    }),
    totalAmount: intl.formatMessage({
      description: 'total amount',
      defaultMessage: 'Total amount',
    }),
    availalable: intl.formatMessage({
      description: 'available',
      defaultMessage: 'Available',
    }),
  }),
  additionalServicesForm: (intl) => ({
    socialEvent: intl.formatMessage({
      description: 'social event',
      defaultMessage: 'Social event',
    }),
    scientificEvent: intl.formatMessage({
      description: 'scientific event',
      defaultMessage: 'Scientific event',
    }),
    totalAmount: intl.formatMessage({
      description: 'total amount',
      defaultMessage: 'Total amount',
    }),
  }),
};
export default translation;

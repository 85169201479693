import { styled, theme } from '@aim/components';

export const MainContainer = styled('div')(({ isMobileOnly }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: isMobileOnly ? '100%' : '80%',
  padding: '10px',
  background: theme.colors.greyScale.white,
}));

export const Container = styled('div')({
  backgroundColor: theme.colors.greyScale.backgroundGrey,
});

export const Card = styled('div')({
  background: theme.colors.greyScale.white,
  boxShadow: '0 0 black !important',
  borderRadius: '0px !important',
  width: '100%',
  margin: 10,
  padding: 10,
});

export const CardHeader = styled('div')({
  borderBottom: `1px solid ${theme.colors.greyScale.black}`,
  paddingBottom: 2,
  paddingLeft: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const CardStatus = styled('div')({
  width: 25,
  height: 25,
  backgroundColor: '#29E129',
  borderRadius: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
});

export const TabWrapper = styled('div')({
  maxHeight: '115px',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  overflowX: 'auto',
  WebkitOverflowScrolling: 'touch',
  marginBottom: '10px',
  '&::-webkit-scrollbar': {
    height: '6px !important',
  },
});

export const ProductGreyCard = styled('div')({
  background: theme.colors.greyScale.backgroundGrey,
  borderRadius: 4,
  padding: '22px 30px',
  marginBottom: '10px',
});

export const ProductCardDivider = styled('div')({
  width: '100%',
  height: '1px',
  backgroundColor: '#DDDCE3',
});

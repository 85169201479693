import React, { useState, useEffect } from 'react';
import { aws, appState, fileHelper, constants } from '@aim/common';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { format, isEqual } from 'date-fns';

import ArrowForward from '@material-ui/icons/ArrowForward';

import {
  AimTypography,
  AimIconAndTextButton,
  CustomIntl,
  richTextToHTML,
  useAimMediaQuery,
} from '@aim/components';

import image from './../../../assets/images/landing-image.png';
import { translation } from './translation';

const ucwords = (str) => {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

const languagesCodes = { english: 'en', italian: 'it' };

const renderDates = ({ start, end, eventInfo, renderStart, renderEnd }) => {
  let __language = eventInfo?.language?.name || 'English';
  __language = __language.toLowerCase();
  const language = languagesCodes[__language] || 'en';
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();
  const formatter = new Intl.DateTimeFormat(language, { month: 'long' });
  const startMonth = ucwords(formatter.format(start));
  const endMonth = ucwords(formatter.format(end));

  if (!renderStart && !renderEnd) {
    return `${start.getDate()} ${
      startMonth !== endMonth || startYear !== endYear ? startMonth : ''
    } ${
      startYear !== endYear ? startYear : ''
    } - ${end.getDate()} ${endMonth} ${endYear}`;
  }

  if (renderStart) {
    return `${start.getDate()} ${
      startMonth !== endMonth || startYear !== endYear ? startMonth : ''
    } ${startYear !== endYear ? startYear : ''}`;
  }

  if (renderEnd) {
    return `${end.getDate()} ${endMonth} ${endYear}`;
  }
};

export const EventHeader = ({
  eventId,
  name,
  start,
  end,
  participant,
  grants,
  secondsRemainingBeforeActivation = 0,
}) => {
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.landing(intl);

  const [eventConfiguration, setEventConfiguration] = useState({});
  const { getAwsS3Link } = fileHelper;
  const [coverImage, setCoverImage] = useState(null);
  const [eventInfo, setEventInfo] = useState({});
  const [participation] = useState(
    appState.user.getValue()?.userAndParticipation?.participation
  );

  const { isMobile, isMobileOnly } = useAimMediaQuery();

  useEffect(() => {
    const subscription = appState.eventConfiguration.subscribe(
      setEventConfiguration
    );
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const _subscription = appState.eventInfo.subscribe(setEventInfo);
    return () => _subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const getImages = async () => {
      if (eventConfiguration?.welcomePageCoverImage) {
        const link = await getAwsS3Link(
          `events/${eventId}/configuration/welcomePageCoverImage`,
          eventConfiguration.welcomePageCoverImage
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setCoverImage(convertedLink);
      }
    };

    getImages();
  }, [eventConfiguration]);

  const isStartEqEnd = isEqual(start, end);
  const startFormatted = format(start, 'dd MMMM');
  const dates = isStartEqEnd
    ? startFormatted
    : `${startFormatted} - ${format(end, 'dd MMMM')}`;

  // to restore with new check for partipations
  // Valentina: ha senso nascondere il bottone solo se non ho configurato nulla quindi neanche la parte di partecipazione ecc.. però non dipende dai servizi attivi perché per aifa, accreditation material, l'agenda e la conferma di partecipazione non ho servizi da attivare, quindi quel controllo va modificato
  const isAtLeastOneActiveService = () => {
    if (eventInfo?.availableServices && eventConfiguration) {
      const abstractActiveService =
        eventConfiguration.welcomePageSendAbstract &&
        eventInfo.availableServices.includes(constants.Services.ABSTRACT.key);

      const allotmentActiveService =
        eventConfiguration.welcomePageAllotment &&
        eventInfo.availableServices.includes(constants.Services.ALLOTMENT.key);

      const abstractGrantActiveService =
        eventConfiguration.welcomePageAbstractGrant &&
        grants.length &&
        grants.findIndex((x) => x.type === 'abstract') > -1 &&
        eventInfo.availableServices.includes(constants.Services.GRANT.key);

      const educationalGrantActiveService =
        eventConfiguration.welcomePageEducationalGrant &&
        grants.length &&
        grants.findIndex((x) => x.type === 'educational') > -1 &&
        eventInfo.availableServices.includes(constants.Services.GRANT.key);

      const scientificEventActiveService =
        eventConfiguration.welcomePageScientificEvent;
      const socialEventActiveService =
        eventConfiguration.welcomePageSocialEvent;

      const visaActiveService =
        eventConfiguration.welcomePageVisa &&
        eventInfo.availableServices.includes(constants.Services.VISA.key);

      return (
        eventConfiguration.welcomePageBuyTicket ||
        abstractActiveService ||
        abstractGrantActiveService ||
        educationalGrantActiveService ||
        allotmentActiveService ||
        scientificEventActiveService ||
        socialEventActiveService ||
        visaActiveService
      );
    }
    return false;
  };

  return (
    <div
      style={{
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)), url(${
          coverImage || image
        })`,
        width: window?.innerWidth || 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? 45 : 80,
        textAlign: 'center',
        // ...(secondsRemainingBeforeActivation > 0 && { height: '100%' }),
        height: '100%',
      }}
    >
      <AimTypography
        variant="h1"
        boxStyle={{
          fontWeight: '600',
          fontSize: isMobile ? '3.5rem' : '4.5rem',
          color: eventConfiguration?.welcomePageTitleColor || 'white',
          textAlign: 'center',
          wordWrap: 'break-word',
          width: window?.innerWidth || 100,
          margin: 0,
        }}
      >
        {eventConfiguration?.welcomePageTitle || name}
      </AimTypography>
      <AimTypography
        style={{
          fontWeight: '600',
          fontSize: isMobile ? '1.5rem' : '2.5rem',
          color: 'white',
          marginTop: 0,
          textAlign: 'center',
        }}
      >
        {eventConfiguration.eventDatesEnabled ? (
          <>
            {!isMobileOnly ? (
              renderDates({
                start,
                end,
                eventInfo,
                isMobileOnly,
              })
            ) : (
              <>
                {renderDates({
                  start,
                  end,
                  eventInfo,
                  isMobileOnly,
                  renderStart: true,
                })}{' '}
                -<br />
                {renderDates({
                  start,
                  end,
                  eventInfo,
                  isMobileOnly,
                  renderEnd: true,
                })}
              </>
            )}
          </>
        ) : null}
      </AimTypography>
      {eventConfiguration?.welcomePageDescription && (
        <div
          style={{
            color: eventConfiguration?.welcomePageDescriptionColor || 'white',
            fontSize: '16px',
          }}
          dangerouslySetInnerHTML={{
            __html: richTextToHTML(
              JSON.parse(eventConfiguration.welcomePageDescription)
            ),
          }}
        />
      )}
      <>
        {secondsRemainingBeforeActivation <= 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            {/* {participant && participant.isSpeaker && (
              // isAtLeastOneActiveService() && (
              <AimIconAndTextButton
                isUpperCase
                textColor="white"
                style={{
                  color: 'white',
                  marginTop: isMobile ? '1rem' : '8rem',
                }}
                text={i18n.buttons.plan}
                onClick={() => history.push(`/events/${eventId}/faculty`)}
              >
                <ArrowForward />
              </AimIconAndTextButton>
            )} */}
            {eventConfiguration.welcomePageJoinEvent ? (
              <AimIconAndTextButton
                isUpperCase
                textColor="white"
                style={{ color: 'white', marginTop: '8rem' }}
                text={
                  eventConfiguration.welcomePageJoinEventLabelItem ||
                  i18n.buttons.joinEvent
                }
                onClick={() => history.push(`/events/${eventId}/homepage`)}
              ></AimIconAndTextButton>
            ) : null}
            {eventConfiguration.welcomePageMyArea &&
            !participation?.sponsorStaff ? (
              <AimIconAndTextButton
                isUpperCase
                textColor="white"
                style={{
                  color: 'white',
                  marginTop: isMobile ? '1rem' : '8rem',
                }}
                text={
                  eventConfiguration.welcomePageMyAreaLabelItem ||
                  i18n.buttons.myArea
                }
                onClick={() =>
                  history.push(`/events/${eventId}/user/${participant.id}`)
                }
              ></AimIconAndTextButton>
            ) : null}
          </div>
        )}
      </>
    </div>
  );
};

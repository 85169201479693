import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { appState, constants } from '@aim/common';
import {
  theme,
  ProgramTemplate,
  CustomIntl,
  styled,
  useAimMediaQuery,
  AimTypography,
} from '@aim/components';

import { HomeContainer } from './home/style';

import { getAgendaSessionsByEvent } from './program/programGqlHelper';

const homeContainerStyle = {
  backgroundColor: theme.colors.greyScale.white,
};

const PageContent = styled('div')(({ isMobileOnly }) => ({
  // ...(!isMobileOnly && { width: 1280 }),
  width: isMobileOnly ? '95%' : '70%',
  minHeight: 'calc(100vh - 260px)',
}));

const Program = () => {
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const { isMobileOnly } = useAimMediaQuery();

  const [
    participationAgendaSessions,
    setParticipationAgendaSessions,
  ] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const sessions = await getAgendaSessionsByEvent(
        eventId,
        appState.user.getValue()?.userAndParticipation?.participation?.isSpeaker
          ? constants.AgendaTypes.SPEAKERS.queryField
          : constants.AgendaTypes.AGENDA.queryField
      );
      setParticipationAgendaSessions(sessions);
    };

    fetchData();
  }, []);

  return (
    <HomeContainer style={homeContainerStyle}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <PageContent {...{ isMobileOnly }}>
          {participationAgendaSessions && (
            <ProgramTemplate
              intl={intl}
              sessions={participationAgendaSessions}
              eventId={eventId}
              previewPdf={false}
              title={'Programma'}
              hideDownloadPdfAndTitle={false}
            />
          )}
        </PageContent>
      </div>
    </HomeContainer>
  );
};

export default Program;

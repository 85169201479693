import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { constants, utilities } from '@aim/common';
import {
  AimSelectForm,
  AimSelectMenuItem,
  AimTypography,
  CustomIntl,
  theme,
  styled,
} from '@aim/components';
import translation from './translation';
import { getEventData } from './gqlHelper';
import { isEqual, isWithinInterval } from 'date-fns';
import { endOfDay } from 'date-fns';

const formControlStyle = { width: 'calc(100% - 10px' };

const propsPriceNamesByParticipationMode = {
  [constants.EventTypes.PHYSICAL.id]: 'priceOnSite',
  [constants.EventTypes.VIRTUAL.id]: 'priceOnAir',
};

const CustomFormControl = styled(({ m = 1, p, ...other }) => (
  <FormControl {...other} />
))({
  margin: (props) => theme.spacing(props.m || 0),
  padding: (props) => (props.p ? theme.spacing(props.p) : undefined),
  minWidth: 120,
  flex: 1,
});

const getFeeDateRangesOfSelectedProfile = (
  feeDateRanges,
  profileBrackets,
  participationMode,
  availableByProfileFeeDateRangeAndTypology,
  profile,
  i18n
) => {
  const feeDateRangesOfProfile = feeDateRanges
    .map((dateRange) => {
      const found = profileBrackets.find((pfb) => {
        return (
          pfb?.[propsPriceNamesByParticipationMode?.[participationMode]] >= 0 &&
          pfb.feeBracket.feeDateRange.id === dateRange.id &&
          pfb.profileFeeConfiguration?.isActive
        );
      });
      if (found) {
        const available =
          availableByProfileFeeDateRangeAndTypology?.[
            `${profile}-${dateRange?.id}-${participationMode}`
          ];
        return {
          ...dateRange,
          label: `${dateRange.label}${
            available?.remainingPlaces > 0
              ? ` (${available.remainingPlaces} ${i18n.availalable})`
              : ''
          }`,
        };
      } else {
        return null;
      }
    })
    .filter((f) => f);
  return feeDateRangesOfProfile;
};

const setPriceValue = ({
  setValue,
  feeDateRanges,
  profileBrackets,
  participationType,
  feeDateRange,
  vat,
  onProfileFeeBracketIdChanged,
}) => {
  try {
    if (!profileBrackets.length || !feeDateRange || !participationType) return;
    const feeDateRangeObj = feeDateRanges.find(
      (fdr) => fdr.id === feeDateRange
    );
    const bracket = profileBrackets.find((profileFeeFracket) => {
      // find feebracket (con il daterange del partecipante)
      const isStartEqual = isEqual(
        new Date(feeDateRangeObj.start),
        new Date(profileFeeFracket.feeBracket.feeDateRange.start)
      );
      const isEndEqual = isEqual(
        new Date(feeDateRangeObj.end),
        new Date(profileFeeFracket.feeBracket.feeDateRange.end)
      );
      return isStartEqual && isEndEqual;
    });

    if (bracket) {
      if (participationType === constants.EventTypes.PHYSICAL.id) {
        const netPrice = bracket.priceOnSite < 0 ? 0 : bracket.priceOnSite;
        setValue(`price`, utilities.vatCalc(netPrice, vat?.vatRate));
      } else if (participationType === constants.EventTypes.VIRTUAL.id) {
        const netPrice = bracket.priceOnAir < 0 ? 0 : bracket.priceOnAir;
        setValue(`price`, utilities.vatCalc(netPrice, vat?.vatRate));
      }
      setValue(`profileFeeBracketId`, bracket.id);
      onProfileFeeBracketIdChanged(bracket.id);
    } else {
      onProfileFeeBracketIdChanged('');
    }
  } catch (error) {
    console.error(error);
  }
};

const TicketForm = ({
  ticketFormRef,
  onProfileChanged,
  onParticipationModeChanged,
  onProfileFeeBracketIdChanged,
  participation,
  availableByProfileFeeDateRangeAndTypology,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.ticketForm(intl);
  const { eventId, cluster, clusterId } = useParams();
  const [eventData, setEventData] = useState({ profiles: [] });
  const [feeDateRanges, setFeeDateRanges] = useState([]);
  const [profileBrackets, setProfileBrackets] = useState([]);
  const [participationModes, setParticipationModes] = useState([]);
  const {
    control,
    setValue,
    watch,
    trigger,
    errors,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      feeDateRange: '',
      profile: '',
      type: '',
      price: '',
      profileFeeBracketId: '',
    },
  });

  const watchProfile = watch(`profile`);
  const watchParticipationMode = watch(`type`);
  const watchFeeDateRange = watch(`feeDateRange`);

  useEffect(() => {
    ticketFormRef.current = { trigger, getValues };
  }, [trigger, getValues]);

  useEffect(() => {
    const loadData = async () => {
      const eventData = await getEventData(eventId, cluster, clusterId);
      setEventData(eventData);
      reset({
        profile: participation.participationProfileId,
        type: participation.type,
        feeDateRange: participation.participationFeeDateRangeId,
      });
    };
    loadData();
  }, [participation]);

  useEffect(() => {
    if (!eventData || !watchProfile) return;
    const profileObj = eventData?.profiles.find((p) => p.id === watchProfile);
    const baseDate = new Date();
    const brackets = profileObj.profileFeeBrackets.items.filter(
      (profileFeeFracket) => {
        // find feebracket (in base allo scaglione)
        const isInDatesInterval = isWithinInterval(baseDate, {
          start: new Date(profileFeeFracket.feeBracket.feeBracketModel.start),
          end: endOfDay(
            new Date(profileFeeFracket.feeBracket.feeBracketModel.end)
          ),
        });
        return isInDatesInterval;
      }
    );
    setProfileBrackets(brackets);
    const nextParticipationModes = [];
    brackets.filter((b) => b.priceOnSite >= 0).length &&
      nextParticipationModes.push(constants.EventTypes.PHYSICAL);
    brackets.filter((b) => b.priceOnAir >= 0).length &&
      nextParticipationModes.push(constants.EventTypes.VIRTUAL);
    setParticipationModes(nextParticipationModes);
    if (nextParticipationModes.length === 1) {
      setValue(`type`, nextParticipationModes[0].id);
    }
  }, [eventData, watchProfile]);

  useEffect(() => {
    onProfileChanged(watchProfile);
  }, [watchProfile]);

  useEffect(() => {
    onParticipationModeChanged(watchParticipationMode);
  }, [watchParticipationMode]);

  useEffect(() => {
    if (
      !eventData ||
      !profileBrackets.length ||
      !watchParticipationMode ||
      !watchProfile
    )
      return;

    const feeDateRangesOfProfile = getFeeDateRangesOfSelectedProfile(
      eventData?.feeDateRanges,
      profileBrackets,
      watchParticipationMode,
      availableByProfileFeeDateRangeAndTypology,
      watchProfile,
      i18n
    );

    setFeeDateRanges(feeDateRangesOfProfile);
    if (feeDateRangesOfProfile.length === 1) {
      setValue(`feeDateRange`, feeDateRangesOfProfile[0].id);
    }
  }, [eventData, profileBrackets, watchParticipationMode, watchProfile]);

  useEffect(() => {
    setPriceValue({
      setValue,
      feeDateRanges: eventData?.feeDateRanges,
      profileBrackets,
      participationType: watchParticipationMode,
      feeDateRange: watchFeeDateRange,
      vat: eventData?.vat,
      onProfileFeeBracketIdChanged,
    });
  }, [profileBrackets, watchParticipationMode, watchFeeDateRange, eventData]);

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <AimSelectForm
          p={1}
          errors={errors}
          selectVariant="secondary"
          control={control}
          name="profile"
          label={i18n.profile}
          formControlStyle={formControlStyle}
          isRequired
        >
          {eventData?.profiles.map((item) => (
            <AimSelectMenuItem key={item.id} value={item.id}>
              {item.name}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
      </Grid>
      <Grid item xs={12} sm={6}>
        <AimSelectForm
          p={1}
          errors={errors}
          selectVariant="secondary"
          control={control}
          name="type"
          label={i18n.participationMode}
          formControlStyle={formControlStyle}
          disabled={!participationModes.length}
          isRequired
        >
          {participationModes.map((eventType) => (
            <AimSelectMenuItem key={eventType.id} value={eventType.id}>
              {eventType.label(intl)}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
      </Grid>
      <Grid item xs={12} sm={6}>
        <AimSelectForm
          p={1}
          errors={errors}
          selectVariant="secondary"
          control={control}
          name="feeDateRange"
          label={i18n.feeDateRange}
          formControlStyle={formControlStyle}
          disabled={!feeDateRanges}
          isRequired
        >
          {feeDateRanges?.map((item) => (
            <AimSelectMenuItem key={item.id} value={item.id}>
              {item.label}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="price"
          control={control}
          render={(props) => {
            return (
              <CustomFormControl
                style={{ ...formControlStyle, margin: 8, padding: 8 }}
              >
                <FormLabel
                  style={{
                    marginBottom: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 14,
                    fontFamily: 'hind',
                    '&.MuiFormLabel-root.Mui-focused': {
                      color: 'black',
                    },
                  }}
                >
                  {i18n.totalAmount}
                </FormLabel>
                <AimTypography
                  variant="text"
                  style={{
                    backgroundColor: 'white',
                    height: 48,
                    borderRadius: 5,
                    padding: '0 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {utilities.formatNumber(utilities.safeNum(props.value))}
                </AimTypography>
              </CustomFormControl>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TicketForm;

import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  theme,
  AimSnackbarSeverity,
  AimSnackbar,
  BillingInformations,
} from '@aim/components';

import { translation } from './translation';
import {
  getAdditionalService,
  getProductsByClientIdServiceId,
  getEventGateway,
} from './gqlHelper';

import { appState, constants, utilities } from '@aim/common';

const { formatNumber, decodeDbNumber, encodeDbNumber, percentage } = utilities;

const GeneralInfo = ({
  i18n,
  additionalService,
  participation,
  vatRate1,
  vatRate2,
}) => {
  const netPrice = additionalService?.amount1 + additionalService?.amount2;

  const vat =
    percentage(vatRate1, additionalService?.amount1) +
    percentage(vatRate2, additionalService?.amount2);
  return (
    (additionalService && participation && (
      <div
        style={{
          background: 'white',
          borderRadius: 3,
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div>
          <AimTypography
            variant="h4"
            boxStyle={{
              borderBottom: `3px solid ${theme.colors.greyScale.backgroundGrey}`,
            }}
          >
            {i18n.page.generalInfo}
          </AimTypography>
        </div>
        <div style={{ display: 'flex' }}>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.service}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.purchasedBy}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.unitaryPrice}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.vatRate}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.price}
          </AimTypography>
        </div>
        <div style={{ display: 'flex' }}>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {additionalService.title}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {`${participation?.givenName} ${participation?.familyName}`}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(netPrice)}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(vat)}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(netPrice + vat)}
          </AimTypography>
        </div>
      </div>
    )) ||
    null
  );
};

const AdditionalServiceBillingInformations = () => {
  const { eventId, type, additionalServiceId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.billingInformations(intl);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [additionalService, setAdditionalService] = useState();
  const [participation] = useState(appState.getCurrentParticipation());
  const [formExportedValues, setFormExportedValues] = useState({});
  const [billingInfoBookings, setBillingInfoBookings] = useState();

  // ref
  const gatewayRef = useRef();

  useEffect(() => {
    const loadData = async () => {
      if (participation) {
        getAdditionalService(additionalServiceId).then((as) => {
          //get additional services purchased
          getProductsByClientIdServiceId(
            eventId,
            constants.AdditionalServicesServiceTypology[as.typology]
              ?.productServiceType
          ).then((prods) => {
            const {
              netPrice1,
              netPrice2,
              vat1,
              vat2,
              vat1Id,
              vat2Id,
              // vatRate1,
              // vatRate2,
            } = utilities.getAdditionalServiceBracketInfo(as);
            return setAdditionalService({
              ...as,
              amount1: decodeDbNumber(netPrice1),
              amount2: decodeDbNumber(netPrice2),
              vat1: decodeDbNumber(vat1),
              vat2: decodeDbNumber(vat2),
              vat1Id,
              vat2Id,
              availableSubscription:
                as.rule === 'yes/no'
                  ? 1
                  : as.maxSubscription
                  ? parseInt(as.maxSubscription, 10) - (prods.length || 0)
                  : undefined,
            });
          });
        });
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    setBillingInfoBookings({
      [additionalServiceId]: {
        participationMode: additionalService?.participationType,
        vatRate1: additionalService?.vat1,
        vatRate2: additionalService?.vat2,
        vat1Id: additionalService?.vat1Id,
        vat2Id: additionalService?.vat2Id,
      },
    });
  }, [additionalService]);

  useEffect(() => {
    if (eventId) {
      const fetchEventGateway = async () => {
        const nextGateway = await getEventGateway({
          id: eventId,
          serviceType: constants.GatewayServices().SOCIAL_EVENT.key,
        });

        gatewayRef.current = nextGateway;
      };
      fetchEventGateway();
    }
  }, [eventId]);

  const checkoutResponse = () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.buyTicketSuccess,
      });
      history.push(
        `/events/${eventId}/additional-services/${type}/${additionalServiceId}/payment-success`
      );
    }
  };

  // const handleCopyFromLastEvent = async () => {
  //   const billingInfo = await getParticipationLastEventBillingInfo(
  //     participation?.cognitoUserId,
  //     eventId
  //   );
  //   reset({
  //     ...billingInfo,
  //     country: { label: billingInfo?.country, value: billingInfo?.country },
  //     city: { label: billingInfo?.city, value: billingInfo?.city },
  //     invoiceToPublicAuthority: billingInfo?.invoiceToPublicAuthority?.toString(),
  //     isSplitPayment: billingInfo?.isSplitPayment?.toString(),
  //     isVatEvent: billingInfo?.isVatEvent?.toString(),
  //   });
  // };

  const afterSendData = async (billingInfo) => {
    const getCartData = async () => {
      const user = appState.user.getValue();

      if (gatewayRef.current === undefined) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.gatewayNotExist,
        });
        return;
      }

      if (gatewayRef.current?.shop?.paymentType === undefined) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.paymentTypeNotExist,
        });
        return;
      }

      const netPrice = additionalService?.amount1 + additionalService?.amount2;

      const paymentType =
        netPrice === 0
          ? constants.PaymentTypes.Free
          : gatewayRef.current?.shop?.paymentType;

      const vatRate1 = formExportedValues?.[additionalServiceId]?.vatRate1;
      const vat1Id = formExportedValues?.[additionalServiceId]?.vat1Id;
      const vatRate2 = formExportedValues?.[additionalServiceId]?.vatRate2;
      const vat2Id = formExportedValues?.[additionalServiceId]?.vat2Id;
      const isForced = formExportedValues?.[additionalServiceId]?.isForced;

      const vat1 = vatRate1
        ? percentage(vatRate1, additionalService?.amount1)
        : 0;
      const vat2 = vatRate2
        ? percentage(vatRate2, additionalService?.amount2)
        : 0;
      const amount1 = additionalService?.amount1 + vat1;
      const amount2 = additionalService?.amount2 + vat2;

      const state = {
        netPrice,
        gateway: gatewayRef.current,
        ...(netPrice === 0 && { paymentType: constants.PaymentTypes.Free }),
        productId: additionalServiceId,
        serviceId: eventId,
        serviceType:
          type === 'social'
            ? constants.ProductType.socialEvents
            : constants.ProductType.scientificEvents,
        clientId: user.userAndParticipation.participation.id,
        clientType: constants.Clusters.Pax.id,
        serviceLabel: additionalService.title,
        quantity:
          additionalService?.configuration?.rule === constants.rules.YESNO.id ||
          additionalService?.configuration?.rule === constants.rules.ONLYONE.id
            ? 1
            : null,
        availableSubscription: additionalService?.availableSubscription,
        paymentMetadata: {
          isForced,
          vatRate1: encodeDbNumber(vatRate1),
          vatRate2: encodeDbNumber(vatRate2),
          vat1Id: vat1Id,
          vat2Id: vat2Id,
          amount1: encodeDbNumber(amount1),
          amount2: encodeDbNumber(amount2),
          netAmount1: encodeDbNumber(additionalService?.amount1) || null,
          netAmount2: encodeDbNumber(additionalService?.amount2) || null,
        },
        vat: {
          isForced,
          vatRate1: vatRate1,
          vatRate2: vatRate2,
          vat1Id: vat1Id,
          vat2Id: vat2Id,
          taxableAmountVat1: additionalService?.amount1,
          taxableAmountVat2: additionalService?.amount2,
        },
        billingInfo,
        url: `/events/${eventId}/additional-services/${type}/${additionalServiceId}`,
        baseUrl: window.location.host,
        // participationGuests: participation.guests, ADDITIONAL SERVICE DOES NOT HAVE GUESTS FOR NOW
        options: { participationType: additionalService.participationType },
      };

      if (paymentType === 'both') {
        history.push({
          pathname: `/events/${eventId}/additional-services/${type}/${additionalServiceId}/payment-opt`,
          state,
        });
      } else {
        history.push({
          pathname: `/events/${eventId}/additional-services/${type}/${additionalServiceId}/payment`,
          state,
        });
      }
    };

    getCartData();
    checkoutResponse();
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          minWidth: 'calc(100vw - 480px)',
          margin: '20px 180px',
        }}
      >
        <AimIconAndTextButton
          isUpperCase
          variant="none"
          text={i18n.actions.backButton}
          style={{
            padding: 0,
          }}
          onClick={() =>
            history.replace(
              `/events/${eventId}/additional-services/${type}/${additionalServiceId}`
            )
          }
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTypography variant="h1">{i18n.page.title}</AimTypography>
        <GeneralInfo
          i18n={i18n}
          additionalService={additionalService}
          intl={intl}
          participation={participation}
          vatRate1={formExportedValues?.[additionalServiceId]?.vatRate1}
          vatRate2={formExportedValues?.[additionalServiceId]?.vatRate2}
        />
        <BillingInformations
          type={constants.Clusters.Pax.id}
          customerId={participation.id}
          history={history}
          intl={intl}
          showSnackbar
          saveButtonText={i18n.actions.continue}
          SaveButtonIcon={ShoppingCartIcon}
          onAfterSave={afterSendData}
          setFormExportedValues={setFormExportedValues}
          billingInfoBookings={billingInfoBookings}
        />
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AdditionalServiceBillingInformations;

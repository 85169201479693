import React, { useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  format,
  isWithinInterval,
  isAfter,
  isBefore,
  parseISO,
  addDays,
} from 'date-fns';

import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import LoopIcon from '@material-ui/icons/Loop';
import { sortBy } from 'lodash';

import { appState, aws, constants, utilities } from '@aim/common';
import {
  CustomIntl,
  AimTablePage,
  MassiveImportDialog,
  AimDialog,
  AimTypography,
  AimTextField,
} from '@aim/components';

import {
  translation,
  updateParticipationIsDeleted,
  updateSponsorList,
} from './sponsorListDetails/index';

import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';
import { customAlphabet } from 'nanoid';
import { useBuyOperations } from './shared/buyOperationsGqlHelper';
import {
  createSponsorListPurchase,
  updateSponsorListPurchase,
} from './shared/sponsorListServiceGqlHelper';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);

const cluster = constants.Clusters.SponsorList.id;
const formattedDate = (date) => date && format(new Date(date), 'dd/MM/yyyy');

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getSponsorListQuery = /* GraphQL */ `
  query GetSponsor(
    $id: ID!
    $eventId: ID!
    $cluster: String!
    $n2NConnectionType: String
  ) {
    getSponsor(id: $id) {
      id
      name
      freeListMembers
      freeListParticipationMode
      freeListTicket {
        id
        start
        end
        label
      }
      event {
        id
        sponsorServiceBookingDuration
        sponsorListService {
          id
          isBankTransferEnabled
          isCreditCardEnabled
        }
        fee {
          vat {
            id
            amount
          }
        }
      }
      freeListProfile {
        id
        name
      }
      sponsorList {
        id
        name
        status
        closingDate
        modificationRequests(filter: { isDeleted: { ne: true } }) {
          items {
            id
            familyName
            givenName
            email
            status
            phone
            notes
            createdAt
            updatedAt
            isDeleted
            modificationRequest {
              id
              requestId
              participationId
              requestStatus
              createdAt
              updatedAt
              penaltyPrice
              type
              participationRequest {
                isDeleted
                participationSubject {
                  id
                  isDeleted
                }
              }
            }
            profile {
              id
              name
            }
            cluster
            isParticipant
            isSpeaker
            isReviewer
          }
        }
        purchases(filter: { isDeleted: { ne: true } }) {
          items {
            id
            isDeleted
            purchasedSubscriptions
            quantity
            participationMode
            feeDateRange {
              id
              start
              end
              label
            }
            profile {
              id
              name
            }
            additionalService {
              id
              title
              typology
            }
            buyOperation {
              id
              purchase {
                id
                createdAt
                payment {
                  id
                  paymentType
                  paymentId
                  paymentIdIngenico
                  paymentIdGpWebpay
                  paymentIdMonetaOnLine
                  paymentError
                  paymentMetadata
                }
              }
            }
          }
        }
        participations(filter: { isDeleted: { ne: true } }) {
          items {
            id
            username
            givenName
            familyName
            email
            createdAt
            n2nConnection(filter: { type: { eq: $n2NConnectionType } }) {
              items {
                id
                n2NConnectionsTableAdditionalServicesId
                n2NConnectionsTableParticipationId
                type
                status
                additionalService: additionalServices {
                  id
                  title
                  typology
                  amount1
                  amount2
                  participationMode: participationType
                  additionaServiceVat1 {
                    id
                    amount
                  }
                  additionaServiceVat2 {
                    id
                    amount
                  }
                }
              }
            }
            profile {
              id
              name
              profileFeeBrackets {
                items {
                  id
                  priceOnAir
                  priceOnSite
                  feeBracket {
                    id
                    feeDateRange {
                      id
                      start
                      end
                      label
                    }
                    feeBracketModel {
                      id
                      start
                      end
                    }
                  }
                }
              }
            }
            feeDateRange {
              id
              label
              start
              end
            }
            feeBracketModel {
              id
            }
            isInvited
            status
            isParticipant
            isSpeaker
            isReviewer
            type
            modificationRequestNameChangeUsername
            participationHistorical {
              items {
                modificationRequest {
                  id
                  requestStatus
                  participationId
                  createdAt
                  updatedAt
                  penaltyPrice
                  type
                }
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    }
    getDeadline(externalId: $eventId, type: $cluster) {
      type
      createdAt
      value
      externalId
    }
  }
`;

// CANDIDATO UTILITIES
const calculateTotalAmountDue = (rows, totalPenaltiesRef) => {
  return (
    rows.reduce((prev, curr) => {
      const totalRowPrice =
        (curr.ticketPrice || 0) + (curr.additionalServicesPrice || 0);
      prev += totalRowPrice;
      return prev;
    }, 0) + (totalPenaltiesRef.current || 0)
  );
};

// CANDIDATO UTILITIES
const checkIsBeforeFreezeDeadline = (deadlinesRef) => {
  return deadlinesRef.current?.freezeDeadline
    ? isBefore(new Date(), parseISO(deadlinesRef.current?.freezeDeadline))
    : true;
};

// CANDIDATO UTILITIES
const generateAllPurchases = (sponsor, sponsorList) => {
  const freeMembers = sponsor?.freeListMembers || 0;

  const freePlaces =
    freeMembers > 0
      ? {
          [`${sponsor?.freeListProfile?.id}-${sponsor?.freeListTicket?.id}-${
            sponsor?.freeListParticipationMode ||
            constants.EventTypes.PHYSICAL.id
          }`]: {
            remainingPlaces: freeMembers,
            totalPrice: 0,
            type: constants.Services.REGISTRATION.key,
          },
        }
      : {};

  const allPlaces = sponsorList.purchases.items
    .filter(
      (p) =>
        p?.buyOperation?.purchase &&
        utilities.isValidPurchase(p.buyOperation.purchase)
    )
    .reduce((prev, curr) => {
      const paymentMetadata = curr.buyOperation.purchase.payment
        ?.paymentMetadata
        ? JSON.parse(curr.buyOperation.purchase.payment.paymentMetadata)
        : [];

      const bookingItem = paymentMetadata.find(
        (boMetadata) => boMetadata.id === curr.buyOperation.id
      );

      if (curr?.feeDateRange?.id) {
        const key = `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.participationMode}`;
        if (prev[key]) {
          prev[key].remainingPlaces += curr.quantity;
          prev[key].totalPrice += bookingItem?.price;
        } else {
          prev[key] = {
            remainingPlaces: curr.quantity,
            totalPrice: bookingItem?.price,
            type: constants.Services.REGISTRATION.key,
          };
        }
      } else if (curr?.additionalService?.id) {
        const key = `${curr?.additionalService?.id}`;
        if (prev[key]) {
          prev[key].remainingPlaces += curr.quantity;
          prev[key].totalPrice += bookingItem?.price;
        } else {
          prev[key] = {
            remainingPlaces: curr.quantity,
            totalPrice: bookingItem?.price,
            type:
              curr?.additionalService?.typology === 'social'
                ? constants.ProductTypes.SOCIAL_EVENT.key
                : constants.ProductTypes.SCIENTIFIC_EVENT.key,
          };
        }
      } else {
        if (prev.penalties) {
          prev.penalties.totalPrice += bookingItem?.price;
        } else {
          prev.penalties = {
            totalPrice: bookingItem?.price,
            type: 'penalties',
          };
        }
      }
      return prev;
    }, freePlaces);
  return allPlaces;
};

// CANDIDATO UTILITIES
const getModificationData = (p) => {
  // get num of modification requests by status
  const initRes = {
    [constants.ModificationRequestStatus.ACCEPTED]: 0,
    [constants.ModificationRequestStatus.REJECTED]: 0,
    [constants.ModificationRequestStatus.PENDING]: 0,
  };

  const modReqs = p.participationHistorical.items.reduce((res, curr) => {
    if (curr.modificationRequest) {
      res[curr.modificationRequest.requestStatus] += 1;
    }
    return res;
  }, initRes);

  // composing of string for modification column
  const modification = Object.keys(modReqs).reduce((res, curr) => {
    if (res !== '' && modReqs[curr] > 0) {
      res += '\n';
    }
    if (modReqs[curr] > 0) {
      return (res += `${curr}${
        p.participationHistorical.items.length > 1 ? `(${modReqs[curr]})` : ''
      }`);
    } else {
      return (res += '');
    }
  }, '');

  return { modification: { label: modification, reqs: modReqs } };
};

const calcNModReqsToPay = (sponsorList) => {
  const lastPenaltiesPuchase = sortBy(
    sponsorList?.purchases?.items.filter(
      (p) =>
        !p.feeDateRange?.id &&
        !p.additionalService?.id &&
        p?.buyOperation?.purchase &&
        utilities.isValidPurchase(p.buyOperation.purchase)
    ),
    (p) => new Date(p.createdAt)
  ).reverse()?.[0];

  const modReqsToPay = sponsorList?.modificationRequests?.items.filter(
    (req) => {
      return (
        req.modificationRequest &&
        (!lastPenaltiesPuchase ||
          isAfter(
            parseISO(req.modificationRequest?.updatedAt),
            parseISO(lastPenaltiesPuchase?.createdAt)
          )) &&
        req.modificationRequest.requestStatus ===
          constants.ModificationRequestStatus.ACCEPTED
      );
    }
  );

  return modReqsToPay;
};

const handleAssignAgency = async ({
  agencyEmail,
  sponsorListRef,
  eventId,
  setAssignAgencyDialog,
  goBack,
}) => {
  // create code on sponsor list
  showLoader();
  const newSponsorListCode = nanoid();
  await updateSponsorList(
    {
      id: sponsorListRef.current.id,
      code: newSponsorListCode,
    },
    false
  );

  // call assign agency email
  await aws.standardAPI.post('apiSendMail', '/sendMail/assign-agency', {
    body: {
      agencyEmail,
      sponsorListId: sponsorListRef.current.id,
      eventId,
    },
  });

  setAssignAgencyDialog({ isOpen: false });
  goBack();
  hideLoader();
};

const SponsorListDetails = () => {
  var result = new Date(Date.now());
  result.setDate(result.getDate() - 100);
  const { eventId, sponsorId } = useParams();
  const buyOperationsHelper = useBuyOperations();
  const history = useHistory();
  const deadlinesRef = useRef();
  const isPostDeadlineRef = useRef();
  const remainingPlacesRef = useRef();
  const sponsorListRef = useRef();
  const modificationRequestsRef = useRef(0);
  const ticketVatRef = useRef();
  const allPurchasesWithRemainingRef = useRef({});
  const modificationReqsAcceptedRef = useRef(0);
  const freeMembersRef = useRef(0);
  const totalPenaltiesRef = useRef(0);
  const sponsorListServiceBookingDurationRef = useRef(0);
  const paymentTypeRef = useRef();

  const [massiveDialog, setMassiveDialog] = useState({
    isOpen: false,
  });
  // const [rowsLoaded, setRowsLoaded] = useState(false);
  const [assignAgencyDialog, setAssignAgencyDialog] = useState({
    isOpen: false,
  });
  const [agencyEmail, setAgencyEmail] = useState('');

  // get translated objects for this route
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorListDetails(intl);
  const goBack = () =>
    history.push(`/events/${eventId}/${sponsorId}/my-services`);

  const importBackUrl = `/events/${eventId}/${sponsorId}/services-configuration/list`;
  const model = (loadData) => ({
    header: {
      title: () => sponsorListRef.current?.name,
      backButton: {
        onClick: () => goBack(),
      },
      action: {
        pay: {
          variant: 'primary',
          disabledWhen: (checkedItems, originalData, data) => {
            // MUST never be append but leaved here just to be sure
            const noProfileFound = data.findIndex((row) => row.noProfile);
            return noProfileFound > -1;
            // || canNotCloseGroup(data);
          },
          hiddenWhen: (checkedItems, originalData, data) => {
            // return data?.getAgencyGroup?.closingDate;
            return calculateTotalAmountDue(data, totalPenaltiesRef) === 0;
          },
          onClick: (checkedItems, data) =>
            handlePayAmountDue(data, totalPenaltiesRef),
        },
        assignAgency: {
          isUpperCase: true,
          variant: 'primary',
          onClick: () => setAssignAgencyDialog({ isOpen: true }),
        },
        close: {
          isUpperCase: true,
          icon: <DoneIcon />,
          variant: 'primary',
          // onClick: () => handleCloseGroup(),
          onClick: 'dialog',
          disabledWhen: () => sponsorListRef.current?.closingDate,
          dialog: 'close',
          snackbar: 'close',
        },
        add: {
          icon: <AddIcon />,
          variant: 'primary',
          onClick: () => handleAdd(isPostDeadlineRef.current),
          disabledWhen: () => !checkIsBeforeFreezeDeadline(deadlinesRef),
        },
      },
    },
    dataLoader: {
      query: getSponsorListQuery,
      variables: {
        id: sponsorId,
        eventId,
        cluster: constants.Clusters.SponsorList.id,
        n2NConnectionType:
          constants.N2NConnectionTypes.PARTICIPATION_ADDITIONAL_SERVICE,
      },
      transform: (data) => {
        const { priceRange, sponsorList, ...sponsor } = data?.getSponsor;
        sponsorListRef.current = sponsorList;
        deadlinesRef.current = data?.getDeadline?.value
          ? JSON.parse(data.getDeadline.value)
          : {};
        ticketVatRef.current = sponsor.event.fee.vat;
        isPostDeadlineRef.current =
          sponsorList?.status === constants.GroupStatuses.CLOSED.id ||
          isAfter(new Date(), new Date(deadlinesRef.current.deadline));
        const sortedParticipations = sortBy(
          data?.getSponsor?.sponsorList?.participations.items,
          (p) => new Date(p.createdAt)
        );
        freeMembersRef.current = sponsor?.freeListMembers || 0;
        sponsorListServiceBookingDurationRef.current =
          sponsor?.event?.sponsorServiceBookingDuration || 0;

        const paymentType =
          sponsor?.event?.sponsorListService?.isCreditCardEnabled &&
          sponsor?.event?.sponsorListService?.isBankTransferEnabled
            ? 'both'
            : sponsor?.event?.sponsorListService?.isCreditCardEnabled
            ? constants.PaymentTypes.CreditCard
            : sponsor?.event?.sponsorListService?.isBankTransferEnabled
            ? constants.PaymentTypes.BankTransfer
            : undefined;
        paymentTypeRef.current = paymentType;

        // setStaffMembers(sponsorList.participations.items);

        // get all places with remaining object that contains object with key of profileId-feeDateRangeId-type with number of free places
        const allPurchases = generateAllPurchases(sponsor, sponsorList);

        // const currentMembersTickets = sponsorList.participations.items
        //   .filter((p) => p.feeDateRange)
        //   .reduce((prev, curr) => {
        //     if (
        //       prev[
        //         `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.type}`
        //       ]
        //     ) {
        //       prev[
        //         `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.type}`
        //       ] += 1;
        //     } else {
        //       prev[
        //         `${curr?.profile?.id}-${curr?.feeDateRange?.id}-${curr.type}`
        //       ] = 1;
        //     }
        //     return prev;
        //   }, {});

        // const allPurchasesWithRemaining = Object.entries(allPurchases).reduce(
        //   (prev, [key, curr]) => {
        //     const remainingPlaces =
        //       // to prevent negative numbers
        //       curr.purchasedSubscriptions -
        //         (currentMembersTickets?.[key] || 0) >
        //       0
        //         ? curr.purchasedSubscriptions -
        //           (currentMembersTickets?.[key] || 0)
        //         : 0;

        //     prev[key] = { ...curr, remainingPlaces };
        //     return prev;
        //   },
        //   {}
        // );
        // allPlacesRef.current = allPlacesWithRemaining;

        const participations = sortBy(
          sortedParticipations,
          'modificationRequestNameChangeUsername'
        ).map((item) => {
          const priceKey = `${item.profile?.id}-${item.feeDateRange?.id}-${item.type}`;
          let ticketPrice = 0;
          let priceObj = {};
          if (item.modificationRequestNameChangeUsername) {
            ticketPrice = 0;
          } else if (allPurchases?.[priceKey]?.remainingPlaces > 0) {
            allPurchases[priceKey].remainingPlaces -= 1;
          } else {
            priceObj = utilities.getParticipationPrice(
              item,
              null,
              ticketVatRef.current
            );
            ticketPrice = priceObj.price;
          }
          let additionalServicesPrice = 0;
          let participationAdditionalServices = [];
          if (item.n2nConnection?.items.length > 0) {
            additionalServicesPrice += item.n2nConnection.items.reduce(
              (prev, { additionalService }) => {
                if (allPurchases?.[additionalService.id]?.remainingPlaces > 0) {
                  allPurchases[additionalService.id].remainingPlaces -= 1;
                } else {
                  const vatRate1 = utilities.decodeDbNumber(
                    additionalService.additionaServiceVat1?.amount ||
                      additionalService.vat1
                  );
                  const vatRate2 = utilities.decodeDbNumber(
                    additionalService.additionaServiceVat2?.amount
                  );
                  const additionalServicePrice = utilities.calculateTotalPriceAdditionalService(
                    additionalService
                  );
                  prev += additionalServicePrice;
                  participationAdditionalServices.push({
                    ...additionalService,
                    id: additionalService.id,
                    netPrice1: additionalService.amount1,
                    netPrice2: additionalService.amount2,
                    vat1: additionalService.additionaServiceVat1,
                    vat2: additionalService.additionaServiceVat2,
                    vatRate1,
                    vatRate2,
                    vat1Id: additionalService.additionaServiceVat1?.id,
                    vat2Id: additionalService.additionaServiceVat2?.id,
                    price: additionalServicePrice,
                  });
                }
                return prev;
              },
              0
            );
          }

          const modificationReqs = getModificationData(item);
          // num of modification reqs accepted
          modificationReqsAcceptedRef.current +=
            modificationReqs.modification.reqs[
              constants.ModificationRequestStatus.ACCEPTED
            ];

          return {
            ...item,
            createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
            profile: item.profile?.name,
            noProfile: !item.profile,
            isReadOnly: item.modificationRequestNameChangeUsername,
            ticketPrice,
            additionalServicesPrice,
            productData: {
              ...priceObj,
              netPrice: utilities.encodeDbNumber(priceObj?.netPrice),
              id: priceObj?.profileFeeBracketId,
              profileId: item.profile?.id,
              profileLabel: item.profile?.name,
              feeDateRange: item.feeDateRange?.id,
              feeDateRangeLabel: item.feeDateRange?.label,
              feeDateRangeStart: item.feeDateRange?.start,
              feeDateRangeEnd: item.feeDateRange?.end,
              participationMode: item.type,
              additionalServices: participationAdditionalServices,
            },
            modification:
              modificationReqs.modification.label !== ''
                ? modificationReqs.modification.label
                : '-',
          };
        });
        remainingPlacesRef.current = Object.values(allPurchases)
          .filter((x) => x.type === constants.Services.REGISTRATION.key)
          .reduce((prev, curr) => (prev += curr.remainingPlaces), 0);

        allPurchasesWithRemainingRef.current = { ...allPurchases };

        const nModReqsToPay = calcNModReqsToPay(sponsorList);
        totalPenaltiesRef.current = nModReqsToPay.reduce((prev, curr) => {
          prev += utilities.decodeDbNumber(
            curr.modificationRequest.penaltyPrice ||
              deadlinesRef.current?.penalty ||
              0
          );
          return prev;
        }, 0);

        let modificationRequests = sponsorList?.modificationRequests?.items
          .filter(
            (x) =>
              x.modificationRequest?.requestStatus ===
              constants.ModificationRequestStatus.PENDING
          )
          .map((req) => {
            modificationRequestsRef.current += 1;

            return {
              ...req,
              createdAt: format(new Date(req.createdAt), 'dd/MM/yyyy'),
              profile: req.profile?.name,
              modification: req.modificationRequest.requestStatus,
              isReadOnly: true,
              noProfile: !req.profile,
            };
          });

        return [...modificationRequests, ...participations];
      },
      bigStats: [
        {
          title: 'participationsAvailable',
          value: () => remainingPlacesRef.current,
        },
        {
          title: 'freeParticipations',
          value: () => freeMembersRef.current,
        },
        {
          title: 'amountDue',
          value: (rows) =>
            utilities.formatNumber(
              calculateTotalAmountDue(rows, totalPenaltiesRef)
            ),
        },
      ],
      stats: [
        {
          title: 'status',
          value: (_, data) =>
            Object.values(constants.GroupStatuses)
              .find(
                (s) =>
                  s.id ===
                  (data?.getSponsor?.sponsorList?.status ||
                    constants.GroupStatuses.OPEN.id)
              )
              ?.label(intl),
        },
        {
          title: 'closingDate',
          value: () =>
            deadlinesRef.current?.deadline
              ? format(new Date(deadlinesRef.current?.deadline), 'dd/MM/yyyy')
              : '-',
        },
        {
          title: 'postCloseChangeDeadline',
          value: () =>
            deadlinesRef.current?.freezeDeadline
              ? format(
                  new Date(deadlinesRef.current?.freezeDeadline),
                  'dd/MM/yyyy'
                )
              : '-',
        },
        {
          title: 'modificationRequests',
          value: () => modificationRequestsRef.current,
        },
        {
          title: 'requestsAccepted',
          value: () => modificationReqsAcceptedRef.current,
        },
      ],
    },
    table: {
      isRowSelectable: true,
      columnsTemplate: [
        {
          id: 'username',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'givenName',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'familyName',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'email',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'phone',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'profile',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'modificationRequestNameChangeUsername',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'createdAt',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'notes',
          numeric: false,
          disablePadding: false,
          isCheckbox: false,
          isDisabled: false,
        },
        {
          id: 'modification',
          isCheckbox: false,
        },
      ],
      rowAction: {
        changeName: {
          icon: <LoopIcon />,
          variant: 'primary',
          hiddenWhen: ({ isReadOnly }) => isReadOnly,
          disabledWhen: () => !checkIsBeforeFreezeDeadline(deadlinesRef),
          onClick: (row) => handleNameChange(row),
        },
        edit: {
          icon: <EditIcon />,
          variant: 'primary',
          hiddenWhen: ({ isReadOnly }) => isReadOnly,
          disabledWhen: () => !checkIsBeforeFreezeDeadline(deadlinesRef),
          onClick: (row) =>
            handleEdit({
              id: row.id,
              row,
              isPostDeadline: !!isPostDeadlineRef.current,
            }),
        },
        delete: {
          icon: <ClearIcon />,
          variant: 'secondary',
          hiddenWhen: ({ isReadOnly }) => isReadOnly,
          disabledWhen: () => !checkIsBeforeFreezeDeadline(deadlinesRef),
          onClick: 'dialog',
          dialog: 'delete',
          snackbar: 'delete',
        },
      },
    },
    dialog: {
      delete: {
        onAgree: async (row) => {
          await handleDelete(row.id);
          await loadData();
        },
        onDisagree: () => {},
      },
      close: {
        onAgree: async () => {
          await handleCloseGroup();
          await loadData();
        },
        onDisagree: () => {},
      },
    },
    snackbar: {
      delete: {},
      close: {},
    },
  });

  const isAfterDeadline = () => {
    if (!deadlinesRef.current?.deadline) return false;
    return isAfter(new Date(), new Date(deadlinesRef.current.deadline));
  };

  const isBetweenDeadlineAndFreezeDeadline = () => {
    if (
      !deadlinesRef.current?.deadline ||
      !deadlinesRef.current?.freezeDeadline
    )
      return false;
    return isWithinInterval(new Date(), {
      start: new Date(deadlinesRef.current.deadline),
      end: new Date(deadlinesRef.current.freezeDeadline),
    });
  };

  const handleAdd = (isPostDeadline) => {
    history.push(
      `/events/${eventId}/participation/${cluster}/${
        sponsorListRef.current.id
      }/new${isPostDeadline ? '/post-deadline' : ''}`,
      {
        availableByProfileFeeDateRangeAndTypology:
          allPurchasesWithRemainingRef.current,
      }
    );
  };

  const handleEdit = ({ id, row, isPostDeadline }) => {
    // we read one availability only if it is purchased
    const key = `${row?.profile?.id}-${row?.feeDateRange?.id}-${row.type}`;
    if (allPurchasesWithRemainingRef.current?.[key]) {
      allPurchasesWithRemainingRef.current[key].remainingPlaces += 1;
    }

    const availableByProfileFeeDateRangeAndTypology = {
      ...allPurchasesWithRemainingRef.current,
      [key]: allPurchasesWithRemainingRef.current[key],
    };

    history.push(
      `/events/${eventId}/participation/${cluster}/${
        sponsorListRef.current.id
      }/${id}${isPostDeadline ? '/post-deadline' : ''}`,
      {
        availableByProfileFeeDateRangeAndTypology,
      }
    );
  };

  const handleNameChange = ({ id }) => {
    //we add one availability, so the number of avaialable remains correct in edit mode
    history.push(
      `/events/${eventId}/participation/${cluster}/${sponsorListRef.current.id}/${id}/name-change`
    );
  };

  const handleCloseGroup = async () => {
    showLoader();
    await updateSponsorList({
      id: sponsorListRef.current.id,
      closingDate: new Date(),
      status: constants.GroupStatuses.CLOSED.id,
    });
    await aws.standardAPI.post('aimlambdaproxy', '/async/', {
      body: {
        lambdaName: 'aimConflictLambda',
        type: 'SPONSOR_LIST_CLOSED',
        id: sponsorListRef.current.id,
      },
    });
    hideLoader();
  };

  const handleDelete = async (id) => await updateParticipationIsDeleted(id);

  const handlePayAmountDue = async (rows, totalPenaltiesRef) => {
    showLoader();
    const bookings = rows.reduce((bookings, curr) => {
      const { additionalServices, ...rest } = curr.productData;
      if (curr.ticketPrice > 0) {
        const bookingItem = bookings.find(
          (b) =>
            b.profileFeeBracketId === curr.productData.profileFeeBracketId &&
            b.participationMode === curr.productData.participationMode
        );
        if (bookingItem) {
          bookingItem.quantity += 1;
        } else {
          bookings.push({
            quantity: 1,
            ...rest,
            productType: constants.ProductType.registration,
          });
        }
      }
      additionalServices
        .filter((as) => as.price > 0)
        .forEach((as) => {
          const bookingItem = bookings.find((b) => b.id === as.id);
          if (bookingItem) {
            bookingItem.quantity += 1;
          } else {
            bookings.push({
              ...as,
              id: as.id,
              additionalServiceId: as.id,
              quantity: 1,
              productType:
                as.typology ===
                constants.AdditionalServicesServiceTypology.scientific.id
                  ? constants.ProductType.scientificEvents
                  : constants.ProductType.socialEvents,
            });
          }
        });
      return bookings;
    }, []);

    if (totalPenaltiesRef.current > 0) {
      bookings.push({
        id: 'penalties',
        quantity: 1,
        netPrice: utilities.encodeDbNumber(totalPenaltiesRef.current),
        vatRate: 0,
        price: utilities.encodeDbNumber(totalPenaltiesRef.current),
        productType: constants.ProductType.penalties,
      });
    }

    const responsePromise = await Promise.all(
      bookings.map(async (item) => {
        const isTicket =
          item.productType === constants.ProductType.registration;
        const sponsorListPurchase = await createSponsorListPurchase(
          {
            //se non ha ticket o servizi addizionali è il pagamento delle penalità
            type: item.productType,
            netPrice: item.netPrice
              ? item.netPrice
              : item.netPrice1 + item.netPrice2,
            sponsorListPurchaseVatId: item.vat?.id,
            netPrice1: item.netPrice1,
            sponsorListPurchaseVat1Id: item.vat1?.id,
            netPrice2: item.netPrice2,
            sponsorListPurchaseVat2Id: item.vat2?.id,
            quantity: item.quantity,
            sponsorListPurchaseSponsorListId: sponsorListRef.current.id,
            participationMode: isTicket ? item.participationMode : undefined,
            sponsorListPurchaseProfileId: item.profileId,
            sponsorListPurchaseFeeDateRangeId: item.feeDateRange,
            sponsorListPurchaseAdditionalServiceId: item.additionalServiceId,
          },
          false
        );

        const bookingDate = new Date();
        const bookingExpiringDate = addDays(
          bookingDate,
          sponsorListServiceBookingDurationRef.current
        );
        const inputBuyOperation = {
          eventId: eventId,
          buyOperationSponsorListPurchaseId: sponsorListPurchase.id,
          buyOperationSponsorId: sponsorId,
          date: bookingDate,
          expiringDate: bookingExpiringDate,
          paymentType: paymentTypeRef.current,
          isExpired: 'false',
          isDeleted: 'false',
        };

        const response = await buyOperationsHelper.create(
          inputBuyOperation,
          false
        );

        await updateSponsorListPurchase(
          {
            id: sponsorListPurchase.id,
            sponsorListPurchaseBuyOperationId: response.id,
          },
          false
        );

        return response;
      })
    );
    hideLoader();
    const destUrl = `/events/${eventId}/${sponsorId}/cart`;
    history.push({
      pathname: destUrl,
      state: {
        bookings: responsePromise,
      },
    });
  };

  return (
    <MainContainer>
      <div style={{ flex: 1 }}>
        <InnerContainer>
          <AimTablePage
            model={model}
            translation={i18n.aimTablePage}
            intl={intl}
          />
        </InnerContainer>
      </div>

      {/* {rowsLoaded && (
        <MassiveImportDialog
          state={{
            remainingPlaces: remainingPlacesRef.current,
            sponsorId,
            backUrl: importBackUrl,
          }}
          eventId={eventId}
          cluster={cluster}
          clusterId={sponsorListRef.current.id}
          massiveDialog={massiveDialog}
          setMassiveDialog={setMassiveDialog}
          intl={intl}
          history={history}
        />
      )} */}
      <AimDialog
        open={assignAgencyDialog.isOpen}
        title={i18n.page.assignAgencyDialog.title}
        onAgree={() =>
          handleAssignAgency({
            agencyEmail,
            sponsorListRef,
            eventId,
            setAssignAgencyDialog,
            goBack,
          })
        }
        onAgreeDisabled={!agencyEmail}
        onClose={() => {
          setAssignAgencyDialog({ isOpen: false });
        }}
      >
        <AimTypography>{i18n.page.assignAgencyDialog.message}</AimTypography>
        <AimTextField
          label={i18n.page.assignAgencyDialog.agencyEmail}
          formControlStyle={{ width: 'calc(100% - 10px)' }}
          value={agencyEmail}
          onChange={(e) => setAgencyEmail(e.target.value)}
        ></AimTextField>
      </AimDialog>
    </MainContainer>
  );
};

export default SponsorListDetails;

import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
  StepDivider,
  Title,
  AimTypography,
  AimSelect,
  AimSelectMenuItem,
  AimChipInput,
} from '@aim/components';

const formControlStyle = { width: 'calc(100% - 10px)' };

const TopicStep = ({
  control,
  title,
  i18n,
  errors,
  abstractService,
  watch,
  variant = 'white',
}) => {
  if (!abstractService) return null;

  const { presentationTypologies, tags } = abstractService;
  const preferredExposureId = watch('preferredExposureId');
  const presentationTypology = presentationTypologies?.items?.find(
    (pt) => pt.id === preferredExposureId
  );
  const currCategoryId = watch('category');
  const currCategory = currCategoryId
    ? presentationTypology?.categories?.items?.find(
        (c) => c?.category?.id === currCategoryId
      )?.category
    : null;

  const keywords = watch('keywords');

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      {tags?.length > 0 && (
        <AimTypography variant="text">
          {i18n.wizard.subtitles.topics}
        </AimTypography>
      )}
      <div>
        <Controller
          name="category"
          control={control}
          render={(props) => (
            <AimSelect
              selectVariant={variant}
              label={i18n.wizard.labels.category}
              selectPlaceholder={i18n.wizard.placeholders.category}
              value={props.value || ''}
              onChange={(e) => props.onChange(e.target.value)}
              formControlStyle={formControlStyle}
            >
              {presentationTypology?.categories?.items
                ?.filter((c) => c?.category)
                ?.sort((a, b) =>
                  a?.category?.name?.localeCompare(b?.category?.name)
                )
                ?.map((c) => (
                  <AimSelectMenuItem key={c.category.id} value={c.category.id}>
                    {c.category.name}
                  </AimSelectMenuItem>
                ))}
            </AimSelect>
          )}
        />
        {/* <ErrorMessage
          errors={errors}
          name="abstractDocumentCategoryId"
          render={() => <p>Error</p>}
        /> */}

        {currCategory && currCategory.subCategories.items.length > 0 && (
          <Controller
            name="subCategory"
            control={control}
            render={(props) => (
              <AimSelect
                selectVariant={variant}
                label={i18n.wizard.labels.subcategory}
                selectPlaceholder={i18n.wizard.placeholders.subcategory}
                value={props.value || ''}
                onChange={(e) => props.onChange(e.target.value)}
                formControlStyle={formControlStyle}
              >
                {currCategory.subCategories.items
                  ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                  ?.map((sc) => (
                    <AimSelectMenuItem key={sc.id} value={sc.id}>
                      {sc.name}
                    </AimSelectMenuItem>
                  ))}
              </AimSelect>
            )}
          />
        )}

        {/* <ErrorMessage
          errors={errors}
          name="abstractDocumentSubCategoryId"
          message="This is required"
        /> */}

        {tags?.length > 0 && (
          <Controller
            name="tags"
            control={control}
            render={(props) => (
              <AimSelect
                selectVariant={variant}
                label={i18n.wizard.labels.tags}
                selectPlaceholder={i18n.wizard.placeholders.tags}
                value={props.value || []}
                onChange={(e) => props.onChange(e.target.value)}
                multiple
                formControlStyle={formControlStyle}
              >
                {tags?.map((t) => (
                  <AimSelectMenuItem key={t} value={t}>
                    {t}
                  </AimSelectMenuItem>
                ))}
              </AimSelect>
            )}
          />
        )}
        {/* <ErrorMessage errors={errors} name="tag" message="This is required" /> */}
        {abstractService?.isKeywordAddEnabled ? (
          <>
            <AimTypography variant="textBold">
              {i18n.wizard.labels.keywords}
            </AimTypography>
            <AimTypography variant="text" style={{ marginTop: -5 }}>
              {i18n.wizard.subtitles.keywords}
            </AimTypography>
            <Controller
              name="keywords"
              control={control}
              render={(props) => (
                <AimChipInput
                  textfieldVariant={variant}
                  onAdd={(chip) => {
                    if (
                      props.value.length <
                      (abstractService?.maxNumberKeyword || 1)
                    ) {
                      props.onChange([...props.value, chip]);
                    }
                  }}
                  onDelete={(chip) => {
                    const values = props.value;
                    const ndx = values.indexOf(chip);
                    values.splice(ndx, 1);
                    props.onChange(values);
                  }}
                  value={props.value}
                  placeholder={i18n.wizard.placeholders.keywords}
                  formControlStyle={formControlStyle}
                  addButton
                  maximumNumberOfElements={
                    abstractService?.maxNumberKeyword === 0
                      ? 0
                      : abstractService?.maxNumberKeyword || 99
                  }
                  elements={props.value}
                />
              )}
            />
          </>
        ) : null}
        {abstractService?.isKeywordAddEnabled &&
        keywords.length === abstractService?.maxNumberKeyword ? (
          <AimTypography variant="text">
            {i18n.wizard.labels.keywordsMaxReached}
          </AimTypography>
        ) : null}
        <ErrorMessage
          errors={errors}
          name="keywords"
          message={i18n.wizard.labels.requiredField}
          render={() => (
            <AimTypography variant="formError">
              {i18n.wizard.labels.requiredField}
            </AimTypography>
          )}
        />
      </div>
    </>
  );
};

export default TopicStep;

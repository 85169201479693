import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { useParams, useHistory, useLocation } from 'react-router-dom';

import translation from './shared/translation';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AbstractDetailPage,
  CustomIntl,
  AimIconAndTextButton,
  AimTitleAndButtons,
} from '@aim/components';

import { getAbstractDocument } from './../hooks/useAbstractDocument';
import { getPreTitles } from '../../../aim-event/src/pages/shared/preTitlesGqlHelper';

export default function AbstractDetail({ from }) {
  // i18n
  const intl = CustomIntl(useIntl());
  const i18n = translation.abstractDetail(intl);

  // Hooks
  const { eventId, abstractId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [reviews, setReviews] = useState([]);
  const [preTitles, setPreTitles] = useState([]);

  // States
  const [abstractDocument, setAbstractDocument] = useState();
  const [viewReview, setViewReview] = useState(false);

  const fetchPreTitles = async () => {
    const fetchedPreTitles = await getPreTitles({ id: eventId });
    // This map should be useless since the titles should already be unique (in old events we still have dirty data)
    const titlesUniqueByKey = [
      ...new Map(fetchedPreTitles.map((item) => [item['key'], item])).values(),
    ];
    // Filter for only active and compiled titles
    const activeTitles = titlesUniqueByKey.filter(
      (title) => title.isActive && title.title
    );
    setPreTitles(activeTitles);
  };

  // Effects
  useEffect(() => {
    fetchData();
    fetchPreTitles();
  }, []);

  // Functions
  const fetchData = async () => {
    // Fetching User
    // Fetching AbstractDocument
    const abstractRes = await getAbstractDocument(abstractId);
    const authors = abstractRes.authors.items;
    // NB: Non so se serve ma ordino gli autori per position
    authors.sort((a, b) => a.position - b.position);
    abstractRes.authors = authors;

    const affiliations = abstractRes.affiliations.items;
    abstractRes.affiliations = affiliations;
    console.log('🚀 ~ fetchData ~ abstractRes:', abstractRes);

    setAbstractDocument(abstractRes);
  };

  const onClickBack = () => {
    if (location.state?.backPath) return history.push(location.state?.backPath);
    switch (from) {
      case 'list':
        history.push(`/events/${eventId}/abstracts-reviewers`);
        break;

      default:
        history.push(`/events/${eventId}/abstracts`);
        break;
    }
  };

  useEffect(() => {
    if (abstractDocument) {
      const reviewsData = abstractDocument.reviews?.items.map((review) => ({
        ...review,
        scores: review.scores,
      }));

      const reviewCriteriaModel =
        abstractDocument.abstractService?.reviewCriteriaModel;

      setReviews({
        reviewCriteriaModel: reviewCriteriaModel,
        reviews: reviewsData,
      });
      setViewReview(abstractDocument.abstractService?.isViewAbstractResult);
    }
  }, [abstractDocument]);

  return (
    /* TODO maxHeight: 0px  capire se si può fare meglio */
    <Grid container style={{ width: '90%' }}>
      <Grid item xs={12}>
        <div style={{ flex: 1, marginTop: 30 }}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <Tooltip title={i18n.page.back.tooltip}>
              <AimIconAndTextButton
                style={{ padding: 8 }}
                variant="none"
                text={i18n.page.back.label}
                onClick={() => onClickBack()}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>

            {/* If you need a Button on the top-right of the page, you are welcome */}

            {/* <Tooltip title={i18n.page.back.tooltip}>
              <AimIconAndTextButton
                style={{ padding: 8 }}
                variant="redFill"
                text={i18n.page.back.label}
                onClick={() => {}}
              >
                <CloseIcon />
              </AimIconAndTextButton>
            </Tooltip> */}
          </div>
          <AimTitleAndButtons title={i18n.page.title} />
        </div>
        <AbstractDetailPage
          intl={intl}
          abstractDocument={abstractDocument}
          maxHeight={'unset'}
          printWithoutReviewsFrontOffice
          reviews={reviews}
          hideFilesFromPage
          hideReviewsFromPage={!viewReview}
          preTitles={preTitles}
          affiliations={abstractDocument?.affiliations || []}
        />
      </Grid>
    </Grid>
  );
}

import React, { useRef, useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';

import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';

import ViewIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { MainContainer, InnerContainer } from './agency/shared/Containers';

import { useForm } from 'react-hook-form';
import { agencyGqlHelper } from './agency/shared/agencyGqlHelper';
import {
  getAgencyService,
  updateAgencyGroup,
  createAgencyGroup,
  getSponsorList,
  updateSponsorList,
} from './agency/agencyHomepage/gqlHelper';
import { translation, AgencyCard } from './agency/agencyHomepage';

import {
  AimIconAndTextButton,
  AimTypography,
  AimSnackbarSeverity,
  AimSnackbar,
  CustomIntl,
  AimCheckbox,
  AimTextFieldForm,
  AimDataGrid,
  AimIconButton,
  AimDialog,
} from '@aim/components';
import { Typography } from '@material-ui/core';
import { sortBy } from 'lodash';
import { constants } from '@aim/common';
const formControlStyle = { width: 'calc(100% - 10px)' };

const AgencyHomepage = () => {
  //Hooks
  const history = useHistory();
  const location = useLocation();
  const { eventId, agencyId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.agencyHomepage(intl);
  const agencyHelper = agencyGqlHelper();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      groupName: '',
      client: '',
      code: '',
    },
  });

  //State
  const [agency, setAgency] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  // const [paymentType, setPaymentType] = useState();
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [agencyService, setAgencyService] = useState(null);

  const [deleteDialog, setDeleteDialog] = useState({ isOpen: false });
  const [createDialog, setCreateDialog] = useState({ isOpen: false });
  const [sponsorCode, setSponsorCode] = useState(false);
  const [prepaidBracketEnd, setPrepaidBracketEnd] = useState(null);
  //Ref
  const submitButton = useRef();
  //Effects
  useEffect(() => {
    fetchAgency();
  }, []);

  const fetchAgency = async () => {
    const respAgency = await agencyHelper.getAgencySpecifications({
      id: eventId,
      agencyId,
    });

    setAgency(respAgency);
    setPrepaidBracketEnd(
      sortBy(
        respAgency.event.fee.feeBracketModels.items,
        (i) => new Date(i.end)
      ).shift()?.end
    );
    setAgencyService(respAgency.event.agencyService);
    // get penalties
    // calc budget: subtract penalties if closing prepaid group has passed
  };

  const loadColumns = ({ labels }) => [
    { field: 'name', headerName: labels.page.groups.grid.name, width: 200 },
    { field: 'client', headerName: labels.page.groups.grid.client, width: 200 },
    {
      field: 'status',
      headerName: labels.page.groups.grid.status,
      width: 200,
      valueGetter: ({ row }) =>
        (
          Object.values(constants.GroupStatuses).find(
            (s) => s.id === row.status
          ) || constants.GroupStatuses.OPEN
        ).label(intl),
    },
    {
      field: 'nPax',
      headerName: labels.page.groups.grid.nPax,
      width: 200,
      valueGetter: ({ row }) => row.participations.items.length,
    },
    // {
    //   field: 'totalExpence',
    //   headerName: labels.page.groups.grid.totalExpence,
    //   width: 200,
    // },
    // {
    //   field: 'budgetPaid',
    //   headerName: labels.page.groups.grid.budgetPaid,
    //   width: 200,
    // },
    // {
    //   field: 'budgetRemaining',
    //   headerName: labels.page.groups.grid.budgetRemaining,
    //   width: 200,
    // },
    // {
    //   field: 'amontDue',
    //   headerName: labels.page.groups.grid.amontDue,
    //   width: 200,
    // },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      pinned: true,
      hideSortIcons: true,
      renderCell: ({ row }) => {
        // eventLists, eventInfo, eventId
        return renderRowButtons(row, i18n, history, setCreateDialog);
      },
    },
  ];

  const renderRowButtons = (row, i18n, history, setCreateDialog) => {
    return row?.status !== constants.GroupStatuses.CANCELLED.id ? (
      <>
        <Tooltip title={i18n.actions.details}>
          <AimIconButton
            variant="primary"
            small={1}
            onClick={() =>
              history.push(
                row.type === 'sponsor-list'
                  ? `/events/${eventId}/agency/${agencyId}/sponsor-lists/${row.id}`
                  : `/events/${eventId}/agency/${agencyId}/groups/${row.id}`
              )
            }
          >
            <ViewIcon />
          </AimIconButton>
        </Tooltip>
        <Tooltip title={i18n.actions.edit}>
          <AimIconButton
            variant="primary"
            small={1}
            disabled={row.type === 'sponsor-list'}
            onClick={() => {
              reset({ groupName: row.name, client: row.client });
              setCreateDialog({ isOpen: true, item: row });
            }}
          >
            <EditIcon />
          </AimIconButton>
        </Tooltip>
        <Tooltip title={i18n.actions.delete}>
          <AimIconButton
            variant="secondary"
            small={1}
            onClick={() => setDeleteDialog({ isOpen: true, item: row })}
          >
            <CloseIcon />
          </AimIconButton>
        </Tooltip>
      </>
    ) : null;
  };

  const columns = useMemo(
    () =>
      loadColumns({
        labels: i18n,
      }),
    [i18n]
  );

  const rows = useMemo(() => {
    const agencyGroups =
      agency && agency.agencyGroups && agency.agencyGroups.items
        ? agency.agencyGroups.items
        : [];

    const sponsorLists =
      agency && agency.sponsorLists && agency.sponsorLists.items
        ? agency.sponsorLists.items
        : [];

    const nextAgencyGroups = agencyGroups.map((a) => ({ ...a, type: 'group' }));
    const nexSponsorLists = sponsorLists.map((s) => ({
      ...s,
      id: s.id,
      name: s.name,
      client: s.sponsor && s.sponsor.name ? s.sponsor.name : '',
      type: 'sponsor-list',
    }));
    const rows = [...nextAgencyGroups, ...nexSponsorLists];

    return rows;
  }, [agency.agencyGroups, agency.sponsorList]);

  const handleGroupDelete = async () => {
    try {
      if (deleteDialog?.item?.type === 'sponsor-list') {
        await updateSponsorList({
          id: deleteDialog.item.id,
          sponsorListAgencyId: null,
        });
      } else if (deleteDialog?.item?.type === 'group') {
        await updateAgencyGroup({
          id: deleteDialog.item.id,
          status: constants.GroupStatuses.CANCELLED.id,
        });
      }
      setDeleteDialog({ isOpen: false });
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.deleteAgencyGroup,
      });
      fetchAgency();
    } catch (err) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: 'Error',
      });
    }
  };

  const onSubmit = async (fields) => {
    try {
      if (!fields.code) {
        if (createDialog?.item?.id) {
          await updateAgencyGroup({
            id: createDialog.item.id,
            name: fields.groupName,
            client: fields.client,
          });
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.updateGroup,
          });
        } else {
          await createAgencyGroup({
            agencyGroupAgencyId: agencyId,
            name: fields.groupName,
            client: fields.client,
            status: constants.GroupStatuses.OPEN.id,
          });
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.createGroup,
          });
        }
      } else if (fields.code) {
        const sponsorList = await getSponsorList(eventId, fields.code);

        if (sponsorList) {
          await updateSponsorList({
            id: sponsorList.id,
            sponsorListAgencyId: agencyId,
          });
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.success,
            message: i18n.snackbar.createGroup,
          });
        } else {
          setSnackbar({
            isOpen: true,
            severity: AimSnackbarSeverity.error,
            message: i18n.snackbar.sponsorCodeNotFound,
          });

          return;
        }
      }
      await fetchAgency();
    } catch (err) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: 'Error',
      });
    }
    setCreateDialog({ isOpen: false });
  };

  return (
    <MainContainer>
      <div>
        <InnerContainer>
          <div style={{ display: 'flex-root', marginTop: 20 }}>
            <Tooltip title={i18n.page.back.tooltip}>
              <AimIconAndTextButton
                variant="text"
                text={i18n.page.back.button}
                onClick={() => history.push(`/events/${eventId}/landing`)}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
          </div>
          {/* <Grid container>
            <Grid item xs={12}>
              <AimTypography variant={'h2'}>{i18n.page.title}</AimTypography>
            </Grid>
          </Grid> */}
          {/* <Grid container>
            <Grid item xs={12}>
              <AgencyCard
                title={renderPrepaidHeader()}
                style={{ whiteSpace: 'nowrap' }}
                textStyle={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                }}
                variant="h3"
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{
                    fontWeight: 'normal',
                    fontSize: '40px',
                    textAlign: 'left',
                    padding: '0px 10px',
                  }}
                >
                  {dataHelper.formatNumber(
                    dataHelper.decodeDbNumber(agency.prepaidBudgetNew ?? 0)
                  )}
                </AimTypography>
              </AgencyCard>
            </Grid>
          </Grid> */}
          <Grid container>
            {/* <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.prepaidUntil}
                style={{ whiteSpace: 'nowrap', flex: 1, marginRight: '10px' }}
                textStyle={{
                  textAlign: 'center',
                }}
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {agency?.firstDeadline &&
                    format(new Date(agency?.firstDeadline), 'dd/MM/yyyy')}
                </AimTypography>
              </AgencyCard>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.canBuyPrepaid}
                style={{
                  flex: 1,
                  marginRight: '10px',
                  backgroundColor: '#f8f6fa',
                }}
                textStyle={{
                  textAlign: 'center',
                  fontWeight: '400',
                }}
                variant="h2"
                noBorder
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {prepaidBracketEnd
                    ? format(new Date(prepaidBracketEnd), 'dd/MM/yyyy')
                    : ''}
                </AimTypography>
              </AgencyCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.closingGroups}
                style={{
                  flex: 1,
                  marginRight: '10px',
                  backgroundColor: '#f8f6fa',
                }}
                textStyle={{
                  textAlign: 'center',
                  fontWeight: '400',
                }}
                variant="h2"
                noBorder
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {agencyService &&
                    agencyService.groupDefinitionDeadline &&
                    format(
                      new Date(agencyService.groupDefinitionDeadline),
                      'dd/MM/yyyy'
                    )}
                </AimTypography>
              </AgencyCard>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{ display: 'flex', height: '140px', marginTop: 10 }}
            >
              <AgencyCard
                title={i18n.page.freezeDeadline}
                style={{ flex: 1, backgroundColor: '#f8f6fa' }}
                textStyle={{
                  textAlign: 'center',
                  fontWeight: '400',
                }}
                variant="h2"
                noBorder
              >
                <AimTypography
                  variant="h1"
                  boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
                >
                  {/* {agencyService && agencyService.freezeDeadline} */}
                  {agencyService && agencyService.freezeDeadline
                    ? format(
                        new Date(agencyService.freezeDeadline),
                        'dd/MM/yyyy'
                      )
                    : null}
                </AimTypography>
              </AgencyCard>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                // height: '140px',
                marginTop: 30,
              }}
            >
              <AimTypography variant="h6">
                {i18n.page.instructions}
              </AimTypography>
              <AimTypography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: agencyService && agencyService.penaltyInstructions,
                  }}
                />
              </AimTypography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              style={{
                display: 'flex',
                marginTop: '30px',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <AimTypography variant="h2">
                  {i18n.page.groups.title}
                </AimTypography>
                <AimIconButton
                  onClick={() => setCreateDialog({ isOpen: true })}
                >
                  <AddIcon />
                </AimIconButton>
              </div>
              <div style={{ height: 500 }}>
                <AimDataGrid
                  columns={columns}
                  rows={rows}
                  tableName="groups"
                  disableMultipleSelections
                  pinnedColumns={{ left: [], right: ['actions'] }}
                />
              </div>
            </Grid>
          </Grid>
          <AimDialog
            open={createDialog.isOpen}
            title={
              createDialog?.item
                ? i18n.dialog.create.titleEdit
                : i18n.dialog.create.titleCreate
            }
            onAgree={() => submitButton.current?.click()}
            onClose={() => {
              setCreateDialog({ isOpen: false });
              setSponsorCode(false);
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                maxWidth: '300px',
              }}
            >
              {!createDialog?.item ? (
                <>
                  <AimTypography variant="text">
                    {i18n.dialog.create.text}
                  </AimTypography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '10px',
                      gap: '15px',
                    }}
                  >
                    <AimCheckbox
                      variant="grey"
                      onChange={(_, a) => setSponsorCode(a)}
                    />
                    <Typography variant="text">
                      {intl.formatMessage({
                        description:
                          'i have a sponsor code agency add group message',
                        defaultMessage: 'I have a sponsor code',
                      })}
                    </Typography>
                  </div>
                </>
              ) : null}
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                {sponsorCode ? (
                  <AimTextFieldForm
                    disabled={!sponsorCode}
                    control={control}
                    name="code"
                    label={i18n.dialog.create.fields.code}
                    formControlStyle={formControlStyle}
                  />
                ) : (
                  <>
                    <AimTextFieldForm
                      control={control}
                      name="groupName"
                      label={i18n.dialog.create.fields.groupName}
                      formControlStyle={formControlStyle}
                    />
                    <AimTextFieldForm
                      control={control}
                      name="client"
                      label={i18n.dialog.create.fields.client}
                      formControlStyle={formControlStyle}
                    />
                  </>
                )}
                <button
                  style={{ display: 'none' }}
                  ref={submitButton}
                  type="submit"
                >
                  {intl.formatMessage({
                    description: 'add button',
                    defaultMessage: 'Add',
                  })}
                </button>
              </form>
            </div>
          </AimDialog>
          <AimDialog
            open={deleteDialog.isOpen}
            title={i18n.dialog.delete.title}
            onAgree={() => handleGroupDelete()}
            onClose={() => setDeleteDialog({ isOpen: false })}
          >
            <AimTypography variant={'text'}>
              {i18n.dialog.delete.text}
            </AimTypography>
          </AimDialog>
          <AimSnackbar
            open={snackbar.isOpen}
            onClose={() => setSnackbar({ isOpen: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </AimSnackbar>
        </InnerContainer>
      </div>
    </MainContainer>
  );
};

export default AgencyHomepage;

export const translation = {
  aimTablePage: (intl) => ({
    header: {
      backButton: intl.formatMessage({
        description: 'Back button label',
        defaultMessage: 'Back',
      }),
      title: intl.formatMessage({
        description: 'Group members title',
        defaultMessage: 'Group members',
      }),
      action: {
        goToFeeTable: {
          label: intl.formatMessage({
            description: 'go to fee table button',
            defaultMessage: 'GO TO FEE TABLE',
          }),
          tooltip: intl.formatMessage({
            description: 'go to fee table button',
            defaultMessage: 'go to fee table',
          }),
        },
        goToWelcomePage: {
          label: intl.formatMessage({
            description: 'go to welcome page button',
            defaultMessage: 'GO TO WELCOME PAGE',
          }),
          tooltip: intl.formatMessage({
            description: 'go to welcome page button',
            defaultMessage: 'go to welcome page',
          }),
        },
        import: {
          tooltip: intl.formatMessage({
            description: 'tooltip import',
            defaultMessage: 'Import',
          }),
        },
        add: {
          tooltip: intl.formatMessage({
            description: 'label add member',
            defaultMessage: 'Add',
          }),
        },
        pay: {
          label: intl.formatMessage({
            description: 'pay amount due',
            defaultMessage: 'PAY AMOUNT DUE',
          }),
        },
        close: {
          label: intl.formatMessage({
            description: 'close',
            defaultMessage: 'CLOSE',
          }),
        },
      },
    },
    bigStats: {},
    stats: {
      amountDue: intl.formatMessage({
        description: 'amount due',
        defaultMessage: 'Amount due',
      }),
      participationsAvailable: intl.formatMessage({
        description: 'participations to configure',
        defaultMessage: 'Participations to configure',
      }),
      status: intl.formatMessage({
        description: 'status',
        defaultMessage: 'Status',
      }),
      minMembers: intl.formatMessage({
        description: 'minimum number of members',
        defaultMessage: 'Minimum number to close the group',
      }),
      closingDate: intl.formatMessage({
        description: 'closing date',
        defaultMessage: 'Closing date',
      }),
      postCloseChangeDeadline: intl.formatMessage({
        description: 'post closing paid changes until',
        defaultMessage: 'Post-closing paid changes until',
      }),
      modificationRequests: intl.formatMessage({
        description: 'pending modification requests',
        defaultMessage: 'Pending modification requests',
      }),
      requestsAccepted: intl.formatMessage({
        description: 'requests accepted',
        defaultMessage: 'Requests accepted',
      }),
    },
    table: {
      empty: intl.formatMessage({
        description: 'Table empty',
        defaultMessage: 'No data',
      }),
      columns: {
        username: intl.formatMessage({
          description: 'username',
          defaultMessage: 'ID',
        }),
        givenName: intl.formatMessage({
          description: 'given name',
          defaultMessage: 'Name',
        }),
        familyName: intl.formatMessage({
          description: 'family name',
          defaultMessage: 'Surname',
        }),
        email: intl.formatMessage({
          description: 'member email',
          defaultMessage: 'Email',
        }),
        modificationRequestNameChangeUsername: intl.formatMessage({
          description: 'name change',
          defaultMessage: 'Name change',
        }),
        profile: intl.formatMessage({
          description: 'member profile',
          defaultMessage: 'Profile',
        }),
        createdAt: intl.formatMessage({
          description: 'member is created at',
          defaultMessage: 'Date added',
        }),
        modification: intl.formatMessage({
          description: 'modification',
          defaultMessage: 'Modification',
        }),
        notes: intl.formatMessage({
          description: 'notes',
          defaultMessage: 'Notes',
        }),
        phone: intl.formatMessage({
          description: 'phone',
          defaultMessage: 'Phone',
        }),
      },
      rowAction: {
        nameChange: {
          tooltip: intl.formatMessage({
            description: 'change name tooltip',
            defaultMessage: 'Change name',
          }),
        },
        edit: {
          tooltip: intl.formatMessage({
            description: 'edit tooltip',
            defaultMessage: 'Edit',
          }),
        },
        delete: {
          tooltip: intl.formatMessage({
            description: 'delete tooltip',
            defaultMessage: 'Delete',
          }),
        },
      },
    },
    dialog: {
      delete: {
        title: intl.formatMessage({
          description: 'Delete Participants title',
          defaultMessage: 'Delete Participants',
        }),
        message: intl.formatMessage({
          description: 'Dialog delete message',
          defaultMessage: 'Are you sure?',
        }),
        agreeText: intl.formatMessage({
          description: 'Dialog delete onAgreeText',
          defaultMessage: 'Delete',
        }),
        disagreeText: intl.formatMessage({
          description: 'Dialog delete onDisagreeText',
          defaultMessage: 'Cancel',
        }),
      },
    },
    modal: {
      calculator: {
        title: intl.formatMessage({
          description: 'buy budget',
          defaultMessage: 'Buy budget',
        }),
        grid: {
          profile: {
            label: intl.formatMessage({
              description: 'profile',
              defaultMessage: 'Profile',
            }),
            placeholder: intl.formatMessage({
              description: 'select a profile',
              defaultMessage: 'Select a profile',
            }),
          },
          participationType: {
            label: intl.formatMessage({
              description: 'participation type',
              defaultMessage: 'Participation type',
            }),
            placeholder: intl.formatMessage({
              description: 'select a participation',
              defaultMessage: 'Select a participation',
            }),
          },
          feeDateRange: {
            label: intl.formatMessage({
              description: 'ticket',
              defaultMessage: 'Ticket',
            }),
            placeholder: intl.formatMessage({
              description: 'select a ticket',
              defaultMessage: 'Select a ticket',
            }),
          },
          price: intl.formatMessage({
            description: 'price',
            defaultMessage: 'Price (€)',
          }),
          quantity: {
            label: intl.formatMessage({
              description: 'quantity',
              defaultMessage: 'Quantity',
            }),
            placeholder: intl.formatMessage({
              description: 'quantity',
              defaultMessage: 'Quantity',
            }),
          },
          total: {
            label: intl.formatMessage({
              description: 'total',
              defaultMessage: 'Total (€)',
            }),
          },
        },
        buttons: {
          cancel: intl.formatMessage({
            description: 'agency purchase calculate budget dialog cancel',
            defaultMessage: 'Cancel',
          }),
          buy: intl.formatMessage({
            description: 'agency purchase calculate budget dialog buy',
            defaultMessage: 'Buy',
          }),
        },
      },
      prepaidBudget: {
        title: intl.formatMessage({
          description: 'prepaid budget',
          defaultMessage: 'Prepaid budget',
        }),
        description: intl.formatMessage({
          description: 'prepaid budget ',
          defaultMessage: 'Prepaid budget',
        }),
        label: intl.formatMessage({
          description: 'budget',
          defaultMessage: 'budget',
        }),
        buttons: {
          close: intl.formatMessage({
            description: 'close',
            defaultMessage: 'Close',
          }),
          buy: intl.formatMessage({
            description: 'buy',
            defaultMessage: 'buy',
          }),
        },
      },
      groups: {
        title: intl.formatMessage({
          description: 'groups',
          defaultMessage: 'Groups',
        }),
        description: intl.formatMessage({
          description: 'prepaid budget ',
          defaultMessage: 'Prepaid budget',
        }),
        label: intl.formatMessage({
          description: 'choose the group',
          defaultMessage: 'choose the group',
        }),
        buttons: {
          cancel: intl.formatMessage({
            description: 'close',
            defaultMessage: 'Close',
          }),
          addParticipant: intl.formatMessage({
            description: 'add participant',
            defaultMessage: 'Add participant',
          }),
        },
      },
      agencyPrepaidBudget: {
        residualBudget: intl.formatMessage({
          description: 'residual budget',
          defaultMessage: 'Residual budget',
        }),
        availableBudgetText1: intl.formatMessage({
          description: 'you have',
          defaultMessage: 'You have',
        }),
        availableBudgetText2: intl.formatMessage({
          description:
            'available from the prepaid budget. Decide whether to use it for the current transaction:',
          defaultMessage:
            'available from the prepaid budget. Decide whether to use it for the current transaction:',
        }),
        useRemainingBudget: intl.formatMessage({
          description: 'use the remaining budget',
          defaultMessage: 'Use the remaining budget',
        }),
        buttons: {
          close: intl.formatMessage({
            description: 'close button dialog',
            defaultMessage: 'Close',
          }),
          buy: intl.formatMessage({
            description: 'buy',
            defaultMessage: 'buy',
          }),
        },
      },
    },

    closeGroupConfirmDialog: {
      title: intl.formatMessage({
        description: 'confirm closing group title',
        defaultMessage: 'Confirm closing group',
      }),
      message: intl.formatMessage({
        description: 'confirm closing group text',
        defaultMessage: 'Are you sure you want to close group?',
      }),
    },
    snackbar: {
      delete: {
        success: intl.formatMessage({
          description: 'Snackbar delete message',
          defaultMessage: 'Delete ok',
        }),
        error: intl.formatMessage({
          description: 'Snackbar delete message',
          defaultMessage: 'Some error',
        }),
      },
      buyBudgetSuccess: intl.formatMessage({
        description: 'buy budget success',
        defaultMessage: 'Buy budget success',
      }),
      closingGroupSuccess: intl.formatMessage({
        description: 'closing group success',
        defaultMessage: 'Closing group success',
      }),
      payingPenaltiesSuccess: intl.formatMessage({
        description: 'paying penalties success',
        defaultMessage: 'Paying penalties success',
      }),
      closeGroup: {
        success: intl.formatMessage({
          description: 'group closed successfully',
          defaultMessage: 'Group closed successfully',
        }),
        errorMessage: intl.formatMessage({
          description: 'group closing error',
          defaultMessage: 'Group closing error',
        }),
      },
    },
    alert: {
      notEnoughBudget: intl.formatMessage({
        description:
          'not Enough Budget: remove participant or buy prepaid budget',
        defaultMessage:
          'Not Enough Budget: remove participant or buy prepaid budget',
      }),
    },
  }),
};

import { aws, appState, constants, utilities } from '@aim/common';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getEventDataQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      type
      fee {
        id
        vat {
          id
          amount
        }
        feeDateRanges {
          items {
            id
            start
            end
            label
          }
        }
      }
      profiles (filter:{clusters: {contains: "${constants.Clusters.Groups.id}"}, isDeleted: {ne: true}}) {
        items {
          name
          id
          profileFeeBrackets {
            items {
              id
              priceOnAir
              priceOnSite
              profileFeeConfiguration {
                id
                isActive
              }
              feeBracket {
                createdAt
                feeBracketModel {
                  end
                  name
                  start
                }
                feeDateRange {
                  id
                  start
                  end
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getSponsorListsQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $code: String!) {
    getEvent(id: $id) {
      id
      sponsorLists(filter: { isDeleted: { ne: true }, code: { eq: $code } }) {
        items {
          id
          code
          name
        }
      }
    }
  }
`;

const updateSponsorListsMutation = /* GraphQL */ `
  mutation updateSponsorList(
    $input: UpdateSponsorListInput!
    $condition: ModelSponsorListConditionInput
  ) {
    updateSponsorList(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateAgencyGroupMutation = /* GraphQL */ `
  mutation updateAgencyGroup(
    $input: UpdateAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    updateAgencyGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

const createAgencyGroupMutation = /* GraphQL */ `
  mutation createAgencyGroup(
    $input: CreateAgencyGroupInput!
    $condition: ModelAgencyGroupConditionInput
  ) {
    createAgencyGroup(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getEventData = (id) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventDataQuery,
      variables: { id },
    })
      .then((response) => {
        const { data } = response;
        resolve({
          profiles: data.getEvent.profiles.items.map((p) => ({
            ...p,
            profileFeeBrackets: {
              items: p.profileFeeBrackets.items.map((b) => ({
                ...b,
                priceOnAir: utilities.decodeDbNumber(b.priceOnAir),
                priceOnSite: utilities.decodeDbNumber(b.priceOnSite),
              })),
            },
          })),
          feeDateRanges: data.getEvent.fee.feeDateRanges.items,
          eventType: data.getEvent.type,
          vat: {
            vatRate: utilities.decodeDbNumber(data.getEvent.fee.vat.amount),
            vatId: data.getEvent.fee.vat.id,
          },
        });
      })
      .catch((e) => {
        console.error('event-profiles', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getSponsorList = (id, code, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getSponsorListsQuery,
      variables: { id, code },
    })
      .then((response) =>
        resolve(response.data.getEvent.sponsorLists.items?.[0])
      )
      .catch((e) => {
        console.error('get-sponsor-lists', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateSponsorList = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateSponsorListsMutation,
      variables: { input },
    })
      .then((response) => resolve(response))
      .then((e) => {
        console.error('updateSponsorLists', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateAgencyGroup = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateAgencyGroupMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateAgencyService))
      .catch((e) => {
        console.error('update-agency-group', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createAgencyGroup = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createAgencyGroupMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createAgencyGroup))
      .catch((e) => {
        console.error('create-agency-group', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

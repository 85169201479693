import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { utcToZonedTime, format } from 'date-fns-tz';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimTitleAndButtons,
  AimIconAndTextButton,
  CustomIntl,
  styled,
  TicketInfo,
} from '@aim/components';

import { appState, constants, utilities } from '@aim/common';

// import { getProduct } from './myAdditionalServiceTicket/additionalServiceMyTicketGqlHelper';

import {
  getParticipationGuests,
  getAdditionalService,
  getProduct,
} from './shared/gqlHelper';

import translation from './myAdditionalServiceTicket/translation';

const { PaymentTypesWithTranslations, PaymentStatuses, Services } = constants;

const formatDate = (dateString) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  const date = utcToZonedTime(new Date(dateString), currentTimezone);

  return format(date, 'dd/MM/yyyy', {
    timeZone: currentTimezone,
  });
};

const CustomButton = styled(AimIconAndTextButton)({
  [`@media print`]: {
    display: 'none',
  },
});

const MyAdditionalServiceTicket = () => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.myTicket(intl);
  const history = useHistory();
  const { eventId, participationId, productId } = useParams();

  const [additionalServicesData, setAdditionalServicesData] = useState();
  const [title, setTitle] = useState();

  const fetchData = async () => {
    const participationResponse = await getParticipationGuests(participationId);
    const responseProduct = await getProduct(productId);
    const nextProduct = {
      ...responseProduct,
      productOwner: {
        id: participationResponse.id,
        givenName: participationResponse.givenName,
        familyName: participationResponse.familyName,
        email: participationResponse.email,
        phone: participationResponse.phone,
      },
    };

    const responseAdditionalService = await getAdditionalService(
      nextProduct.productId
    );

    setTitle(responseAdditionalService.title);

    const result = [
      responseAdditionalService.typology ===
      constants.AdditionalServicesServiceTypology.scientific.id
        ? {
            ...nextProduct,
            additionalService: responseAdditionalService || {},
            gateway:
              participationResponse.event.gateways.items.find(
                (g) => g.service === 'SCIENTIFIC_EVENT'
              ) || {},
          }
        : {
            ...nextProduct,
            additionalService: responseAdditionalService || {},
            gateway:
              participationResponse.event.gateways.items.find(
                (g) => g.service === 'SOCIAL_EVENT'
              ) || {},
          },
    ];

    const ADDITIONAL_SERVICES_data = {
      title: Services.SOCIAL_EVENT.label(intl),
      selected: false,
      blockKey: 'SOCIAL_EVENTS',
      data: result,
    };
    setAdditionalServicesData(ADDITIONAL_SERVICES_data);
  };

  useEffect(() => {
    fetchData();
  }, [participationId]);

  const TitleAndButton = () => (
    <div style={{ marginBottom: 10 }}>
      <AimTitleAndButtons
        titleBoxStyle={{ marginLeft: 0 }}
        title={title || ''}
      />
    </div>
  );

  const getBasicData = ({ product, intl }) => {
    const { givenName, familyName, email, phone } = product?.productOwner || {};
    // product?.payment?.BillingInformation || {};

    const clientInformations = {
      givenName,
      familyName,
      email,
      phone,
    };

    const billingInformations =
      //skip payment details in free payments
      product?.payment?.BillingInformation && product?.payment?.amount > 0
        ? {
            ...(product?.payment?.BillingInformation || {}),
            isVATExent: product?.payment?.BillingInformation?.isVatEvent,
            invoiceTo: product?.payment?.BillingInformation?.invoiceTo
              ? Object.values(constants.InvoiceToType)
                  .find(
                    (it) =>
                      it.id === product?.payment?.BillingInformation?.invoiceTo
                  )
                  ?.label(intl)
              : null,
            reference:
              product?.payment?.BillingInformation?.invoiceTo ===
              constants.InvoiceToType.COMPANY.id
                ? product?.payment?.BillingInformation.reference
                : givenName,
          }
        : null;

    const paymentDetails =
      //skip payment details in free payments
      product?.payment && product?.payment?.amount > 0
        ? {
            // ...(product?.payment || {}),
            total: utilities.formatNumber(
              utilities.decodeDbNumber(product?.payment?.amount)
            ),
            paymentMethod:
              Object.values(PaymentTypesWithTranslations)
                .find((x) => x.key === product?.payment?.paymentType)
                .label(intl) || product?.payment?.paymentType,
            paymentStatus: product?.payment?.paymentStatus
              ? Object.values(PaymentStatuses)
                  .find((x) => x.key === product?.payment?.paymentStatus)
                  .label(intl) || product?.payment?.paymentStatus
              : null,
            paymentReason: product?.gateway?.shop?.ibanCausal,
            IBAN: product?.gateway?.shop?.iban,
            addressedTo: product?.gateway?.shop?.addressedTo,
            paymentObject: product?.payment,
          }
        : null;

    return { clientInformations, billingInformations, paymentDetails };
  };

  return (
    <div
      style={{
        flex: 1,
        maxWidth: 1320,
        width: 'calc(100% - 80px)',
      }}
    >
      <Tooltip title={i18n.page.tooltip}>
        <CustomButton
          variant="none"
          text={intl.formatMessage({
            description: 'back',
            defaultMessage: 'back',
          })}
          style={{
            padding: 0,
            marginLeft: 0,
          }}
          onClick={() =>
            history.push(`/events/${eventId}/user/${participationId}/services`)
          }
        >
          <ArrowBack />
        </CustomButton>
      </Tooltip>
      <TitleAndButton />
      {additionalServicesData &&
        additionalServicesData.data.map((product, ndx) => {
          const {
            clientInformations,
            billingInformations,
            paymentDetails,
          } = getBasicData({ product, intl });

          const additionalServicesInformations = {
            title: product?.additionalService?.title,
            description: product?.additionalService?.description,
            date: formatDate(product?.additionalService?.dateService),
            startTime: product?.additionalService?.startTime,
            endTime: product?.additionalService?.endTime,
            locationName: product?.additionalService?.locationName,
            locationAddress: product?.additionalService?.locationAddress,
            paymentType: paymentDetails?.paymentMethod.toLowerCase() || '',
            payment: paymentDetails,
            billingDocument: product?.billingDocument,
            creditNotesDocument: product?.creditNotesDocument,
            serviceId: product?.serviceId,
          };

          return (
            <TicketInfo
              key={ndx}
              showPrice={product.showPrice}
              {...{
                intl,
                clientInformations,
                billingInformations,
                paymentDetails,
                additionalServicesInformations,
                module: 'additionalServices',
                i18n,
              }}
            />
          );
        })}
    </div>
  );
};

export default MyAdditionalServiceTicket;

import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getSponsorListDataQuery = /* GraphQL */ `
  query GetSponsorListData($id: ID!, $eventId: ID!, $type: String!) {
    getSponsor(id: $id) {
      id
      freeListMembers
      sponsorList {
        id
      }
      event {
        sponsorServiceBookingDuration
        sponsorListService {
          description
          id
          isBankTransferEnabled
          isCreditCardEnabled
          publicationDate
          name
          vatRate
          documents {
            items {
              id
              name: originalName
              size
              extension
            }
          }
        }
      }
      purchases {
        items {
          id
          eventId
          buyOperations {
            items {
              id
              sponsorListPurchase {
                id
                feeDateRange {
                  id
                  label
                }
                purchasedSubscriptions
              }
            }
          }
          paymentType
          date
          purchaseId
          payment {
            id
            paymentId
            paymentIdMonetaOnLine
            paymentIdIngenico
            paymentIdGpWebpay
            paymentError
            date
            stepName
            stepNote
            paymentType
            amount
            createdAt
            updatedAt
          }
        }
      }
    }
    getDeadline(externalId: $eventId, type: $type) {
      value
      externalId
    }
  }
`;

const updateSponsorListServiceMutation = /* GraphQL */ `
  mutation UpdateSponsorListService(
    $input: UpdateSponsorListServiceInput!
    $condition: ModelSponsorListServiceConditionInput
  ) {
    updateSponsorListService(input: $input, condition: $condition) {
      id
    }
  }
`;

const createSponsorListPurchaseMutation = /* GraphQL */ `
  mutation CreateSponsorListPurchase(
    $input: CreateSponsorListPurchaseInput!
    $condition: ModelSponsorListPurchaseConditionInput
  ) {
    createSponsorListPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateSponsorListPurchaseMutation = /* GraphQL */ `
  mutation CreateSponsorListPurchase(
    $input: UpdateSponsorListPurchaseInput!
    $condition: ModelSponsorListPurchaseConditionInput
  ) {
    updateSponsorListPurchase(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getSponsorListData = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getSponsorListDataQuery,
      variables: { ...input, type: constants.Clusters.SponsorList.id },
    })
      .then((response) => {
        return resolve({
          ...response.data.getSponsor,
          id: response.data.getSponsor.sponsorList.id,
          deadlines: response.data?.getDeadline
            ? JSON.parse(response.data.getDeadline.value)
            : {},
        });
      })
      .catch((e) => {
        console.error('event-sponsor-list-service', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createSponsorListPurchase = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createSponsorListPurchaseMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createSponsorListPurchase))
      .catch((e) => {
        console.error('create-sponsor-list-purchase', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateSponsorListPurchase = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateSponsorListPurchaseMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateSponsorListPurchase))
      .catch((e) => {
        console.error('update-sponsor-list-purchase', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateSponsorListService = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateSponsorListServiceMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateSponsorList))
      .catch((e) => {
        console.error('update-sponsor-list-service', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

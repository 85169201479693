import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PersonIcon from '@material-ui/icons/Person';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import logo from './logo.png';

import { isAfter, parseISO } from 'date-fns';

import {
  AimTypography,
  CustomIntl,
  useAimMediaQuery,
  theme,
} from '@aim/components';
import { appState, constants, aws } from '@aim/common';

import translation from './translation';
import { getEventParticipations } from './gqlHelper';

const pNew = {
  givenName: '',
  familyName: '',
  email: '',
  isParticipant: false,
  isSpeaker: false,
  isReviewer: false,
};

const useStyles = makeStyles(() => ({
  cardRoot: (props) => ({
    paddingTop: 40,
    alignItems: 'start',
    width: '180px',
    justifyContent: 'center',
    height: '180px',
    borderRadius: 1,
    cursor: 'pointer',
    boxShadow: '0 0 black',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: theme.colors.light.grey,
    // border: isSelected
    //   ? 'solid 2px ' + orTheme.palette.action.selected
    //   : 'solid 2px transparent',
    position: 'relative',
  }),
  cardContentRoot: {
    flex: 1,
    flexDirection: 'column',
    padding: '0!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    top: 35,
    left: 82,
    width: 55,
    height: 55,
    borderRadius: '50%',
    opacity: 1,
    position: 'absolute',
  },
}));

const RegistrationType = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.participationEdit(intl);
  const { isMobile, isMobileOnly } = useAimMediaQuery();
  const classes = useStyles({ isMobile, isMobileOnly });

  const [user] = useState(appState.user.getValue());
  const [isRegistrationClosed, setIsRegistrationClosed] = useState(false);
  const [currentParticipation, setCurrentParticipation] = useState();

  useEffect(() => {
    const eventInfo = appState.eventInfo.getValue();
    if (eventInfo.byInvitationOnlyFromEnabled) {
      if (isAfter(Date.now(), parseISO(eventInfo.byInvitationOnlyFromDate))) {
        setIsRegistrationClosed(true);
        return;
      }
    }
    if (eventInfo.maxParticipants) {
      getEventParticipations(eventId).then((participations) => {
        if (eventInfo.maxParticipants <= participations.length) {
          setIsRegistrationClosed(true);
          return;
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!user) return;

    const getData = async () => {
      showLoader();

      const nextParticipation = user?.userAndParticipation?.participation;
      const cluster = nextParticipation?.cluster || constants.Clusters.Pax.id;

      let p = {
        ...(nextParticipation || pNew),
        event: { id: eventId },
        cluster,
        username: nextParticipation?.username || user.awsUser.username,
        cognitoUserId:
          nextParticipation?.cognitoUserId || user.awsUser.attributes.sub,
        email: nextParticipation?.email || user.awsUser.attributes.email,
        isParticipant: true,
        status: 'registered',
      };
      setCurrentParticipation(p);
      hideLoader();
    };

    getData();
  }, [user]);

  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);

  const handleLogout = async () => {
    await aws.signOutFromWebsite(eventId);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {/* LOGO */}
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={logo} style={{ width: 150, height: 150 }} />
          </Grid>
        </Grid>

        {/* check if registration was closed and if user is a backoffice user */}
        {(!user?.awsUser?.isBackofficeUser &&
          isRegistrationClosed &&
          currentParticipation &&
          !currentParticipation?.id) ||
        (user?.awsUser?.isBackofficeUser &&
          currentParticipation &&
          !currentParticipation?.id) ? (
          <Grid
            container
            style={{
              height: 30,
              justifyContent: 'center',
              justifyItems: 'center',
              margin: '5% auto',
              width: '55%',
            }}
          >
            {!user?.awsUser?.isBackofficeUser &&
              isRegistrationClosed &&
              currentParticipation &&
              !currentParticipation?.id && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <AimTypography variant="text">
                    {i18n.registrationForm.registrationClosedSubtitle}
                  </AimTypography>
                  <AimTypography
                    variant="textBold"
                    onClick={handleLogout}
                    style={{ cursor: 'pointer' }}
                  >
                    {i18n.registrationForm.clickHereToLogout}
                  </AimTypography>
                </Grid>
              )}
            {user?.awsUser?.isBackofficeUser &&
            currentParticipation &&
            !currentParticipation?.id ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AimTypography variant="text">
                    {i18n.registrationForm.backofficeUserBlocked}
                  </AimTypography>
                </Grid>
              </>
            ) : null}
          </Grid>
        ) : null}

        {/* CARDS */}
        {user &&
          (currentParticipation?.id ||
            (!isRegistrationClosed && !user?.awsUser?.isBackofficeUser)) && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                lg={'auto'}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Card className={classes.cardRoot}>
                  <CardContent
                    classes={{ root: classes.cardContentRoot }}
                    onClick={() => {
                      history.push(`/events/${eventId}/registration/agency`);
                    }}
                  >
                    <span
                      className={classes.icon}
                      style={{ background: '#FFD400' }}
                    />
                    <PersonIcon
                      style={{ position: 'relative', width: 75, height: 75 }}
                    />
                    <AimTypography variant="h6">{'Agency'}</AimTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={'auto'}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Card className={classes.cardRoot}>
                  <CardContent
                    classes={{ root: classes.cardContentRoot }}
                    onClick={() => {
                      history.push(`/events/${eventId}/registration/sponsor`);
                    }}
                  >
                    <span
                      className={classes.icon}
                      style={{ background: '#30CFD0' }}
                    />
                    <PersonIcon
                      style={{ position: 'relative', width: 75, height: 75 }}
                    />
                    <AimTypography variant="h6">{'Sponsor'}</AimTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={'auto'}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Card className={classes.cardRoot}>
                  <CardContent
                    classes={{ root: classes.cardContentRoot }}
                    onClick={() => {
                      history.push(`/events/${eventId}/registration/pax`);
                    }}
                  >
                    <span
                      className={classes.icon}
                      style={{ background: '#9336FD' }}
                    />
                    <PersonIcon
                      style={{ position: 'relative', width: 75, height: 75 }}
                    />
                    <AimTypography variant="h6">{'Participant'}</AimTypography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
      </Grid>
    </div>
  );
};

export default RegistrationType;

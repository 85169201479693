export const translation = {
  programPublicPage: (intl) => ({
    title: intl.formatMessage({
      description: 'program public page title',
      defaultMessage: 'Program',
    }),
    back: intl.formatMessage({
      description: 'program public page back button',
      defaultMessage: 'Go to landing',
    }),
    notFound: intl.formatMessage({
      description: 'program public page not found',
      defaultMessage: 'Program not found',
    }),
    loading: intl.formatMessage({
      description: 'program public page loading',
      defaultMessage: 'Program loading...',
    }),
  }),
};

export const translation = {
  payment: (intl) => ({
    page: {
      title: {
        cart: intl.formatMessage({
          description: 'cart title text',
          defaultMessage: 'Cart',
        }),
        checkout: intl.formatMessage({
          description: 'checkout title text',
          defaultMessage: 'Purchase',
        }),
      },
      back: {
        cart: {
          label: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment options',
            defaultMessage: 'Payment options',
          }),
        },
        checkout: {
          label: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'back',
          }),
          tooltip: intl.formatMessage({
            description: 'back to payment',
            defaultMessage: 'Payment',
          }),
        },
      },
      // messages: {
      //   noBookings: {
      //     label: intl.formatMessage({
      //       description: 'no bookings message',
      //       defaultMessage: 'Cart is empty',
      //     }),
      //   },
      // },
      errors: {
        loadBookings: {
          label: intl.formatMessage({
            description: 'load bookings error',
            defaultMessage: 'Error loading bookings list',
          }),
        },
        product: {
          label: intl.formatMessage({
            description: 'product error',
            defaultMessage: 'Error during creating product',
          }),
        },
      },
      checkout: {
        bankTransferIndication: intl.formatMessage({
          description: 'bank transfer indication label',
          defaultMessage: 'Bank transfer indication',
        }),
        iban: intl.formatMessage({
          description: 'IBAN label',
          defaultMessage: 'IBAN',
        }),
        reasonForPayment: intl.formatMessage({
          description: 'Reason for payment label',
          defaultMessage: 'Reason for payment',
        }),
        addressedTo: intl.formatMessage({
          description: 'addressed to',
          defaultMessage: 'Addressed to',
        }),
      },
      totalAmountDue: intl.formatMessage({
        description: 'Total amount due label',
        defaultMessage: 'Total amount due',
      }),
      registration: intl.formatMessage({
        description: 'registration label',
        defaultMessage: 'Registration',
      }),
      additionalService: intl.formatMessage({
        description: 'additional service label',
        defaultMessage: 'Additional service',
      }),
      penalties: intl.formatMessage({
        description: 'penalties label',
        defaultMessage: 'Penalties',
      }),
    },
    checkoutTable: {
      buttons: {
        buy: {
          label: intl.formatMessage({
            description: 'buy checkout button',
            defaultMessage: 'Buy',
          }),
        },
      },
      columns: {
        service: {
          label: intl.formatMessage({
            description: 'service column',
            defaultMessage: 'Service',
          }),
        },
        unitaryNetPrice: {
          label: intl.formatMessage({
            description: 'unitary net price column',
            defaultMessage: 'Unitary net (€)',
          }),
        },
        vatAmount: {
          label: intl.formatMessage({
            description: 'vat amount column',
            defaultMessage: 'VAT €',
          }),
        },
        quantity: {
          label: intl.formatMessage({
            description: 'quantity column',
            defaultMessage: 'Quantity',
          }),
        },
        netPrice: {
          label: intl.formatMessage({
            description: 'net price column',
            defaultMessage: 'Net price (€)',
          }),
        },
        totalNetPrice: {
          label: intl.formatMessage({
            description: 'total net price column',
            defaultMessage: 'Total net (€)',
          }),
        },
        amount: {
          label: intl.formatMessage({
            description: 'amount column',
            defaultMessage: 'Amount (€)',
          }),
        },
      },
      summary: {
        netTotal: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Net Total (€)',
          }),
        },
        vat: {
          label: intl.formatMessage({
            description: 'vat summary',
            defaultMessage: 'VAT',
          }),
        },
        total: {
          label: intl.formatMessage({
            description: 'net total summary',
            defaultMessage: 'Total (€)',
          }),
        },
      },
    },
    checkoutAlerts: {
      eventIsOver: {
        label: intl.formatMessage({
          description: "the event is over, you can't confirm the purchase",
          defaultMessage: "The event is over, you can't confirm the purchase",
        }),
      },
    },
  }),
  paymentOptions: (intl) => ({
    page: {
      back: {
        tooltip: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Go back',
        }),
        button: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'back',
        }),
      },
      title: intl.formatMessage({
        description: 'payment method page title',
        defaultMessage: ' Payment method',
      }),
      bankTransfer: {
        label: intl.formatMessage({
          description: 'payemnt by bank transfer',
          defaultMessage: 'Bank Transfer',
        }),
      },
      creditCard: {
        label: intl.formatMessage({
          description: 'payment by credit card',
          defaultMessage: 'Credit Card',
        }),
      },
    },
  }),
};

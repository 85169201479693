import { aws, appState, utilities, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const listAdditionalServicesConfigsQuery = /* GraphQL */ `
  query ListAdditionalServices($id: ID!) {
    getEvent(id: $id) {
      additionalServicesConfigs {
        items {
          id
          additionalServiceType
          additionalServicesOrder
          name
          rule
          description
          position
          additionalServices {
            items {
              id
              title
              dateService
              startTime
              endTime
              closingDate
              description
              locked
              isDeleted
              typology
              categories
              maxSubscription
              requiredRegistration
              images {
                items {
                  id
                  extension
                  size
                  originalName
                }
              }
              reservedToCluster
              reservedToProfiles
              reservedToTipologies
              prices {
                items {
                  id
                  start
                  end
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getProductsByServiceIdServiceTypeQuery = /* GraphQL */ `
  query getProductsByClientIdServiceId($serviceId: String) {
    scientificEventsProducts: productByServiceIdServiceType(
      serviceId: $serviceId
      serviceType: { eq: "${constants.AdditionalServicesServiceTypology.scientific.productServiceType}" }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
        }
      }
    }
    socialEventsProducts: productByServiceIdServiceType(
      serviceId: $serviceId
      serviceType: { eq: "${constants.AdditionalServicesServiceTypology.social.productServiceType}" }
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
        }
      }
    }
  }
`;

export const listAdditionalServicesConfigs = (
  eventId,
  type,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: listAdditionalServicesConfigsQuery,
      variables: {
        id: eventId,
        type,
      },
    })
      .then((response) => {
        resolve(response.data.getEvent);
      })
      .catch((e) => {
        console.error('list-additional-services-configs', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getProductsByServiceIdServiceType = (
  serviceId,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getProductsByServiceIdServiceTypeQuery,
      variables: {
        serviceId,
      },
    })
      .then((response) => {
        const {
          scientificEventsProducts,
          socialEventsProducts,
        } = response.data;
        const products = [
          ...(scientificEventsProducts?.items || []),
          ...(socialEventsProducts?.items || []),
        ];
        const validProducts = products.filter((product) =>
          utilities.isValidPayment(product.payment)
        );
        resolve(validProducts);
      })
      .catch((e) => {
        console.error('products-by-service-id-service-type', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

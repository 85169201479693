import { appState, aws } from '@aim/common';

const listPackagesQuery = `
query ListPackages($id: ID!) {
  getEvent(id: $id) {
      sponsorPackageService {
        deactivationDate
        packages {
          items {
          id
          name
          quantity
          publicationDate
          services {
              items {
              id
              netPrice
              vatRate
              quantity
              }
          }
          buyOperations {
              items {
              id
              purchase {
                  id
                  purchaseId
                  paymentType
                  payment {
                      paymentId
                      paymentIdMonetaOnLine
                      shasignIngenico
                      paymentIdGpWebpay
                      paymentType
                  }
              }
            }
          }
        }
      }
    }
  }
}`;

const getSponsorPackageQuery = `
  query GetSponsorPackage($id: ID!) {
    getSponsorPackage(id: $id) {
      buyOperations {
        items {
          id
          sponsor { 
            id
          }
          purchase {
            id
            purchaseId
            paymentType
            payment {
              paymentId
              paymentIdMonetaOnLine
              shasignIngenico
              paymentIdGpWebpay
              paymentType
            }
          }
        }
      }
      name
      description
      publicationDate
      quantity
      services {
        items {
          name
          id
          netPrice
          originalNetPrice
          quantity
          vatRate
        }
      }
      packageService {
        isCreditCardEnabled
        isBankTransferEnabled
        event {
          sponsorServiceBookingDuration
        }
      }
    }
  }
  `;

export const listSponsorPackages = (eventId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: listPackagesQuery,
      variables: { id: eventId },
    })
      .then(({ data }) => {
        resolve({
          deactivationDate:
            data.getEvent.sponsorPackageService?.deactivationDate,
          packages: data.getEvent.sponsorPackageService?.packages.items,
        });
      })
      .catch((e) => {
        console.error('list-sponsor-packages', e);
        reject(e);
      })
      .finally(hideLoader);
  });

export const getSponsorPackage = (sponsorPackageId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getSponsorPackageQuery,
      variables: { id: sponsorPackageId },
    })
      .then(({ data }) => {
        resolve(data.getSponsorPackage);
      })
      .catch((e) => {
        console.error('get-sponsor-package', e);
        reject(e);
      })
      .finally(hideLoader);
  });

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

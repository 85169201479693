import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import ArrowBack from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';

import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6);
import { useDataHelper } from '../../shared/dataHelper';
import { paymentGqlHelper } from '../../shared/paymentGqlHelper';
import {
  createBillingInformation,
  updatePayment,
} from '../shared/agencyGqlHelper';
import { productGqlHelper } from '../shared/productGqlHelper';

import {
  AimTypography,
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
  CustomIntl,
} from '@aim/components';
import { constants, appState, aws, massiveQueriesHelper } from '@aim/common';

import AgencyCheckout from './AgencyCheckout';
import { MainContainer, InnerContainer } from '../shared/Containers';
import { translation } from './translation';
import { format } from 'date-fns';

const createProduct = /* GraphQL */ `
  createProduct(input: $input) {
    id
  }
`;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const createBillingInfo = async (
  paymentId,
  billingInfo,
  paymentOtherDataToUpdate
) => {
  const billingInfoResp = await createBillingInformation({
    ...billingInfo,
  });

  await updatePayment(
    {
      id: paymentId,
      paymentBillingInformationId: billingInfoResp.id,
      ...paymentOtherDataToUpdate,
    },
    false
  );
};

const mapBookings = (bookings, i18n, intl) => {
  const nextBookings = bookings.map((booking) => {
    let bookingLabel = '';
    let productId = '';
    if (booking.productType === constants.ProductType.registration) {
      const startDate = format(
        new Date(booking.feeDateRangeStart),
        'dd/MM/yyyy'
      );
      const endDate = format(new Date(booking.feeDateRangeEnd), 'dd/MM/yyyy');
      const isStartDateEqualEndDate = startDate === endDate;
      bookingLabel = `${i18n.page.registration}: ${booking.quantity} x ${
        booking.profileLabel
      } - ${booking.feeDateRangeLabel} - ${Object.values(constants.EventTypes)
        .find((t) => t.id === booking.participationMode)
        ?.label(intl)} - ${startDate}${
        isStartDateEqualEndDate ? '' : ` - ${endDate}`
      }`;
      productId = booking.profileFeeBracketId;
    } else if (
      booking.productType === constants.ProductType.scientificEvents ||
      booking.productType === constants.ProductType.socialEvents
    ) {
      bookingLabel = `${i18n.page.additionalService}: ${booking.quantity} x ${booking.title}`;
      productId = booking.additionalServiceId;
    } else if (booking.productType === constants.ProductType.penalties) {
      bookingLabel = `${i18n.page.penalties}`;
      productId = constants.ProductType.penalties; // non abbiamo un id da collegare
    }
    return {
      ...booking,
      bookingLabel,
      productId,
    };
  });
  return nextBookings;
};

const AgencyPayment = () => {
  const history = useHistory();
  const { eventId, agencyId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.payment(intl);
  const paymentHelper = paymentGqlHelper();
  const productHelper = productGqlHelper();
  const { encodeDbNumber } = useDataHelper();

  const gateway = history.location.state.gateway;
  const paymentType =
    history.location.state.paymentType || gateway?.shop?.paymentType;
  const serviceId = history.location?.state?.serviceId;
  const serviceType = history.location?.state?.serviceType;
  const clientId = history.location?.state?.clientId;
  const clientType = history.location?.state?.clientType;
  const productId = history.location?.state?.prodId;
  const billingInfo = history.location.state?.billingInfo;
  const groupId = history.location?.state?.groupId;
  const sponsorListId = history.location?.state?.sponsorListId;
  const bookings = mapBookings(history.location?.state?.bookings, i18n, intl);

  // states
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [responsePaymentIngenico, setResponsePaymentIngenico] = useState();

  const eventCodeRef = useRef();

  useEffect(() => {
    const fetchEvent = async () => {
      const response = await productHelper.getEventCode(eventId);
      eventCodeRef.current = response.code;
    };
    fetchEvent();
  }, []);

  const getPaymentIdMonetaOnLine = async (paymentId, relurl) => {
    // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
    const baseurl = window.location.host; // testurl.split('//')[1] ||

    const apiName = 'aimMonetaOnLine';
    const path = '/monetaonline/initpayment';
    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
        relurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const initPaymentIngenico = async (paymentId, relurl) => {
    // const testurl = 'https://test.d1zwmjatj8p4ws.amplifyapp.com';
    const baseurl = procewindow.location.host; // testurl.split('//')[1] ||

    const apiName = 'aimIngenico';
    const path = '/ingenico/initpayment';
    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
        relurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const initGpWebpay = async (paymentId, relurl) => {
    const baseurl = window.location.host; // testurl.split('//')[1] ||

    const apiName = 'aimGpWebPay';
    const path = '/gpwebpay/initpayment';

    const queryData = {
      queryStringParameters: {
        paymentId,
        url: baseurl,
        relurl,
      },
      responseType: 'json',
    };

    const response = await aws.standardAPI.get(apiName, path, queryData);
    return response;
  };

  const onClickConfirm = async () => {
    showLoader();
    let product;

    const amount = encodeDbNumber(
      bookings.reduce((prev, curr) => (prev += curr.price * curr.quantity), 0)
    );

    try {
      // create product
      const paymentId = `${eventCodeRef.current}-${nanoid()}`;
      product = await productHelper.create(
        {
          clientId,
          clientType,
          serviceId,
          serviceType,
          productId,
          isFrontOfficePurchase: true,
        },
        false
      );

      const payment = await paymentHelper.create(
        {
          paymentProductId: product.id,
          paymentType,
          date: new Date(),
          amount,
          description: bookings
            .map(({ bookingLabel }) => bookingLabel)
            .join(', '),
          paymentId,
          paymentStatus: constants.PaymentStatuses.PENDING.key,
        },
        false
      );

      const products = await massiveQueriesHelper.massiveMutation({
        query: createProduct,
        queryName: 'CreateAgencyGroupPurchaseProducts',
        queryInputType: 'CreateProductInput',
        items: bookings.map((booking) => ({
          clientId,
          clientType,
          serviceId,
          serviceType: booking.productType,
          productId: booking.productId,
          productCartId: product.id,
          participationType: booking.participationMode,
          quantity: booking.quantity,
          productPaymentId: payment.id,
        })),
      });

      await createBillingInfo(payment.id, billingInfo, {
        paymentMetadata: JSON.stringify(
          bookings.map((booking, idx) => ({
            ...booking,
            productTableId: Object.values(products)?.[idx]?.id,
          }))
        ),
      });

      await productHelper.update(
        {
          id: product.id,
          productPaymentId: payment.id,
        },
        false
      );

      if (paymentType === constants.PaymentTypes.CreditCard) {
        // init gpwebpay
        if (gateway.shop.gatewayType === constants.PaymentGateway[2].key) {
          // init payment GpWebpay
          const response = await initGpWebpay(
            payment.id,
            sponsorListId
              ? `events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}`
              : `events/${eventId}/agency/${agencyId}/groups/${groupId}`
          );
          if (response.url) {
            window.location = response.url;
            // setResponseGpWebpay(response.html);
          } else {
            // history.push(
            //   `/events/${eventId}/${sponsorId}/checkout-error?errorcode=${response.errorcode}&merchantorderid=${purchase.id}`
            // );
            if (sponsorListId) {
              history.push(
                `/events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}`
              );
            } else {
              history.push(
                `/events/${eventId}/agency/${agencyId}/groups/${groupId}`
              );
            }
          }
        }

        // init ingenico
        if (gateway.shop.gatewayType === constants.PaymentGateway[1].key) {
          // init payment Ingenico
          const response = await initPaymentIngenico(
            payment.id,
            sponsorListId
              ? `events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}`
              : `events/${eventId}/agency/${agencyId}/groups/${groupId}`
          );
          setResponsePaymentIngenico(response.html);
        }

        // init monetaonline
        if (gateway.shop.gatewayType === constants.PaymentGateway[0].key) {
          // get payment id from MonetaOnLine
          const response = await getPaymentIdMonetaOnLine(
            payment.id,
            sponsorListId
              ? `events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}`
              : `events/${eventId}/agency/${agencyId}/groups/${groupId}`
          );

          if (response.error > 0) {
            history.push(
              sponsorListId
                ? `/events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}/checkout-error?errorcode=` +
                    response.error
                : `/events/${eventId}/agency/${agencyId}/groups/${groupId}/checkout-error?errorcode=` +
                    response.error
            );
          } else {
            const { paymentid, hostedpageurl } = response;
            // console.log("url ", `${hostedpageurl}?paymentid=${paymentid}`);
            window.location = `${hostedpageurl}?paymentid=${paymentid}`;
            // window.open(`${hostedpageurl}?paymentid=${paymentid}`, '_blank');
          }
        }
      } else {
        // bank transfer
        history.push(
          sponsorListId
            ? `/events/${eventId}/agency/${agencyId}/sponsor-lists/${sponsorListId}/checkout-success`
            : `/events/${eventId}/agency/${agencyId}/groups/${groupId}/checkout-success`
        );
      }
    } catch (e) {
      console.error(e);
      if (product?.id) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.page.errors.product.label,
        });
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <MainContainer>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <InnerContainer>
            <Tooltip title={i18n.page.back.checkout.tooltip}>
              <AimIconAndTextButton
                variant="text"
                text={i18n.page.back.checkout.label}
                onClick={() => {
                  if (gateway?.shop?.paymentType === 'both') {
                    history.push({
                      pathname: `/events/${eventId}/agency/${agencyId}/payment-opt`,
                      state: { ...history.location?.state },
                    });
                  } else {
                    const nextState = {
                      ...history.location?.state,
                      bookings: history.location?.state.cartBookings,
                    };
                    history.push({
                      pathname: `/events/${eventId}/agency/${agencyId}/billing-informations`,
                      state: nextState,
                    });
                  }
                }}
              >
                <ArrowBack />
              </AimIconAndTextButton>
            </Tooltip>
            <AimTypography variant={'h1'}>
              {i18n.page.title.checkout}
            </AimTypography>
          </InnerContainer>
        </div>
        <div>
          {gateway.shop.gatewayType === constants.PaymentGateway[1].key &&
          responsePaymentIngenico ? (
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: responsePaymentIngenico,
              }}
            ></div>
          ) : (
            <InnerContainer bottomSpacing>
              <AgencyCheckout
                checkoutBookings={bookings}
                // setCheckoutBookings={setCheckoutBookings}
                onClickConfirm={onClickConfirm}
                i18n={i18n}
                paymentGateway={gateway}
                paymentType={paymentType}
              />
            </InnerContainer>
          )}
        </div>
      </MainContainer>

      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AgencyPayment;

import React from 'react';

import { useIntl } from 'react-intl';

import Logo from './shared/logo/Logo';
import Footer from './shared/Footer';

import { AimTypography, styled, theme, CustomIntl } from '@aim/components';

import { translation } from './genericPayments/translation';

const PageContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '95%',
  margin: '0 auto',
  paddingLeft: '11%',
  paddingRight: '11%',
  paddingTop: 70,
});

const ResponseContainer = styled('div')({
  display: 'flex',
  flex: 1,
  width: '100%',
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 75,
  marginBottom: 75,
});

const GenericPaymentsCheckoutResponse = () => {
  // i18n
  const intl = CustomIntl(useIntl());
  const i18n = translation.checkout(intl);

  const query = new URLSearchParams(location.search);
  const successCode = query.get('successCode');
  const errorCode = query.get('errorcode');

  const MainContainer = ({ children }) => (
    <div
      style={{
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  );

  return (
    <>
      <MainContainer>
        <Logo />
        <PageContent>
          <ResponseContainer>
            <AimTypography variant={'h2'}>
              {successCode
                ? i18n.success
                : errorCode
                ? i18n.error
                : i18n.alreadyPaid}
            </AimTypography>
          </ResponseContainer>
        </PageContent>
        <Footer />
      </MainContainer>
    </>
  );
};

export default GenericPaymentsCheckoutResponse;

import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getFileQuery = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      originalName
      id
      extension
    }
  }
`;

const getEventParticipationsQuery = /* GraphQL */ `
  query GetEventParticipations($id: ID!) {
    participantByEventId(
      participationEventId: $id
      filter: { isDeleted: { ne: true } }
    ) {
      items {
        id
      }
    }
  }
`;

const getEventDetailsQuery = /* GraphQL */ `
  query getEventDetails($id: ID!, $filter: ModelProfileFilterInput) {
    getEvent(id: $id) {
      id
      name
      privacyFiles
      privacyFile {
        id
        originalName
        extension
        size
      }
      profiles(filter: $filter) {
        items {
          id
          name
          category {
            name
          }
        }
      }
      fee {
        feeDateRanges {
          items {
            id
            start
            end
            label
          }
        }
      }
      participationTypologies {
        items {
          id
          name
        }
      }
      preTitles {
        items {
          id
          key
          title
          preTitle
          isActive
        }
      }
    }
  }
`;

const listParticipationFieldValues = `
  query listParticipationFieldValues($id: ID!) {
    getParticipation(id: $id) {
      id
      givenName
      familyName
      email
      status
      phone
      type
      cluster
      isParticipant
      isSpeaker
      isReviewer
      event {
        id
      }
      profile {
        id
      }
      delegations: delegation {
        id
      }
      groups: agencyGroup {
       id
      }
      fieldValues {
        items {
          id
          value
          fieldDefinition {
            id
          }
        }
      }
    }
  }
`;

const createParticipationMutation = /* GraphQL */ `
  mutation CreateParticipation(
    $input: CreateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    createParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationMutation = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

const createFieldValueMutation = /* GraphQL */ `
  mutation CreateFieldValue(
    $input: CreateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    createFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateFieldValueMutation = /* GraphQL */ `
  mutation UpdateFieldValue(
    $input: UpdateFieldValueInput!
    $condition: ModelFieldValueConditionInput
  ) {
    updateFieldValue(input: $input, condition: $condition) {
      id
    }
  }
`;

const listParticipationsByCognitoUserIdQuery = /* GraphQL */ `
  query ListParticipationsByCognitoUserId($id: ID!) {
    participantByCognitoUserId(
      cognitoUserId: $id
      filter: { isDeleted: { ne: true } }
    ) {
      items {
        id
        givenName
        familyName
        email
        phone
        createdAt
        fieldValues {
          items {
            id
            value
            fieldDefinition {
              key
              controlType
              id
            }
          }
        }
      }
    }
  }
`;

const updateParticipationServicesQuery = /* GraphQL */ `
  mutation UpdateParticipationServices(
    $input: UpdateParticipationServicesInput!
    $condition: ModelParticipationServicesConditionInput
  ) {
    updateParticipationServices(input: $input, condition: $condition) {
      id
    }
  }
`;

// const getFieldsListByContextsQuery = /* GraphQL */ `
//   query getFieldsListByContexts(
//     $id: ID!
//     $filter: ModelFieldContextFilterInput
//   ) {
//     getEvent(id: $id) {
//       standardFields(filter: { isDeleted: { ne: true } }) {
//         items {
//           controlType
//           id
//           label
//           options
//           placeholder
//           contextsOfUse(filter: $filter) {
//             items {
//               id
//               position
//               contextName
//               isHidden
//               isRequired
//             }
//           }
//         }
//       }
//       services(filter: { serviceName: { eq: "PARTICIPATION" } }) {
//         items {
//           serviceName
//           id
//           customFields(filter: { isDeleted: { ne: true } }) {
//             items {
//               controlType
//               id
//               label
//               options
//               placeholder
//               contextsOfUse(filter: $filter) {
//                 items {
//                   id
//                   position
//                   contextName
//                   isHidden
//                   isRequired
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const getFile = (fileId, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getFileQuery,
      variables: {
        id: fileId,
      },
    })
      .then((response) => resolve(response.data?.getFile))
      .catch((e) => {
        console.error('get-file', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getEventDetails = (eventId, cluster, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventDetailsQuery,
      variables: {
        id: eventId,
        filter: { and: { isPublic: { eq: true }, cluster: { eq: cluster } } },
      },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-event-details', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const listParticipationsByCognitoUserId = (email, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: listParticipationsByCognitoUserIdQuery,
      variables: {
        id: email,
        filter: { isDeleted: { ne: true }, email: { eq: email } },
      },
    })
      .then(({ data }) => resolve(data.participantByCognitoUserId.items))
      .catch((e) => {
        console.error('list-participations-by-cognito-user-id', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

// export const getFieldsListByContexts = (
//   eventId,
//   // cluster,
//   profileId,
//   isLoader = true
// ) =>
//   new Promise((resolve, reject) => {
//     isLoader && showLoader();
//     API.graphql({
//       query: getFieldsListByContextsQuery,
//       variables: {
//         id: eventId,
//         filter: {
//           and: { isHidden: { eq: false } }, // apply to registration form
//           or: [
//             {
//               contextName: {
//                 eq: 'pax',
//               },
//             },
//             {
//               contextName: {
//                 eq: `profile|${profileId}`,
//               },
//             },
//           ],
//         },
//       },
//     })
//       .then(({ data }) => {
//         const result = {
//           standardFields: data.getEvent.standardFields.items,
//           customFields: data.getEvent.services.items.length
//             ? data.getEvent.services.items[0].customFields.items
//             : [],
//         };
//         resolve(result);
//       })
//       .catch((e) => {
//         console.error('get-fields-list-by-contexts', e);
//         reject();
//       })
//       .finally(() => isLoader && hideLoader());
//   });

// export const getDynamicFields = async (
//   eventId,
//   // cluster,
//   profileId
// ) => {
//   const contextsOfUse = [`pax`];
//   const {
//     standardFields: sFields,
//     customFields: cFields,
//   } = await getFieldsListByContexts(eventId, profileId);
//   const standardFields = sFields.filter(
//     (f) =>
//       f.contextsOfUse.items.length > 0 &&
//       f.contextsOfUse.items.filter((c) => !c.isHidden)
//   );
//   const customFields = cFields.filter(
//     (f) =>
//       f.contextsOfUse.items.length > 0 &&
//       f.contextsOfUse.items.filter((c) => !c.isHidden)
//   );
//   return { contextsOfUse, fields: [standardFields, customFields] };
// };

// export const getEventFields = ({ eventId, contextsOfUse, isLoader = true }) =>
//   new Promise((resolve, reject) => {
//     isLoader && showLoader();
//     API.graphql({
//       query: getEventFieldsQuery,
//       variables: {
//         id: eventId,
//         filter: { or: contextsOfUse.map((c) => ({ contextName: { eq: c } })) },
//       },
//     })
//       .then(({ data }) => {
//         const result = {
//           isParticipant: data.getEvent.isParticipant,
//           isSpeaker: data.getEvent.isSpeaker,
//           isReviewer: data.getEvent.isReviewer,
//           standardFields: data.getEvent.standardFields.items,
//           customFields: data.getEvent.services.items.length
//             ? data.getEvent.services.items[0].customFields.items
//             : [],
//         };
//         resolve(result);
//       })
//       .catch((e) => {
//         console.error('list-event-field-by-context', e);
//         reject();
//       })
//       .finally(() => isLoader && hideLoader());
//   });

export const getParticipationUserAndFieldValues = (
  participationId,
  isLoader = true
) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: listParticipationFieldValues,
      variables: { id: participationId },
    })
      .then((response) => resolve(response.data.getParticipation))
      .catch((e) => {
        console.error('get-field-values', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const getEventParticipations = (id, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: getEventParticipationsQuery,
      variables: { id },
    })
      .then(({ data }) => resolve(data.participantByEventId.items))
      .catch((e) => {
        console.error('get-field-values', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const createParticipation = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: createParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createParticipation.id))
      .catch((e) => {
        console.error('create-participation', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateParticipation = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateParticipationMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipation))
      .catch((e) => {
        console.error('update-participation', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const createFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: createFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateFieldValue = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateFieldValueMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateFieldValue))
      .catch((e) => {
        console.error('update-field-value', e);
        reject();
      })
      .finally(() => isLoader && hideLoader());
  });

export const updateParticipationServices = (input, isLoader = true) =>
  new Promise((resolve, reject) => {
    isLoader && showLoader();
    API.graphql({
      query: updateParticipationServicesQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error('update-participation-services', e);
        reject();
      })
      .finally(() => isLoader && hideLoader);
  });

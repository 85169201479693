import React, { useState } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { nanoid } from 'nanoid';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import {
  AimDialog,
  AimTextField,
  AimIconButton,
  AimSelect,
  AimSelectMenuItem,
  AimCheckbox,
  AimTypography,
  StepDivider,
  Title,
  AimDraggableList,
  CitiesAutocompleteByGeoDbDynamic,
  CountriesAutocompleteByGeoDbDynamic,
  theme,
} from '@aim/components';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';

const AuthorRow = ({
  item,
  index,
  control,
  register,
  setValue,
  getValues,
  fieldArrayName,
  affiliations,
  titles,
  i18n,
  onDelete,
  errors,
  clearErrors,
  isAifaEvent,
  isRequestAifaForPresenter,
  checkPresentingAuthors,
  participation,
}) => {
  console.log('🚀 ~ isRequestAifaForPresenter:', isRequestAifaForPresenter);
  const formControlStyle = { width: 'calc(100% - 10px)' };
  const squareText = index > -1 ? `[${index}]` : '';

  // I dati AIFA sono disabilitati solo per gli autori quando isRequestAifaForPResenter è a true
  const areAifaFieldsDisabled =
    isRequestAifaForPresenter &&
    !getValues(`${fieldArrayName}${squareText}.isPresentingAuthor`);

  const isPresenter = getValues(
    `${fieldArrayName}${squareText}.isPresentingAuthor`
  );

  const areAifaFieldsMandatory =
    (isRequestAifaForPresenter && isPresenter) || !isRequestAifaForPresenter;

  const authors = getValues('authors');

  const ownerAuthorRowIndex = authors.findIndex(
    (author) => author.email === participation.email
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          backgroundColor: theme.colors.greyScale.backgroundGrey,
        }}
      >
        <div style={{ flex: 1 }}>
          <Grid container>
            <Hidden smUp>
              <Grid item xs={12} sm={6} md={2}>
                <Controller
                  render={(props) => (
                    <AimTextField
                      label={i18n.wizard.labels.surname}
                      textfieldVariant="white"
                      value={props.value || ''}
                      onChange={(e) => {
                        props.onChange(e.target.value);
                        clearErrors('authors');
                      }}
                      formControlStyle={formControlStyle}
                    />
                  )}
                  name={`${fieldArrayName}${squareText}.id`}
                  control={control}
                  defaultValue={item?.id ?? ''}
                />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                render={(props) => (
                  <AimSelect
                    label={i18n.wizard.labels.preTitle}
                    selectVariant="white"
                    value={props.value || ''}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      if (errors) clearErrors('authors');
                    }}
                    formControlStyle={formControlStyle}
                    disabled={titles && titles.length <= 0}
                  >
                    {titles?.map((c) => (
                      <AimSelectMenuItem
                        key={c.id || c.nanoId}
                        value={c.id || c.nanoId}
                      >
                        {c.title}
                      </AimSelectMenuItem>
                    ))}
                  </AimSelect>
                )}
                name={`${fieldArrayName}${squareText}.preTitle`}
                control={control}
                defaultValue={item?.preTitle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                render={(props) => (
                  <AimTextField
                    label={i18n.wizard.labels.name}
                    textfieldVariant="white"
                    value={props.value || ''}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      if (errors) clearErrors('authors');
                    }}
                    formControlStyle={formControlStyle}
                    addAsterisk={isPresenter}
                  />
                )}
                name={`${fieldArrayName}${squareText}.name`}
                control={control}
                defaultValue={item?.name ?? ''}
                rules={{
                  required: isPresenter,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                render={(props) => (
                  <AimTextField
                    label={i18n.wizard.labels.surname}
                    textfieldVariant="white"
                    value={props.value || ''}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      if (errors) clearErrors('authors');
                    }}
                    formControlStyle={formControlStyle}
                    addAsterisk={isPresenter}
                  />
                )}
                name={`${fieldArrayName}${squareText}.surname`}
                control={control}
                defaultValue={item?.surname ?? ''}
                rules={{
                  required: isPresenter,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                render={(props) => (
                  <AimTextField
                    label={i18n.wizard.labels.email}
                    textfieldVariant="white"
                    value={props.value || ''}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      if (errors) clearErrors('authors');
                    }}
                    formControlStyle={formControlStyle}
                    addAsterisk={isPresenter}
                  />
                )}
                name={`${fieldArrayName}${squareText}.email`}
                control={control}
                defaultValue={item?.email ?? ''}
                rules={{
                  required: isPresenter,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CitiesAutocompleteByGeoDbDynamic
                control={control}
                register={register}
                setValue={setValue}
                errors={errors}
                name={`${fieldArrayName}${squareText}.city`}
                defaultValue={item?.city}
                formControlStyle={formControlStyle}
                textfieldVariant="white"
                clearErrors={clearErrors}
                label={i18n.wizard.labels.city}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <CountriesAutocompleteByGeoDbDynamic
                control={control}
                register={register}
                setValue={setValue}
                errors={errors}
                name={`${fieldArrayName}${squareText}.country`}
                defaultValue={item?.country}
                formControlStyle={formControlStyle}
                textfieldVariant="white"
                clearErrors={clearErrors}
                label={i18n.wizard.labels.country}
              />
            </Grid>
            {isAifaEvent && (
              <>
                <Grid item xs={12} sm={6} md={2}>
                  <Controller
                    render={(props) => (
                      <AimTextField
                        label={i18n.wizard.labels.degree}
                        textfieldVariant="white"
                        value={props.value || ''}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                          if (errors) clearErrors('authors');
                        }}
                        formControlStyle={formControlStyle}
                        disabled={areAifaFieldsDisabled}
                        addAsterisk={areAifaFieldsMandatory}
                      />
                    )}
                    name={`${fieldArrayName}${squareText}.degree`}
                    control={control}
                    defaultValue={item?.degree ?? ''}
                    rules={{
                      required: areAifaFieldsMandatory,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Controller
                    render={(props) => (
                      <AimTextField
                        label={i18n.wizard.labels.speciality}
                        textfieldVariant="secondary"
                        value={props.value || ''}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                          if (errors) clearErrors('authors');
                        }}
                        formControlStyle={formControlStyle}
                        disabled={areAifaFieldsDisabled}
                        addAsterisk={areAifaFieldsMandatory}
                      />
                    )}
                    name={`${fieldArrayName}${squareText}.speciality`}
                    control={control}
                    defaultValue={item?.speciality ?? ''}
                    rules={{
                      required: areAifaFieldsMandatory,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Controller
                    render={(props) => (
                      <AimTextField
                        label={i18n.wizard.labels.jobTitle}
                        textfieldVariant="white"
                        value={props.value || ''}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                          if (errors) clearErrors('authors');
                        }}
                        formControlStyle={formControlStyle}
                        disabled={areAifaFieldsDisabled}
                        addAsterisk={areAifaFieldsMandatory}
                      />
                    )}
                    name={`${fieldArrayName}${squareText}.jobTitle`}
                    control={control}
                    defaultValue={item?.jobTitle ?? ''}
                    rules={{
                      required: areAifaFieldsMandatory,
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} md={2}>
              <Controller
                render={(props) => (
                  <AimSelect
                    label={i18n.wizard.affiliations}
                    selectVariant="white"
                    value={props.value || ''}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      if (errors) clearErrors('authors');
                    }}
                    formControlStyle={formControlStyle}
                    disabled={
                      affiliations && affiliations.length > 0 ? false : true
                    }
                  >
                    {affiliations?.map((c) => (
                      <AimSelectMenuItem
                        key={c.id || c.nanoId}
                        value={c.id || c.nanoId}
                      >
                        {c.institution}
                      </AimSelectMenuItem>
                    ))}
                  </AimSelect>
                )}
                name={`${fieldArrayName}${squareText}.affiliation`}
                control={control}
                defaultValue={item?.affiliation ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} style={{ display: 'flex' }}>
              <Controller
                render={(props) => (
                  <AimCheckbox
                    onChange={(e) => {
                      checkPresentingAuthors(
                        e.target.checked,
                        props.name,
                        index
                      );
                    }}
                    checked={props.value}
                    label={i18n.wizard.labels.presentingAuthor}
                    labelPlacement="end"
                    style={{ marginLeft: 10 }}
                  />
                )}
                name={`${fieldArrayName}${squareText}.isPresentingAuthor`}
                control={control}
                defaultValue={item?.isPresentingAuthor || false}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AimIconButton
            variant="redFill"
            onClick={() => onDelete(item, index)}
            disabled={authors.length === 1}
          >
            <CloseIcon style={{ color: theme.colors.secondary.red }} />
          </AimIconButton>
        </div>
      </div>
    </>
  );
};

const AuthorsAndPresenterStep = ({
  control,
  title,
  i18n,
  abstractService,
  affiliations,
  titles,
  getValues,
  setValue,
  errors,
  clearErrors,
  fieldArrayName,
  participation,
}) => {
  const [presentingAuthorDialog, setPresentingAuthorDialog] = useState({
    isOpen: false,
  });

  // Check if all fields of author are empty excluding isPresentingAuthor (boolean)
  const checkEmpty = (author) => {
    const keys = Object.keys(author);
    const exclude = 'isPresentingAuthor';

    const filteredKeys = keys.filter(
      (key) => key !== exclude && (key !== 'nanoId') & (key !== 'position')
    );

    return filteredKeys.every((key) => {
      return author[key] === '';
    });
  };

  const setOwnerPresentingAuhtor = (authors, isChecked) => {
    if (isChecked) {
      const emptyAuthorRowIndex = authors.findIndex((author) =>
        checkEmpty(author)
      );
      // If there an empty author row -> fill author row with owner data
      if (emptyAuthorRowIndex > -1) {
        setValue(
          `${fieldArrayName}[${emptyAuthorRowIndex}].name`,
          participation.givenName
        );
        setValue(
          `${fieldArrayName}[${emptyAuthorRowIndex}].surname`,
          participation.familyName
        );
        setValue(
          `${fieldArrayName}[${emptyAuthorRowIndex}].email`,
          participation.email
        );
        setValue(
          `${fieldArrayName}[${emptyAuthorRowIndex}].isPresentingAuthor`,
          true
        );
      } else {
        // If there's no empty author row
        const ownerRowIndex = authors.findIndex((author) => {
          return author.email === participation.email;
        });
        if (ownerRowIndex > -1) {
          setValue(
            `${fieldArrayName}[${ownerRowIndex}].isPresentingAuthor`,
            true
          );
        } else {
          //  else -> append a new author row with owner data
          append({
            isPresentingAuthor: true,
            name: participation.givenName,
            surname: participation.familyName,
            email: participation.email,
          });
        }
      }
    } else {
      // andare a togliere la spunta anche all'autore owner
      const ownerRowIndex = authors.findIndex((author) => {
        return author.email === participation.email;
      });
      if (ownerRowIndex > -1) {
        setValue(
          `${fieldArrayName}[${ownerRowIndex}].isPresentingAuthor`,
          false
        );
      }
    }
  };

  const setPresentingAuthor = (index, isChecked) => {
    const authors = getValues('authors');
    const isOwner = authors[index].email === participation.email;

    isOwner
      ? setValue('isOwnerAPresentingAuthor', isChecked)
      : setValue('isOwnerAPresentingAuthor', false);
  };

  const { fields, append, move, remove } = useFieldArray({
    control,
    name: fieldArrayName,
    keyName: 'formKey',
  });

  const rowWidth = '95%';

  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  const checkPresentingAuthors = (isChecked, fieldName, index) => {
    const isChild = fieldName !== 'isOwnerAPresentingAuthor';
    const authors = getValues('authors');

    // Check if there is a presenting author child and get relative index
    const presenterAlreadySelectedIndex = authors.findIndex(
      (author) => author.isPresentingAuthor
    );

    /* Se spunto isOwnerAPresentingAuthor
     * la dialog si vede solo se esiste un altro autore presentatore che non ha la mail dell'owner
     * altrimenti setto quell'autore con i dati dell'owner e isPresentingAuthor a true
     */

    let showDialog = false;
    if (!isChild) {
      if (
        presenterAlreadySelectedIndex >= 0 &&
        authors[presenterAlreadySelectedIndex].email !== participation.email
      ) {
        showDialog = true;
      }
    } else {
      showDialog =
        presenterAlreadySelectedIndex >= 0 &&
        presenterAlreadySelectedIndex !== index;
    }

    const checkCurrentCheckbox = () => {
      setValue(fieldName, isChecked);

      isChild
        ? setPresentingAuthor(index, isChecked)
        : setOwnerPresentingAuhtor(authors, isChecked);

      if (errors) clearErrors('authors');
    };

    showDialog
      ? setPresentingAuthorDialog({
          isOpen: true,
          onAgree: () => {
            // Sbianco i valori del vecchio presentatore selezionato
            if (presenterAlreadySelectedIndex >= 0) {
              setValue(
                `${fieldArrayName}[${presenterAlreadySelectedIndex}].isPresentingAuthor`,
                false
              );
              setValue(
                `${fieldArrayName}[${presenterAlreadySelectedIndex}].degree`,
                ''
              );
              setValue(
                `${fieldArrayName}[${presenterAlreadySelectedIndex}].speciality`,
                ''
              );
              setValue(
                `${fieldArrayName}[${presenterAlreadySelectedIndex}].jobTitle`,
                ''
              );
            }
            checkCurrentCheckbox();
          },
        })
      : checkCurrentCheckbox();
  };

  const handleConfim = () => {
    presentingAuthorDialog.onAgree();
    setPresentingAuthorDialog({ isOpen: false });
  };

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <AimTypography variant="text" style={{ paddingLeft: 25 }}>
        <ul>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionOne}</li>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionTwo}</li>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionThree}</li>
          <li>{i18n.wizard.subtitles.authorsAndPresenter.instructionFour}</li>
        </ul>
      </AimTypography>
      <div
        style={{
          background: 'white',
          borderRadius: 4,
          flex: 1,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Controller
          render={(props) => (
            <AimCheckbox
              onChange={(e) => {
                checkPresentingAuthors(e.target.checked, props.name);
              }}
              checked={props.value}
              label={i18n.wizard.labels.checkboxAutorsAndPresenter}
              labelPlacement="end"
              style={{ marginLeft: 10 }}
            />
          )}
          name={`isOwnerAPresentingAuthor`}
          control={control}
          defaultValue={false}
        />
        <div style={{ marginTop: 20 }}>
          <AimDraggableList
            items={fields}
            ItemComponent={AuthorRow}
            itemComponentProps={{
              control,
              fieldArrayName,
              onDelete: (item, itemIndex) => {
                if (
                  item.email === participation.email &&
                  item.isPresentingAuthor
                ) {
                  setValue('isOwnerAPresentingAuthor', false);
                }
                remove(itemIndex);
              },
              affiliations,
              titles,
              getValues,
              setValue,
              i18n,
              errors,
              clearErrors,
              isAifaEvent: abstractService.isAifaEvent,
              isRequestAifaForPresenter:
                abstractService.isRequestAifaForPresenter,
              checkPresentingAuthors,
              participation,
            }}
            keyName="formKey"
            customDraggableIdKey={'nanoId'}
            onDragEnd={handleDrag}
            rowWidth={rowWidth}
          />
        </div>
        <AimIconButton
          variant="yellowFill"
          onClick={() =>
            append({ isPresentingAuthor: false, nanoId: nanoid() })
          }
        >
          <AddIcon />
        </AimIconButton>
      </div>
      <ErrorMessage
        errors={errors}
        name="authors"
        render={(data) => (
          <AimTypography variant="formError">{data.message}</AimTypography>
        )}
      />
      <AimDialog
        title={'Warning'}
        open={presentingAuthorDialog.isOpen}
        onClose={() => setPresentingAuthorDialog({ isOpen: false })}
        onAgree={handleConfim}
        onDisagree={() => setPresentingAuthorDialog({ isOpen: false })}
        agreeText={'Ok'}
        disagreeText={'Cancel'}
      >
        <AimTypography variant="text" boxStyle={{ margin: '10px 0px' }}>
          {
            'You have already selected a presenter, are you sure you want to change it?'
          }
        </AimTypography>
      </AimDialog>
    </>
  );
};

export default AuthorsAndPresenterStep;

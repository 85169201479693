import { aws } from '@aim/common';
const { API } = aws;

const getPreTitlesQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      preTitles {
        items {
          isActive
          key
          title
          preTitle
          id
        }
      }
    }
  }
`;

const createPreTitleQuery = /* GraphQL */ `
  mutation CreatePreTitle($input: CreatePreTitleInput!) {
    createPreTitle(input: $input) {
      id
    }
  }
`;

export const createPreTitle = (input) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: createPreTitleQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error('create-pre-title', e);
        reject();
      });
  });

export const getPreTitles = ({ id }) =>
  new Promise((resolve, reject) => {
    API.graphql({
      query: getPreTitlesQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data?.getEvent?.preTitles?.items))
      .catch((e) => {
        console.error('get-pre-titles', e);
        reject();
      });
  });

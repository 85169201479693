import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
// import { isMobileOnly } from 'react-device-detect';

// import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { startOfDay, format } from 'date-fns';
import {
  theme,
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  // AimTextField,
  // styled,
  AimDialog,
} from '@aim/components';
import { appState, constants, utilities } from '@aim/common';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import useI18n from './i18n';
import { getFeeDetails } from './gqlHelper';
import { TicketAdditionalServicesDialog } from './TicketAdditionalServicesDialog';

const Card = ({ items, i18n, event }) => {
  const history = useHistory();
  const { eventId } = useParams();
  const cardRef = useRef();
  const [cardWidth, setCardWidth] = useState(10);
  const [readFullLengthDialog, setReadFullLengthDialog] = useState({
    open: false,
  });

  const [ticketDialog, setTicketDialog] = useState({ isOpen: false });

  useLayoutEffect(() => {
    setCardWidth(cardRef.current.offsetWidth);
  }, []);

  return items.map((item, index) => {
    return (
      <div
        key={'aim-card' + index}
        style={{
          width:
            event.type === constants.EventTypes.HYBRID.id
              ? 'calc(100% - 14px)'
              : 'calc(50% - 14px)',
          // height: '240px',
          backgroundColor:
            item.type === constants.EventTypes.VIRTUAL.id
              ? '#fbf8e6'
              : theme.colors.greyScale.backgroundGrey,
          margin: '10px 7px',

          borderRadius: 4,
          boxShadow: '15px 15px 25px rgb(0 0 0 / 12%)',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 25px',

            height: 'calc(100% - 10px)',
            justifyContent: 'space-between',
          }}
          ref={cardRef}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AimTypography variant="text" margin={0}>
              {format(new Date(item?.start), 'dd/MM/yyyy')} -{' '}
              {format(new Date(item?.end), 'dd/MM/yyyy')}
            </AimTypography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <AimTypography
                  margin={0}
                  style={{ fontSize: '28px', fontWeight: 'bold' }}
                >
                  {i18n.page.priceLabel}
                </AimTypography>
                <AimTypography
                  margin={0}
                  style={{ fontSize: '28px', fontWeight: 'bold' }}
                >
                  {item?.price ? item.price.toFixed(2) : i18n.page.free}
                </AimTypography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <AimTypography margin={0} variant="text">
                  {`- ${
                    item?.netPrice && item?.netPrice > 0
                      ? `${utilities.formatNumber(item?.netPrice)} ${
                          i18n.page.participantFee
                        }`
                      : `${i18n.page.free} ${i18n.page.participantFee}`
                  }`}
                </AimTypography>
                {item?.isAccompanyingPersonEnabled &&
                appState.purchaseData.getValue()?.guests?.length ? (
                  <AimTypography margin={0} variant="text">
                    {`- ${
                      item?.accompanyingPersonPrice &&
                      item?.accompanyingPersonPrice > 0
                        ? `${utilities.formatNumber(
                            item?.accompanyingPersonPrice
                          )} ${i18n.page.accompanyingPersonFee}`
                        : `${i18n.page.free} ${i18n.page.accompanyingPersonFee}`
                    }`}
                  </AimTypography>
                ) : null}
              </div>
            </div>
            <AimTypography variant="h5" boxStyle={{ margin: 0 }}>
              {/* {item?.label?.toUpperCase()} */}
              {item?.label}
            </AimTypography>
            <div style={{ width: 10, wordBreak: 'break-all' }}>
              <AimTypography
                boxStyle={{
                  marginLeft: 0,
                  marginRight: 0,
                  width: cardWidth > 10 ? cardWidth - 50 : cardWidth,
                }}
              >
                {/* <pre
                  style={{
                    fontFamily: 'inherit',
                    fontSize: 12,
                  }}
                >
                  {item.ticketDescription}
                </pre> */}
                {(item.ticketDescription?.length > 350
                  ? `${item.ticketDescription.slice(0, 350)}...`
                  : item.ticketDescription || ''
                )
                  .split('\n')
                  .map((r, i) => (
                    <div key={i}>{r}</div>
                  ))}
              </AimTypography>
              {item.ticketDescription?.length > 350 && (
                <div
                  onClick={() => {
                    setReadFullLengthDialog({
                      open: true,
                      content: {
                        title: `${item?.label} - ${format(
                          new Date(item?.start),
                          'dd/MM/yyyy'
                        )} - ${format(new Date(item?.end), 'dd/MM/yyyy')}`,
                        text: item?.ticketDescription,
                      },
                    });
                  }}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: cardWidth > 10 ? cardWidth - 50 : cardWidth,
                  }}
                >
                  <AimTypography
                    {...{
                      variant: 'h6',
                      margin: 0,
                    }}
                  >
                    {i18n.page.readAll}
                  </AimTypography>
                </div>
              )}
            </div>
          </div>
          <AimIconAndTextButton
            text={i18n.page.buy}
            aria-label="buy"
            style={{ width: '140px' }}
            onClick={() => {
              setTicketDialog({
                isOpen: true,
                selectedTicket: item,
              });
              // history.push(
              //   `/events/${eventId}/tickets/billing-info/${item.id}/${item.type}`
              // )
            }}
          >
            <ShoppingCartIcon />
          </AimIconAndTextButton>
        </div>
        <TicketAdditionalServicesDialog
          isDialogOpen={ticketDialog.isOpen}
          eventId={eventId}
          i18n={i18n}
          selectedTicket={ticketDialog.selectedTicket}
          history={history}
          onAgree={(checkedItems) => {
            const stateToSend = {
              ...history.location?.state,
              selectedAdditionalServicesIncluded: checkedItems,
            };
            history.push({
              pathname: `/events/${eventId}/tickets/billing-info/${item.id}/${item.type}`,
              state: {
                ...history.location?.state,
                selectedAdditionalServicesIncluded: checkedItems,
              },
            });
          }}
          onDisagree={() => setTicketDialog({ isOpen: false })}
        />
        <AimDialog
          title={readFullLengthDialog.content?.title || ''}
          open={readFullLengthDialog.open}
          onClose={() =>
            setReadFullLengthDialog({ ...readFullLengthDialog, open: false })
          }
          // onAgree={handleOnDecline}
          hideCancelButton
          agreeText={i18n.dialog.agreeText}
        >
          {(readFullLengthDialog.content?.text || '')
            .split('\n')
            .map((r, i) => (
              <div key={i}>{r}</div>
            ))}
        </AimDialog>
      </div>
    );
  });
};

const CardSection = ({ event, sectionTitle, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection:
          event.type === constants.EventTypes.HYBRID.id ? 'column' : 'row',
        width: event.type === constants.EventTypes.HYBRID.id ? '50%' : '100%',
        // margin: '0 7px',
      }}
    >
      {event.type === constants.EventTypes.HYBRID.id && (
        <div
          style={{
            margin: '10px 7px',
            borderBottom: `1px solid ${theme.colors.greyScale.grey2}`,
          }}
        >
          <AimTypography variant="h4">{sectionTitle}</AimTypography>
        </div>
      )}
      {children}
    </div>
  );
};

const RegistrationFee = () => {
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  const { eventId } = useParams();
  const [virFee, setVirFee] = useState([]);
  const [phyFee, setPhyFee] = useState([]);
  const [user, setUser] = useState([]);
  const [event, setEvent] = useState();

  useEffect(() => {
    const userSubscription = appState.user.subscribe((nextUser) => {
      setUser(nextUser?.userAndParticipation?.participation);
    });
    return () => userSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    const fetchEventDetails = async () => {
      if (user.profile?.id) {
        const participation = appState.getCurrentParticipation();
        const res = await getFeeDetails(participation.profile?.id, eventId);

        const newRes = [];
        const now = startOfDay(new Date());
        res.getProfile?.profileFeeBrackets.items
          .filter((i) => i.profileFeeConfiguration?.isActive)
          .forEach((b) => {
            const priceOnAir = utilities.decodeDbNumber(b.priceOnAir);
            const priceOnSite = utilities.decodeDbNumber(b.priceOnSite);
            const accompanyingPersonPrice = utilities.decodeDbNumber(
              b.accompanyingPersonPrice
            );
            const isAccompanyingPersonEnabled =
              res.getProfile.isAccompanyingPersonEnabled;
            const start = startOfDay(
              new Date(b.feeBracket?.feeBracketModel.start)
            );
            const end = startOfDay(new Date(b.feeBracket?.feeBracketModel.end));
            if (now >= start && now <= end) {
              const baseObj = {
                id: b.id,
                start: b.feeBracket?.feeDateRange.start,
                end: b.feeBracket?.feeDateRange.end,
                label: b.feeBracket?.feeDateRange?.label,
                type: res.getEvent.type,
                ticketDescription: b.profileFeeConfiguration?.ticketDescription,
              };
              const nGuests =
                appState.purchaseData.getValue()?.guests?.length || 0;
              if (
                res.getEvent.type === constants.EventTypes.HYBRID.id ||
                res.getEvent.type === constants.EventTypes.VIRTUAL.id
              ) {
                newRes.push({
                  ...baseObj,
                  price:
                    priceOnAir +
                    nGuests *
                      (accompanyingPersonPrice > 0
                        ? accompanyingPersonPrice
                        : 0),
                  type: constants.EventTypes.VIRTUAL.id,
                  netPrice: priceOnAir,
                  accompanyingPersonPrice: accompanyingPersonPrice,
                  isAccompanyingPersonEnabled,
                });
              }
              if (
                res.getEvent.type === constants.EventTypes.HYBRID.id ||
                res.getEvent.type === constants.EventTypes.PHYSICAL.id
              ) {
                newRes.push({
                  ...baseObj,
                  price:
                    priceOnSite +
                    nGuests *
                      (accompanyingPersonPrice > 0
                        ? accompanyingPersonPrice
                        : 0),
                  type: constants.EventTypes.PHYSICAL.id,
                  netPrice: priceOnSite,
                  accompanyingPersonPrice: accompanyingPersonPrice,
                  isAccompanyingPersonEnabled,
                });
              }
            }
          });

        // setFee(newRes);
        setEvent(res.getEvent);
        setVirFee(
          newRes.filter(
            (i) =>
              console.log('i.type, i.price', i.type, i.price) ||
              (i.type === constants.EventTypes.VIRTUAL.id && i.price >= 0)
          )
        );
        setPhyFee(
          newRes.filter(
            (i) => i.type === constants.EventTypes.PHYSICAL.id && i.price >= -1
          )
        );
      }
    };

    fetchEventDetails();
  }, [user]);

  const noItems =
    virFee && virFee.length === 0 && phyFee && phyFee.length === 0;

  return (
    <div
      style={{ flex: 1, minWidth: 'calc(100vw - 180px)', marginBottom: '20px' }}
    >
      <AimIconAndTextButton
        isUpperCase
        variant="none"
        text={i18n.actions.backButton}
        style={{
          padding: 0,
        }}
        onClick={() => history.replace(`/events/${eventId}/tickets/profile`)}
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <AimTypography variant="h3" boxStyle={{ marginBottom: '0px' }}>
        {i18n.page.registrationFee}
      </AimTypography>
      <AimTypography variant="h5" boxStyle={{ margin: '0px 8px' }}>
        {i18n.page.chooseTheTicket}
      </AimTypography>
      {event && !noItems ? (
        <>
          <div style={{ display: 'flex' }}>
            {phyFee && phyFee.length > 0 && (
              <CardSection
                event={event}
                sectionTitle={i18n.page.onSiteParticipate}
              >
                <Card items={phyFee} i18n={i18n} event={event} />
              </CardSection>
            )}
            {virFee && virFee.length > 0 && (
              <CardSection
                event={event}
                sectionTitle={i18n.page.onLineParticipate}
              >
                <Card items={virFee} i18n={i18n} event={event} />
              </CardSection>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            margin: '20px 10px',
            padding: '20px 0px',
            borderTop: `1px solid ${theme.colors.greyScale.grey2}`,
          }}
        >
          <AimTypography variant="h7" boxStyle={{ margin: '0px' }}>
            {i18n.page.thereAreNoTicket}
          </AimTypography>
        </div>
      )}
    </div>
  );
};

export default RegistrationFee;

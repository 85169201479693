export default function (intl) {
  return {
    page: {
      title: intl.formatMessage({
        description: 'Sponsors page title',
        defaultMessage: 'Sponsors',
      }),
    },
    buttons: {
      buy: intl.formatMessage({
        description: 'sponsor card component buy button',
        defaultMessage: 'Buy',
      }),
      configure: intl.formatMessage({
        description: 'sponsor card component configure button',
        defaultMessage: 'Configure',
      }),
      insertBadge: intl.formatMessage({
        description: 'sponsor card component insert badge button',
        defaultMessage: 'Insert badge',
      }),
    },
    filter: {
      name: {
        label: intl.formatMessage({
          description: 'filter list by name',
          defaultMessage: 'Name',
        }),
      },
      category: {
        label: intl.formatMessage({
          description: 'filter list by category',
          defaultMessage: 'Category',
        }),
      },
      sponsorship: {
        label: intl.formatMessage({
          description: 'filter list by sponsorship',
          defaultMessage: 'sponsorship',
        }),
      },
    },
  };
}

import { aws, appState } from '@aim/common';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getParticipationGuestsQuery = /* GraphQL */ `
  query GetParticipationGuests($id: ID!) {
    getParticipation(id: $id) {
      id
      givenName
      familyName
      email
      phone
      title
      event {
        id
        gateways {
          items {
            id
            service
            shop {
              paymentType
              iban
              ibanCausal
              addressedTo
            }
          }
        }
      }
      guests {
        items {
          id
          givenName
          familyName
          phone
          country
        }
      }
    }
  }
`;

const getParticipationQuery = /* GraphQL */ `
  query GetParticipation($id: ID!) {
    getParticipation(id: $id) {
      id
      givenName
      familyName
      email
      phone
      title
      event {
        id
        gateways {
          items {
            id
            service
            shop {
              paymentType
              iban
              ibanCausal
              addressedTo
            }
          }
        }
      }
    }
  }
`;

const getParticipationGuestsHotelRoomReservationQuery = /* GraphQL */ `
  query GetParticipationGuestsHotelRoomReservation($id: ID!) {
    getParticipation(id: $id) {
      id
      givenName
      familyName
      email
      phone
      title
      event {
        id
        gateways {
          items {
            id
            service
            shop {
              paymentType
              iban
              ibanCausal
              addressedTo
            }
          }
        }
      }
      guests {
        items {
          id
          givenName
          familyName
          phone
          country
          hotelRoomReservation {
            id
            hotelReservation {
              id
              participation {
                id
                givenName
                familyName
                email
                phone
              }
              isDeleted
            }
          }
        }
      }
    }
  }
`;

const getProductQuery = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      productId
      productType
      serviceId
      serviceType
      showPrice
      guests {
        items {
          id
          givenName
          familyName
          country
        }
      }
      billingDocument {
        id
        docNumber
        billingCustomer
        billingFlow {
          id
          flowNumber
          docType
        }
        document {
          id
          originalName
          size
          extension
        }
        isPublished
      }
      creditNotesDocument {
        id
        docNumber
        billingCustomer
        billingFlow {
          id
          flowNumber
          docType
        }
        document {
          id
          originalName
          size
          extension
        }
        isPublished
      }
      payment {
        id
        paymentError
        paymentId
        paymentIdGpWebpay
        paymentIdIngenico
        paymentIdMonetaOnLine
        paymentMetadata
        paymentStatus
        paymentType
        cancellationNotes
        cancellationStatus
        amount
        isDeleted
        BillingInformation {
          id
          invoiceTo
          name
          surname
          address
          postalCode
          city
          regionProvinceState
          region
          province
          country
          countryIsoCode
          vatCode
          taxCode
          reference
          email
          pec
          phone
          invoiceToPublicAuthority
          uniqueCode
          ipaCode
          isSplitPayment
          isVatEvent
        }
      }
      showPrice
    }
  }
`;

const getHotelReservationQuery = /* GraphQL */ `
  query getHotelReservation($id: ID!) {
    getHotelReservation(id: $id) {
      id
      isDeleted
      hotelRoomReservation {
        items {
          endDate
          startDate
          isDeleted
          bookingCode
          treatment
          guests {
            items {
              id
              givenName
              familyName
              phone
              country
            }
          }
          hotelRoom {
            name
            frontofficeName
            id
            isDeleted
            hotel {
              id
              address
              city
              cityTax
              country
              latitude
              longitude
              name
              region
              province
              postalCode
              phone
              email
              stars
              route
              streetNumber
            }
          }
        }
      }
    }
  }
`;

const getRegistrationQuery = /* GraphQL */ `
  query GetRegistration($id: ID!) {
    getProfileFeeBracket(id: $id) {
      feeBracket {
        id
        feeDateRange {
          id
          start
          label
          end
        }
      }
    }
  }
`;

const createSocialEventQueries = (products) => {
  const registrationQueries =
    products?.SOCIAL_EVENT?.map((product) => {
      if (!product.id) return '';

      return `
  alias_${product.id.replace(/-/g, '_')}: getAdditionalServices(id: "${
        product.productId
      }") {
        id
        title
        startTime
        endTime
        dateService
        typology
        description
        locationName
        locationAddress
        vat1
        vat2
      }
    `;
    }) || [];
  return registrationQueries;
};

const createScientificEventQueries = (products) => {
  const registrationQueries =
    products?.SCIENTIFIC_EVENT?.map((product) => {
      if (!product.id) return '';

      return `
  alias_${product.id.replace(/-/g, '_')}: getAdditionalServices(id: "${
        product.productId
      }") {
        id
        title
        startTime
        endTime
        dateService
        typology
        description
        locationName
        locationAddress
        vat1
        vat2
      }
    `;
    }) || [];
  return registrationQueries;
};

const getAdditionalServicesQuery = /* GraphQL */ `
  query GetAdditionalServices($id: ID!) {
    getAdditionalServices(id: $id) {
      id
      title
      startTime
      endTime
      dateService
      typology
      description
      locationName
      locationAddress
      vat1
      vat2
    }
  }
`;

const createAllotmentGuestsQueries = (products) => {
  const allotmentGuestProductsQueries =
    products?.guestReservations?.map((product) => {
      if (!product.id) return '';
      //for guests product.id is guest id
      return `
  alias_guest_product${product.id.replace(
    /-/g,
    '_'
  )}: productByProductId(productId: "${product.productId}") {
        nextToken
        items {
          id
          productId
          productType
          serviceId
          serviceType
          payment {
            id
            paymentError
            paymentId
            paymentIdGpWebpay
            paymentIdIngenico
            paymentIdMonetaOnLine
            paymentMetadata
            paymentStatus
            paymentType
            cancellationNotes
            cancellationStatus
            amount
            isDeleted
            BillingInformation {
              id
              invoiceTo
              name
              surname
              address
              postalCode
              city
              regionProvinceState
              region
              province
              country
              countryIsoCode
              vatCode
              taxCode
              reference
              email
              pec
              phone
              invoiceToPublicAuthority
              uniqueCode
              ipaCode
              isSplitPayment
              isVatEvent
            }
          }
        }
      }
    `;
    }) || [];

  const allotmentGuestHotelReservationQueries =
    products?.guestReservations?.map((product) => {
      if (!product.id) return '';
      //for guests product.id is guest id
      return `
    alias_guest_reservation${product.id.replace(
      /-/g,
      '_'
    )}: getHotelReservation(id: "${product.productId}") {
      id
      isDeleted
      hotelRoomReservation {
        items {
          endDate
          startDate
          isDeleted
          bookingCode
          guests {
            items {
              id
              givenName
              familyName
              phone
              country
            }
          }
          hotelRoom {
            name
            frontofficeName
            id
            isDeleted
            hotel {
              id
              name
              region
              province
              postalCode
              phone
              email
              stars
              route
              streetNumber
            }
          }
        }
      }
    }
  `;
    }) || [];
  return [
    ...allotmentGuestProductsQueries,
    ...allotmentGuestHotelReservationQueries,
  ];
};

export const getParticipation = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationQuery,
      variables: {
        id,
      },
    })
      .then((response) => resolve(response.data.getParticipation))
      .catch((e) => {
        console.error('get-participation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getParticipationGuests = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationGuestsQuery,
      variables: {
        id,
      },
    })
      .then((response) => resolve(response.data.getParticipation))
      .catch((e) => {
        console.error('get-participation-guests', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getParticipationGuestsHotelRoomReservation = (
  id,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getParticipationGuestsHotelRoomReservationQuery,
      variables: {
        id,
      },
    })
      .then((response) => resolve(response.data.getParticipation))
      .catch((e) => {
        console.error('get-participation-guests-hotel-room-reservation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getProduct = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getProductQuery,
      variables: {
        id,
      },
    })
      .then((response) => resolve(response.data.getProduct))
      .catch((e) => {
        console.error('get-product', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getRegistration = (productId, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getRegistrationQuery,
      variables: { id: productId },
    })
      .then((response) => resolve(response.data.getProfileFeeBracket))
      .catch((e) => {
        console.error('get-registration', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getAdditionalServicesAllProducts = (
  productsAndReservations,
  useLoader = true
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    const socialEventQueries = createSocialEventQueries(
      productsAndReservations
    );
    const scientificEventQueries = createScientificEventQueries(
      productsAndReservations
    );
    const queries = [socialEventQueries, scientificEventQueries].join('');

    if (!queries) return {};

    const query = `query GetAllProducts { ${queries} }`;

    API.graphql({
      query,
      variables: productsAndReservations,
    })
      .then((response) => resolve(response.data))
      .catch((e) => {
        console.error(
          'get-additionalServices-all-products-by-productsAndReservations',
          e
        );
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getHotelReservation = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getHotelReservationQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data.getHotelReservation))
      .catch((e) => {
        console.error('get-hotel-reservation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getAdditionalService = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getAdditionalServicesQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data.getAdditionalServices))
      .catch((e) => {
        console.error('get-additional-services', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

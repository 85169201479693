export const translation = {
  genericPayment: (intl) => ({
    greetings: intl.formatMessage({
      description: 'Generic payment greeting title',
      defaultMessage: 'Hello',
    }),
    subtitle: intl.formatMessage({
      description: 'Generic payment subtitle title',
      defaultMessage: 'You have been requested to pay the following order.',
    }),
    id: intl.formatMessage({
      description: 'Generic payment id grid title',
      defaultMessage: 'ID',
    }),
    description: intl.formatMessage({
      description: 'Generic payment description grid title',
      defaultMessage: 'Service',
    }),
    netPrice: intl.formatMessage({
      description: 'Generic payment netPrice grid title',
      defaultMessage: 'Net price',
    }),
    vatPercentage: intl.formatMessage({
      description: 'Generic payment vatPercentage grid title',
      defaultMessage: 'Vat %',
    }),
    vatAmount: intl.formatMessage({
      description: 'Generic payment vatAmount grid title',
      defaultMessage: 'Vat (€)',
    }),
    total: intl.formatMessage({
      description: 'Generic payment total grid title',
      defaultMessage: 'Total (€)',
    }),
    buy: intl.formatMessage({
      description: 'Generic payment buy button label',
      defaultMessage: 'Buy',
    }),
    status: intl.formatMessage({
      description: 'Generic payment status grid title',
      defaultMessage: 'Status',
    }),
    statuses: {
      pending: intl.formatMessage({
        description: 'Generic payment status pending',
        defaultMessage: 'Pending',
      }),
      done: intl.formatMessage({
        description: 'Generic payment status done',
        defaultMessage: 'Done',
      }),
    },
  }),
  billingInformations: (intl) => ({
    title: intl.formatMessage({
      description: 'Generic payment billing info title',
      defaultMessage: 'Billing Info',
    }),
    unauthorized: intl.formatMessage({
      description: 'Generic payment billing info unauthorized',
      defaultMessage: 'You are not authorized to view this page',
    }),
    actions: {
      backButton: intl.formatMessage({
        description: 'Generic payment billing info back button',
        defaultMessage: 'Back',
      }),
      saveButton: intl.formatMessage({
        description: 'Generic payment billing info save button',
        defaultMessage: 'Continue',
      }),
    },
  }),
  cart: (intl) => ({
    title: intl.formatMessage({
      description: 'Generic payment cart title',
      defaultMessage: 'Shopping cart',
    }),
    unauthorized: intl.formatMessage({
      description: 'Generic payment cart unauthorized',
      defaultMessage: 'You are not authorized to view this page',
    }),
    actions: {
      buyButton: intl.formatMessage({
        description: 'Generic payment cart buy button',
        defaultMessage: 'Buy',
      }),
    },
    service: intl.formatMessage({
      description: 'Generic payment cart service',
      defaultMessage: 'Service',
    }),
    price: intl.formatMessage({
      description: 'Generic payment cart price',
      defaultMessage: 'Price (€)',
    }),
    vat: intl.formatMessage({
      description: 'Generic payment cart vat',
      defaultMessage: 'Vat',
    }),
    total: intl.formatMessage({
      description: 'Generic payment cart total',
      defaultMessage: 'Total (€)',
    }),
  }),
  checkout: (intl) => ({
    success: intl.formatMessage({
      description: 'Generic payment checkout success response',
      defaultMessage:
        'Thanks, the procedure was successful!\n You will receive a confirmation mail.',
    }),
    error: intl.formatMessage({
      description: 'Generic payment checkout error response',
      defaultMessage:
        'Sorry, an error occurred during payment.\n Please try again later.',
    }),
    alreadyPaid: intl.formatMessage({
      description: 'Generic payment checkout already paid response',
      defaultMessage: 'The purchase has already been completed.\n Thank you!',
    }),
  }),
};

import React, { useEffect, useState } from 'react';
import { AimDialog, useAimMediaQuery } from '@aim/components';
import { constants } from '@aim/common';

import TravelAndTransferPrefs from './TravelTransferPrefs';

const TravelTransferDialog = ({
  dialogState,
  typology,
  onClose,
  onSuccess,
  onError,
  i18n,
}) => {
  const _i18n = i18n.dialogs.travelAndTransfer;
  const { isMobile } = useAimMediaQuery();
  const [travelTransfer, setTravelTransfer] = useState();
  const [isEditable, setIsEditable] = useState();

  // Effects
  useEffect(() => {
    if (dialogState) {
      const rawFacultyResponse =
        dialogState?.participationServices?.travelFacultyResponse;
      let facultyResponse;
      if (rawFacultyResponse)
        facultyResponse = JSON.parse(rawFacultyResponse) || {};

      setIsEditable(
        facultyResponse?.responseStatus !==
          constants.FacultyResponseStatus.PUBLISHED
      );
      setTravelTransfer({
        id: dialogState?.participationServices?.id,
        ...facultyResponse,
      });
    }
  }, [dialogState]);

  if (!dialogState) return null;

  return (
    <AimDialog
      open={dialogState.isOpen || false}
      title={_i18n.title}
      onClose={onClose}
      disableEnforceFocus
      hideAgreeButton={true}
      hideCancelButton={true}
      style={{
        width: isMobile ? '100%' : '85%',
        minWidth: '300px',
        maxWidth: '100%',
        margin: '0 auto',
      }}
      PaperProps={{
        style: {
          ...(isMobile && { margin: 10 }),
          paddingRight: isMobile ? 10 : 30,
          paddingLeft: isMobile ? 10 : 30,
          paddingTop: isMobile ? 10 : 20,
          paddingBottom: isMobile ? 10 : 30,
          maxWidth: isMobile ? '80%' : '70%',
          ...(!isMobile && { minWidth: 600 }),
        },
      }}
    >
      <TravelAndTransferPrefs
        onSuccess={onSuccess}
        onError={onError}
        onClose={onClose}
        participationServices={dialogState.participationServices}
        travelTransferData={travelTransfer}
        typology={typology}
        isEditable={isEditable}
      />
    </AimDialog>
  );
};

export default TravelTransferDialog;

import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getAgencyGatewayQuery = /* GraphQL */ `
  query ListGateways($filter: ModelGatewayFilterInput) {
    listGateways(filter: $filter) {
      items {
        id
        service
        createdAt
        updatedAt
        shop {
          id
          name
          paymentType
          gatewayType
          gatewayId
          gatewayPwd
          iban
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      end
      id
    }
  }
`;

export const getAgencyGateway = () =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getAgencyGatewayQuery,
      variables: {
        filter: { service: { eq: constants.GatewayServices().GROUP.id } },
      },
    })
      .then((response) => {
        return resolve(response.data.listGateways.items[0]);
      })
      .catch((e) => {
        console.error('get-agency-error', e);
        reject();
      })
      .finally(() => hideLoader());
  });

export const getEndDateOfEvent = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEvent,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-end date of event-operation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

// export const getAgencyGateway = (useLoader = true) => {
//   new Promise((resolve, reject) => {
//     useLoader && showLoader();

//     API.graphql({
//       query: getAgencyGatewayQuery,
//       variables: {},
//     })
//       .then((response) => {
//         return resolve({
//           gateway: response.data.listGateways.items[0],
//         });
//       })
//       .catch((e) => {
//         console.error('agency-gateway', e);
//         reject();
//       })
//       .finally(() => useLoader && hideLoader());
//   });
// };

import { format, isAfter, isSameDay } from 'date-fns';
import { startOfToday } from 'date-fns';
import { constants, appState } from '@aim/common';
import { utcToZonedTime } from 'date-fns-tz';
const currentTimezone = appState.eventInfo.getValue().timezone;

export const useDataHelper = () => {
  /**
   * Format Date
   */
  const getFormattedDate = (dateString, formatString = 'dd/MM/yyyy') => {
    try {
      const date = utcToZonedTime(new Date(dateString), currentTimezone);

      return format(date, formatString, {
        timeZone: currentTimezone,
      });
    } catch (e) {
      //console.log(e, dateString);
      return dateString;
    }
  };

  /**
   * Parse richText string to JSON
   */
  const parseRichText = (str) => {
    if (str) {
      try {
        const jsonParsed = JSON.parse(str);
        return jsonParsed.blocks[0].text ? jsonParsed : null;
      } catch (e) {
        console.warn(e);
      }
    }
    return null;
  };

  const safeNum = (value) => (isNaN(Number(value)) ? 0 : Number(value));

  const encodeDbNumber = (value) => safeNum((safeNum(value) * 100).toFixed(2));

  const decodeDbNumber = (value) => safeNum(value) / 100;

  const decodeDbHours = (value) => {
    const hd = safeNum(value) / 60;
    const hi = Math.floor(hd);
    const md = hd - hi;
    const mi = md * 60;
    return `${hi}h ${mi}min`;
  };

  const percentageCalc = (p, v) => {
    return Number(p) + (Number(p) * Number(v)) / 100;
  };

  const percentage = (vat, totalValue) => {
    const perc = (totalValue / 100) * vat;
    return roundTo2Decimal(perc);
  };
  const vatCalc = (p, v) => {
    const taxedPrice = percentageCalc(p, v);
    return roundTo2Decimal(taxedPrice);
  };

  const roundTo2Decimal = (number) =>
    Math.round((number + Number.EPSILON) * 100) / 100;

  const formatNumber = (number) =>
    new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(number);

  const physicalStandSpacePriceCalc = ({
    netPrice,
    vatRate,
    noVat,
    extraPriceDisabled,
    extraPriceRate,
    extraPriceDate,
  }) => {
    const extraNetPrice = roundTo2Decimal(
      percentageCalc(netPrice, extraPriceDisabled ? 0 : extraPriceRate)
    );
    const newVatRate = noVat ? 0 : vatRate;

    return {
      applyExtraPrice: extraPriceDate
        ? isAfter(startOfToday(), new Date(extraPriceDate)) ||
          isSameDay(startOfToday(), new Date(extraPriceDate))
        : false,
      vatRate: newVatRate,
      netPrice,
      extraNetPrice,
      vatPrice: vatCalc(netPrice, newVatRate),
      extraVatPrice: vatCalc(extraNetPrice, newVatRate),
    };
  };

  const sortBy = (array, fieldName) => {
    return array
      ? array.sort((a, b) => {
          if (a[fieldName] < b[fieldName]) return -1;
          if (b[fieldName] > a[fieldName]) return 1;
          return 0;
        })
      : [];
  };

  const searchSponsorHighestRankingVirtualStand = (sponsorVirtualStands) => {
    let nextHighestRankingVirtualStand;
    sponsorVirtualStands.forEach((v) => {
      const virtualStand = v.virtualStand;
      if (
        virtualStand?.isEnabled &&
        virtualStand?.ranking > (nextHighestRankingVirtualStand?.ranking || -1)
      ) {
        nextHighestRankingVirtualStand = { ...v };
      }
    });
    return nextHighestRankingVirtualStand;
  };

  return {
    safeNum,
    encodeDbNumber,
    decodeDbNumber,
    decodeDbHours,
    percentageCalc,
    vatCalc,
    percentage,
    roundTo2Decimal,
    formatNumber,
    sortBy,
    physicalStandSpacePriceCalc,
    getFormattedDate,
    parseRichText,
    searchSponsorHighestRankingVirtualStand,
  };
};

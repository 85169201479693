import React from 'react';
import { useIntl } from 'react-intl';
import GetAppIcon from '@material-ui/icons/GetApp';
import Divider from '@material-ui/core/Divider';

import {
  AimTableNoPaging,
  AimTableRow,
  AimTableCell,
  AimIconButton,
  AimTypography,
  CustomIntl,
  theme,
  styled,
} from '@aim/components';

import { fileHelper } from '@aim/common';
import {
  getExtensionLabel,
  getIconByExt,
} from './../shared/ExtensionsUtilities';
import { translation } from './translation';

const DownloadAreaContent = styled('div')({
  backgroundColor: 'white',
});
const DownloadAreaFilesList = styled('div')({
  backgroundColor: theme.colors.greyScale.backgroundGrey,
  padding: 20,
  minHeight: 150,
  marginBottom: 20,
});

const DownloadArea = ({ files, dirPath }) => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.faculty(intl);

  const downloadAreaHeadCells = () => [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: '',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: i18n.downloadArea.table.columns.name.label,
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: i18n.downloadArea.table.columns.type.label,
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const DownloadAreaRow = ({ row }) => {
    return (
      <>
        <AimTableRow hover>
          <AimTableCell>{getIconByExt(row.extension)}</AimTableCell>
          <AimTableCell>{row?.name}</AimTableCell>
          <AimTableCell>{getExtensionLabel(row?.extension, i18n)}</AimTableCell>
          <AimTableCell align="right">
            <AimIconButton
              variant={'lightBlueFill'}
              small="true"
              onClick={() => fileHelper.downloadFile({ file: row, dirPath })}
            >
              <GetAppIcon />
            </AimIconButton>
          </AimTableCell>
        </AimTableRow>
      </>
    );
  };

  return (
    <DownloadAreaContent>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <AimTypography variant="textBold" boxStyle={{ marginLeft: 0 }}>
          {i18n.downloadArea.title}
        </AimTypography>
      </div>
      <Divider style={{ width: '100%', marginBottom: 10 }} />
      <DownloadAreaFilesList>
        <AimTableNoPaging
          title={i18n.downloadArea.table.title}
          headCells={downloadAreaHeadCells()}
          rows={files}
          notCheckable={true}
          rowKey="id"
        >
          <DownloadAreaRow />
        </AimTableNoPaging>
      </DownloadAreaFilesList>
    </DownloadAreaContent>
  );
};

export default DownloadArea;

import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  theme,
  AimSnackbarSeverity,
  AimSnackbar,
  BillingInformations,
} from '@aim/components';

import translation from './translation';
import {
  // getAdditionalService,
  // getProductsByClientIdServiceId,
  getEventGateway,
} from './gqlHelper';
import { useWelcomePageModulesRedirects } from '../../hooks/useWelcomePageModulesRedirects';

import {
  appState,
  constants,
  utilities /* , googleHelper */,
} from '@aim/common';

const { formatNumber, percentage, decodeDbNumber, encodeDbNumber } = utilities;

const GeneralInfo = ({ i18n, bookingInfo, participation, vatRate }) => {
  const vat = percentage(vatRate, bookingInfo?.totalAmount);

  return (
    (bookingInfo && participation && (
      <div
        style={{
          background: 'white',
          borderRadius: 3,
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div>
          <AimTypography
            variant="h4"
            boxStyle={{
              borderBottom: `3px solid ${theme.colors.greyScale.backgroundGrey}`,
            }}
          >
            {i18n.page.generalInfo}
          </AimTypography>
        </div>
        <div style={{ display: 'flex' }}>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.service}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.purchasedBy}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.unitaryPrice}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.vatRate}
          </AimTypography>
          <AimTypography variant="columnHeader" style={{ flex: 1 }}>
            {i18n.page.labels.price}
          </AimTypography>
        </div>
        <div style={{ display: 'flex' }}>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {bookingInfo.title}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {`${participation?.givenName} ${participation?.familyName}`}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(bookingInfo.totalAmount)}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(vat)}
          </AimTypography>
          <AimTypography variant="text" style={{ flex: 1 }}>
            {formatNumber(bookingInfo.totalAmount + vat)}
          </AimTypography>
        </div>
      </div>
    )) ||
    null
  );
};

const AllotmentBillingInformations = () => {
  const { getServiceRedirectLinkEvent } = useWelcomePageModulesRedirects();
  const { eventId, hotelEventVentureId } = useParams();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const i18n = translation.billingInformations(intl);

  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [bookingInfo, setBookingInfo] = useState();
  const [participation] = useState(appState.getCurrentParticipation());
  const [formExportedValues, setFormExportedValues] = useState({});
  const [billingInfoBookings, setBillingInfoBookings] = useState();

  // ref
  const gatewayRef = useRef();

  useEffect(() => {
    checkoutResponse();
  }, []);

  const checkoutResponse = () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.success,
      });
      const link = getServiceRedirectLinkEvent('Allotment');
      if (link) {
        history.push(link);
      } else {
        history.push(
          `/events/${eventId}/allotment/${hotelEventVentureId}/payment-success?t=${constants.ProductType.allotment}&order=${order}`
        );
      }
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (participation && !bookingInfo) {
        const subscriptionPurchase = appState.purchaseData.subscribe(
          (bookingInfo) => {
            if (bookingInfo) {
              const totalAmount = bookingInfo.hotelRoomsToReserve.reduce(
                (res, curr) => (res += curr.amount),
                0
              );
              setBookingInfo({
                ...bookingInfo,
                totalAmount,
                title: i18n.title,
              });
            }
          }
        );

        // getAdditionalService(additionalServiceId).then((as) => {
        //   //get additional services purchased
        //   getProductsByClientIdServiceId(
        //     eventId,
        //     constants.AdditionalServicesServiceTypology[as.typology]
        //       ?.productServiceType
        //   ).then((prods) => {
        //     return setAdditionalService({
        //       ...as,
        //       amount1: decodeDbNumber(as.amount1),
        //       amount2: decodeDbNumber(as.amount2),
        //       vat1: decodeDbNumber(as.vat1),
        //       vat2: decodeDbNumber(as.vat2),
        //       availableSubscription:
        //         as.rule === 'yes/no'
        //           ? 1
        //           : as.maxSubscription
        //           ? parseInt(as.maxSubscription, 10) - (prods.length || 0)
        //           : undefined,
        //     });
        //   });
        // });

        return () => {
          subscriptionPurchase.unsubscribe();
        };
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    setBillingInfoBookings({
      [hotelEventVentureId]: {
        vatRate1: bookingInfo?.vat,
        vat1Id: bookingInfo?.vatId,
        participationMode: constants.EventTypes.PHYSICAL.id,
      },
    });
  }, [bookingInfo]);

  useEffect(() => {
    if (eventId) {
      const fetchEventGateway = async () => {
        const nextGateway = await getEventGateway({
          id: eventId,
          serviceType: constants.GatewayServices().ALLOTMENT.key,
        });

        gatewayRef.current = nextGateway;
      };
      fetchEventGateway();
    }
  }, [eventId]);

  // const handleCopyFromLastEvent = async () => {
  //   const billingInfo = await getParticipationLastEventBillingInfo(
  //     participation?.cognitoUserId,
  //     eventId
  //   );
  //   reset({
  //     ...billingInfo,
  //     country: { label: billingInfo?.country, value: billingInfo?.country },
  //     city: { label: billingInfo?.city, value: billingInfo?.city },
  //     invoiceToPublicAuthority: billingInfo?.invoiceToPublicAuthority?.toString(),
  //     isSplitPayment: billingInfo?.isSplitPayment?.toString(),
  //     isVatEvent: billingInfo?.isVatEvent?.toString(),
  //   });
  // };

  const afterSendData = async (billingInfo) => {
    const getCartData = async () => {
      const user = appState.user.getValue();

      if (gatewayRef.current === undefined) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.gatewayNotExist,
        });
        return;
      }

      if (gatewayRef.current?.shop?.paymentType === undefined) {
        setSnackbar({
          isOpen: true,
          severity: AimSnackbarSeverity.error,
          message: i18n.snackbar.paymentTypeNotExist,
        });
        return;
      }

      const paymentType =
        bookingInfo.totalAmount === 0
          ? constants.PaymentTypes.Free
          : gatewayRef.current?.shop?.paymentType;

      // const paymentMetadata = bookingInfo?.hotelRoomsToReserve?.reduce(
      //   (res, curr, index) => {
      //     res = {
      //       ...res,
      //       [`idRoom${index}`]: curr.id,
      //       [`roomFrontOfficeName${index}`]: curr.frontofficeName,
      //       [`netAmount${index}`]: encodeDbNumber(curr.amount),
      //       [`varRate${index}`]: encodeDbNumber(bookingInfo.vat),
      //     };
      //     return res;
      //   },
      //   {}
      // );

      const vatRate = formExportedValues?.[hotelEventVentureId]?.vatRate1;
      const vatId = formExportedValues?.[hotelEventVentureId]?.vat1Id;
      const isForced = formExportedValues?.[hotelEventVentureId]?.isForced;

      const paymentMetadata = bookingInfo?.hotelRoomsToReserve?.reduce(
        (res, curr) => {
          const room = {
            id: curr.id,
            frontofficeName: curr.frontofficeName,
            amount1: encodeDbNumber(curr.amount),
            netAmount1: encodeDbNumber(curr.amount),
            vatRate1: encodeDbNumber(vatRate),
            vat1Id: vatId,
            isForced,
          };
          res = {
            rooms: [...res.rooms, room],
          };
          return res;
        },
        { rooms: [] }
      );

      const state = {
        netPrice: bookingInfo.totalAmount,
        gateway: gatewayRef.current,
        ...(bookingInfo.totalAmount === 0 && {
          paymentType: constants.PaymentTypes.Free,
        }),
        productId: null,
        serviceId: eventId,
        serviceType: constants.Services.ALLOTMENT.key,
        clientId: user.userAndParticipation.participation.id,
        clientType: constants.Clusters.Pax.id,
        serviceLabel: i18n.title,
        quantity: null,
        availableSubscription: null,
        vat: {
          isForced,
          vatRate1: vatRate,
          vat1Id: vatId,
          taxableAmountVat1: bookingInfo.totalAmount,
        },
        paymentMetadata,
        billingInfo,
        url: `/events/${eventId}/allotment/${hotelEventVentureId}`,
        baseUrl: window.location.host,
        participationGuests: participation.guests,
        options: { hotelEventVentureId },
      };

      if (paymentType === 'both') {
        history.push({
          pathname: `/events/${eventId}/allotment/${hotelEventVentureId}/payment-opt`,
          state,
        });
      } else {
        history.push({
          pathname: `/events/${eventId}/allotment/${hotelEventVentureId}/payment`,
          state,
        });
      }
    };

    getCartData();
    // checkoutResponse();
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          minWidth: 'calc(100vw - 480px)',
          margin: '20px 180px',
        }}
      >
        <AimIconAndTextButton
          isUpperCase
          variant="none"
          text={i18n.actions.backButton}
          style={{
            padding: 0,
          }}
          onClick={
            () => {
              history.goBack();
            }
            // history.replace(
            //   `/events/${eventId}/allotment/${hotelEventVentureId}/booking`
            // )
          }
        >
          <ArrowBack />
        </AimIconAndTextButton>
        <AimTypography variant="h1">{i18n.page.title}</AimTypography>
        {bookingInfo && (
          <GeneralInfo
            i18n={i18n}
            intl={intl}
            bookingInfo={bookingInfo}
            participation={participation}
            vatRate={formExportedValues?.[hotelEventVentureId]?.vatRate1}
          />
        )}
        <BillingInformations
          type={constants.Clusters.Pax.id}
          customerId={participation.id}
          history={history}
          intl={intl}
          showSnackbar
          saveButtonText={i18n.actions.continue}
          SaveButtonIcon={ShoppingCartIcon}
          onAfterSave={afterSendData}
          setFormExportedValues={setFormExportedValues}
          billingInfoBookings={billingInfoBookings}
        />
      </div>
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AllotmentBillingInformations;

import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const productByServiceIdServiceTypeQuery = /* GraphQL */ `
  query ProductByServiceIdServiceType(
    $serviceId: String
    $serviceType: String
  ) {
    productByServiceIdServiceType(
      serviceId: $serviceId
      serviceType: { eq: $serviceType }
      limit: 1000
    ) {
      items {
        id
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          paymentStatus
          amount
          paymentMetadata
          createdAt
          updatedAt
          isDeleted
          BillingInformation {
            id
            isVatExent: isVatEvent
            invoiceTo
            name
            address
            city
            postalCode
            region
            province
            country
            countryIsoCode
            vatCode
            taxCode
            reference
            email
            pec
            phone
            invoiceToPublicAuthority
            uniqueCode
            ipaCode
            isSplitPayment
            po {
              originalName
            }
            cig
            cup
            tripletta
            venue
          }
        }
        childs {
          items {
            id
            productId
            clientId
            clientType
            serviceId
            serviceType
            createdAt
          }
        }
        cart {
          id
          productId
          clientId
          clientType
          serviceId
          serviceType
          createdAt
        }
        cartChildren {
          items {
            id
            productId
            clientId
            clientType
            serviceId
            serviceType
            createdAt
          }
        }
      }
    }
  }
`;

const getAgencyQuery = /* GraphQL */ `
  query getAgency($id: ID!) {
    getAgency(id: $id) {
      id
      name
      agencyGroups {
        items {
          id
          name
        }
      }
    }
  }
`;

const getAgenciesByEventIdQuery = /* GraphQL */ `
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      id
      agencies {
        items {
          id
          name
          agencyGroups {
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const listPurchases = ({ eventId, serviceType, useLoader = true }) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: productByServiceIdServiceTypeQuery,
      variables: {
        serviceId: eventId,
        serviceType,
      },
    })
      .then((response) => {
        console.log('listPurchases response ', response);
        resolve(response.data.productByServiceIdServiceType.items);
      })
      .catch((e) => {
        console.error('list-agency-purchases', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getAgenciesByEventId = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getAgenciesByEventIdQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        return resolve(response.data.getEvent?.agencies?.items);
      })
      .catch((e) => {
        console.error('agencies-by-event', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getAgency = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getAgencyQuery,
      variables: { id },
    })
      .then((response) => {
        return resolve(response.data.getAgency);
      })
      .catch((e) => {
        console.error('get-agency', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

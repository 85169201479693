import { aws, appState, constants } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getAgendaSessionsByEventQuery = (agendaType) => /* GraphQL */ `
  query GetEventSessions($id: ID!) {
    getEvent(id: $id) {
      agenda: ${agendaType} {
        sessions {
          items {
            id
            name
            code
            shortDescription
            date
            start
            end
            room {
              id
              name
              ordering
            }
            attendeeRoles {
              items {
                id
                role {
                  id
                  name
                  frontofficeName
                  showInProgram
                }
                attendees {
                  items {
                    id
                    participation {
                      id
                      givenName
                      familyName
                      user {
                        nationality
                      }
                    }
                  }
                }
              }
            }
            subSessions {
              items {
                id
                name
                code
                shortDescription
                start
                end
                isCoincideEnabled
                attendeeRoles {
                  items {
                    id
                    role {
                      id
                      name
                      frontofficeName
                      showInProgram
                    }
                    attendees {
                      items {
                        id
                        participation {
                          id
                          givenName
                          familyName
                          user {
                            nationality
                          }
                        }
                      }
                    }
                  }
                }
                speeches {
                  items {
                    id
                    start
                    end
                    code
                    titleDescription
                    session {
                      id
                    }
                    title
                    attendeeRoles {
                      items {
                        id
                        role {
                          id
                          name
                          frontofficeName
                          showInProgram
                        }
                        attendees {
                          items {
                            id
                            participation {
                              id
                              givenName
                              familyName
                              user {
                                nationality
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getAgendaSessionsByEvent = (
  eventId,
  agendaType = constants.AgendaTypes.AGENDA.queryField,
  confirm,
  useLoader = false
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getAgendaSessionsByEventQuery(agendaType),
      variables: {
        id: eventId,
      },
    })
      .then((response) => {
        resolve(response.data.getEvent.agenda?.sessions || []);
      })
      .catch((e) => {
        console.error('get-agendaSession-event-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

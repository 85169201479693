import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { constants } from '@aim/common';
import translation from './translation';

import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';

import Grid from '@material-ui/core/Grid';

import {
  AimDragDropFileUploader,
  AimRadioForm,
  AimFormControlLabel,
  AimRadioButton,
  AimTypography,
  AimRichText,
  Travel,
  // Transfer,
  AimIconAndTextButton,
  styled,
  CustomIntl,
  useDataHelper,
  theme,
} from '@aim/components';

import { updateParticipationServices } from './travelTransferGqlHelper';
import { fileHelper, appState } from '@aim/common';

//Constants
const formControlStyle = { width: 'calc(100% - 15px)' };

const InnerContainer = styled('div')({
  padding: 20,
});

const Container = styled('div')({
  backgroundColor: 'white',
  margin: '10px 0px',
  display: 'flex',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 10,
});

const TravelTransferPrefs = ({
  participationServices,
  travelTransferData,
  typology,
  onSuccess,
  onError,
  onClose,
  isEditable,
}) => {
  const intl = CustomIntl(useIntl());
  const i18n = translation.TravelTransfer(intl);
  const { parseRichText } = useDataHelper();
  // const participationId = appState.user.getValue().userAndParticipation
  //   .participation.id;

  const [files, setFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);

  const { eventId } = useParams();
  const [radioPanel, setRadioPanel] = useState(0);
  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      transferId: null,
    },
  });
  const s3EventFolder = `events/${eventId}`;

  // Helpers
  const deleteAndUploadFiles = async () => {
    const dirPath = `${s3EventFolder}/participations/${participationServices?.participation.id}`;
    // files to remove
    const nextFilesToRemove = [...new Set(filesToRemove.map((t) => t.file))];
    for (let i = 0; i < nextFilesToRemove.length; i++) {
      const file = nextFilesToRemove[i];
      if (file.id) {
        await fileHelper.deleteFile({
          dirPath,
          fileData: file,
          useLoader: false,
        });
      }
    }
    setFilesToRemove([]);

    // files to add
    // const nextFiles = [...new Set(files.map((t) => t.file))];
    const newFilesFormatted = files.filter((fd) => !fd.file.id);
    const nextNewFilesFormatted = await Promise.all(
      newFilesFormatted.map(async (fileFormatted) => {
        const originalName =
          fileFormatted?.file?.name || new Date().getTime().toString();

        const matchExtension = originalName.match(/\.[0-9a-z]+$/i);
        const extension = matchExtension ? matchExtension[0] : '';

        const fileSize = fileFormatted?.file?.size;

        const input = {
          originalName: originalName,
          extension: extension,
          size: Number(fileSize) || 0,
        };

        const fileId = await fileHelper.createFile({
          dirPath,
          fileData: input,
          localFile: fileFormatted?.file,
        });
        const fileInput = { ...input, name: originalName, id: fileId };

        const nextFileFormatted = { ...fileFormatted, file: fileInput };
        return nextFileFormatted;
      })
    );
    return nextNewFilesFormatted;
  };

  const removeFile = async (file) => {
    setFilesToRemove([
      ...filesToRemove,
      {
        // type: typology.PARTICIPATION_FILE.id,
        // topic: 'Participation contents',
        file,
      },
    ]);
    const nextUpdatedFiles = files.filter((fd) =>
      file.id ? fd.file.id !== file.id : fd.file.name !== file.name
    );
    setFiles(nextUpdatedFiles);
  };

  const downloadFile = async (item, dirPath, key) => {
    if (item.id) {
      await fileHelper.downloadFromS3({
        dirPath: dirPath
          ? dirPath
          : `${s3EventFolder}/participations/${participationServices.participation.id}/`,
        fileData: item,
      });
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(item);
      link.download = item.name;
      link.click();
      link.remove();
    }
  };

  const formattingFiles = ({ filesToFormat, type, topic }) => {
    return filesToFormat.map((f) => ({
      type,
      topic,
      file: f,
    }));
  };

  // test data
  // {
  //   prefsTravelOneWayTravelBy: 'airplane',
  //   chooseBooking: '2',
  //   prefsTravelOneWayLeavingFrom: 'Ancona',
  //   prefsTravelOneWayArrivingIn: 'Milano da bere',
  //   prefsTravelOneWayLeavingAt: '12:30',
  //   bookByAgency: false,
  //   prefsTravelReturnArrivingIn: 'Ancona',
  //   pprefsTransferOneWayFrom: 'airport',
  //   prefsTravelTelephoneNumber: '11111111',
  //   prefsTravelReturnLeavingAt: '15:30',
  //   prefsCheckedBaggage: 'no',
  //   prefsTransferReturnTime: '17:00',
  //   prefsTravelOneWayArrivingAt: '20:00',
  //   prefsTravelOneWayIntermediateAirport: 'Canicattì',
  //   prefsProposal: 'yes',
  //   prefsTransferReturnPickUpDestination: 'Mialno malpensa',
  //   prefsTravelNotes: 'è ora de lassa perde',
  //   prefsTransferOneWayPickUpLocation: 'Milano malpensa',
  //   prefsTransferOneWayPickUpDestination: 'Milano fiera',
  //   prefsTravelAirplaneFidelityCard: '35454543',
  //   prefsTravelOneWayFlightTrainN: '13213',
  //   prefsTransferReturnTo: 'airport',
  //   prefsTransferOneWayTime: '18:30',
  //   prefsTravelReturnIntermediateAirport: 'Vattelapesca',
  //   prefsTravelReturnTravelBy: 'airplane',
  //   prefsTransferReturnFrom: 'congress',
  //   bookByAuth: true,
  //   prefsTravelTrainFidelityCard: '55555555555',
  //   prefsTransferReturnDate: '2021-12-09',
  //   responseStatus: 'published',
  //   prefsTravelOneWayDate: '2021-12-02',
  //   prefsIssueTickets: 'yes',
  //   prefsTravelReturnLeavingFrom: 'Milano bevuta',
  //   prefsTransferOneWayTo: 'congress',
  //   bookByMyself: false,
  //   prefsTravelReturnArrivingAt: '19:00',
  //   prefsTransferOneWayDate: '2021-12-03',
  //   prefsTravelReturnDate: '2021-12-09',
  //   prefsTransferReturnPickUpLocation: 'Milano fiera',
  //   prefsTravelReturnFlightTrainN: '1234532',
  // };

  const onSave = async (data, status) => {
    try {
      //files
      const filesToRemoveCopy = [...filesToRemove];
      const facultyAttachmentsFiles = participationServices?.facultyAttachmentsResponse
        ? JSON.parse(participationServices.facultyAttachmentsResponse)
        : [];

      let filesNotRemoved = [...facultyAttachmentsFiles];
      if (filesToRemoveCopy?.length) {
        const filesToRemoveCopyIds = [
          ...new Set(filesToRemoveCopy.map((fd) => fd.file.id)),
        ];
        filesNotRemoved = facultyAttachmentsFiles.filter(
          (fd) =>
            !filesToRemoveCopyIds?.length ||
            !filesToRemoveCopyIds.includes(fd?.file?.id)
        );
      }

      // new files to upload
      const newFilesFormatted = await deleteAndUploadFiles();

      const facultyAttachmentsResponse = JSON.stringify([
        ...filesNotRemoved,
        ...newFilesFormatted,
      ]);

      const input = {
        id: participationServices.id,
        travelFacultyResponse: JSON.stringify({
          ...data,
          bookByAuth: radioPanel === 0,
          bookByAgency: radioPanel === 1,
          bookByMyself: radioPanel === 2,
          responseStatus: status, // constants.FacultyResponseStatus.PUBLISHED,
        }),
        facultyAttachmentsResponse,
      };

      if (input?.id) {
        await updateParticipationServices(input);
      }
      onClose();
      onSuccess();
    } catch (err) {
      console.error('err', err);
      onError();
    }
  };

  useEffect(() => {
    // fetchData();
    setRadioPanel(parseInt(travelTransferData.chooseBooking, 10));

    const facultyAttachmentsResponse = participationServices?.facultyAttachmentsResponse
      ? JSON.parse(participationServices?.facultyAttachmentsResponse)
      : [];

    if (facultyAttachmentsResponse?.length) {
      const travelPrefsFiles = facultyAttachmentsResponse
        ? facultyAttachmentsResponse.filter(
            (fd) =>
              fd.type === typology.TRAVEL_BOOK_BY_AGENCY.id ||
              fd.type === typology.TRAVEL_BOOK_BY_MYSELF.id
          )
        : [];

      setFiles(travelPrefsFiles);
    }
    reset(travelTransferData);
  }, []);

  const handleRadio = (e) => {
    const res = parseInt(e.target.value, 10);
    setRadioPanel(res);
  };

  return (
    <InnerContainer>
      {/* <Tooltip title={i18n.page.backButton.tooltip}>
        <AimIconAndTextButton
          variant="text"
          style={{ padding: 0 }}
          text={i18n.page.backButton.label}
          onClick={() => {}}
        >
          <ArrowBack />
        </AimIconAndTextButton>
      </Tooltip> */}
      <Container style={{ flexDirection: 'column' }}>
        <div
          style={{
            zIndex: 10,
            backgroundColor: 'white',
          }}
        >
          <AimTypography variant="h3">{i18n.page.travel.title}</AimTypography>
          {/* <AimTypography variant="textBold" boxStyle={{ margin: '0px 10px' }}>
            {i18n.page.travel.myTravels}
          </AimTypography> */}
          {/* <AimTypography variant="text" boxStyle={{ margin: '0px 10px' }}>
            {i18n.page.travel.myTravelDescr}
          </AimTypography>
          <ul style={{ marginLeft: '40px' }}>
            <li>
              <AimTypography variant="text">
                {i18n.page.travel.myTravelItem1}
              </AimTypography>
            </li>
            <li>
              <AimTypography variant="text">
                {i18n.page.travel.myTravelItem2}
              </AimTypography>
            </li>
          </ul> */}

          <AimRichText
            value={parseRichText(participationServices?.note)}
            readOnly
            variant="white"
            editorStyleOverride={{
              minHeight: 'auto',
              maxHeight: 'fit-content',
              overflow: 'hidden !important',
            }}
          />
        </div>
        <div
        // style={{
        //   paddingTop: 180,
        //   position: 'absolute',
        //   top: 190,
        //   left: 16,
        //   right: 0,
        //   bottom: 0,
        //   overflow: 'auto',
        // }}
        >
          <AimTypography variant="textBold" boxStyle={{ margin: '0px 10px' }}>
            {i18n.page.travel.generalPrefs}
          </AimTypography>
          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AimRadioForm
                  {...{
                    control,
                    name: 'chooseBooking',
                    defaultValue: radioPanel,
                    style: { display: 'flex' },
                    ...(isEditable && { onClick: handleRadio }),
                    value: radioPanel,
                    disabled: !isEditable,
                  }}
                >
                  <AimFormControlLabel
                    key={0}
                    value={0}
                    control={<AimRadioButton />}
                    label={i18n.page.authBooking}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {radioPanel === 0 && (
            <>
              {participationServices?.isTravelIncluded && (
                <>
                  <Travel
                    i18n={i18n}
                    formControlStyle={formControlStyle}
                    control={control}
                    intl={intl}
                    isEditable={isEditable}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <AimRadioForm
                        control={control}
                        name="prefsCheckedBaggage"
                        defaultValue={'no'}
                        style={{ display: 'flex' }}
                        label={i18n.page.travel.consentForTheCheckedBaggage}
                        disabled={!isEditable}
                      >
                        <AimFormControlLabel
                          key={0}
                          value={'yes'}
                          control={<AimRadioButton />}
                          label={i18n.page.travel.yes}
                        />
                        <AimFormControlLabel
                          key={1}
                          value={'no'}
                          control={<AimRadioButton />}
                          label={i18n.page.travel.no}
                        />
                      </AimRadioForm>
                    </Grid>
                  </Grid>
                </>
              )}
              {(participationServices?.isTransferAIncluded ||
                participationServices?.isTransferRIncluded) && (
                <Grid container>
                  {/* old transfer preferences */}
                  {/* <Grid item xs={12}>
                    <Transfer
                      i18n={i18n}
                      formControlStyle={formControlStyle}
                      control={control}
                      intl={intl}
                      isEditable={true}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <AimTypography variant="text">
                      {i18n.page.transfer.title}
                    </AimTypography>
                    <div
                      style={{
                        backgroundColor: theme.colors.greyScale.backgroundGrey,
                        margin: 10,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <AimTypography
                          variant="textBold"
                          // boxStyle={{ margin: '0px 5px' }}
                        >
                          {`${i18n.page.transfer.transferAIncluded}: `}
                        </AimTypography>
                        <AimTypography variant="text">
                          {participationServices.isTransferAIncluded
                            ? i18n.page.transfer.yes
                            : i18n.page.transfer.no}
                        </AimTypography>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <AimTypography
                          variant="textBold"
                          // boxStyle={{ margin: '0px 5px' }}
                        >
                          {`${i18n.page.transfer.transferRIncluded}: `}
                        </AimTypography>
                        <AimTypography variant="text">
                          {participationServices.isTransferRIncluded
                            ? i18n.page.transfer.yes
                            : i18n.page.transfer.no}
                        </AimTypography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <AimRadioForm
                    control={control}
                    name="prefsIssueTickets"
                    defaultValue={'yes'}
                    style={{ display: 'flex' }}
                    label={i18n.page.travel.issueTickets}
                    disabled={!isEditable}
                  >
                    <AimFormControlLabel
                      key={0}
                      value={'yes'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.yes}
                    />
                    <AimFormControlLabel
                      key={1}
                      value={'no'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.no}
                    />
                  </AimRadioForm>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AimRadioForm
                    control={control}
                    name="prefsProposal"
                    defaultValue={'yes'}
                    style={{ display: 'flex' }}
                    label={i18n.page.travel.proposal}
                    disabled={!isEditable}
                  >
                    <AimFormControlLabel
                      key={0}
                      value={'yes'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.yes}
                    />
                    <AimFormControlLabel
                      key={1}
                      value={'no'}
                      control={<AimRadioButton />}
                      label={i18n.page.travel.no}
                    />
                  </AimRadioForm>
                </Grid>
              </Grid>
            </>
          )}

          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <AimRadioForm
                  {...{
                    control,
                    name: 'chooseBooking',
                    defaultValue: radioPanel,
                    style: { display: 'flex' },
                    ...(isEditable && { onClick: handleRadio }),
                    value: radioPanel,
                    disabled: !isEditable,
                  }}
                >
                  <AimFormControlLabel
                    key={1}
                    value={1}
                    control={<AimRadioButton />}
                    label={i18n.page.useAgencyToBook}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {radioPanel === 1 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <AimTypography
                    variant="textBold"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.selectTheContentToUpload}
                  </AimTypography>
                  <AimDragDropFileUploader
                    {...{
                      dragTitle: i18n.page.upload.dragAndDrop.title,
                      dragSubTitle: i18n.page.upload.dragAndDrop.subTitle,
                      clickText: i18n.page.upload.dragAndDrop.clickText,
                      onUploadFile: (filesToAdd) =>
                        setFiles([
                          ...files,
                          ...formattingFiles({
                            filesToFormat: filesToAdd,
                            type: typology.TRAVEL_BOOK_BY_AGENCY.id,
                          }),
                        ]),
                      onRemove: removeFile,
                      onDownload: downloadFile,
                      files: [
                        ...new Set(
                          files
                            .filter(
                              (fd) =>
                                fd.type === typology.TRAVEL_BOOK_BY_AGENCY.id
                            )
                            .map((t) => t.file)
                        ),
                      ],
                      maxSize: '5242880',
                      disabledRemove: !isEditable,
                      disabledDownload: !isEditable,
                      dropzoneAdditionalProps: {
                        multiple: true,
                        disabled: !isEditable,
                      },
                    }}
                  />
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote1}
                  </AimTypography>
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote2}
                  </AimTypography>
                </Grid>
              </Grid>
            </>
          )}

          <div
            style={{
              display: 'flex',
              marginLeft: 16,
              marginTop: 10,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <AimRadioForm
                  {...{
                    control,
                    name: 'chooseBooking',
                    defaultValue: radioPanel,
                    style: { display: 'flex' },
                    ...(isEditable && { onClick: handleRadio }),
                    value: radioPanel,
                    disabled: !isEditable,
                  }}
                >
                  <AimFormControlLabel
                    key={2}
                    value={2}
                    control={<AimRadioButton />}
                    label={i18n.page.autonomousBooking}
                  />
                </AimRadioForm>
              </Grid>
            </Grid>
          </div>
          {radioPanel === 2 && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <AimTypography
                    variant="textBold"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.selectTheContentToUpload}
                  </AimTypography>
                  <AimDragDropFileUploader
                    {...{
                      dragTitle: i18n.page.upload.dragAndDrop.title,
                      dragSubTitle: i18n.page.upload.dragAndDrop.subTitle,
                      clickText: i18n.page.upload.dragAndDrop.clickText,
                      onUploadFile: (filesToAdd) =>
                        setFiles([
                          ...files,
                          ...formattingFiles({
                            filesToFormat: filesToAdd,
                            type: typology.TRAVEL_BOOK_BY_MYSELF.id,
                          }),
                        ]),
                      onRemove: removeFile,
                      onDownload: downloadFile,
                      files: [
                        ...new Set(
                          files
                            .filter(
                              (fd) =>
                                fd.type === typology.TRAVEL_BOOK_BY_MYSELF.id
                            )
                            .map((t) => t.file)
                        ),
                      ],
                      maxSize: '5242880',
                      disabledRemove: !isEditable,
                      disabledDownload: !isEditable,
                      dropzoneAdditionalProps: {
                        multiple: true,
                        disabled: !isEditable,
                      },
                    }}
                  />
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote1}
                  </AimTypography>
                  <AimTypography
                    variant="text"
                    boxStyle={{ margin: '0px 5px' }}
                  >
                    {i18n.page.uploadNote2}
                  </AimTypography>
                </Grid>
              </Grid>
            </>
          )}
        </div>

        {/* <AimCheckboxForm
            control={control}
            name="authBooking"
            defaultValue={''}
          ></AimCheckboxForm> */}
        {/* <AimTypography variant="text" boxStyle={{ margin: '0px 5px' }}>
            {i18n.page.travel.authBooking}
          </AimTypography> */}

        <ButtonContainer>
          <AimIconAndTextButton
            onClick={() => {
              onClose();
            }}
            variant="greyFill"
            text={i18n.page.cancel}
          >
            <ReplayIcon />
          </AimIconAndTextButton>
          <AimIconAndTextButton
            text={i18n.page.confirm}
            variant="greenFill"
            onClick={() => {
              handleSubmit(
                onSave(getValues(), constants.FacultyResponseStatus.DRAFT)
              );
            }}
            disabled={!isEditable}
          >
            <CheckIcon />
          </AimIconAndTextButton>
        </ButtonContainer>
      </Container>
    </InnerContainer>
  );
};

export default TravelTransferPrefs;

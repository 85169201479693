import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isMobileOnly, isMobile } from 'react-device-detect';

import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import Avatar from '@material-ui/core/Avatar';

import { appState, fileHelper, aws, constants } from '@aim/common';
import { AimTypography, CustomIntl } from '@aim/components';
import { translation } from './translation';

const links = ({ eventId, sponsorId, hasPremiumRanges, i18n }) => {
  return [
    {
      id: 'services',
      label: i18n.services,
      link: `/events/${eventId}/${sponsorId}/my-services`,
      linkRedirect: `/events/${eventId}/${sponsorId}/price-ranges`,
      visible: true,
    },
    {
      id: 'premiumRanges',
      label: i18n.premiumRanges,
      link: `/events/${eventId}/${sponsorId}/price-ranges`,
      visible: hasPremiumRanges,
    },
    // {
    //   label: i18n.servicesConfiguration,
    //   link: `/events/${eventId}/${sponsorId}/my-services`,
    //   visible: true,
    // },
    {
      id: 'cart',
      label: i18n.cart,
      link: `/events/${eventId}/${sponsorId}/cart`,
      visible: true,
    },
  ];
};

const ProfileAvatar = ({ hasPremiumRanges, hideMyArea }) => {
  //Hooks
  const history = useHistory();
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.profile(intl);

  //States
  const [participation, setParticipation] = useState();

  //Effects
  useEffect(() => {
    const loadInfo = (user) => {
      const profileImageLink =
        user?.userAndParticipation?.participation?.userShowcase?.profileImage &&
        fileHelper.getPublicFileLink({
          dirPath: `events/${eventId}/user/${user?.userAndParticipation?.participation.id}/showcase/profileImage`,
          fileData:
            user?.userAndParticipation?.participation.userShowcase.profileImage,
          skipFileDataOnS3Link: true,
        });
      const nextParticipation = {
        ...user?.userAndParticipation?.participation,
        profileImageLink,
      };
      console.log('nextParticipation ', nextParticipation);
      setParticipation(nextParticipation);
    };

    const subscription = appState.user.subscribe(loadInfo);

    return () => subscription.unsubscribe();
  }, []);

  // Functions
  const handleMyArea = () => {
    history.push(`/events/${eventId}/user/${participation?.id}`);
  };

  const handleMyPurchases = () => {
    history.push(`/events/${eventId}/${sponsor?.id}/my-purchases`);
  };

  const handleMyAgencyPurchases = () => {
    history.push(`/events/${eventId}/agency/${agency?.id}/my-purchases`);
  };

  const handleInvoices = () => {
    history.push(`/events/${eventId}/${sponsor?.id}/invoices`);
  };

  const handleBillingInformations = () => {
    history.push(`/events/${eventId}/${sponsor?.id}/billing-informations`);
  };

  const handleMyGroups = () => {
    const agencyId = participation?.agency?.id;
    history.push(`/events/${eventId}/agency/${agencyId}`);
  };

  const handleCustomizedOrdesSponsor = () => {
    history.push(`/events/${eventId}/${sponsor?.id}/custom-orders`);
  };

  const handleCustomizedOrdesParticipantion = () => {
    history.push(`/events/${eventId}/user/${participation?.id}/custom-orders`);
  };

  // const handleMyServices = () => {
  //   history.push(
  //     `/events/${eventId}/${sponsor?.id}/my-services`
  //   );
  // };

  const handleLogout = async () => {
    await aws.signOutFromWebsite(eventId);
  };

  const handleLanding = () => {
    history.push(`/events/${eventId}/landing`);
  };

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;
  const agency = participation?.agency; // || participation?.sponsorStaff?.sponsor;

  const isPax = participation?.cluster === constants.Clusters.Pax.id;
  const isDelegation =
    participation?.cluster === constants.Clusters.Delegations.id;
  const paxOrDelegation = isPax || isDelegation;

  const isValidSponsor =
    !participation?.isDeleted && sponsor?.id && !sponsor?.isDeleted;

  const isValidAgency =
    !participation?.isDeleted && agency?.id && !agency?.isDeleted;

  const isAgencyAdmin =
    !participation?.isDeleted &&
    participation?.agency?.id &&
    !participation?.agency?.isDeleted;

  const UserAvatar = () => (
    <Avatar
      style={{
        width: isMobileOnly ? 47 : 60,
        height: isMobileOnly ? 47 : 60,
        background: '#EBEBEF',
        color: appState.eventConfiguration.getValue().primaryColor,
        // ...(isMobileOnly && { position: 'absolute', right: '15px' }),
      }}
    >
      {participation?.profileImageLink ? (
        <img
          src={participation?.profileImageLink}
          style={{
            objectFit: 'cover',
            width: 'inherit',
            height: 'inherit',
          }}
        />
      ) : (
        participation?.givenName?.substring(0, 1).toUpperCase() +
        participation?.familyName?.substring(0, 1).toUpperCase()
      )}
    </Avatar>
  );

  const hideMyAreaForSponsorStaff = hideMyArea && participation?.sponsorStaff;
  return (
    <>
      {isMobile ? (
        <UserAvatar />
      ) : (
        <Menu menuButton={UserAvatar}>
          {!hideMyAreaForSponsorStaff && (
            <MenuItem onClick={handleMyArea}>
              <AimTypography textAlign="center">{i18n.myArea}</AimTypography>
            </MenuItem>
          )}
          <MenuItem onClick={handleLanding}>
            <AimTypography textAlign="center">{i18n.landing}</AimTypography>
          </MenuItem>
          {isValidSponsor &&
            links({
              eventId: eventId,
              sponsorId: sponsor?.id,
              hasPremiumRanges,
              i18n,
            })
              .filter((x) => x.visible)
              .map(({ link, label, id, linkRedirect }, ndx) => {
                return (
                  <MenuItem
                    key={`links_${ndx}`}
                    onClick={() => {
                      if (id === 'services' && !sponsor?.priceRange?.id) {
                        history.push(linkRedirect);
                      } else {
                        history.push(link);
                      }
                    }}
                  >
                    <AimTypography textAlign="center">{label}</AimTypography>
                  </MenuItem>
                );
              })}
          {isValidSponsor && (
            <MenuItem onClick={handleMyPurchases}>
              <AimTypography textAlign="center">{i18n.purchases}</AimTypography>
            </MenuItem>
          )}
          {isValidAgency && (
            <MenuItem onClick={handleMyAgencyPurchases}>
              <AimTypography textAlign="center">{i18n.purchases}</AimTypography>
            </MenuItem>
          )}
          {isValidSponsor && (
            <MenuItem onClick={handleInvoices}>
              <AimTypography textAlign="center">{i18n.invoices}</AimTypography>
            </MenuItem>
          )}
          {isValidSponsor && (
            <MenuItem onClick={handleCustomizedOrdesSponsor}>
              <AimTypography textAlign="center">
                {i18n.customOrders}
              </AimTypography>
            </MenuItem>
          )}
          {paxOrDelegation && (
            <MenuItem onClick={handleCustomizedOrdesParticipantion}>
              <AimTypography textAlign="center">
                {i18n.customOrders}
              </AimTypography>
            </MenuItem>
          )}
          {isValidSponsor && (
            <MenuItem onClick={handleBillingInformations}>
              <AimTypography textAlign="center">
                {i18n.billingInformation}
              </AimTypography>
            </MenuItem>
          )}
          {isAgencyAdmin && (
            <MenuItem onClick={handleMyGroups}>
              <AimTypography textAlign="center">{i18n.myGroups}</AimTypography>
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>
            <AimTypography textAlign="center">{i18n.logout}</AimTypography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

ProfileAvatar.propTypes = {};

export default ProfileAvatar;

import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const getAdditionalServicesByEventIdQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      additionalServices(filter: { isDeleted: { ne: true } }) {
        items {
          id
          description
          dateService
          startTime
          closingDate
          locationAddress
          locationName
          title
          typology
          reservedToFaculty
          images {
            items {
              id
              extension
              originalName
              size
            }
          }
        }
      }
    }
  }
`;

const getEventOrderQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      eventOrder {
        items {
          id
          code
          codeName
          sponsor {
            id
            name
            isDeleted
          }
          delegation {
            id
            name
          }
        }
      }
    }
  }
`;

const getParticipationServicesQuery = /* GraphQL */ `
  query GetParticipationServices($id: ID!) {
    getParticipation(id: $id) {
      participationServices {
        id
        # travel / transfer
        isTravelIncluded
        isTransferAIncluded
        isTransferRIncluded
        travelFacultyResponse
        note
        # allotment
        allotmentIncluded
        allotmentAccompanyingIncluded
        allotmentPreferences
        allotmentEventOrderId
        allotmentCodeEventOrder
        allotmentConfigurationCompleted
        allotmentHotel
        allotmentRoomType
        allotmentCheckInDate
        allotmentCheckOutDate
        allotmentFacultyResponse
        # additionalServices
        additionalServicesIncluded
        additionalServicesEventOrderId
        additionalServicesCodeEventOrder
        additionalServicesDetailsCommunication
        additionalServicesIncludedServices
        additionalServicesFacultyResponse
        createdAt
        updatedAt
        participation {
          id
          givenName
          familyName
          event {
            id
            name
            start
            end
          }
        }
        # accreditationMaterial
        accreditationMaterialDescription
        accreditationMaterialIncluded
        accreditationMaterialCVIncluded
        accreditationMaterialFiscalCodeIncluded
        accreditationMaterialCOIIncluded
        accreditationMaterialSurveyIncluded
        accreditationMaterialTemplates
        accreditationMaterialFacultyResponse
        # AIFA
        aifaDataRequiredIncluded
        aifaDataDescription
        aifaDataFacultyResponse
        # participation
        participationToBeInvited
        participationIncluded
        participationPaymentMethod
        participationEventOrderId
        participationCodeEventOrder
        participationCompany
        participationAgency
        participationScientificCommitment
        participationOnsiteOption
        participationShowAgenda
        participationReportUpload
        participationPresentationUpload
        participationFacultyResponse
        publicationDateOfPrefs
        facultyStatus
        #faculty
        facultyDownloadArea
        facultyAttachmentsResponse
      }
    }
  }
`;

const createParticipationServicesMutation = /* GraphQL */ `
  mutation CreateParticipationServices(
    $input: CreateParticipationServicesInput!
    $condition: ModelParticipationServicesConditionInput
  ) {
    createParticipationServices(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateParticipationServicesMutation = /* GraphQL */ `
  mutation UpdateParticipationServices(
    $input: UpdateParticipationServicesInput!
    $condition: ModelParticipationServicesConditionInput
  ) {
    updateParticipationServices(input: $input, condition: $condition) {
      id
    }
  }
`;

const getAgendaSessionsByEventQuery = /* GraphQL */ `
  query GetEventSessions($id: ID!) {
    getEvent(id: $id) {
      agenda: agendaSpeakers {
        sessions {
          items {
            id
            name
            description
            shortDescription
            date
            start
            end
            room {
              id
              name
              ordering
            }
            attendeeRoles {
              items {
                id
                role {
                  id
                  name
                  frontofficeName
                  showInProgram
                }
                attendees {
                  items {
                    id
                    participation {
                      id
                      givenName
                      familyName
                      user {
                        nationality
                      }
                    }
                  }
                }
              }
            }
            subSessions {
              items {
                id
                name
                shortDescription
                start
                end
                isCoincideEnabled
                attendeeRoles {
                  items {
                    id
                    role {
                      id
                      name
                      frontofficeName
                      showInProgram
                    }
                    attendees {
                      items {
                        id
                        participation {
                          id
                          givenName
                          familyName
                          user {
                            nationality
                          }
                        }
                      }
                    }
                  }
                }
                speeches {
                  items {
                    id
                    start
                    end
                    titleDescription
                    session {
                      id
                    }
                    type
                    code
                    title
                    titleDescription
                    attendeeRoles {
                      items {
                        id
                        role {
                          id
                          name
                          frontofficeName
                          showInProgram
                        }
                        attendees {
                          items {
                            id
                            participation {
                              id
                              givenName
                              familyName
                              user {
                                nationality
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getAgendaSessionsByEventAndParticipantQuery = /* GraphQL */ `
  query getAgendaSessions($id: ID!, $participantId: ID) {
    getEvent(id: $id) {
      participations(filter: { id: { eq: $participantId } }) {
        items {
          myAgenda {
            items {
              id
              session {
                id
                name
                date
                start
                end
                room {
                  name
                }
                speeches {
                  items {
                    id
                    start
                    end
                    titleDescription
                    session {
                      id
                    }
                    title
                    attendees {
                      items {
                        participation {
                          id
                          givenName
                          familyName
                          user {
                            nationality
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const listAdditionalServices = (eventId, useLoader = false) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getAdditionalServicesByEventIdQuery,
      variables: {
        id: eventId,
      },
    })
      .then((response) =>
        resolve(response.data.getEvent.additionalServices?.items || [])
      )
      .catch((e) => {
        console.error('list-AdditionalServices-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEventOrder = (id, useLoader = false) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: getEventOrderQuery, variables: { id } })
      .then((response) =>
        resolve(response?.data?.getEvent?.eventOrder?.items || [])
      )
      .catch((e) => {
        console.error('get-event-order', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getParticipationServices = (id, useLoader = false) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({ query: getParticipationServicesQuery, variables: { id } })
      .then((response) =>
        resolve(response?.data?.getParticipation?.participationServices)
      )
      .catch((e) => {
        console.error('get-event-order', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createParticipationServices = (input, useLoader = false) =>
  new Promise((resolve, reject) => {
    if (useLoader) showLoader();

    API.graphql({
      query: createParticipationServicesMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.createParticipationServices))
      .catch((e) => {
        console.error('agenda-createParticipationServices-error', e);
        reject();
      })
      .finally(useLoader ? hideLoader : null);
  });

export const updateParticipationServices = (input, useLoader = false) =>
  new Promise((resolve, reject) => {
    if (useLoader) showLoader();

    API.graphql({
      query: updateParticipationServicesMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipationServices))
      .catch((e) => {
        console.error('agenda-updateParticipationServices-error', e);
        reject();
      })
      .finally(useLoader ? hideLoader : null);
  });

export const getAgendaSessionsByEvent = (
  eventId,
  participantId,
  useLoader = false
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getAgendaSessionsByEventQuery,
      variables: {
        id: eventId,
      },
    })
      .then((response) => {
        resolve(response.data.getEvent.agenda?.sessions?.items || []);
      })
      .catch((e) => {
        console.error('get-agendaSession-event-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getAgendaSessionsByEventAndParticipant = (
  eventId,
  participantId,
  useLoader = false
) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getAgendaSessionsByEventAndParticipantQuery,
      variables: {
        id: eventId,
        participantId,
      },
    })
      .then((response) => {
        console.log(
          'test response ',
          response.data.getEvent.participations?.items[0].myAgenda?.items
        );
        resolve(
          response.data.getEvent.participations?.items[0].myAgenda?.items || []
        );
      })
      .catch((e) => {
        console.error('get-agendaSession-eventAndParticipant-error', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

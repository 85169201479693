import { aws, appState, constants, utilities } from '@aim/common';

const getEventDataQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      type
      profiles(
        filter: {
          or: [
            { cluster: { contains: "sponsorstaff" } }
            { clusters: { contains: "sponsorstaff" } }
          ]
          isDeleted: { ne: true }
        }
      ) {
        items {
          name
          id
          cluster
          clusters
          sponsorstaff
          profileFeeBrackets {
            items {
              id
              priceOnAir
              priceOnSite
              profileFeeConfiguration {
                id
                isActive
              }
              feeBracket {
                createdAt
                feeBracketModel {
                  end
                  name
                  start
                }
                feeDateRange {
                  id
                  start
                  end
                  label
                }
              }
            }
          }
        }
      }
      fee {
        feeDateRanges {
          items {
            id
            start
            end
            label
          }
        }
      }
    }
  }
`;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const getEventData = (id, sponsorStaffId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventDataQuery,
      variables: { id },
    })
      .then((response) => {
        const { data } = response;
        resolve({
          profiles: data.getEvent.profiles.items
            .filter(
              (p) =>
                (p.sponsorstaff || ['all'])?.includes('all') ||
                p.sponsorstaff.includes(sponsorStaffId)
            )
            .map((p) => ({
              ...p,
              profileFeeBrackets: {
                items: p.profileFeeBrackets.items.map((b) => ({
                  ...b,
                  priceOnAir: utilities.decodeDbNumber(b.priceOnAir),
                  priceOnSite: utilities.decodeDbNumber(b.priceOnSite),
                })),
              },
            })),
          feeDateRanges: data.getEvent?.fee?.feeDateRanges?.items,
          eventType: data.getEvent?.type,
        });
      })
      .catch((e) => {
        console.error('event-profiles', e);
        reject();
      })
      .finally(hideLoader);
  });

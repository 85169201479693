import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import Logo from './shared/logo/Logo';
import Footer from './shared/Footer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { appState, aws, constants, utilities } from '@aim/common';
import CheckIcon from '@material-ui/icons/Check';

import {
  AimSnackbar,
  AimTypography,
  CustomIntl,
  BillingInformations,
  styled,
} from '@aim/components';

const { decodeDbNumber, vatCalc } = utilities;

import { translation } from './genericPayments/translation';

const PageContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '95%',
  margin: '0 auto',
  paddingLeft: '11%',
  paddingRight: '11%',
  paddingTop: 70,
});

const MainContainer = ({ children }) => (
  <div
    style={{
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      paddingTop: 20,
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </div>
);

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

/* const mockFormExportedValues = {
  'bddb993a-ec50-4722-bb13-26f67b870a40': {
    vatRate1: 0,
    vat1Id: '7cc5ad0f-0819-4344-a640-c7a8d77e7dac',
    isForced: true,
  },
}; */

const GenericPaymentsBillingInformationsPublicPage = () => {
  const { eventId, genericPaymentId } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const code = state?.code;
  const intl = CustomIntl(useIntl());
  const i18n = translation.billingInformations(intl);
  const [genericPayment, setGenericPayment] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [formExportedValues, setFormExportedValues] = useState();
  const [billingInfoBookings, setBillingInfoBookings] = useState();
  const [snackbar, setSnackbar] = useState({ isOpen: false });

  const showUnauthorizedMessage = () => (
    <AimTypography variant={'textThin'} boxStyle={{ textAlign: 'center' }}>
      {i18n.unauthorized}
    </AimTypography>
  );

  const afterSendData = async (billingInfo) => {
    const getCartData = async () => {
      const isForced = formExportedValues?.[genericPaymentId]?.isForced;

      const vatRate = isForced
        ? formExportedValues?.[genericPaymentId]?.vatRate
        : genericPayment?.vatRate;

      const totalAmount = vatCalc(
        decodeDbNumber(genericPayment?.netAmount),
        decodeDbNumber(vatRate)
      );

      const paymentMetadata = {
        paymentId: genericPayment?.payment?.id,
        amount: totalAmount,
        netAmount: genericPayment?.netAmount,
        vatRate: vatRate,
        description: genericPayment?.description,
        userAmountType: genericPayment?.userAmountType,
      };

      history.push({
        pathname: `/events/${eventId}/genericPayments/${genericPaymentId}/cart`,
        state: paymentMetadata,
      });
    };

    getCartData();
  };

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const res = await aws.standardAPI.post(
          'aimlambdaproxy',
          '/admin/generic-payment',
          {
            body: {
              eventId,
              genericPaymentId,
              code,
            },
          }
        );
        setGenericPayment(res.getGenericPayment);
        hideLoader();
      } catch (error) {
        setUnauthorized(true);
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const getCustomerId = () => {
    switch (genericPayment.cluster) {
      case 'pax':
      case 'faculty': {
        return genericPayment.participation.id;
      }
      case 'sponsor': {
        const sponsorAdmin = genericPayment?.sponsor?.admins?.items.find(
          (admin) => admin.isMainBuyer
        );
        return sponsorAdmin?.id;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (billingInfoBookings) return;

    if (genericPayment) {
      setUnauthorized(false);
      setBillingInfoBookings({
        [genericPaymentId]: {
          vatRate1: decodeDbNumber(genericPayment?.vatRate),
          vat1Id: genericPayment?.vat?.id,
          participationMode: genericPayment?.cluster,
        },
      });
    }
  }, [genericPayment]);

  return (
    <>
      <MainContainer>
        <Logo />
        <PageContent>
          {genericPayment && (
            <>
              <BillingInformations
                type={
                  genericPayment.cluster === constants.Clusters.Pax.id ||
                  genericPayment.cluster === 'other'
                    ? 'generic_payment'
                    : 'generic_payment_company'
                }
                customerId={getCustomerId()}
                history={history}
                pageTitle={i18n.title}
                showSubTitle
                intl={intl}
                showSnackbar
                saveButtonText={i18n.actions.saveButton}
                SaveButtonIcon={CheckIcon}
                onAfterSave={afterSendData}
                setFormExportedValues={setFormExportedValues}
                billingInfoBookings={billingInfoBookings}
                paymentId={genericPayment?.payment.id}
                backUrl={`/events/${eventId}/genericPayments/${genericPaymentId}?code=${code}`}
              />
              <AimSnackbar
                open={snackbar.isOpen}
                onClose={() => setSnackbar({ isOpen: false })}
                severity={snackbar.severity}
              >
                {snackbar.message}
              </AimSnackbar>
            </>
          )}
          {unauthorized && showUnauthorizedMessage()}
        </PageContent>
        <Footer />
      </MainContainer>
    </>
  );
};

export default GenericPaymentsBillingInformationsPublicPage;

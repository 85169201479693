import { listCertifications } from '../../../shared/papersGqlHelper';
import { isAfter } from 'date-fns';
const { getS3Link } = fileHelper;
import { fileHelper, appState, constants } from '@aim/common';

export const getMyCertifications = async ({ eventId, participationId }) => {
  const res = await listCertifications(eventId);
  const filledPapers = appState.user.getValue()?.userAndParticipation
    ?.participation?.filledPapers.items;
  const papers = [
    ...res.papers.items
      .filter(
        (p) =>
          p.publishDate != null && isAfter(new Date(), new Date(p.publishDate))
      )
      .map((p) => {
        return {
          id: p.id,
          name: p.name,
          isGeneric: p.isGeneric,
          file: p?.filledPapers?.items[0]?.file,
        };
      }),
    ...filledPapers
      .filter(
        (fp) =>
          (fp.paper.type.includes(constants.PapersTypologies.NOMINAL.id) ||
            fp.paper.type.includes(constants.PapersTypologies.ABSTRACT.id)) &&
          !fp.paper.isDeleted
      )
      .filter(
        (fp) =>
          fp.paper.publishDate != null &&
          isAfter(new Date(), new Date(fp.paper.publishDate))
      )
      .map((fp) => ({
        id: fp.paper.id,
        name: fp.paper.name,
        isGeneric: fp.paper.isGeneric,
        file: fp.file,
      })),
  ];
  if (papers) {
    const res2 = await Promise.all(
      papers.map(async (p) => {
        return {
          ...p,
          link: await getS3Link(
            `events/${eventId}/papers/certificates/filled/${
              p.isGeneric ? '' : `${participationId}/`
            }`,
            {
              id: p?.file?.id,
              extension: '.pdf',
              originalName: `${p.name?.replace(/ /g, '_')}.pdf`,
            }
          ),
        };
      })
    );
    return res2;
  } else {
    return [];
  }
};

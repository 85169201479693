const translation = {
  participantsWall: (intl) => ({
    page: {
      title: {
        label: intl.formatMessage({
          description: 'back button tooltip',
          defaultMessage: 'Participants Wall',
        }),
      },
      back: {
        tooltip: {
          label: intl.formatMessage({
            description: 'back button tooltip',
            defaultMessage: 'Go to participants list',
          }),
        },
        button: {
          pax: {
            label: intl.formatMessage({
              description: 'back button label',
              defaultMessage: 'Back to participations list',
            }),
          },
          delegations: {
            label: intl.formatMessage({
              description: 'back button label',
              defaultMessage: 'Back to delegations list',
            }),
          },
        },
      },
    },
    wallItems: {
      anagraphicSettings: intl.formatMessage({
        description: 'anagraphic settings label',
        defaultMessage: 'Anagraphic Settings',
      }),
      participationFee: intl.formatMessage({
        description: 'participation fee label',
        defaultMessage: 'Participation Fee',
      }),
      groups: intl.formatMessage({
        description:
          'delegations, groups, Sponsored Participations, sponsor staff label',
        defaultMessage:
          'Delegations, Groups, Sponsored Participations, Sponsor staff',
      }),
      modificationRequest: intl.formatMessage({
        description: 'modification request label',
        defaultMessage: 'Modification Label',
      }),
    },
  }),
  anagraphic: (intl) => ({
    page: {
      backButton: {
        profiles: {
          label: intl.formatMessage({
            description: 'participations label',
            defaultMessage: 'Back to profiles',
          }),
          tooltip: intl.formatMessage({
            description: 'back to participations',
            defaultMessage: 'Back to profiles',
          }),
        },
        pax: {
          label: intl.formatMessage({
            description: 'participations label',
            defaultMessage: 'Back to participations wall',
          }),
          tooltip: intl.formatMessage({
            description: 'back to participations',
            defaultMessage: 'Back to participations wall',
          }),
        },
        delegations: {
          label: intl.formatMessage({
            description: 'delegations label',
            defaultMessage: 'Back to delegations wall',
          }),
          tooltip: intl.formatMessage({
            description: 'back to delegations',
            defaultMessage: 'Back to delegations wall',
          }),
        },
      },
      title: intl.formatMessage({
        description: 'anagraphic configure title',
        defaultMessage: 'Anagraphic Configure',
      }),
    },
    sendDataError: intl.formatMessage({
      description: 'error saving data text',
      defaultMessage: 'Error during data update.',
    }),
    actions: {
      back: intl.formatMessage({
        description: 'back wizard button',
        defaultMessage: 'back',
      }),
      save: intl.formatMessage({
        description: 'save button',
        defaultMessage: 'Save',
      }),
    },
    headers: {
      label: intl.formatMessage({
        description: 'label text',
        defaultMessage: 'Label',
      }),
      type: intl.formatMessage({
        description: 'type text',
        defaultMessage: 'Type',
      }),
      placeholder: intl.formatMessage({
        description: 'placeholder text',
        defaultMessage: 'Placeholder',
      }),
      registrationForm: intl.formatMessage({
        description: 'Apply to registration form text',
        defaultMessage: 'Apply to registration form',
      }),
      mandatory: intl.formatMessage({
        description: 'mandatory text',
        defaultMessage: 'Mandatory',
      }),
    },
    modal: {
      title: intl.formatMessage({
        description: 'select standard fields to add title',
        defaultMessage: 'Select standard fields to add',
      }),
      add: intl.formatMessage({
        description: 'add button',
        defaultMessage: 'Add',
      }),
      cancel: intl.formatMessage({
        description: 'cancel button',
        defaultMessage: 'Cancel',
      }),
    },
    drawer: {
      title: intl.formatMessage({
        description: 'form new custom field title',
        defaultMessage: 'New custom field',
      }),
      fieldLabel: {
        label: intl.formatMessage({
          description: 'field name label',
          defaultMessage: 'Label',
        }),
        placeholder: intl.formatMessage({
          description: 'field name placeholder',
          defaultMessage: 'Write here field label',
        }),
      },
      fieldType: {
        label: intl.formatMessage({
          description: 'field type label',
          defaultMessage: 'Type',
        }),
        placeholder: intl.formatMessage({
          description: 'field type placeholder',
          defaultMessage: 'Select here field name',
        }),
      },
      fieldOptions: {
        label: intl.formatMessage({
          description: 'field options label',
          defaultMessage: 'Options',
        }),
        placeholder: intl.formatMessage({
          description: 'field options placeholder text',
          defaultMessage: 'Write here field options',
        }),
      },
      fieldPlaceholder: {
        label: intl.formatMessage({
          description: 'field placeholder label',
          defaultMessage: 'Placeholder',
        }),
        placeholder: intl.formatMessage({
          description: 'field placeholder, placeholder text',
          defaultMessage: 'Write here field placeholder',
        }),
      },
      controlTypes: {
        text: intl.formatMessage({
          description: 'control type text option',
          defaultMessage: 'Text',
        }),
        number: intl.formatMessage({
          description: 'control type number option',
          defaultMessage: 'Number',
        }),
        date: intl.formatMessage({
          description: 'control type date option',
          defaultMessage: 'Date',
        }),
        select: intl.formatMessage({
          description: 'control type select option',
          defaultMessage: 'Select',
        }),
        multiselect: intl.formatMessage({
          description: 'control type multiselect option',
          defaultMessage: 'Multiselect',
        }),
      },
      actions: {
        save: intl.formatMessage({
          description: 'save button',
          defaultMessage: 'Save',
        }),
        cancel: intl.formatMessage({
          description: 'cancel button',
          defaultMessage: 'Cancel',
        }),
      },
    },
  }),
  agencyEdit: (intl) => ({
    snackbar: {
      saveSuccess: {
        label: intl.formatMessage({
          description: 'snackbar agency save success',
          defaultMessage: 'Agency saved',
        }),
      },
      saveError: {
        label: intl.formatMessage({
          description: 'snackbar agency save error',
          defaultMessage: 'Agency save error',
        }),
      },
    },
    actions: {
      save: intl.formatMessage({
        description: 'save button',
        defaultMessage: 'Save',
      }),
      cancel: intl.formatMessage({
        description: 'cancel button',
        defaultMessage: 'Cancel',
      }),
      backButton: {
        labels: {
          pax: intl.formatMessage({
            description: 'back to participations label',
            defaultMessage: 'Back to participations',
          }),
          delegations: intl.formatMessage({
            description: 'back to delegation label',
            defaultMessage: 'Back to delegation',
          }),
          groups: intl.formatMessage({
            description: 'back to group label',
            defaultMessage: 'Back to group',
          }),
          back: intl.formatMessage({
            description: 'back label',
            defaultMessage: 'Back',
          }),
        },
      },
    },
    registrationForm: {
      title: intl.formatMessage({
        description: 'agency registration',
        defaultMessage: 'Agency Registration',
      }),
      subtitle: intl.formatMessage({
        description:
          'to attend the event you need to fill in following form by entering your data',
        defaultMessage:
          'To attend the event you need to fill in following form by entering your data',
      }),
      registrationClosedSubtitle: intl.formatMessage({
        description: 'registrations for this event are closed',
        defaultMessage: 'Registrations for this event are closed',
      }),
      backofficeUserBlocked: intl.formatMessage({
        description: 'backoffice users cannot join the event helper text',
        defaultMessage: 'Backoffice users cannot join the event',
      }),
      clickHereToLogout: intl.formatMessage({
        description: 'Click here to logout',
        defaultMessage: 'Click here to logout',
      }),
      additionalSectionTitle: intl.formatMessage({
        description: 'buyer details',
        defaultMessage: 'Buyer datails',
      }),
      // recoveryPersonalDetails: {
      //   description: intl.formatMessage({
      //     description: 'recovery personal details',
      //     defaultMessage: 'Recovery personal details',
      //   }),
      //   tooltip: intl.formatMessage({
      //     description: 'recovery personal details from last event',
      //     defaultMessage: 'Recovery personal details from last event',
      //   }),
      //   buttonLabel: intl.formatMessage({
      //     description: 'recovery',
      //     defaultMessage: 'Recovery',
      //   }),
      // },
      termsAndPrivacyText: intl.formatMessage({
        description: 'registration agree text',
        defaultMessage: 'I agree to the',
      }),
      termsAndPrivacyPolicy: intl.formatMessage({
        description: 'registration terms and condition and privacy policy',
        defaultMessage: 'Terms and Conditions and Privacy policy.',
      }),
    },
    labels: {
      companyName: intl.formatMessage({
        description: 'company name label',
        defaultMessage: 'Company name',
      }),
      city: intl.formatMessage({
        description: 'city label',
        defaultMessage: 'City name',
      }),
      country: intl.formatMessage({
        description: 'country label',
        defaultMessage: 'Country name',
      }),
      address: intl.formatMessage({
        description: 'address label',
        defaultMessage: 'Address',
      }),
      telephone: intl.formatMessage({
        description: 'telephone label',
        defaultMessage: 'Telephone',
      }),
      postalCode: intl.formatMessage({
        description: 'telephone label',
        defaultMessage: 'Postal Code/Zip',
      }),
      taxIdNumber: intl.formatMessage({
        description: 'tax identification number or fiscal code label',
        defaultMessage: 'Tax identification number (or fiscal code)',
      }),
      status: intl.formatMessage({
        description: 'status label',
        defaultMessage: 'Status',
      }),
      givenName: intl.formatMessage({
        description: 'name label',
        defaultMessage: 'Name',
      }),
      familyName: intl.formatMessage({
        description: 'surname label',
        defaultMessage: 'Surname',
      }),
      bemail: intl.formatMessage({
        description: 'email label',
        defaultMessage: 'Email',
      }),
      bphone: intl.formatMessage({
        description: 'telephone label',
        defaultMessage: 'Telephone',
      }),
    },
  }),
};

export default translation;

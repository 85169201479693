import { theme, styled } from '@aim/components';

export const DataContainer = styled('div')(({ backgroundColorOverride }) => ({
  backgroundColor: backgroundColorOverride || theme.colors.greyScale.white,
  width: '100%',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'inset 0px 3px 6px rgba(0, 0, 0, 0.16)',

}));

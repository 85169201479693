import { aws, appState, constants } from '@aim/common';
const { API } = aws;

import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 6); // 1 id/hour --> 1% probability of collision in 27 years

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const createProductMutation = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateProductMutation = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventCodeQuery = /* GraphQL */ `
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      id
      code
    }
  }
`;

// const getEventDataQuery = /* GraphQL */ `
//   query GetEvent($id: ID!) {
//     getEvent(id: $id) {
//       profiles (filter: {cluster: {eq: "${constants.Clusters.Groups.id}"}, isDeleted: {ne: true}}) {
//         items {
//           name
//           id
//           profileFeeBrackets {
//             items {
//               id
//               priceOnAir
//               priceOnSite
//               profileFeeConfiguration {
//                 id
//                 isActive
//               }
//               feeBracket {
//                 createdAt
//                 feeBracketModel {
//                   end
//                   name
//                   start
//                 }
//                 feeDateRange {
//                   start
//                   end
//                   label
//                 }
//               }
//             }
//           }
//         }
//       }
//       fee {
//         feeDateRanges {
//           items {
//             id
//             start
//             end
//             label
//           }
//         }
//       }
//     }
//   }
// `;

// const getAgencyPaymentQuery = /* GraphQL */ `
//   query GetPayment($id: ID!) {
//     getPayment(id: $id) {
//       id
//       paymentType
//       amount
//       paymentId
//     }
//   }
// `;

// const getAgencyGroupsQuery = /* GraphQL */ `
//   query GetPayment($id: ID!) {
//     getAgency(id: $id) {
//       agencyGroups {
//         items {
//           id
//           name
//         }
//       }
//     }
//   }
// `;

export const productGqlHelper = () => {
  const create = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: createProductMutation,
        variables: { input: { ...input, bookingId: nanoid() } },
      })
        .then((response) => resolve(response.data.createProduct))
        .catch((e) => {
          console.error('create-product', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const update = (input, useLoader = true) =>
    new Promise((resolve, reject) => {
      useLoader && showLoader();

      API.graphql({
        query: updateProductMutation,
        variables: { input },
      })
        .then((response) => resolve(response.data.updateProduct))
        .catch((e) => {
          console.error('update-product', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  const getEventCode = (eventId, useLoader = true) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({
        query: getEventCodeQuery,
        variables: { id: eventId },
      })
        .then((response) => {
          return resolve({
            ...response.data.getEvent,
          });
        })
        .catch((e) => {
          console.error('get-event-code', e);
          reject();
        })
        .finally(() => useLoader && hideLoader());
    });

  // const getAgencyPayment = (id, useLoader = true) =>
  //   new Promise((resolve, reject) => {
  //     useLoader && showLoader();

  //     API.graphql({
  //       query: getAgencyPaymentQuery,
  //       variables: { id },
  //     })
  //       .then((response) => resolve(response.data.getPayment))
  //       .catch((e) => {
  //         console.error('get-agency-payment', e);
  //         reject();
  //       })
  //       .finally(() => useLoader && hideLoader());
  //   });

  // const getEventData = (id) =>
  //   new Promise((resolve, reject) => {
  //     showLoader();
  //     aws.API.graphql({
  //       query: getEventDataQuery,
  //       variables: { id },
  //     })
  //       .then((response) => {
  //         const { data } = response;
  //         resolve({
  //           profiles: data.getEvent.profiles.items,
  //           feeDateRanges: data.getEvent.fee.feeDateRanges.items,
  //         });
  //       })
  //       .catch((e) => {
  //         console.error('event-profiles', e);
  //         reject();
  //       })
  //       .finally(hideLoader);
  //   });

  return {
    create,
    update,
    getEventCode,
    // getAgencyPayment,
    // getEventData,
  };
};

import React, { useState, useEffect, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import translation from './shared/translation';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';
import {
  // getAgenciesByEventId,
  getAgency,
  listPurchases,
} from './shared/agencyPurchaseGqlHelper';

import {
  // AimPurchasesTable,
  AimAgencyPurchasesDatagrid,
  AimIconAndTextButton,
  AimTitleAndButtons,
  CustomIntl,
} from '@aim/components';
import { constants, utilities } from '@aim/common';

const mapBillingInformations = (product, intl) => {
  const billing = product?.payment?.BillingInformation;
  if (!billing) {
    return {};
  }
  const countryJson = billing?.countryIsoCode
    ? constants.countriesIsoCodesJson.find(
        (cc) => cc.alpha2 === billing?.countryIsoCode
      )
    : {};

  return {
    id: billing?.id,
    billingEmail: billing?.email,
    pec: billing?.pec,
    invoiceToLabel: Object.values(constants.InvoiceToType)
      .find((type) => type.id === billing?.invoiceTo)
      ?.label(intl),
    invoiceTo: billing?.invoiceTo,
    billingName: billing?.name || '',
    address: billing?.address,
    postalCode: billing?.postalCode,
    city: billing?.city,
    province: billing?.province,
    region: billing?.region,
    country: billing?.country,
    countryIsoCode: billing?.countryIsoCode,
    countryIsoAlpha3: countryJson?.alpha3 || '',
    vatCode: billing?.vatCode,
    taxCode: billing?.taxCode,
    phone: billing?.phone,
    reference: billing?.reference,
    invoiceToPublicAuthority: billing?.invoiceToPublicAuthority,
    uniqueCode: billing?.uniqueCode,
    ipaCode: billing?.ipaCode,
    isSplitPayment: billing?.isSplitPayment,
    isVatExent: billing?.isVatExent,
    po: billing?.po?.originalName,
    cig: billing?.cig,
    cup: billing?.cup,
    tripletta: billing?.tripletta,
    venue: billing?.venue,
  };
};

const MyAgencyPurchases = () => {
  const { eventId, agencyId } = useParams();
  const history = useHistory();
  // const { decodeDbNumber, vatCalc, sortBy } = useDataHelper();

  const intl = CustomIntl(useIntl());
  const i18n = translation.agencyPurchasesPage(intl);
  const i18nTable = translation.agencyPurchasesTable(intl);

  //refs
  const datagridFilteredRowsRef = useRef();

  const [purchases, setPurchases] = useState([]);
  // const currentTimezone = appState.eventInfo.getValue().timezone;

  useEffect(() => {
    if (eventId) {
      fetchProducts();
    }
  }, []);

  const fetchProducts = async () => {
    // PRODUCT TABLE property matches / parent product:
    // productId -> id gruppo
    // clientId -> id dell'agenzia (utente che compra)
    // clientType -> cluster AGENCY
    // serviceId -> eventId
    // serviceType -> AGENCY_GROUP

    // key to search by agency and event: serviceId, serviceType

    // PRODUCT TABLE property matches / product child:
    // productId -> id registrazione
    // clientId -> id dell'agenzia
    // clientType -> cluster AGENCY
    // serviceId -> eventId
    // serviceType -> REGISTRATION
    // participationType -> physical or virtual
    // quantiy -> numero biglietti acquistati
    // productCartId-> id prodotto padre
    // productPaymentId -> id pagamento del padre

    // get agency
    let agencies = [];
    if (agencyId) {
      const agencyResult = await getAgency(agencyId);
      if (agencyResult) agencies.push(agencyResult);
    }

    const [products, prepaidProducts] = await Promise.all([
      await listPurchases({
        eventId,
        serviceType: constants.ProductType.agencyGroup,
      }),
      await listPurchases({
        eventId,
        serviceType: constants.ProductType.agencyGroupPrepaid,
      }),
    ]);
    const productsResult = [...products, ...prepaidProducts];
    const agencyIds = [...new Set(agencies.map((x) => x.id))];
    const filteredProducts = productsResult.filter((purchase) =>
      agencyIds.includes(purchase.clientId)
    );

    const sortedPurchases = filteredProducts.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    // get agencies have done purchases
    const agencyIdsOfProducts = [
      ...new Set(filteredProducts.map((x) => x.clientId)),
    ];
    // const resultAgencyMassiveQuery = await listAgenciesDataById(agencyIds);
    const agenciesHavePurchased = agencies.filter((x) =>
      agencyIdsOfProducts.includes(x.id)
    );

    const nextPurchases = filteredProducts.map((parentProduct, index) => {
      let paymentTypeFormat = '';
      switch (parentProduct?.payment?.paymentType) {
        case constants.PaymentTypes.BankTransfer:
          paymentTypeFormat = i18n.paymentTypes.bankTransfer.label;
          break;
        case constants.PaymentTypes.CreditCard:
          paymentTypeFormat = i18n.paymentTypes.creditCard.label;
          break;
        default:
          break;
      }
      const billing = mapBillingInformations(parentProduct, intl);

      const paymentData =
        parentProduct?.payment &&
        JSON.parse(parentProduct.payment.paymentMetadata);

      const paidFee = parentProduct?.payment?.amount || 0;

      const parentUniqId =
        parentProduct?.payment?.paymentId || `paymentError${index}`;

      let totalFee = 0;

      const buyOperationsItems = parentProduct?.cartChildren.items?.map(
        (childProduct, index) => {
          const serviceData = paymentData?.find(
            (service) => service.productTableId === childProduct.id
          );

          // totalFee: total included Vat
          totalFee += serviceData?.price * serviceData?.quantity || 0;

          const nextChildProduct = {
            ...childProduct,
            childUniqId: `item ${index + 1}`,
            parsedUnitaryPrice: serviceData?.netPrice || 0,
            parsedUnitaryVatRate: serviceData?.vatRate || 0,
            parsedUnitaryVatAmount: serviceData?.vatAmount || 0,
            quantity: serviceData?.quantity || 0,
            parsedTotalNetPrice: serviceData
              ? serviceData.netPrice * serviceData.quantity
              : 0,
            parsedTotalVatAmount: serviceData
              ? serviceData.vatAmount * serviceData.quantity
              : 0,
            parsedTotalPriceVatIncluded: serviceData
              ? serviceData?.price * serviceData.quantity
              : 0,
            description: serviceData?.bookingLabel,
          };
          return nextChildProduct;
        }
      );

      const netTotal = buyOperationsItems.reduce(
        (res, curr) => (res += curr.parsedUnitaryPrice * curr.quantity),
        0
      );

      const vatTotal = buyOperationsItems.reduce(
        (res, curr) => (res += curr.parsedTotalVatAmount),
        0
      );

      const totalCost = buyOperationsItems.reduce(
        (res, curr) => (res += curr.parsedTotalCost),
        0
      );

      // const nextItems = items.map((item, index) => ({
      //   ...item,
      //   conflicts: Object.values(resultMassiveQuery)[index],
      // }));

      const nextAgency = agenciesHavePurchased.find(
        (x) => x.id === parentProduct.clientId
      );

      const nextGroup = nextAgency.agencyGroups?.items.find(
        (x) => x.id === parentProduct.productId
      );

      return {
        ...parentProduct,
        parentUniqId,
        agency: nextAgency?.name,
        group: nextGroup?.name,
        // shortIsoDate: customDateFormat(item.date, 'yyyy-MM-dd'),
        // purchaseDate: customDateFormat(item.date, 'dd/MM/yyyy'),
        paymentTypeLabel: paymentTypeFormat,
        paidFee: utilities.decodeDbNumber(paidFee),
        totalFee: Number(totalFee),
        // totalCost: Number(totalCost),
        netTotal: Number(netTotal),
        vatAmount: Number(vatTotal),
        buyOperations: {
          items: buyOperationsItems,
        },
        // hasPaymentError:
        //   !utilities.isValidPurchase(item) ||
        //   item.buyOperations.items.length === 0,
        billing,
      };
    });

    setPurchases(nextPurchases);
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginLeft: 15, marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
          <AimTitleAndButtons title={i18n.page.title.label} />
        </div>
        <AimAgencyPurchasesDatagrid
          purchases={purchases}
          i18n={i18nTable}
          intl={intl}
          onUpdateVisibleAndCheckedRows={(data) => {
            datagridFilteredRowsRef.current = data.filteredRows;
          }}
          columnsToHide={['unitaryCost', 'totalCost']}
        />
      </InnerContainer>
    </MainContainer>
  );
};

export default MyAgencyPurchases;

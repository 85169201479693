import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import { useFieldArray } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

import {
  AimTextFieldForm,
  CountriesAutocompleteByGeoDb,
  AimIconButton,
  AimTypography,
  AimCheckbox,
} from '@aim/components';

const renderFields = (
  f,
  index,
  control,
  errors,
  i18n,
  remove,
  variant,
  setValue,
  register,
  clearErrors
) => {
  return (
    <Grid container key={index}>
      <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <AimTextFieldForm
              control={control}
              formControlStyle={{ display: 'none' }}
              name={`guests[${index}].id`}
              defaultValue={f.id}
            />
            <AimTextFieldForm
              control={control}
              defaultValue={f.givenName}
              errors={errors}
              textfieldVariant={variant}
              placeholder={i18n.guestsForm.name}
              label={i18n.guestsForm.name}
              name={`guests[${index}].givenName`}
              formControlStyle={{ width: '95%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <AimTextFieldForm
              control={control}
              defaultValue={f.familyName}
              errors={errors}
              textfieldVariant={variant}
              placeholder={i18n.guestsForm.familyName}
              label={i18n.guestsForm.surname}
              name={`guests[${index}].familyName`}
              formControlStyle={{ width: '95%' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <CountriesAutocompleteByGeoDb
              control={control}
              register={register}
              setValue={setValue}
              textfieldVariant={variant}
              label={i18n.guestsForm.country}
              placeholder={i18n.guestsForm.country}
              name={`guests[${index}].country`}
              formControlStyle={{ width: '95%' }}
              clearErrors={clearErrors}
              defaultValue={f.country}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        lg={1}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: '5px',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <AimIconButton
            variant={'secondary'}
            onClick={() => {
              remove(index);
            }}
          >
            <ClearIcon />
          </AimIconButton>
        </div>
      </Grid>
    </Grid>
  );
};

const RenderAccompanyingPerson = ({
  remove,
  profile,
  i18n,
  fields,
  isAccompanyingPersons,
  setIsAccompanyingPersons,
  variant,
  append,
  control,
  errors,
  setValue,
  register,
  clearErrors,
}) => {
  if (profile?.isAccompanyingPersonEnabled > 0) {
    return (
      <Grid container style={{ padding: '0 10px' }}>
        <Grid item xs={12}>
          <AimTypography
            variant="h4"
            style={{
              textTransform: 'uppercase',
              fontSize: '0.5rem',
            }}
          >
            {i18n.guestsForm.accompanyingPersons}
          </AimTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <AimCheckbox
            label={i18n.guestsForm.checkboxTitle}
            variant={variant}
            onClick={() => {
              if (isAccompanyingPersons) {
                remove();
              }
              setIsAccompanyingPersons(!isAccompanyingPersons);
            }}
            checked={isAccompanyingPersons}
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <AimTypography variant="text">
            {i18n.guestsForm.maxAccompanyingPersons}:
            {profile?.maxAccompanyingPersons}
          </AimTypography>
        </Grid>
        {fields.map((f, index) =>
          renderFields(
            f,
            index,
            control,
            errors,
            i18n,
            remove,
            variant,
            setValue,
            register,
            clearErrors
          )
        )}

        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AimIconButton
              variant="yellowFill"
              onClick={() => {
                append({ id: 'new-' + nanoid() });
              }}
              disabled={
                !isAccompanyingPersons ||
                fields?.length === profile?.maxAccompanyingPersons
              }
            >
              <AddIcon />
            </AimIconButton>
            <AimTypography variant="text">
              {i18n.guestsForm.addPerson}
            </AimTypography>
          </div>
        </Grid>
      </Grid>
    );
  } else return null;
};

const GuestsForm = ({
  profile,
  i18n,
  // isAccompanyingPersons = true,
  // setIsAccompanyingPersons,
  variant,
  refButton,
  onClickSend,
  onSendDataFn,
  defaultGuests,
}) => {
  const {
    control,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
    reset,
    register,
    formState,
    clearErrors,
  } = useForm({
    defaultValues: {},
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'guests',
    keyName: 'key',
  });

  const [isAccompanyingPersons, setIsAccompanyingPersons] = useState(false);

  useEffect(() => {
    if (defaultGuests?.length > 0) {
      setIsAccompanyingPersons(true);
      defaultGuests.map((g) => {
        const nextG = { ...g, country: g.countryObj };
        append(nextG);
      });
    }
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSendDataFn)}
        style={{ padding: '0 10px 0px 10px' }}
      >
        <RenderAccompanyingPerson
          {...{
            remove,
            profile,
            i18n,
            fields,
            isAccompanyingPersons,
            setIsAccompanyingPersons,
            variant,
            append,
            control,
            errors,
            setValue,
            register,
            clearErrors,
          }}
        />
        <input type={'submit'} hidden ref={refButton} />
      </form>
    </>
  );
};

export default GuestsForm;

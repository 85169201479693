export const translation = {
  profile: (intl) => ({
    myArea: intl.formatMessage({
      description: 'my area label',
      defaultMessage: 'My Area',
    }),
    landing: intl.formatMessage({
      description: 'landing label',
      defaultMessage: 'Landing',
    }),
    purchases: intl.formatMessage({
      description: 'purchases label',
      defaultMessage: 'Purchases',
    }),
    invoices: intl.formatMessage({
      description: 'invoices label',
      defaultMessage: 'Invoices',
    }),
    //same name and description of ResponsiveDrawer.js to be sure to have a unique translation for both
    billingInformation: intl.formatMessage({
      description: 'profile menu tooltip billing informations label',
      defaultMessage: 'Billing Information',
    }),
    myGroups: intl.formatMessage({
      description: 'my groups label',
      defaultMessage: 'My Groups',
    }),
    customOrders: intl.formatMessage({
      description: 'custom orders label',
      defaultMessage: 'Custom orders',
    }),
    logout: intl.formatMessage({
      description: 'logout label',
      defaultMessage: 'Logout',
    }),
    services: intl.formatMessage({
      description: 'Opportunities menu item',
      defaultMessage: 'Opportunities',
    }),
    premiumRanges: intl.formatMessage({
      description: 'premium ranges menu item',
      defaultMessage: 'Premium ranges',
    }),
    cart: intl.formatMessage({
      description: 'cart menu item',
      defaultMessage: 'Cart',
    }),
    servicesConfiguration: intl.formatMessage({
      description: 'servicesConfiguration item',
      defaultMessage: 'Services configuration',
    }),
  }),
};

import {
  listProductByClientIdServiceTypeServiceId,
  listEventAdditionalServices,
} from '../../userSocialEvents/gqlHelper';
import { getAdditionalService } from '../../shared/gqlHelper';
import { translation } from '../../../participationServicesPublicPage/translation';
import { aws } from '@aim/common';
import { format } from 'date-fns';

export const getMyAdditionalServices = async ({
  participationId,
  eventId,
  serviceType,
  intl,
}) => {
  const i18n = translation.participationServicesPublicPage(intl).pdf;
  let prodResults = await listProductByClientIdServiceTypeServiceId(
    participationId,
    eventId,
    serviceType
  );

  let additionalServicesResults = await listEventAdditionalServices(eventId);

  additionalServicesResults = prodResults
    ?.filter((p) =>
      additionalServicesResults.find((as) => p.productId === as.id)
    )
    .map((p) => ({
      ...additionalServicesResults.find((as) => p.productId === as.id),
      productId: p.id,
    }));

  const additionalServiceData = await Promise.all(
    additionalServicesResults?.map((element) =>
      getAdditionalService(element.id)
    )
  );

  const data = await Promise.all(
    additionalServiceData
      ?.map((element, idx) => ({
        ...element,
        images: additionalServicesResults[idx]?.images,
        productId: additionalServicesResults[idx]?.productId,
      }))
      .map(async (element) => {
        const s3Folder = `events/${eventId}/additional-services/${element.id}/gallery/`;
        const firstImg = element?.images?.items[0];
        if (firstImg) {
          const image = await aws.Storage.get(
            `${s3Folder}${firstImg.id}${firstImg.extension}`
          );
          return {
            ...element,
            image,
          };
        }
        return {
          ...element,
          image: '',
        };
      })
  );

  return data && data.length > 0
    ? data
        .map((additionalService) => {
          const parsed = {
            ...additionalService,
            event: additionalService?.title || '-',
            description: additionalService?.description || '-',
            date: additionalService?.dateService
              ? format(new Date(additionalService?.dateService), 'dd/MM/yyyy')
              : '-',
            startTime: additionalService?.startTime || '-',
            endTime: additionalService?.endTime || '-',
            location: additionalService?.locationName || '-',
            address: additionalService?.locationAddress || '-',
          };

          return parsed;
        })
        .map((additionalService) =>
          parseMyAdditionalService(additionalService, i18n)
        )
    : [];
};

export const parseMyAdditionalService = (additionalService, translations) =>
  Object.entries(additionalService).map(([key, value]) =>
    translations[key]
      ? {
          id: key,
          label: translations[key],
          value,
        }
      : { [key]: value }
  );

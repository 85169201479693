import React, { useEffect, useState } from 'react';
// import { usePubNub } from 'pubnub-react';
import { nanoid } from 'nanoid';
import { withRouter, matchPath, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LinkContainer, MenuContainer, MenuNav } from './style';
import Badge from '@material-ui/core/Badge';

import {
  CustomIntl,
  AimTypography,
  useChat,
  useAimMediaQuery,
} from '@aim/components';
import { appState, constants } from '@aim/common';
import { isBefore } from 'date-fns';
import { sortBy } from 'lodash';

const renderLink = ({ props, label, eventId, link }) => (
  <AimTypography
    variant="text"
    boxStyle={{
      fontWeight:
        props.history.location.pathname === `/events/${eventId}/${link}`
          ? 700
          : 300,
      fontSize: '1rem',
    }}
  >
    {label}
  </AimTypography>
);

const getUserMetadata = (participation) => {
  return {
    participationId: participation?.id,
    displayName: participation?.givenName + ' ' + participation?.familyName,
    initial:
      participation?.givenName && participation?.familyName
        ? participation?.givenName[0]?.toUpperCase() +
          participation?.familyName[0]?.toUpperCase()
        : '--',
  };
};

const Menu = (props) => {
  const intl = CustomIntl(useIntl());
  const match = matchPath(props.history.location.pathname, {
    path: '/events/:id',
  });

  const { isMobile } = useAimMediaQuery();

  const [unreadMessages, setUnreadMessages] = useState(0);
  const [headerMenuItems, setHeaderMenuItems] = useState([]);
  const [areHeaderMenuVoicesShown, setAreHeaderMenuVoicesShown] = useState(
    true
  );
  // const client = usePubNub();

  const participation = appState.user.getValue()?.userAndParticipation
    ?.participation;

  const sponsor = participation?.sponsor; // || participation?.sponsorStaff?.sponsor;

  const isValidSponsor =
    !participation?.isDeleted && sponsor?.id && !sponsor?.isDeleted;

  const onMembershipChanged = () => {
    //reloadPrivateChats();
  };

  const channelGroups = ['cg_' + eventId, 'cg_' + participation?.id];

  // const {
  //   addChannelsToGroup,
  //   checkUnreadedMessages,
  //   listGroupChannels,
  //   setChannelMetadata,
  // } = useChat({
  //   client,
  //   userUuid: participation?.id,
  //   userMetadata: getUserMetadata(participation),
  //   onMembershipChanged,
  //   channelGroups,
  // });

  let eventId;
  if (match && match.params.id) {
    eventId = match.params.id;
  }

  useEffect(() => {
    const unreadMessagesSubscription = appState.loungeUnreadMessages.subscribe(
      (nextUnreadMessages) => {
        if (nextUnreadMessages !== unreadMessages) {
          setUnreadMessages(nextUnreadMessages);
        }
      }
    );
    fetchHeaderMenu();

    return () => {
      unreadMessagesSubscription.unsubscribe();
    };
  }, []);

  const fetchHeaderMenu = () => {
    // if (
    //   !appState.user.getValue()?.userAndParticipation?.participation?.hasTicket
    // )
    //   return;
    const configuration = appState.eventConfiguration.getValue();
    const menuItems = Object.values(constants.EventGenericMenuItems);
    const menuItemsSorted = sortBy(menuItems, 'order');
    const nextHeaderMenu = menuItemsSorted
      .filter((item) => {
        const configItem = configuration.eventGenericMenuItems.items.find(
          (configItem) => configItem.key === item.key
        );

        return (
          item.frontofficeLink &&
          configItem?.activeFlagChecked &&
          (!item.dateOfActivation ||
            !isBefore(Date.now(), new Date(configItem?.dateOfActivation)))
        );
      })
      .map((item) => {
        const configItem = configuration.eventGenericMenuItems.items.find(
          (configItem) => configItem.key === item.key
        );
        const label =
          configItem?.menuItemLabel ||
          Object.values(constants.EventGenericMenuItems)
            .find((menuItem) => menuItem.key === item.key)
            .label(intl);
        return { link: item.frontofficeLink, label };
      });
    setHeaderMenuItems(isValidSponsor ? [] : nextHeaderMenu);
  };

  useEffect(() => {
    const menuHeaderVoicesShownSubscription = appState.areHeaderMenuVoicesShown.subscribe(
      setAreHeaderMenuVoicesShown
    );

    return () => {
      menuHeaderVoicesShownSubscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <MenuContainer>
        {!isMobile && (
          <>
            {areHeaderMenuVoicesShown && (
              <MenuNav>
                {headerMenuItems.length &&
                  headerMenuItems.map(({ label, link }) => {
                    if (link && link !== '') {
                      return (
                        <LinkContainer>
                          <Link to={`/events/${eventId}/${link}`}>
                            {link === 'lounge-area' && unreadMessages > 0 ? (
                              <Badge
                                variant="dot"
                                badgeContent={unreadMessages}
                                color="secondary"
                              >
                                {renderLink({
                                  props,
                                  label,
                                  eventId,
                                  link,
                                })}
                              </Badge>
                            ) : (
                              renderLink({
                                props,
                                label,
                                eventId,
                                link,
                              })
                            )}
                          </Link>
                        </LinkContainer>
                      );
                    }
                  })}
              </MenuNav>
            )}
            {/* <AimIconButton>
              <QuestionAnswerOutlinedIcon />
            </AimIconButton> */}
          </>
        )}
      </MenuContainer>
    </>
  );
};

Menu.propTypes = {};

export default withRouter(Menu);

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { utcToZonedTime, format } from 'date-fns-tz';

import { useHistory, useParams } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  AimTitleAndButtons,
  AimIconAndTextButton,
  CustomIntl,
  styled,
  TicketInfo,
} from '@aim/components';
import { appState, constants, utilities } from '@aim/common';

import {
  getParticipation,
  getProduct,
  getRegistration,
} from './shared/gqlHelper';

import translation from './mySingleTicket/translation';

const CustomButton = styled(AimIconAndTextButton)({
  [`@media print`]: {
    display: 'none',
  },
});

const formatDate = (dateString) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  const date = utcToZonedTime(new Date(dateString), currentTimezone);

  return format(date, 'dd/MM/yyyy', {
    timeZone: currentTimezone,
  });
};

const MySingleTicket = () => {
  const { eventId, participationId, productId } = useParams();
  const history = useHistory();

  const intl = CustomIntl(useIntl());
  const i18n = translation.myTicket(intl);
  const { PaymentTypesWithTranslations, PaymentStatuses, Services } = constants;

  const [registrationData, setRegistrationData] = useState();

  const [title, setTitle] = useState();

  const fetchData = async () => {
    const participationResponse = await getParticipation(participationId);
    const responseProduct = await getProduct(productId);

    const nextProduct = {
      ...responseProduct,
      productOwner: {
        id: participationResponse.id,
        givenName: participationResponse.givenName,
        familyName: participationResponse.familyName,
        email: participationResponse.email,
        phone: participationResponse.phone,
      },
      productGuests: responseProduct.guests?.items.map((guest) => ({
        id: guest.id,
        givenName: guest.givenName,
        familyName: guest.familyName,
        country: guest.country,
      })),
    };

    const responseRegistration = await getRegistration(
      responseProduct.productId
    );

    setTitle(
      `${responseRegistration?.feeBracket?.feeDateRange?.label} ${
        responseRegistration?.feeBracket?.feeDateRange?.start &&
        format(
          new Date(responseRegistration.feeBracket?.feeDateRange.start),
          'dd/MM/yyyy'
        )
      } ${
        responseRegistration?.feeBracket?.feeDateRange?.end &&
        '- ' +
          format(
            new Date(responseRegistration.feeBracket?.feeDateRange.end),
            'dd/MM/yyyy'
          )
      } `
    );

    const result = [
      {
        ...nextProduct,
        ticket: responseRegistration || {},
        gateway:
          participationResponse.event.gateways.items.find(
            (g) => g.service === 'REGISTRATION'
          ) || {},
      },
    ];

    const REGISTRATION_data = {
      title: Services.REGISTRATION.label(intl),
      blockKey: 'REGISTRATION',
      data: result,
    };
    setRegistrationData(REGISTRATION_data);
  };

  useEffect(() => {
    fetchData();
  }, [participationId]);

  const TitleAndButton = () => (
    <div style={{ marginBottom: 10 }}>
      <AimTitleAndButtons
        titleBoxStyle={{ marginLeft: 0 }}
        title={title || ''}
      />
    </div>
  );

  const getBasicData = ({ product, intl }) => {
    const { givenName, familyName, email, phone } = product?.productOwner || {};
    // product?.payment?.BillingInformation || {};

    const clientInformations = {
      givenName,
      familyName,
      email,
      phone,
      guests: product?.productGuests,
    };

    const billingInformations =
      //skip payment details in free payments
      product?.payment?.BillingInformation && product?.payment?.amount > 0
        ? {
            ...(product?.payment?.BillingInformation || {}),
            isVATExent: product?.payment?.BillingInformation?.isVatEvent,
            invoiceTo: product?.payment?.BillingInformation?.invoiceTo
              ? Object.values(constants.InvoiceToType)
                  .find(
                    (it) =>
                      it.id === product?.payment?.BillingInformation?.invoiceTo
                  )
                  ?.label(intl)
              : null,
            reference:
              product?.payment?.BillingInformation?.invoiceTo ===
              constants.InvoiceToType.COMPANY.id
                ? product?.payment?.BillingInformation.reference
                : givenName,
          }
        : null;

    const paymentDetails =
      //skip payment details in free payments
      product?.payment && product?.payment?.amount > 0
        ? {
            // ...(product?.payment || {}),
            total: utilities.formatNumber(
              utilities.decodeDbNumber(product?.payment?.amount)
            ),
            paymentMethod:
              Object.values(PaymentTypesWithTranslations)
                .find((x) => x.key === product?.payment?.paymentType)
                .label(intl) || product?.payment?.paymentType,
            paymentStatus: product?.payment?.paymentStatus
              ? Object.values(PaymentStatuses)
                  .find((x) => x.key === product?.payment?.paymentStatus)
                  .label(intl) || product?.payment?.paymentStatus
              : null,
            paymentReason: product?.gateway?.shop?.ibanCausal,
            IBAN: product?.gateway?.shop?.iban,
            addressedTo: product?.gateway?.shop?.addressedTo,
            paymentObject: product?.payment,
          }
        : null;

    return { clientInformations, billingInformations, paymentDetails };
  };

  return (
    <div
      style={{
        flex: 1,
        maxWidth: 1320,
        width: 'calc(100% - 80px)',
      }}
    >
      <Tooltip title={i18n.page.tooltip}>
        <CustomButton
          variant="none"
          text={intl.formatMessage({
            description: 'back',
            defaultMessage: 'back',
          })}
          style={{
            padding: 0,
            marginLeft: 0,
          }}
          onClick={() =>
            history.push(`/events/${eventId}/user/${participationId}/services`)
          }
        >
          <ArrowBack />
        </CustomButton>
      </Tooltip>
      <TitleAndButton />

      {registrationData &&
        registrationData.data.map((product, ndx) => {
          const {
            clientInformations,
            billingInformations,
            paymentDetails,
          } = getBasicData({ product, intl });

          const registrationInformations = {
            registration: product?.ticket?.feeBracket?.feeDateRange?.label,
            start: formatDate(product.ticket.feeBracket.feeDateRange.start),
            end: formatDate(product.ticket.feeBracket.feeDateRange.end),
            billingDocument: product.billingDocument,
            creditNotesDocument: product.creditNotesDocument,
            serviceId: product.serviceId,
          };

          return (
            <TicketInfo
              key={ndx}
              {...{
                intl,
                clientInformations,
                billingInformations,
                paymentDetails,
                registrationInformations,
                module: 'registration',
                i18n,
                showPrice: product.showPrice,
              }}
            />
          );
        })}
    </div>
  );
};

export default MySingleTicket;

import { aws, appState, constants } from '@aim/common';
import { isAfter, isBefore, startOfDay, endOfDay } from 'date-fns';
import { useFileHelper } from '../../../pages/shared/fileHelper';
import { useBreakoutRoomUtils } from '../../../hooks/useBreakoutRoomUtils';
import {
  utcToZonedTime,
  // getTimezoneOffset,
  // formatInTimeZone,
  format as formatTz,
} from 'date-fns-tz';
const currentTimezone = appState.eventInfo.getValue().timezone;

const { states } = useBreakoutRoomUtils();
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

/**handle participation */
export const getParticipantByCognitoIdEventId = /* GraphQL */ `
  query ParticipantByCognitoIdEventId(
    $cognitoUserId: ID
    $participationEventId: ModelIDKeyConditionInput
  ) {
    participantByCognitoIdEventId(
      cognitoUserId: $cognitoUserId
      participationEventId: $participationEventId
    ) {
      items {
        id
        givenName
        familyName
        email
        event {
          id
        }
        profile {
          id
          name
          category {
            id
            name
          }
        }
        myAgenda {
          items {
            id
            session {
              id
            }
          }
        }
        myBreakoutRoom(filter: {state: {eq: "${states.ACCEPTED}"}}) {
          items {
            id
            state
            slot {
              id
              start
              end
              title
              breakoutRoom {
                sponsor {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

//TODO: filtrare in base a visibility e permessi utente
const getEventAgenda = (agendaType) => /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      start
      end
      symposiumService {
        id
      }
      agenda: ${agendaType} {
        id
        tags
        sessionTypologies {
          items {
            color
            name
            id
            description
          }
        }
        sessions {
          items {
            id
            canBeBought
            code
            symposium {
              id
              buyOperation {
                id
                sponsor {
                  name
                }
              }
              sponsorPackageItem {
                package {
                  buyOperations {
                    items {
                      id
                    }
                  }
                }
              }
            }
            isPublishedForSpeakers
            attendeeRoles {
              items {
                id
                role {
                  id
                  name
                  frontofficeName
                }
                attendees {
                  items {
                    id
                    participation {
                      id
                      givenName
                      familyName
                      userShowcase {
                        id
                        profileImage {
                          id
                          extension
                          originalName
                          size
                        }
                      }
                    }
                  }
                }
              }
            }
            coverImage {
              id
              originalName
              extension
              size
            }
            date
            description
            profileVisibilityIds
            categoryVisibility {
              id
            }
            name
            followers {
              items {
                id
              }
            }
            linkSpeaker
            linkPax
            hasWebinarChat
            hasWebinarCounterUser
            hasWebinarPolling
            hasWebinarQA
            hasWebinarReactions
            linkPax
            linkSpeaker
            room {
              id
              name
              ordering
              type
              delay
              capacity
            }
            sessionMode
            sessionTypology {
              color
              id
              name
              description
            }
            shortDescription
            subSessions {
              items {
                name
                start
                end
                attendeeRoles {
                  items {
                    id
                    role {
                      id
                      name
                      frontofficeName
                    }
                    attendees {
                      items {
                        id
                        participation {
                          id
                          givenName
                          familyName
                        }
                      }
                    }
                  }
                }
                isCoincideEnabled
                speeches {
                  items {
                    id
                    instructions
                    title
                    start
                    end
                    type
                    code
                    titleDescription
                    attendeeRoles {
                      items {
                        id
                        role {
                          id
                          name
                          frontofficeName
                          showInProgram
                        }
                        attendees {
                          items {
                            id
                            participation {
                              id
                              givenName
                              familyName
                              user {
                                nationality
                              }
                            }
                          }
                        }
                      }
                    }
                    abstract {
                      id
                      title
                      authors {
                        items {
                          id
                          fieldValues {
                            items {
                              id
                              value
                              fieldDefinition {
                                controlType
                                id
                                key
                                label
                                options
                                placeholder
                              }
                            }
                          }
                          affiliation {
                            id
                            institution
                          }
                        }
                      }
                      presenter {
                        id
                        participant {
                          givenName
                          familyName
                        }
                        affiliation {
                          id
                          institution
                        }
                      }
                    }
                    session {
                      id
                    }
                  }
                }
              }
            }
            start
            end
            status
            tags
          }
        }
      }
      rooms {
        items {
          id
          type
          capacity
          delay
          name
          ordering
        }
        nextToken
      }
    }
  }
`;

const getAgendaSessionQuery = /* GraphQL */ `
  query GetAgendaSession($id: ID!) {
    getAgendaSession(id: $id) {
      id
      name
      code
      canBeBought
      date
      start
      end
      shortDescription
      description
      status
      tags
      hasWebinarChat
      hasWebinarCounterUser
      hasWebinarPolling
      hasWebinarQA
      hasWebinarReactions
      isInterventionsVisibleOnSessionDetails
      isInterventionsVisibleToSpeakerAndModerator
      id
      linkPax
      linkSpeaker
      symposium {
        id
        buyOperation {
          id
          sponsor {
            name
          }
        }
        sponsorPackageItem {
          package {
            buyOperations {
              items {
                id
              }
            }
          }
        }
      }
      coverImage {
        extension
        id
        originalName
        size
      }

      profileVisibility {
        id
      }
      categoryVisibility {
        id
      }
      followers {
        items {
          id
        }
      }
      webinarModerator {
        id
        participation {
          familyName
          givenName
          id
        }
      }

      moderators {
        items {
          id
          participation {
            id
            isSpeaker
            isDeleted
            givenName
            familyName
          }
        }
      }
      room {
        id
        name
        type
        delay
        capacity
      }
      sessionMode
      sessionTypology {
        color
        id
        name
        description
      }

      attendeeRoles {
        items {
          id
          role {
            id
            name
            frontofficeName
            showInProgram
          }
          attendees {
            items {
              id
              participation {
                id
                givenName
                familyName
                user {
                  nationality
                }
              }
            }
          }
        }
      }
      subSessions {
        items {
          id
          name
          shortDescription
          start
          end
          isCoincideEnabled
          attendeeRoles {
            items {
              id
              role {
                id
                name
                frontofficeName
                showInProgram
              }
              attendees {
                items {
                  id
                  participation {
                    id
                    givenName
                    familyName
                    user {
                      nationality
                    }
                  }
                }
              }
            }
          }
          speeches {
            items {
              id
              start
              end
              titleDescription
              session {
                id
              }
              title
              attendeeRoles {
                items {
                  id
                  role {
                    id
                    name
                    frontofficeName
                    showInProgram
                  }
                  attendees {
                    items {
                      id
                      participation {
                        id
                        givenName
                        familyName
                        user {
                          nationality
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getAgendaSessionQueryOld = /* GraphQL */ `
  query GetAgendaSession($id: ID!) {
    getAgendaSession(id: $id) {
      id
      code
      canBeBought
      symposium {
        id
        buyOperation {
          id
          sponsor {
            name
          }
        }
        sponsorPackageItem {
          package {
            buyOperations {
              items {
                id
              }
            }
          }
        }
      }
      coverImage {
        extension
        id
        originalName
        size
      }
      date
      description
      end
      profileVisibility {
        id
      }
      categoryVisibility {
        id
      }
      followers {
        items {
          id
        }
      }
      webinarModerator {
        id
        participation {
          familyName
          givenName
          id
        }
      }
      hasWebinarChat
      hasWebinarCounterUser
      hasWebinarPolling
      hasWebinarQA
      hasWebinarReactions
      isInterventionsVisibleOnSessionDetails
      isInterventionsVisibleToSpeakerAndModerator
      id
      linkPax
      linkSpeaker
      moderators {
        items {
          id
          participation {
            id
            isSpeaker
            isDeleted
            givenName
            familyName
          }
        }
      }
      name
      room {
        id
        name
        type
        delay
        capacity
      }
      sessionMode
      sessionTypology {
        color
        id
        name
        description
      }
      shortDescription
      start
      end
      status
      tags
      attendeeRoles {
        items {
          id
          role {
            id
            name
            frontofficeName
          }
          attendees {
            items {
              id
              participation {
                id
                givenName
                familyName
              }
            }
          }
        }
      }
      subSessions {
        items {
          id
          start
          end
          name
          isCoincideEnabled
          attendeeRoles {
            items {
              id
              role {
                id
                name
                frontofficeName
              }
              attendees {
                items {
                  id
                  participation {
                    id
                    givenName
                    familyName
                  }
                }
              }
            }
          }
          speeches {
            items {
              instructions
              id
              start
              end
              title
              type
              titleDescription
              abstract {
                id
                title
                status
                presenter {
                  participant {
                    id
                    givenName
                    familyName
                    userShowcase {
                      profileImage {
                        id
                        extension
                        originalName
                        size
                      }
                    }
                  }
                }
              }
              attendees {
                items {
                  participation {
                    id
                    givenName
                    familyName
                    userShowcase {
                      profileImage {
                        id
                        extension
                        originalName
                        size
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getAgendaPosterSessionQuery = /* GraphQL */ `
  query GetAgendaPosterSession($id: ID!) {
    getAgendaPosterSession(id: $id) {
      id
      posterSessionNumber
      type
      title
      initial
      posters {
        items {
          id
          code
          name
          orderNumber
          abstract {
            id
            title
            status
            abstractSections {
              items {
                id
                value
                createdAt
                updatedAt
                fieldDefinition {
                  id
                  key
                  label
                  contextsOfUse {
                    items {
                      contextName
                      position
                    }
                  }
                }
              }
            }
            attachments {
              items {
                id
                name: originalName
                extension
                size
              }
            }
            category {
              id
              name
            }
            acceptedPresentationTypology {
              id
              name
            }
            processingStatus
            tags
            finalScore
            authors {
              items {
                id
                fieldValues {
                  items {
                    id
                    value
                    fieldDefinition {
                      controlType
                      id
                      key
                      label
                      options
                      placeholder
                    }
                  }
                }
                affiliation {
                  id
                  institution
                }
              }
            }
            presenter {
              id
              participant {
                givenName
                familyName
              }
              affiliation {
                id
                institution
              }
            }
          }
        }
      }
      room {
        id
        name
        type
        capacity
        delay
        createdAt
        updatedAt
        event {
          id
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          createdAt
          updatedAt
        }
        agendaSessions {
          nextToken
        }
      }
      sessionSlots {
        items {
          id
          date
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      attendeePresidents {
        items {
          id
          participation {
            familyName
            givenName
            id
          }
        }
      }
      attendeeModerators {
        items {
          id
          participation {
            familyName
            givenName
            id
          }
        }
      }
      attendeeChairs {
        items {
          id
          participation {
            familyName
            givenName
            id
          }
        }
      }
      attendeeFacilitators {
        items {
          id
          participation {
            familyName
            givenName
            id
          }
        }
      }
      attendeeSpeakers {
        items {
          id
          participation {
            familyName
            givenName
            id
          }
        }
      }
      attendeePresenters {
        items {
          id
          participation {
            familyName
            givenName
            id
          }
        }
      }
      posters {
        items {
          id
          code
          name
          orderNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      agenda {
        id
        sessions {
          items {
            speeches(filter: {type: {eq: "${constants.AgendaSpeechesTypologies.ABSTRACT}"}}) {
              items {
                abstract {
                  id
                  title
                }
              }
            }
          }
        }
        tags
        sessionTypologies {
          nextToken
        }
        createdAt
        updatedAt
        event {
          id
          createdBy
          isOwnedByCongress
          name
          code
          organizer
          start
          end
          venue
          country
          city
          initials
          invoicingInitials
          order
          isDeleted
          sponsorServiceBookingDuration
          IBAN
          mailUpListId
          createdAt
          updatedAt
        }
      }
      canBeBought
      status
      createdAt
      updatedAt
      coverImage {
        id
        originalName
        size
        extension
        createdAt
        updatedAt
      }
    }
  }
`;

export const getPosterQuery = /* GraphQL */ `
  query GetPoster($id: ID!) {
    getPoster(id: $id) {
      id
      code
      name
      orderNumber
      abstract {
        id
        title
        status
        cod0
        cod1
        cod2
        url
        int
        disc
        finalScore
        preferredExposure
        category {
          id
          name
          createdAt
          updatedAt
        }
        subCategory {
          id
          name
          createdAt
          updatedAt
        }
        proposedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          validationDate
          expirationDate
          createdAt
          updatedAt
        }
        acceptedPresentationTypology {
          id
          name
          description
          presentationMode
          presentationRequired
          validationDate
          expirationDate
          createdAt
          updatedAt
        }
        processingStatus
        tags
        keywords
        reviews {
          nextToken
        }
        isReadyToReview
        affiliations {
          nextToken
        }
        authors {
          nextToken
        }
        presenter {
          id
          createdAt
          updatedAt
        }
        images
        isDeleted
        isOwnerAPresentingAuthor
        speeches {
          nextToken
        }
        posters {
          nextToken
        }

        attachments {
          nextToken
        }
        presentationFile {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      agendaPosterSession {
        id
        posterSessionNumber
        type
        title
        initial
        room {
          id
          name
          type
          capacity
          delay
          createdAt
          updatedAt
        }
        sessionSlots {
          nextToken
        }
        attendeePresidents {
          nextToken
        }
        attendeeModerators {
          nextToken
        }
        attendeeChairs {
          nextToken
        }
        attendeeFacilitators {
          nextToken
        }
        attendeeSpeakers {
          nextToken
        }
        attendeePresenters {
          nextToken
        }
        posters {
          nextToken
        }
        agenda {
          id
          tags
          createdAt
          updatedAt
        }
        canBeBought
        status
        createdAt
        updatedAt
        coverImage {
          id
          originalName
          size
          extension
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export function useAgenda() {
  const getEvent = (
    eventId,
    agendaType = constants.AgendaTypes.AGENDA.queryField
  ) =>
    new Promise((resolve, reject) => {
      showLoader();
      console.log('getEvent eventId ', agendaType);

      API.graphql({
        query: getEventAgenda(agendaType),
        variables: { id: eventId },
      })
        .then((response) => resolve(response.data.getEvent))
        .catch((e) => {
          console.error('agenda-getEvent-error', e);
          reject();
        })
        .finally(hideLoader);
    });

  const getAgendaSession = (id) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: getAgendaSessionQuery, variables: { id } })
        .then((response) => resolve(response.data.getAgendaSession))
        .catch((e) => {
          console.error('agenda-getAgendaSession-error', e);
          reject();
        })
        .finally(hideLoader);
    });

  const getAgendaPosterSession = (id) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: getAgendaPosterSessionQuery, variables: { id } })
        .then((response) => resolve(response.data.getAgendaPosterSession))
        .catch((e) => {
          console.error('agenda-getAgendaSession-error', e);
          reject();
        })
        .finally(hideLoader);
    });

  const getPoster = (id) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: getPosterQuery, variables: { id } })
        .then((response) => resolve(response.data.getPoster))
        .catch((e) => {
          console.error('agenda-getPoster-error', e);
          reject();
        })
        .finally(hideLoader);
    });

  const getDraftAgendaId = (genericTableId) =>
    genericTableId.replace('agenda-', '').replace('speakers-', '');

  const parseSession = async (session, eventId) => {
    const nextSpeeches = session.subSessions?.items.flatMap((subSession) => {
      const {
        speeches,
        name,
        start,
        end,
        attendeeRoles,
        isCoincideEnabled,
      } = subSession;

      console.log('parseSession session ', session);

      const interv = speeches.items.map((i) => ({
        name: i.type === 'ABSTRACT' ? i.abstract.title : i.title,
        posters: i.type === 'ABSTRACT' ? i.abstract.posters.items : [],
        start: new Date(i.start),
        end: new Date(i.end),
        attendees: i.attendees?.items.map((i) => i.participation),
      }));

      const sub = {
        isSubsession: true,
        name: name,
        start: new Date(start),
        end: new Date(end),
        attendees: attendeeRoles.items.flatMap((ar) =>
          ar.attendees.items.map((a) => ({
            ...a.participation,
            role: ar.role.frontofficeName,
          }))
        ),
      };

      return isCoincideEnabled ? interv : [sub, ...interv];
    });

    const start = utcToZonedTime(new Date(session.start), currentTimezone);
    const end = utcToZonedTime(new Date(session.end), currentTimezone);

    let values = {
      ...session,
      speeches: nextSpeeches,
      start,
      end,
      tags: session.tags?.join(', ') || null,
      description: session?.description || '',
      room: session?.room?.name,
      date:
        session.start && session.end
          ? `${formatTz(start, 'dd/MM/yyyy', {
              timeZone: currentTimezone,
            })} [${formatTz(start, 'HH:mm', {
              timeZone: currentTimezone,
            })} - ${formatTz(end, 'HH:mm', {
              timeZone: currentTimezone,
            })}]`
          : null,
    };
    if (eventId) {
      const { getS3Link } = useFileHelper(`events/${eventId}/`);
      if (session.coverImage) {
        console.log(
          'id',
          session.id.replace('agenda-', '').replace('speakers-', '')
        );
        values.coverImage = {
          text: session.coverImage.originalName,
          img: await getS3Link(
            //we will search images on draft agenda url
            `agendaSessions/${getDraftAgendaId(session.id)}/coverImage`,
            {
              ...session.coverImage,
              id: getDraftAgendaId(session.coverImage.id),
            }
          ),
        };
      }
    }
    return values;
  };

  const isJoinVisible = (session) => {
    console.log('isJoinVisible session ', session);
    if (session.linkPax) {
      const start = startOfDay(session.start);
      const now = new Date();
      const end = endOfDay(session.end);
      return isAfter(now, start) && isBefore(now, end);
    }
    return false;
  };

  return {
    isJoinVisible,
    parseSession,
    getEvent,
    getAgendaSession,
    getAgendaPosterSession,
    getPoster,
  };
}

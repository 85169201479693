import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import Logo from '../../src/pages/shared/logo/Logo';
import Footer from '../../src/pages/shared/Footer';
import { Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { appState, aws } from '@aim/common';
import { constants, utilities } from '@aim/common';

import {
  AimIconAndTextButton,
  AimTypography,
  styled,
  theme,
  CustomIntl,
} from '@aim/components';

import { translation } from './genericPayments/translation';

const PageContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '95%',
  margin: '0 auto',
  paddingLeft: '11%',
  paddingRight: '11%',
  paddingTop: 70,
});

const { formatNumber, decodeDbNumber } = utilities;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

const GenericPaymentsPublicPage = () => {
  // i18n
  const intl = CustomIntl(useIntl());
  const i18n = translation.genericPayment(intl);
  const { eventId, genericPaymentId } = useParams();
  const history = useHistory();
  const [genericPayment, setGenericPayment] = useState();

  const query = new URLSearchParams(location.search);
  const code = query.get('code');

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        const res = await aws.standardAPI.post(
          'aimlambdaproxy',
          '/admin/generic-payment',
          {
            body: {
              genericPaymentId,
              code,
            },
          }
        );
        hideLoader();
        setGenericPayment(res.getGenericPayment);
      } catch (error) {
        console.log('🚀 ~ fetchData ~ error:', error);
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const MainContainer = ({ children }) => (
    <div
      style={{
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  );

  const getCredentials = () => {
    let completeName = '';
    switch (genericPayment.cluster) {
      case 'pax':
      case 'faculty': {
        completeName = `${genericPayment.participation.givenName} ${genericPayment.participation.familyName}`;
        break;
      }
      case 'sponsor': {
        const sponsorAdmin = genericPayment?.sponsor?.admins?.items.find(
          (admin) => admin.isMainBuyer
        );
        completeName = `${sponsorAdmin?.givenName} ${sponsorAdmin?.familyName}`;
        break;
      }
      default:
        break;
    }

    return (
      <AimTypography variant={'textThin'} style={{ lineHeight: '0.5' }}>
        {`${i18n.greetings} ${completeName}`}
      </AimTypography>
    );
  };

  return (
    <>
      <MainContainer>
        <Logo />
        <PageContent>
          {genericPayment && (
            <>
              {getCredentials()}
              <AimTypography
                variant={`textThin`}
                boxStyle={{ fontSize: '1.313rem' }}
              >
                {i18n.subtitle}
              </AimTypography>
              <Grid
                container
                direction="row"
                style={{
                  backgroundColor: theme.colors.greyScale.backgroundGrey,
                  marginTop: 50,
                  padding: 50,
                }}
              >
                <Grid item xs={2}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AimTypography variant={'textBold'}>
                      {i18n.id}
                    </AimTypography>
                    <AimTypography variant={'text'}>
                      {genericPayment?.payment?.paymentId}
                    </AimTypography>
                  </div>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AimTypography variant={'textBold'}>
                      {i18n.description}
                    </AimTypography>
                    <AimTypography variant={'text'}>
                      {genericPayment?.description}
                    </AimTypography>
                  </div>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AimTypography variant={'textBold'}>
                      {i18n.netPrice}
                    </AimTypography>
                    <AimTypography variant={'text'}>
                      {formatNumber(decodeDbNumber(genericPayment?.netAmount))}
                    </AimTypography>
                  </div>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AimTypography variant={'textBold'} textAlign="right">
                      {i18n.vatPercentage}
                    </AimTypography>
                    <AimTypography variant={'text'} textAlign="right">
                      {decodeDbNumber(genericPayment?.vatRate)}
                    </AimTypography>
                  </div>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AimTypography variant={'textBold'} textAlign="right">
                      {i18n.vatAmount}
                    </AimTypography>
                    <AimTypography variant={'text'} textAlign="right">
                      {formatNumber(decodeDbNumber(genericPayment?.vat.amount))}
                    </AimTypography>
                  </div>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AimTypography variant={'textBold'} textAlign="right">
                      {i18n.total}
                    </AimTypography>
                    <AimTypography variant={'text'} textAlign="right">
                      {formatNumber(
                        decodeDbNumber(genericPayment?.totalAmount)
                      )}
                    </AimTypography>
                  </div>
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    <AimTypography variant={'textBold'} textAlign="right">
                      {i18n.status}
                    </AimTypography>
                    <div
                      style={{
                        backgroundColor:
                          genericPayment.payment.paymentStatus ===
                          constants.PaymentStatuses.DONE.key
                            ? '#BEF6BE'
                            : '#C9DEFF',
                        padding: '4px 8px',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {genericPayment.payment.paymentStatus ===
                      constants.PaymentStatuses.DONE.key
                        ? i18n.statuses.done
                        : i18n.statuses.pending}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <AimIconAndTextButton
                style={{
                  alignSelf: 'flex-end',
                  width: '100px',
                  marginTop: 26,
                }}
                isUpperCase
                onClick={() =>
                  history.push(
                    `/events/${eventId}/genericPayments/${genericPaymentId}/billing-info`,
                    { code }
                  )
                }
                text={i18n.buy}
                disabled={
                  genericPayment.payment.paymentStatus ===
                  constants.PaymentStatuses.DONE.key
                }
              >
                <ShoppingCartIcon />
              </AimIconAndTextButton>
            </>
          )}
        </PageContent>
        <Footer />
      </MainContainer>
    </>
  );
};

export default GenericPaymentsPublicPage;

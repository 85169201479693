import React from 'react';

import Divider from '@material-ui/core/Divider';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SettingsIcon from '@material-ui/icons/Settings';
import { useIntl } from 'react-intl';
import i18n from '../i18n';
import Tooltip from '@material-ui/core/Tooltip';
import { StarIcon } from '../../../../assets/icons';
import translation from './../../shared/translation';

import {
  AimTypography,
  AimIconAndTextButton,
  AimCard,
  styled,
  theme,
  CustomIntl,
} from '@aim/components';
import { lightenDarkenColor } from '../../../shared/utilities';

const StyledCard = styled(AimCard)(
  ({ showCardContent, disabled, selected }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 10,
    height: showCardContent ? 280 : 120,
    justifyContent: 'center',
    textAlign: 'center',
    // backgroundColor: 'transparent',
    backgroundColor: selected
      ? theme.colors.greyScale.grey5
      : theme.colors.greyScale.backgroundGrey,
    opacity: disabled ? 0.5 : 1,
  })
);

const StyledCardHeader = styled('div')({
  alignItems: 'baseline',
  fontWeight: 'bold',
  textAlign: 'center',
});

const StyledCardContent = styled('div')({
  fontSize: 30,
  fontWeight: 'lighter',
});

const StyledCardActions = styled('div')(({ noCursorPointer }) => ({
  display: 'flex',
  marginTop: 15,
  color: theme.colors.secondary.lightBlue,
  fontWeight: 'bold',
  cursor: noCursorPointer ? 'default' : 'pointer',
  '& button': {
    flex: 0.5,
  },
}));

const StyledDivider = styled(Divider)({
  margin: '4px 0px 8px 0px !important',
});

const StyledStarIconButton = styled(AimIconAndTextButton)(
  ({ textColor, buttonBackgroundColor }) => ({
    display: 'flex',
    flex: 'none !important',
    flexShrink: 0,
    width: '120px',
    padding: '11px 20px',
    alignItems: 'center',
    gap: '8px',
    color: textColor ? textColor : 'black',
    borderRadius: '4px',
    backgroundColor: buttonBackgroundColor,
    '& span': {
      gap: '8px',
    },
    '&:hover': {
      color: textColor ? textColor : 'black',
      borderRadius: '4px',
      backgroundColor: buttonBackgroundColor,
    },
  })
);

const SponsorFrontServiceCard = ({
  children,
  title,
  onClickBuy,
  subtitle,
  bordered,
  buyDisabled,
  onClickConfiguration,
  configurationVisible,
  configurationDisabled,
  configurationTooltip,
  customTooltip = null,
  showCardContent = false,
  buttonBackgroundColor,
  buttonTextColor,
  disabled,
  selected,
}) => {
  const intl = CustomIntl(useIntl());
  const i18nSponsorStaff = translation.sponsorStaff(intl);

  const styleBordered = {
    border: `2px solid ${theme.colors.primary.yellow}`,
    borderRadius: 5,
  };

  return (
    <StyledCard
      style={bordered ? styleBordered : null}
      showCardContent={showCardContent}
      disabled={disabled}
      selected={selected}
    >
      <StyledCardHeader>
        <AimTypography variant="h4" margin={0} textAlign="center">
          {title}
        </AimTypography>
        <AimTypography variant="h5" margin={0}>
          {subtitle}
        </AimTypography>
      </StyledCardHeader>
      <StyledDivider />
      {showCardContent && <StyledCardContent>{children}</StyledCardContent>}

      <StyledCardActions disableSpacing noCursorPointer={buyDisabled}>
        {onClickBuy ? (
          <Tooltip title={customTooltip ? customTooltip : ''}>
            <span>
              <AimIconAndTextButton
                variant="primary"
                text={i18n(intl).buttons.buy}
                onClick={onClickBuy}
                disabled={buyDisabled}
              >
                <ShoppingCartIcon />
              </AimIconAndTextButton>
            </span>
          </Tooltip>
        ) : null}
        {configurationVisible && (
          <Tooltip title={configurationTooltip}>
            <span>
              <AimIconAndTextButton
                noCursorPointer={buyDisabled}
                variant="secondary"
                text={
                  title === i18nSponsorStaff.page.title.label
                    ? i18n(intl).buttons.insertBadge
                    : i18n(intl).buttons.configure
                }
                onClick={onClickConfiguration}
                disabled={configurationDisabled}
              >
                <SettingsIcon />
              </AimIconAndTextButton>
            </span>
          </Tooltip>
        )}
        {buttonBackgroundColor ? (
          <StyledStarIconButton
            text={title}
            small
            aria-label="filter"
            buttonBackgroundColor={
              // buttonBackgroundColor
              lightenDarkenColor(buttonBackgroundColor, 10)
            }
            textColor={buttonTextColor}
            onClick={() => {}}
          >
            <StarIcon
              fill={
                lightenDarkenColor(buttonBackgroundColor, -25)
                // buttonBackgroundColor
                // buttonTextColor ? buttonTextColor : `${buttonBackgroundColor}`
              }
            />
          </StyledStarIconButton>
        ) : null}
      </StyledCardActions>
    </StyledCard>
  );
};

export default SponsorFrontServiceCard;

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';

import {
  AimTypography,
  AimIconAndTextButton,
  AimIconButton,
  CustomIntl,
  theme,
  // AimSnackbarSeverity,
  // styled,
} from '@aim/components';
// import { constants, appState, aws } from '@aim/common';

import { MainContainer, InnerContainer } from '../shared/Containers';
import { translation } from './translation';

// const showLoader = () => appState.isLoader.next(true);
// const hideLoader = () => appState.isLoader.next(false);

const AgencyPaymentOptions = () => {
  const history = useHistory();
  const { eventId, agencyId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.paymentOptions(intl);

  //Effects
  useEffect(() => {
    // fetchGateway();
  }, []);

  // states
  // const [gateway, setGateway] = useState();
  // const [snackbar, setSnackbar] = useState({ isOpen: false });

  // const fetchGateway = async () => {
  //   setGateway(gateway);
  // };

  const selectPaymentType = async (paymentType) => {
    // history.location.state.prepaidBudget
    history.push({
      pathname: `/events/${eventId}/agency/${agencyId}/payment`,
      state: {
        ...history.location.state,
        paymentType,
      },
    });
  };

  return (
    <MainContainer>
      <div>
        <InnerContainer>
          <Grid container>
            <Grid item xs={12}>
              <Tooltip title={i18n.page.back.tooltip}>
                <AimIconAndTextButton
                  variant="text"
                  text={i18n.page.back.button}
                  onClick={() => {
                    const nextState = {
                      ...history.location?.state,
                      bookings: history.location?.state.cartBookings,
                    };
                    if (history.location?.state?.backUrl) {
                      history.push({
                        pathname: history.location?.state?.backUrl,
                        state: nextState,
                      });
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <ArrowBack />
                </AimIconAndTextButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <AimTypography variant="h3Regular">
                {i18n.page.title}
              </AimTypography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: theme.colors.greyScale.backgroundGrey,
                  padding: '20px',
                  marginBottom: '10px',
                  alignItems: 'center',
                }}
              >
                <AimTypography variant="h4Regular">
                  {i18n.page.bankTransfer.label}
                </AimTypography>
                <AimIconButton
                  small
                  variant="primary"
                  onClick={() => selectPaymentType('bankTransfer')}
                >
                  <ArrowForward />
                </AimIconButton>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: theme.colors.greyScale.backgroundGrey,
                  padding: '20px',
                  alignItems: 'center',
                }}
              >
                <AimTypography variant="h4Regular">
                  {i18n.page.creditCard.label}
                </AimTypography>
                <AimIconButton
                  small
                  variant="primary"
                  onClick={() => selectPaymentType('creditCard')}
                >
                  <ArrowForward />
                </AimIconButton>
              </div>
            </Grid>
          </Grid>
        </InnerContainer>
      </div>
    </MainContainer>
  );
};

export default AgencyPaymentOptions;

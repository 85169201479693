import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
  AimRichText,
  AimTypography,
  AimCheckbox,
  StepDivider,
  Title,
} from '@aim/components';

const formControlStyle = { width: 'calc(100% - 10px)' };

const AgreementStep = ({ control, title, i18n, errors, abstractService }) => {
  const { agreement } = abstractService;

  return (
    <>
      <Title>{title}</Title>
      <StepDivider />
      <div style={formControlStyle}>
        <AimRichText
          readOnly={true}
          variant="white"
          value={JSON.parse(agreement)}
        />

        <div style={{ display: 'flex', paddingLeft: 15 }}>
          <Controller
            name="isAgreementChecked"
            control={control}
            //rules={{ validate: (value) => value === true || 'error message' }}
            render={(props) => (
              <AimCheckbox
                checked={props.value}
                onClick={(e) => props.onChange(e.target.checked)}
                label={i18n.wizard.labels.privacyAcceptance}
                labelPlacement="end"
              />
            )}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name="isAgreementChecked"
          render={() => (
            <AimTypography variant="formError">
              {i18n.wizard.errors.labels.isAgreementChecked}
            </AimTypography>
          )}
        />
      </div>
    </>
  );
};

export default AgreementStep;

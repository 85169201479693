import React, {
  useEffect,
  useState,
  // , useRef
} from 'react';
import { groupBy } from 'lodash';

import {
  format,
  parseISO,
  // , isAfter, isBefore
} from 'date-fns';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {
  AimTypography,
  AimGallery,
  CustomIntl,
  AimIconAndTextButton,
  AimSnackbarSeverity,
  AimSnackbar,
} from '@aim/components';
import {
  aws,
  constants,
  // appState,
  utilities,
} from '@aim/common';

import { useDataHelper } from './shared/dataHelper';

import {
  getAdditionalService,
  getProductsByClientIdServiceId,
} from './additionalService/gqlHelper';
import useI18n from './additionalService/i18n';
import { useWelcomePageModulesRedirects } from '../hooks/useWelcomePageModulesRedirects';

// import { productGqlHelper } from './shared/productGqlHelper';

const TYPOLOGIES = {
  SOCIAL: 'social',
  SCIENTIFIC: 'scientific',
};
const AdditionalService = () => {
  const { getServiceRedirectLinkEvent } = useWelcomePageModulesRedirects();
  const { eventId, additionalServiceId, type } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = useI18n(intl);
  const history = useHistory();
  // const productHelper = productGqlHelper();
  // const user = appState.user.getValue();

  const { decodeDbNumber } = useDataHelper();

  const [additionalService, setAdditionalService] = useState({ images: [] });
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [disableBuyTicket, setDisableBuyTicket] = useState(false);

  // useEffect(() => {
  //   if (eventId) {
  //     const fetchEventGateway = async () => {
  //       const nextGateway = await getEventGateway({
  //         id: eventId,
  //         serviceId: constants.GatewayServices()['SOCIAL_EVENT'].id,
  //       });
  //       gatewayRef.current = nextGateway;
  //     };
  //     fetchEventGateway();
  //   }
  // }, [eventId]);

  useEffect(() => {
    const fetchData = async () => {
      let service = await getAdditionalService(additionalServiceId);

      //rimappare immagini
      const s3Folder = `events/${eventId}/additional-services/${additionalServiceId}/gallery/`;
      const images =
        (await Promise.all(
          service?.images?.items.map(async (i) =>
            aws.Storage.get(`${s3Folder}${i.id}${i.extension}`)
          )
        )) || [];

      //recupero numero di servizi acquistati
      const res = await getProductsByClientIdServiceId(
        eventId,
        constants.AdditionalServicesServiceTypology[service.typology]
          ?.productServiceType
      );
      const groupedAdditionalServiceProducts = groupBy(
        res,
        (product) => product.productId
      );
      const availableSubscription = service.maxSubscription
        ? parseInt(service.maxSubscription, 10) -
          (groupedAdditionalServiceProducts?.[service.id]?.length || 0)
        : undefined;

      const maxPurchasableSubscriptions =
        (service.rule === constants.rules.YESNO.id ||
          service.rule === constants.rules.ONLYONE.id) &&
        availableSubscription < 1
          ? 0
          : availableSubscription || null;

      const {
        netPrice1,
        netPrice2,
        vatRate1,
        vatRate2,
      } = utilities.getAdditionalServiceBracketInfo(service);

      const amount1 = netPrice1 ? decodeDbNumber(netPrice1) : null;
      const amount2 = netPrice2 ? decodeDbNumber(netPrice2) : null;

      const vat1Percentage = vatRate1 ? decodeDbNumber(vatRate1) : null;
      const vat2Percentage = vatRate2 ? decodeDbNumber(vatRate2) : null;

      setAdditionalService({
        ...service,
        images,
        availableSubscription: maxPurchasableSubscriptions,
        amount1,
        amount2,
        // vat1,
        // vat2,
        vat1Percentage,
        vat2Percentage,
      });
    };

    fetchData();
    checkoutResponse();
  }, []);

  const checkoutResponse = () => {
    const isCheckoutSuccess = location.pathname.includes('checkout-success');
    const isCheckoutError = location.pathname.includes('checkout-error');
    const query = new URLSearchParams(location.search);
    const errorcode = query.get('errorcode');
    const order = query.get('merchantorderid');
    if (isCheckoutError && errorcode && errorcode > 0) {
      const messageError = '';
      switch (parseInt(errorcode)) {
        case 1: // query error in lambda
          messageError(`${i18n.checkout.errors.error1} ${order}`);
          break;
        case 2: // response error in lambda
          messageError(`${i18n.checkout.errors.error2} ${order}`);
          break;
        case 3: // error in lambda, init payment
          messageError(`${i18n.checkout.errors.error3} ${order}`);
          break;
        case 4: // duplicate order or other errors
          messageError(`${i18n.checkout.errors.error4} ${order}`);
          break;
        default:
          break;
      }
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: messageError,
      });
    } else if (isCheckoutSuccess) {
      setDisableBuyTicket(true);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.success,
        message: i18n.snackbar.buyTicketSuccess,
      });
      const link = getServiceRedirectLinkEvent(
        type === constants.AdditionalServicesServiceTypology.social.id
          ? 'SocialEvent'
          : 'ScientificEvent'
      );
      if (link) {
        history.push(link);
      } else {
        history.push(
          `/events/${eventId}/additional-services/${type}/${additionalServiceId}`
        );
      }
    }
  };

  const formattedDateTime = (date) => {
    if (date) return format(parseISO(date), 'dd MMMM yyyy HH:mm');
  };

  const formattedDate = (date) => {
    if (date) return format(parseISO(date), 'dd MMMM yyyy');
  };

  // const purchaseTicket = () => {
  //   let amountToPay = 0;
  //   if (additionalService?.amount1)
  //     amountToPay = parseInt(additionalService?.amount1, 10);
  //   if (additionalService?.amount2)
  //     amountToPay += parseInt(additionalService?.amount2, 10);

  //   if (!amountToPay) {
  //     setSnackbar({
  //       isOpen: true,
  //       severity: AimSnackbarSeverity.error,
  //       message: 'is not a valid number',
  //     });
  //     return;
  //   }

  //   if (gatewayRef.current === undefined) {
  //     setSnackbar({
  //       isOpen: true,
  //       severity: AimSnackbarSeverity.error,
  //       message: i18n.snackbar.gatewayNotExist,
  //     });
  //     return;
  //   }

  //   if (gatewayRef.current.paymentType === undefined) {
  //     setSnackbar({
  //       isOpen: true,
  //       severity: AimSnackbarSeverity.error,
  //       message: i18n.snackbar.paymentTypeNotExist,
  //     });
  //     return;
  //   }

  //   if (gatewayRef.current.paymentType === 'both') {
  //     history.push({
  //       pathname: `/events/${eventId}/additional-services/${type}/${additionalServiceId}/payment-opt`,
  //       state: {
  //         amount: amountToPay,
  //         gateway: gatewayRef.current,
  //         serviceId: additionalServiceId,
  //         serviceType: constants.ProductType.socialEvents,
  //         isService: true,
  //         isClient: true,
  //         clientId: user.userAndParticipation.participation.id,
  //         additionalService: additionalService,
  //       },
  //     });
  //   } else {
  //     history.push({
  //       pathname: `/events/${eventId}/additional-services/${type}/${additionalServiceId}/payment`,
  //       state: {
  //         amount: amountToPay,
  //         gateway: gatewayRef.current,
  //         serviceId: additionalServiceId,
  //         serviceType: constants.ProductType.socialEvents,
  //         isService: true,
  //         isClient: true,
  //         clientId: user.userAndParticipation.participation.id,
  //         additionalService: additionalService,
  //       },
  //     });
  //   }
  // };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingLeft: '15%',
          paddingRight: '15%',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tooltip title={i18n.page.backButton}>
            <AimIconAndTextButton
              isUpperCase
              variant="none"
              text={i18n.page.backButton}
              style={{
                padding: 0,
              }}
              onClick={() =>
                history.push(`/events/${eventId}/additional-services/${type}`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
          <Tooltip title={i18n.page.buyTicket}>
            <AimIconAndTextButton
              isUpperCase
              variant="yellow"
              text={i18n.page.buyTicket}
              // style={{
              //   marginTop: 10,
              //   padding: 0,
              // }}
              disabled={
                disableBuyTicket || additionalService?.availableSubscription < 1
              }
              onClick={() =>
                history.push(
                  `/events/${eventId}/additional-services/${type}/${additionalServiceId}/billing-info`
                )
              }
            >
              <ShoppingCartIcon />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <AimTypography variant="h1" style={{ width: '100%' }}>
          {additionalService?.title || ''}
        </AimTypography>
        <AimTypography variant="h4">
          {additionalService?.locationAddress || ''}
        </AimTypography>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AimTypography variant="h4Regular">
            {`${formattedDate(additionalService?.dateService) || ''} ${
              additionalService?.startTime || ''
            }`}
          </AimTypography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AimTypography variant="h4Regular">
            {additionalService?.description || ''}
          </AimTypography>
        </div>
        {!additionalService.isAvailabilityHidden && (
          <>
            {/* {additionalService?.minSubscription > 0 && (
              <AimTypography variant="h4">
                {`${i18n.page.minSubscription}: ${additionalService?.minSubscription}`}
              </AimTypography>
            )} */}
            {additionalService?.maxSubscription > 0 && (
              <AimTypography variant="h4">
                {`${i18n.page.maxSubscription}: ${additionalService?.maxSubscription}`}
              </AimTypography>
            )}
            {additionalService?.availableSubscription > 0 && (
              <AimTypography variant="h4">
                {`${i18n.page.availableSubscription}: ${additionalService?.availableSubscription}`}
              </AimTypography>
            )}
          </>
        )}
      </div>
      {additionalService?.images?.length > 0 && (
        <div
          // style={{
          //   display: 'flex',
          //   flexDirection: 'row',
          //   flex: 1,
          //   paddingLeft: '26px',
          //   justifyContent: 'flex-start',
          //   alignItems: 'center',
          // }}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            // minHeight: 400,
            paddingLeft: '15%',
            paddingRight: '15%',
            marginTop: '3%',
            marginBottom: '3%',
          }}
        >
          <AimGallery images={additionalService?.images} />
        </div>
      )}
      {additionalService?.typology === TYPOLOGIES.SCIENTIFIC &&
        additionalService?.additionalServiceSessions?.items?.length > 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: 30,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              // minHeight: 300,
              paddingLeft: '15%',
              paddingRight: '15%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                maxWidth: '90%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <AimTypography variant="h4Regular">
                  {additionalService?.additionalServiceSessions?.items.map(
                    (i) => (
                      <>
                        <AimTypography>
                          {i.agendaSession?.name || ''}
                        </AimTypography>
                        <AimTypography italic>
                          {`${
                            formattedDateTime(i.agendaSession?.start) || ''
                          } - ${formattedDateTime(i.agendaSession?.end) || ''}`}
                        </AimTypography>
                      </>
                    )
                  )}
                </AimTypography>
              </div>
            </div>
          </div>
        )}
      {(additionalService?.amount1 || additionalService?.amount2) && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            // minHeight: 300,
            paddingLeft: '15%',
            paddingRight: '15%',
            marginBottom: '3%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingTop: 13,
            }}
          >
            {additionalService?.vat1Percentage && additionalService?.amount1 && (
              <div>
                <div style={{ display: 'flex' }}>
                  <AimTypography variant="h4">{i18n.page.cost}</AimTypography>
                  <AimTypography variant="h4Regular">
                    {utilities.formatNumber(additionalService?.amount1 || '')}
                  </AimTypography>
                </div>
                <div style={{ display: 'flex' }}>
                  <AimTypography variant="h4">{i18n.page.vat}</AimTypography>
                  <AimTypography variant="h4Regular">
                    {/* {utilities.formatNumber(additionalService?.vat1)} */}
                    {`${additionalService.vat1Percentage || 0}%`}
                  </AimTypography>
                </div>
                <div style={{ display: 'flex' }}>
                  <AimTypography variant="h4">{i18n.page.total}</AimTypography>
                  <AimTypography variant="h4Regular">
                    {/* {utilities.formatNumber(additionalService?.vat1)} */}
                    {`${utilities.formatNumber(
                      (additionalService.amount1 / 100) *
                        additionalService.vat1Percentage +
                        additionalService.amount1
                    )}`}
                  </AimTypography>
                </div>
              </div>
            )}
            {additionalService?.amount2 && additionalService?.vat2Percentage && (
              <div style={{ marginTop: 50 }}>
                <div style={{ display: 'flex' }}>
                  <AimTypography variant="h4">{i18n.page.cost}</AimTypography>
                  <AimTypography variant="h4Regular">
                    {utilities.formatNumber(additionalService?.amount2)}
                  </AimTypography>
                </div>
                <div style={{ display: 'flex' }}>
                  <AimTypography variant="h4">{i18n.page.vat}</AimTypography>
                  <AimTypography variant="h4Regular">
                    {`${additionalService.vat2Percentage || 0}%`}
                  </AimTypography>
                </div>
                <div style={{ display: 'flex' }}>
                  <AimTypography variant="h4">{i18n.page.total}</AimTypography>
                  <AimTypography variant="h4Regular">
                    {/* {utilities.formatNumber(additionalService?.vat1)} */}
                    {`${utilities.formatNumber(
                      (additionalService.amount2 / 100) *
                        additionalService.vat2Percentage +
                        additionalService.amount2
                    )}`}
                  </AimTypography>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {additionalService?.subPolicyDescription && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            // minHeight: 400,
            paddingLeft: '15%',
            paddingRight: '15%',
            marginBottom: '5%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              maxWidth: '90%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <AimTypography variant="h4Regular">
                {additionalService?.subPolicyDescription || ''}
              </AimTypography>
            </div>
          </div>
        </div>
      )}
      <AimSnackbar
        open={snackbar.isOpen}
        onClose={() => setSnackbar({ isOpen: false })}
        severity={snackbar.severity}
      >
        {snackbar.message}
      </AimSnackbar>
    </>
  );
};

export default AdditionalService;

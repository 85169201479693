import { aws, appState } from '@aim/common';
const { API } = aws;
const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

import { customAlphabet } from 'nanoid';
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 8); // 1 id/hour --> 1% probability of collision in 27 years

const getParticipationInfoQuery = /* GraphQL */ `
  query ParticipationInfo($id: ID!) {
    getParticipation(id: $id) {
      id
      email
      givenName
      familyName
      phone
      profile {
        id
        name
      }
      guests {
        items {
          id
          givenName
          familyName
          country
        }
      }
    }
  }
`;

const getEventGatewaysQuery = /* GraphQL */ `
  query GetEvent($id: ID!, $serviceType: String) {
    getEvent(id: $id) {
      id
      gateways(filter: { service: { eq: $serviceType } }) {
        items {
          id
          service
          shop {
            id
            gatewayType
            paymentType
            iban
            ibanCausal
            addressedTo
          }
        }
      }
    }
  }
`;

const getProductsByClientIdServiceTypeServiceIdQuery = /* GraphQL */ `
  query getProductByClientIdServiceTypeServiceId(
    $clientId: String
    $serviceId: String
    $serviceType: String
  ) {
    productByClientIdServiceTypeServiceId(
      clientId: $clientId
      serviceTypeServiceId: {
        eq: { serviceId: $serviceId, serviceType: $serviceType }
      }
      filter: { isPublished: { eq: "true" } }
    ) {
      items {
        id
        productType
        productId
        clientId
        clientType
        serviceId
        serviceType
        createdAt
        guests {
          items {
            id
            givenName
            familyName
            country
          }
        }
        childs {
          items {
            id
            productId
            payment {
              id
              paymentId
            }
          }
        }
        payment {
          id
          paymentId
          paymentType
          paymentIdMonetaOnLine
          paymentIdIngenico
          paymentIdGpWebpay
          paymentError
          amount
          paymentStatus
          BillingInformation {
            id
            invoiceTo
            name
            address
            postalCode
            city
            region
            province
            country
            countryIsoCode
            vatCode
            taxCode
            reference
            email
            pec
            phone
            invoiceToPublicAuthority
            uniqueCode
            ipaCode
            isSplitPayment
            isVatEvent
          }
        }
        billing {
          id
          invoiceTo
          name
          address
          postalCode
          city
          region
          province
          country
          countryIsoCode
          country
          vatCode
          taxCode
          reference
          email
          pec
          phone
          invoiceToPublicAuthority
          uniqueCode
          ipaCode
          isSplitPayment
          isVatEvent
        }
        showPrice
      }
    }
  }
`;

const getProfileFeeBracketQuery = /* GraphQL */ `
  query getProfileFeeBracketInfo($id: ID!) {
    getProfileFeeBracket(id: $id) {
      id
      profile {
        id
        name
      }
      feeBracket {
        feeDateRange {
          start
          label
          end
        }
      }
    }
  }
`;

export const getProducts = (clientId, serviceId, serviceType) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getProductsByClientIdServiceTypeServiceIdQuery,
      variables: {
        clientId,
        serviceId,
        serviceType,
      },
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        console.error('get-products-my-tickets', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getParticipationInfo = (participationId) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getParticipationInfoQuery,
      variables: { id: participationId },
    })
      .then((response) =>
        resolve({
          ...response?.data?.getParticipation,
        })
      )
      .catch((e) => {
        console.error('get-participation-info-my-ticket', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getEventGateway = (input) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getEventGatewaysQuery,
      variables: input,
    })
      .then((response) => {
        const { data } = response;
        resolve(data.getEvent.gateways.items[0]);
      })
      .catch((e) => {
        console.error('event-gateways', e);
        reject();
      })
      .finally(hideLoader);
  });

export const getProfileFeeBracket = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: getProfileFeeBracketQuery,
      variables: {
        id,
      },
    })
      .then((response) => resolve(response.data?.getProfileFeeBracket))
      .catch((e) => {
        console.error('get-profile-fee-bracket- my-ticket', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

//

const createProductMutation = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

const updateProductMutation = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

const createBillingInformationMutation = /* GraphQL */ `
  mutation createBillingInformation(
    $input: CreateBillingInformationInput!
    $condition: ModelBillingInformationConditionInput
  ) {
    createBillingInformation(input: $input, condition: $condition) {
      id
    }
  }
`;

const createGuestMutation = /* GraphQL */ `
  mutation createGuest(
    $input: CreateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    createGuest(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEventCodeQuery = /* GraphQL */ `
  query getEvent($id: ID!) {
    getEvent(id: $id) {
      id
      code
    }
  }
`;

export const createProduct = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createProductMutation,
      variables: {
        input: { ...input, ...(!input.productId && { productId: nanoid() }) },
      },
    })
      .then((response) => resolve(response.data.createProduct))
      .catch((e) => {
        console.error('create-product', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateProduct = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateProductMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateProduct))
      .catch((e) => {
        console.error('update-product', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createBillingInformation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createBillingInformationMutation,
      variables: {
        input: { ...input },
      },
    })
      .then((response) => resolve(response.data.createBillingInformation))
      .catch((e) => {
        console.error('create-billing-information', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createGuest = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createGuestMutation,
      variables: {
        input: { ...input },
      },
    })
      .then((response) => resolve(response.data.createGuest))
      .catch((e) => {
        console.error('create-guest', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const getEventCode = (eventId, useLoader = true) =>
  new Promise((resolve, reject) => {
    showLoader();

    API.graphql({
      query: getEventCodeQuery,
      variables: { id: eventId },
    })
      .then((response) => {
        return resolve({
          ...response.data.getEvent,
        });
      })
      .catch((e) => {
        console.error('get-event-code', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createPaymentMutation = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updatePaymentMutation = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

const deletePaymentMutation = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      end
      id
    }
  }
`;

const updateParticipationQuery = /* GraphQL */ `
  mutation UpdateParticipation(
    $input: UpdateParticipationInput!
    $condition: ModelParticipationConditionInput
  ) {
    updateParticipation(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getEndDateOfEvent = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: getEvent,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-event-end', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updateParticipation = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updateParticipationQuery,
      variables: { input },
    })
      .then((response) => resolve(response.data.updateParticipationQuery))
      .catch((e) => {
        console.error('update-particpation', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const createPayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: createPaymentMutation,
      variables: { input }, //TODO set paymentId with the one from effective payment
    })
      .then((response) => resolve(response.data.createPayment))
      .catch((e) => {
        console.error('create-payment', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const updatePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: updatePaymentMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.updatePayment))
      .catch((e) => {
        console.error('update-payment-components-paymentcart', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

export const removePayment = (input, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();

    API.graphql({
      query: deletePaymentMutation,
      variables: { input },
    })
      .then((response) => resolve(response.data.deletePayment))
      .catch((e) => {
        console.error('delete-payment', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });

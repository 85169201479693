export const translation = {
  sponsorListDetails: (intl) => ({
    aimTablePage: {
      header: {
        backButton: intl.formatMessage({
          description: 'Services Configuration label',
          defaultMessage: 'Services Configuration',
        }),
        action: {
          exportExcel: {
            tooltip: intl.formatMessage({
              description: 'export selected tooltip',
              defaultMessage: 'Export',
            }),
          },
          import: {
            tooltip: intl.formatMessage({
              description: 'massive import',
              defaultMessage: 'Massive import',
            }),
          },
          deleteAll: {
            tooltip: intl.formatMessage({
              description: 'delete selected tooltip',
              defaultMessage: 'Delete selected',
            }),
          },
          assignAgency: {
            label: intl.formatMessage({
              description: 'assign an agency label',
              defaultMessage: 'Assign an agency',
            }),
            tooltip: intl.formatMessage({
              description: 'assign an agency tooltip',
              defaultMessage: 'Assign an agency',
            }),
          },
          add: {
            tooltip: intl.formatMessage({
              description: 'add member tooltip',
              defaultMessage: 'Add',
            }),
          },
          pay: {
            label: intl.formatMessage({
              description: 'pay amount due',
              defaultMessage: 'PAY AMOUNT DUE',
            }),
          },
          close: {
            label: intl.formatMessage({
              description: 'close button',
              defaultMessage: 'CLOSE GROUP',
            }),
          },
          payApprovedChanges: {
            label: intl.formatMessage({
              description: 'pay approved changes button',
              defaultMessage: 'PAY APPROVED CHANGES',
            }),
          },
        },
      },
      stats: {
        participationsAvailable: intl.formatMessage({
          description: 'participations to configure',
          defaultMessage: 'Participations to configure',
        }),
        freeParticipations: intl.formatMessage({
          description: 'free participations',
          defaultMessage: 'Free participations',
        }),
        amountDue: intl.formatMessage({
          description: 'amount due',
          defaultMessage: 'Amount due',
        }),
        status: intl.formatMessage({
          description: 'status label',
          defaultMessage: 'Status',
        }),
        closingDate: intl.formatMessage({
          description: 'closing group date label',
          defaultMessage: 'Closing date',
        }),
        postCloseChangeDeadline: intl.formatMessage({
          description: 'post closing paid changes until',
          defaultMessage: 'Post-closing paid changes until',
        }),
        modificationRequests: intl.formatMessage({
          description: 'pending modification requests',
          defaultMessage: 'Pending modification requests',
        }),
        requestsAccepted: intl.formatMessage({
          description: 'requests accepted',
          defaultMessage: 'Requests accepted',
        }),
      },
      table: {
        empty: intl.formatMessage({
          description: 'Table empty',
          defaultMessage: 'No data',
        }),
        columns: {
          username: intl.formatMessage({
            description: 'username',
            defaultMessage: 'ID',
          }),
          givenName: intl.formatMessage({
            description: 'given name',
            defaultMessage: 'Name',
          }),
          familyName: intl.formatMessage({
            description: 'family name',
            defaultMessage: 'Surname',
          }),
          email: intl.formatMessage({
            description: 'member email',
            defaultMessage: 'Email',
          }),
          modificationRequestNameChangeUsername: intl.formatMessage({
            description: 'name change',
            defaultMessage: 'Name change',
          }),
          profile: intl.formatMessage({
            description: 'member profile',
            defaultMessage: 'Profile',
          }),
          createdAt: intl.formatMessage({
            description: 'member is created at',
            defaultMessage: 'Date added',
          }),
          modification: intl.formatMessage({
            description: 'modification',
            defaultMessage: 'Modification',
          }),
          notes: intl.formatMessage({
            description: 'notes',
            defaultMessage: 'Notes',
          }),
          phone: intl.formatMessage({
            description: 'phone',
            defaultMessage: 'Phone',
          }),
        },
        rowActionText: intl.formatMessage({
          description: 'under examination',
          defaultMessage: 'Under Examination',
        }),
        rowAction: {
          edit: intl.formatMessage({
            description: 'edit tooltip',
            defaultMessage: 'Edit',
          }),
          delete: intl.formatMessage({
            description: 'delete tooltip',
            defaultMessage: 'Delete',
          }),
        },
      },
      dialog: {
        delete: {
          title: intl.formatMessage({
            description: 'Delete Participants title',
            defaultMessage: 'Delete Participants',
          }),
          message: intl.formatMessage({
            description: 'Dialog delete message',
            defaultMessage: 'Are you sure?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Delete',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
        deleteAll: {
          title: intl.formatMessage({
            description: 'Delete All Participants title',
            defaultMessage: 'Delete Participants',
          }),
          message: intl.formatMessage({
            description: 'Dialog delete message',
            defaultMessage: 'Do you want delete all selected participants?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Delete',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
        buyTickets: {
          title: intl.formatMessage({
            description: 'buy list participations label',
            defaultMessage: 'Buy additional list participations',
          }),
          message: intl.formatMessage({
            description: 'Dialog add message',
            defaultMessage:
              'If you want to add new list members, you have to purchase the corresponding fee. Choose the number of members you would like to engage.',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog buy onAgreeText',
            defaultMessage: 'Buy',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog buy onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
        close: {
          title: intl.formatMessage({
            description: 'Confirm Sponsored Participations close title',
            defaultMessage: 'Confirm closing group',
          }),
          message: intl.formatMessage({
            description: 'Dialog close message',
            defaultMessage: 'Are you sure you want to close group?',
          }),
          agreeText: intl.formatMessage({
            description: 'Dialog delete onAgreeText',
            defaultMessage: 'Close',
          }),
          disagreeText: intl.formatMessage({
            description: 'Dialog delete onDisagreeText',
            defaultMessage: 'Cancel',
          }),
        },
      },
      snackbar: {
        delete: {
          success: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Delete ok',
          }),
          error: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Some error',
          }),
        },
        close: {
          success: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage: 'Sponsored Participation group closed',
          }),
          error: intl.formatMessage({
            description: 'Snackbar delete message',
            defaultMessage:
              'Is not possible to close Sponsored Participation Group',
          }),
        },
      },
    },
    page: {
      assignAgencyDialog: {
        title: intl.formatMessage({
          description: 'assign agency dialog title',
          defaultMessage: 'Assign an agency',
        }),
        message: intl.formatMessage({
          description: 'assign agency dialog message',
          defaultMessage:
            'To transfer the management of your group to an agency, enter the name and email of the agency below.',
        }),
        agencyEmail: intl.formatMessage({
          description: 'agency email label',
          defaultMessage: 'Agency email',
        }),
      },
      snackbar: {
        participationsWithNoTickets: intl.formatMessage({
          description:
            'sponsor list close error all participations must have ticket, participation mode and profile',
          defaultMessage:
            'All participations must have ticket, participation mode and profile',
        }),
        missingTicketsForCobmination: intl.formatMessage({
          description:
            'sponsor list close error all there are not sufficient quotas for:',
          defaultMessage: 'there are not sufficient tickets for:',
        }),
        missing: intl.formatMessage({
          description: 'sponsor list close error all missing',
          defaultMessage: 'missing',
        }),
      },
    },
  }),
};

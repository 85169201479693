export const translation = {
  faculty: (intl) => ({
    buttons: {
      back: intl.formatMessage({
        description: 'faculty / back btn',
        defaultMessage: 'Back',
      }),
      purchaseServices: intl.formatMessage({
        description: 'faculty / purchase services btn',
        defaultMessage: 'purchase services',
      }),

      saveAndSend: intl.formatMessage({
        description: 'faculty / save and send button',
        defaultMessage: 'Save & send',
      }),
      saveDraft: intl.formatMessage({
        description: 'faculty / save draft button',
        defaultMessage: 'Save draft',
      }),
    },
    title: intl.formatMessage({
      description: 'faculty / title faculty ',
      defaultMessage: 'Confirm your participation to the event',
    }),
    cards: {
      allotment: intl.formatMessage({
        description: 'faculty / Allotment title',
        defaultMessage: 'ALLOTMENT',
      }),
      additionalServices: intl.formatMessage({
        description: 'faculty / Additional Services title',
        defaultMessage: 'ADDITIONAL SERVICES',
      }),
      travelTransfer: intl.formatMessage({
        description: 'faculty / TRAVEL / TRANSFER title',
        defaultMessage: 'TRAVEL / TRANSFER',
      }),
      travel: intl.formatMessage({
        description: 'faculty / TRAVEL title',
        defaultMessage: 'TRAVEL',
      }),
      transfer: intl.formatMessage({
        description: 'faculty / TRANSFER title',
        defaultMessage: 'TRANSFER',
      }),
    },
    sections: {
      participationDetails: {
        title: intl.formatMessage({
          description: 'faculty / services and preferences section title',
          defaultMessage: 'Details of your participation',
        }),
        subtitle: intl.formatMessage({
          description: 'faculty / services and preferences section subtitle ',
          defaultMessage:
            'Select the available options below to manage your participation',
        }),
      },
      servicesAndPreferences: {
        title: intl.formatMessage({
          description: 'faculty / services and preferences section title',
          defaultMessage:
            'List of included services and preferences management',
        }),
        subtitle: intl.formatMessage({
          description: 'faculty / services and preferences section subtitle ',
          defaultMessage:
            'Select the available options below to manage your preferences',
        }),
      },
      myContributions: {
        title: intl.formatMessage({
          description: 'faculty / my contributions title',
          defaultMessage: 'MY CONTRIBUTIONS',
        }),
      },
      agenda: {
        title: intl.formatMessage({
          description: 'faculty / agenda title',
          defaultMessage: 'Agenda',
        }),
      },
      participation: {
        title: intl.formatMessage({
          description: 'faculty / participation title',
          defaultMessage: 'PARTICIPATION',
        }),
      },
    },
    upload: {
      uploadArea: intl.formatMessage({
        description: 'select the content to upload',
        defaultMessage: 'Select the contents to upload',
      }),
      dragAndDrop: {
        title: intl.formatMessage({
          description: 'accreditation material drag & drop your files',
          defaultMessage: 'drag & drop your files',
        }),
        subTitle: intl.formatMessage({
          description: 'accreditation material drag and drop subtitle',
          defaultMessage: 'or',
        }),
        clickText: intl.formatMessage({
          description: 'accreditation material drag and drop click text',
          defaultMessage: 'select from your pc',
        }),
        fileLimitText: intl.formatMessage({
          description: 'drag and drop file limit text',
          defaultMessage: 'Limit of file reached',
        }),
      },
    },
    downloadArea: {
      title: intl.formatMessage({
        description: 'download',
        defaultMessage: 'Download area',
      }),
      downloadMembersInfo: intl.formatMessage({
        description: 'faculty members info',
        defaultMessage:
          'Faculty members will be able to download these files in the faculty page',
      }),
      table: {
        title: intl.formatMessage({
          description: 'download',
          defaultMessage: 'Download',
        }),
        noData: intl.formatMessage({
          description: 'there are no files',
          defaultMessage: 'There are no files',
        }),
        columns: {
          name: {
            label: intl.formatMessage({
              description: 'name',
              defaultMessage: 'Name',
            }),
          },
          type: {
            label: intl.formatMessage({
              description: 'type',
              defaultMessage: 'Type',
            }),
          },
        },
      },
    },
    dialogs: {
      additionalServices: {
        title: intl.formatMessage({
          description: 'faculty / additional services dialog title',
          defaultMessage: 'Additional Services',
        }),
        sectionTitles: {
          detailsCommunication: intl.formatMessage({
            description: 'faculty / details communication section title',
            defaultMessage: 'Events',
          }),
          selectServices: intl.formatMessage({
            description: 'faculty / select services section title',
            defaultMessage: 'Select the services you wish to participate in',
          }),
          notes: intl.formatMessage({
            description: 'faculty / speaker notes section title',
            defaultMessage:
              'Please enter yout notes for any additional provisions in the block below',
          }),
        },
        placeholders: {
          notes: intl.formatMessage({
            description: 'faculty / Speaker notes textfield placeholder',
            defaultMessage: 'Insert notes...',
          }),
        },
        cards: {
          willParticipate: intl.formatMessage({
            description: 'faculty / I will participate label',
            defaultMessage: 'I will participate',
          }),
        },
        buttons: {
          cancel: intl.formatMessage({
            description: 'faculty / additional services dialog cancel button',
            defaultMessage: 'Cancel',
          }),
          saveDraft: intl.formatMessage({
            description:
              'faculty / additional services dialog save draft button',
            defaultMessage: 'Save Draft',
          }),
          confirm: intl.formatMessage({
            description: 'faculty / additional services dialog confirm button',
            defaultMessage: 'Confirm',
          }),
          saveAndSend: intl.formatMessage({
            description:
              'faculty / additional services dialog save and send button',
            defaultMessage: 'Save and Send',
          }),
        },
      },
      accreditationMaterial: {
        title: intl.formatMessage({
          description: 'faculty / Accreditation material dialog title',
          defaultMessage: 'Accreditation material',
        }),
        sectionTitles: {
          fiscalCode: intl.formatMessage({
            description: 'fiscal code',
            defaultMessage: 'Fiscal code',
          }),
          accreditationMaterialCV: intl.formatMessage({
            description: 'faculty / accreditation material cv section title',
            defaultMessage: 'Upload your CV in European format below',
          }),
          accreditationMaterialCVFormatAccepted: intl.formatMessage({
            description:
              'faculty / accreditation material cv format accepted text',
            defaultMessage: 'format accepted: .pdf, .doc, .docx, max 200kb',
          }),
          accreditationMaterialCOI: intl.formatMessage({
            description: 'faculty / accreditation material coi section title',
            defaultMessage:
              'Please upload below the Contrast Of Interest document',
          }),
          accreditationMaterialCOIFormatAccepted: intl.formatMessage({
            description:
              'faculty / accreditation material coi format accepted text',
            defaultMessage: 'format accepted: .pdf, .doc, .docx, max 200kb',
          }),
          accreditationMaterialSurvey: intl.formatMessage({
            description:
              'faculty / accreditation material survey section title',
            defaultMessage:
              'Upload the document with the questions for the ECM course questionnaire below',
          }),
          accreditationMaterialSurveyFormatAccepted: intl.formatMessage({
            description:
              'faculty / accreditation material survey format accepted text',
            defaultMessage: 'format accepted: .pdf, .doc, .docx, max 200kb',
          }),
          aifaCV: intl.formatMessage({
            description: 'faculty / aifa cv section title',
            defaultMessage: 'Upload your CV in European format below',
          }),
          aifaDFF: intl.formatMessage({
            description: 'faculty / aifa dff section title',
            defaultMessage:
              'Please upload below the Declaration of Financing Sources document',
          }),
          aifaCOI: intl.formatMessage({
            description: 'faculty / aifa coi section title',
            defaultMessage:
              'Please upload below the Contrast Of Interest document',
          }),
          aifaSurvey: intl.formatMessage({
            description: 'faculty / aifa survey section title',
            defaultMessage:
              'Upload the document with the questions for the ECM course questionnaire below',
          }),
        },
        dragAndDrop: {
          title: intl.formatMessage({
            description: 'faculty / drag & drop your files',
            defaultMessage: 'drag & drop your files',
          }),
          subTitle: intl.formatMessage({
            description: 'faculty / drag and drop subtitle',
            defaultMessage: 'or',
          }),
          clickText: intl.formatMessage({
            description: 'faculty / drag and drop click text',
            defaultMessage: 'select from your pc',
          }),
        },
        buttons: {
          cancel: intl.formatMessage({
            description:
              'faculty / accreditation material dialog cancel button',
            defaultMessage: 'Cancel',
          }),
          saveDraft: intl.formatMessage({
            description:
              'faculty / accreditation material dialog save draft button',
            defaultMessage: 'Save Draft',
          }),
          save: intl.formatMessage({
            description: 'faculty / accreditation material dialog save button',
            defaultMessage: 'Save',
          }),
          confirm: intl.formatMessage({
            description:
              'faculty / accreditation material dialog confirm button',
            defaultMessage: 'Confirm',
          }),
        },
      },
      aifaData: {
        title: intl.formatMessage({
          description: 'faculty / Aifa data dialog title',
          defaultMessage: 'AIFA data',
        }),
        subTitle: intl.formatMessage({
          description: 'faculty / Check your professional data',
          defaultMessage: 'Check your professional data',
        }),
        qualification: intl.formatMessage({
          description: 'faculty /  qualification',
          defaultMessage: 'qualification',
        }),
        degree: intl.formatMessage({
          description: 'faculty / degree',
          defaultMessage: 'Degree',
        }),
        specialization: intl.formatMessage({
          description: 'faculty / specialization',
          defaultMessage: 'Specialization',
        }),
        buttons: {
          cancel: intl.formatMessage({
            description: 'faculty / aifa dialog cancel button',
            defaultMessage: 'Cancel',
          }),
          saveDraft: intl.formatMessage({
            description: 'faculty / aifa dialog save draft button',
            defaultMessage: 'Save Draft',
          }),
          save: intl.formatMessage({
            description: 'faculty / aifa dialog save button',
            defaultMessage: 'Save',
          }),
          confirm: intl.formatMessage({
            description: 'faculty / aifa dialog confirm button',
            defaultMessage: 'Confirm',
          }),
        },
      },
      travelAndTransfer: {
        title: intl.formatMessage({
          description: 'faculty / travel and transfer dialog title',
          defaultMessage: 'Travel and transfer',
        }),
      },
      allotment: {
        title: intl.formatMessage({
          description: 'faculty / allotment dialog title',
          defaultMessage: 'Allotment',
        }),
      },
      scientificCommitment: {
        title: intl.formatMessage({
          description: 'faculty / scientific commitment dialog title',
          defaultMessage: 'Scientific commitment',
        }),
        sectionTitles: {
          report: intl.formatMessage({
            description: 'faculty / report title',
            defaultMessage:
              '1. Please upload the text document of the Report on your scientific commitment below',
          }),
          presentation: intl.formatMessage({
            description: 'faculty / presentation title',
            defaultMessage:
              '2. Please upload the presentation document of your scientific commitment below',
          }),
        },
        dragAndDrop: {
          title: intl.formatMessage({
            description: 'faculty / drag & drop your files',
            defaultMessage: 'drag & drop your files',
          }),
          subTitle: intl.formatMessage({
            description: 'faculty / drag and drop subtitle',
            defaultMessage: 'or',
          }),
          clickText: intl.formatMessage({
            description: 'faculty / drag and drop click text',
            defaultMessage: 'select from your pc',
          }),
        },
        buttons: {
          cancel: intl.formatMessage({
            description: 'faculty / scientific commitment dialog cancel button',
            defaultMessage: 'Cancel',
          }),
          saveAndSend: intl.formatMessage({
            description: 'faculty / scientific commitment dialog save button',
            defaultMessage: 'Save and Send',
          }),
          confirm: intl.formatMessage({
            description:
              'faculty / scientific commitment dialog confirm button',
            defaultMessage: 'Confirm',
          }),
        },
      },
    },
    pax: {
      subTitle1: intl.formatMessage({
        description:
          'We are pleased to inform you that AIM guarantees your participation to the Event.',
        defaultMessage:
          'We are pleased to inform you that AIM guarantees your participation to the Event.',
      }),
      subTitle2: intl.formatMessage({
        description: 'faculty /  We kindly ask you to confirm your presence',
        defaultMessage: 'We kindly ask you to confirm your presence',
      }),
      confirmPax: intl.formatMessage({
        description:
          'I confirm my participation and scientific commitment for the dates of the event',
        defaultMessage:
          'I confirm my participation and scientific commitment for the dates of the event',
      }),
      notConfirmPax: intl.formatMessage({
        description: 'faculty / I will not participate',
        defaultMessage: 'I will not participate',
      }),
      paxOnSite: intl.formatMessage({
        description: 'faculty / participation on-site',
        defaultMessage: 'Participation on-site',
      }),
      yes: intl.formatMessage({
        description: 'faculty / yes',
        defaultMessage: 'Yes',
      }),
      no: intl.formatMessage({
        description: 'faculty / no',
        defaultMessage: 'No',
      }),
      notes: intl.formatMessage({
        description: 'faculty / your notes for any different arrangments',
        defaultMessage:
          'Please enter your notes in the block below for any different arrangments:',
      }),
      buttons: {
        cancel: intl.formatMessage({
          description: 'faculty / pax cancel button',
          defaultMessage: 'Cancel',
        }),
        saveAndSend: intl.formatMessage({
          description: 'faculty / pax save and send button',
          defaultMessage: 'Save and Send',
        }),
        save: intl.formatMessage({
          description: 'faculty / pax save button',
          defaultMessage: 'Save',
        }),
      },
    },
    snackbar: {
      success: intl.formatMessage({
        description: 'faculty / save success snackbar message',
        defaultMessage: 'Saved succesfully',
      }),
      errors: {
        saveDraft: intl.formatMessage({
          description: 'faculty / error save draft',
          defaultMessage: 'Error save draft',
        }),
        saveAndSend: intl.formatMessage({
          description: 'faculty / error save and send',
          defaultMessage: 'Error save and send',
        }),
        accreditationMaterial: intl.formatMessage({
          description: 'faculty / accreditation material generic error',
          defaultMessage: 'Error while uploading files.',
        }),
        aifaData: intl.formatMessage({
          description: 'faculty / aifa data generic error',
          defaultMessage: 'Error! ',
        }),
        scientificCommitment: intl.formatMessage({
          description: 'faculty / scientific commitment error',
          defaultMessage: 'Error while uploading files.',
        }),
        additionalServices: intl.formatMessage({
          description: 'faculty / additional services generic error',
          defaultMessage: 'Error saving',
        }),
        travelTransfer: intl.formatMessage({
          description: 'faculty / travel transfer generic error',
          defaultMessage: 'Error saving',
        }),
        allotment: intl.formatMessage({
          description: 'faculty / allotment generic error',
          defaultMessage: 'Error saving',
        }),
      },
    },
    page: {
      table: {
        excel: intl.formatMessage({
          description: 'excel label',
          defaultMessage: 'Excel',
        }),
        word: intl.formatMessage({
          description: 'word label',
          defaultMessage: 'Word',
        }),
        pdf: intl.formatMessage({
          description: 'pdf label',
          defaultMessage: 'Pdf',
        }),
        image: intl.formatMessage({
          description: 'image label',
          defaultMessage: 'Image',
        }),
        other: intl.formatMessage({
          description: 'other label',
          defaultMessage: 'Other',
        }),
        accreditationMaterial: intl.formatMessage({
          description: 'accreditation material',
          defaultMessage: 'Accreditation Material',
        }),
        participationFile: intl.formatMessage({
          description: 'participation file',
          defaultMessage: 'Participation file',
        }),
        generic: intl.formatMessage({
          description: 'generic',
          defaultMessage: 'Generic',
        }),
      },
    },
    info: {
      noInfo: intl.formatMessage({
        description: 'no information entered',
        defaultMessage: 'No information entered',
      }),
      insert: intl.formatMessage({
        description: 'insert',
        defaultMessage: 'insert',
      }),
      bookByAuth: intl.formatMessage({
        description:
          'I authorise AIM Travel to proceed with the booking of my Services',
        defaultMessage:
          'I authorise AIM Travel to proceed with the booking of my Services',
      }),
      bookByAgency: intl.formatMessage({
        description: 'I use an agency to book the Services',
        defaultMessage: 'I use an agency to book the Services',
      }),
      bookByMyself: intl.formatMessage({
        description:
          'I prefer to make my own booking and purchase and request a refund afterwards',
        defaultMessage:
          'I prefer to make my own booking and purchase and request a refund afterwards',
      }),

      travel: {
        title: intl.formatMessage({
          description: 'travel transfer preferences',
          defaultMessage: 'TRAVEL & TRANSFER PREFERENCES',
        }),
        subTitle1: intl.formatMessage({
          description: 'included Travel preferences',
          defaultMessage: 'Included Travel preferences',
        }),
        subTitle2: intl.formatMessage({
          description: 'delegation to AIM Travel',
          defaultMessage: 'Delegation to AIM Travel',
        }),
        oneWay: intl.formatMessage({
          description: 'travel one Way',
          defaultMessage: 'Travel one way',
        }),
        return: intl.formatMessage({
          description: 'travel return',
          defaultMessage: 'Travel return',
        }),
        travelDate: intl.formatMessage({
          description: 'Date',
          defaultMessage: 'Date',
        }),
        travelBy: intl.formatMessage({
          description: 'travel by',
          defaultMessage: 'Travel by',
        }),
        flightTrainN: intl.formatMessage({
          description: 'flight / train number',
          defaultMessage: 'Flight/Train n°',
        }),
        leavingFrom: intl.formatMessage({
          description: 'Leaving from',
          defaultMessage: 'Leaving from',
        }),
        at: intl.formatMessage({
          description: 'at',
          defaultMessage: 'At (hrs)',
        }),
        intermediateAirport: intl.formatMessage({
          description: 'intermediate airport',
          defaultMessage: 'Intermediate airport',
        }),
        arrivingIn: intl.formatMessage({
          description: 'arriving in',
          defaultMessage: 'Arriving in',
        }),
        returnDate: intl.formatMessage({
          description: 'Pick Up Date',
          defaultMessage: 'Pick Up Date',
        }),
        airplaneFidelityCard: intl.formatMessage({
          description: 'airplane fidelity fard (e.g. Mille Miglia)',
          defaultMessage: 'Airplane Fidelity Card (e.g. Mille Miglia)',
        }),
        trainFidelityCard: intl.formatMessage({
          description: 'train fidelity fard (e.g. Carta Freccia)',
          defaultMessage: 'Train Fidelity Card (e.g. Carta Freccia)',
        }),
        telephoneNumber: intl.formatMessage({
          description: 'telephone number',
          defaultMessage: 'Telephone number',
        }),
        notes: intl.formatMessage({
          description: 'notes',
          defaultMessage: 'Notes',
        }),
        consentForTheCheckedBaggage: intl.formatMessage({
          description: 'consent for the checked baggage',
          defaultMessage: 'Consent for the checked baggage',
        }),
        issueTickets: intl.formatMessage({
          description: 'issue tickets according to the indications provided',
          defaultMessage: 'Issue tickets according to the indications provided',
        }),
        proposal: intl.formatMessage({
          description: 'send me proposal accoding to the specifications',
          defaultMessage: 'Send me proposal accoding to the specifications',
        }),
      },
    },
  }),
};

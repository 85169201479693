import { aws, appState } from '@aim/common';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const getSessionsFromEvent = /* GraphQL */ `
  query getSessions($eventId: ID!) {
    getEvent(id: $eventId) {
      id
      agenda {
        id
        sessions {
          items {
            id
            webinarModerator {
              id
              participation {
                id
                givenName
                familyName
              }
            }
            attendeeRoles {
              items {
                id
                role {
                  id
                  frontofficeName
                  showInProgram
                }
                attendees {
                  items {
                    id
                    participation {
                      id
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
            subSessions {
              items {
                id
                start
                end
                attendeeRoles {
                  items {
                    id
                    role {
                      id
                      frontofficeName
                      showInProgram
                    }
                    attendees {
                      items {
                        id
                        participation {
                          id
                          givenName
                          familyName
                        }
                      }
                    }
                  }
                }
                speeches {
                  items {
                    instructions
                    id
                    start
                    end
                    title
                    type
                    titleDescription
                    attendeeRoles {
                      items {
                        id
                        role {
                          id
                          name
                          frontofficeName
                          showInProgram
                        }
                        attendees {
                          items {
                            id
                            participation {
                              id
                              givenName
                              familyName
                              isFaculty
                              isSpeaker
                              userShowcase {
                                profileImage {
                                  id
                                  extension
                                  originalName
                                  size
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    abstract {
                      id
                      title
                      status
                      presenter {
                        participant {
                          id
                          givenName
                          familyName
                          userShowcase {
                            profileImage {
                              id
                              extension
                              originalName
                              size
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            name
            date
            start
            end
            linkPax
            room {
              id
              name
            }
            description
            shortDescription
            coverImage {
              id
              originalName
              size
              extension
            }
          }
        }
      }
    }
  }
`;

export const getSessions = (eventId) =>
  new Promise((resolve, reject) => {
    showLoader();
    aws.API.graphql({
      query: getSessionsFromEvent,
      variables: { eventId },
    })
      .then((response) => {
        resolve(response.data.getEvent.agenda.sessions);
      })
      .catch((e) => {
        console.error('get-sessions-per-event', e);
        reject();
      })
      .finally(hideLoader());
  });

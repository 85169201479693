import React from 'react';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { AimTypography } from '@aim/components';

const AgencyCheckoutSummaryRow = ({ title, label }) => {
  return (
    <>
      <Grid
        container
        // style={{ paddingLeft: 35, paddingRight: 35, paddingTop: 10 }}
      >
        <Grid item xs={7} sm={8} md={8} lg={9}></Grid>
        <Grid item xs={4} sm={3} md={3} lg={2}>
          <AimTypography variant={'h5'} boxStyle={{ fontWeight: 'bold' }}>
            {title}
          </AimTypography>
        </Grid>
        <Grid item xs={1}>
          <AimTypography variant={'h4Regular'} textAlign="right">
            {Number(label || 0).toFixed(2)}
          </AimTypography>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid item xs={6} sm={7} md={7} lg={8}></Grid>
        <Grid item xs={5} sm={4} md={4} lg={3}>
          <Divider style={{ margin: '0px 8px' }} />
        </Grid>
      </Grid>
    </>
  );
};

export default AgencyCheckoutSummaryRow;

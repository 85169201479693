import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { useHistory, useParams } from 'react-router-dom';

import { useIntl } from 'react-intl';
import translation from './../shared/translation';

import { addDays } from 'date-fns';

import { aws, appState, constants, utilities } from '@aim/common';

import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CartIcon from '@material-ui/icons/ShoppingCart';

import {
  MainContainer,
  InnerContainer,
} from './../shared/SponsorPagesContainers';

import { useDataHelper } from '../shared/dataHelper';

import {
  createSponsorListPurchase,
  getSponsorListData,
  updateSponsorListPurchase,
} from '../shared/sponsorListServiceGqlHelper';
import { useBuyOperations } from '../shared/buyOperationsGqlHelper';
import SponsorListCard from './sponsorList/SponsorListCard';
import SponsorListDialog from './sponsorList/SponsorListDialog';

const showLoader = () => appState.isLoader.next(true);
const hideLoader = () => appState.isLoader.next(false);

import {
  AimIconAndTextButton,
  AimTypography,
  AimSnackbarSeverity,
  AimSnackbar,
  AimRichText,
  AimFileList,
  CustomIntl,
} from '@aim/components';

// const showLoader = () => appState.isLoader.next(true);
// const hideLoader = () => appState.isLoader.next(false);

const SponsorList = () => {
  //Hooks
  const history = useHistory();
  const { eventId, sponsorId, service } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorList(intl);
  const buyOperationsHelper = useBuyOperations();

  //State
  const [sponsorListData, setSponsorListData] = useState([]);
  // const [selectedAreas, setSelectedAreas] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen: false });
  const [paymentType, setPaymentType] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [bookingDuration, setBookingDuration] = useState();

  //Effects
  useEffect(() => {
    if (!isDialogOpen) {
      fetchSponsorList();
    }
  }, []);

  // Functions
  const fetchSponsorList = async () => {
    const nextSponsorListData = await getSponsorListData({
      id: sponsorId,
      eventId,
    });

    const purchasedParticipations = nextSponsorListData.purchases.items
      .filter((p) => utilities.isValidPurchase(p))
      .reduce(
        (res, curr) =>
          (res += curr.buyOperations.items
            .filter((bo) => bo.sponsorListPurchase?.feeDateRange)
            .reduce(
              (acc, bo) =>
                (acc += bo.sponsorListPurchase?.purchasedSubscriptions || 0),
              0
            )),
        nextSponsorListData.freeListMembers || 0
      );
    setSponsorListData({ ...nextSponsorListData, purchasedParticipations });
    setBookingDuration(
      nextSponsorListData.event.sponsorServiceBookingDuration || 10
    );

    const paymentType =
      nextSponsorListData.event.sponsorListService.isCreditCardEnabled &&
      nextSponsorListData.event.sponsorListService.isBankTransferEnabled
        ? 'both'
        : nextSponsorListData.event.sponsorListService.isCreditCardEnabled
        ? constants.PaymentTypes.CreditCard
        : nextSponsorListData.event.sponsorListService.isBankTransferEnabled
        ? constants.PaymentTypes.BankTransfer
        : undefined;
    setPaymentType(paymentType);
  };

  const downloadFile = async (item) => {
    const s3Folder = `events/${eventId}/sponsor/${service}/documents/`;
    const link = `${s3Folder}${item.id}${item.extension}`;

    var res = await aws.Storage.get(link);
    window.open(res);
  };

  const addToCart = async (data, purchaseNow = false) => {
    showLoader();
    // add netPrice for cart

    try {
      const responsePromise = await Promise.all(
        data.map(async (item) => {
          const sponsorListPurchase = await createSponsorListPurchase(
            {
              netPrice: utilities.encodeDbNumber(item.price),
              quantity: item.quantity,
              participationMode: item.participationMode,
              sponsorListPurchaseSponsorListId: sponsorListData.id,
              sponsorListPurchaseProfileId: item.profile,
              sponsorListPurchaseFeeDateRangeId: item.feeDateRange,
            },
            false
          );

          const bookingDate = new Date();
          const bookingExpiringDate = addDays(bookingDate, bookingDuration);
          const inputBuyOperation = {
            eventId: eventId,
            buyOperationSponsorListPurchaseId: sponsorListPurchase.id,
            buyOperationSponsorId: sponsorId,
            date: bookingDate,
            expiringDate: bookingExpiringDate,
            paymentType: paymentType,
            isExpired: 'false',
            isDeleted: 'false',
          };

          const response = await buyOperationsHelper.create(
            inputBuyOperation,
            false
          );

          await updateSponsorListPurchase(
            {
              id: sponsorListPurchase.id,
              sponsorListPurchaseBuyOperationId: response.id,
            },
            false
          );

          return response;
        })
      );

      const destUrl = `/events/${eventId}/${sponsorId}/cart`;
      if (purchaseNow) {
        history.push({
          pathname: destUrl,
          state: {
            bookings: responsePromise,
          },
        });
      }
    } catch (e) {
      console.error(e);
      setSnackbar({
        isOpen: true,
        severity: AimSnackbarSeverity.error,
        message: i18n.snackbar.addToCartFail,
      });
    } finally {
      hideLoader();
    }
  };

  const handleClose = () => setIsDialogOpen(false);

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button}
              onClick={() =>
                history.push(`/events/${eventId}/${sponsorId}/my-services`)
              }
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <AimTypography variant={'h2'}>{i18n.page.title}</AimTypography>
          </Grid>
          <Grid item xs={12}>
            {sponsorListData.event?.sponsorListService?.description && (
              <AimRichText
                label={i18n.page.instructions}
                value={JSON.parse(
                  sponsorListData.event.sponsorListService.description
                )}
                readOnly
                editorStyleOverride={{
                  minHeight: 'auto',
                  maxHeight: 'fit-content',
                  overflow: 'hidden !important',
                }}
              />
            )}
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            flexDirection: 'row',
            height: '140px',
            justifyContent: 'space-around',
          }}
        >
          <SponsorListCard
            title={i18n.page.purchasedParticipations}
            style={{ whiteSpace: 'nowrap' }}
          >
            <AimTypography
              variant="h1"
              boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
            >
              {sponsorListData?.purchasedParticipations || 0}
            </AimTypography>
          </SponsorListCard>
          <SponsorListCard title={i18n.page.addSpots}>
            {/* <div
              style={{ width: '50%', display: 'flex', alignItems: 'center' }}
            > */}
            <Tooltip
              title={i18n.page.purchaseButton.tooltip}
              style={{
                minWidth: 170,
                display: 'flex',
                alignSelf: 'center',
                margin: '20px 0px',
              }}
            >
              <AimIconAndTextButton
                // style={{
                //   padding: 2,
                // }}
                variant="yellowFill"
                onClick={() => setIsDialogOpen(true)}
                text={i18n.page.purchaseButton.title}
              >
                <CartIcon />
              </AimIconAndTextButton>
            </Tooltip>
            {/* </div> */}
          </SponsorListCard>
          <SponsorListCard title={i18n.page.deadline}>
            <AimTypography
              variant="h1"
              boxStyle={{ fontWeight: 'normal', textAlign: 'center' }}
            >
              {sponsorListData?.deadlines?.deadline &&
                format(
                  new Date(sponsorListData?.deadlines.deadline),
                  'dd/MM/yyyy'
                )}
            </AimTypography>
          </SponsorListCard>
        </div>

        <SponsorListDialog
          isDialogOpen={isDialogOpen}
          onAgree={(data) => addToCart(data, true)}
          onDisagree={handleClose}
          i18n={i18n}
          intl={intl}
          eventId={eventId}
          sponsorListId={sponsorListData?.id}
        />

        {sponsorListData.event?.sponsorListService?.documents?.items?.length ? (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: 'white',
                maxWidth: '98%',
              }}
            >
              <Grid
                item
                container
                alignItems={'center'}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <Grid
                  item
                  xs={10}
                  style={{
                    paddingLeft: 15,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileName}
                  </AimTypography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    paddingLeft: 60,
                  }}
                >
                  <AimTypography variant={'h3'}>
                    {i18n.page.fileList.fileDimension}
                  </AimTypography>
                </Grid>
              </Grid>
              <AimFileList
                files={sponsorListData.event.sponsorListService.documents.items}
                onDownload={downloadFile}
              />
            </Grid>
          </Grid>
        ) : null}

        <AimSnackbar
          open={snackbar.isOpen}
          onClose={() => setSnackbar({ isOpen: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </AimSnackbar>
      </InnerContainer>
    </MainContainer>
  );
};

export default SponsorList;
